import React from 'react';
import TabContainer from '../TabContainer';
import { dashboardTab } from '../../lib/constants/dashboardTab';

const DashboardTabLayout = ({ children }) => {
  return (
    <>
      <TabContainer tabsData={dashboardTab} maxWidthContainer="xl"/>
      {children}
    </>
  )
}

export default DashboardTabLayout