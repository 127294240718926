import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";

export default function AdminVerticalStepper({ buyerInfo }) {
  const [stepData, setStepData] = React.useState({});
  let timelineChart = [];

  const stateTimelineData = buyerInfo?.sale_transaction_statuses;

  React.useEffect(() => {
    if (stateTimelineData && stateTimelineData?.length > 0) {
      stateTimelineData.map((item) => {
        const url =
          item.status === "Complete"
            ? "/assets/image/icons1.png"
            : item.status === "Pending Validation"
            ? "/assets/image/icons3.png"
            : item.status === "Action Required"
            ? "/assets/image/icons4.png"
            : item.status === "In Progress"
            ? "/assets/image/icons5.png"
            : item.status === "Yet to Start"
            ? "/assets/icons/YetToStart.svg"
            : "/assets/image/icons2.png";

        const iconColor =
          item.status === "Complete"
            ? "#227231"
            : item.status === "Pending Validation"
            ? "#FFA826"
            : item.status === "Action Required"
            ? "#E02B1D"
            : item.status === "In Progress"
            ? "#7B05D8"
            : item.status === "Yet to Start"
            ? "#0671E0"
            : "gray";

        const iconColorBg =
          item.status === "Complete"
            ? "#F1FBF8"
            : item.status === "In Progress"
            ? "#F7EDFF"
            : item.status === "Yet to Start"
            ? "#F5F7FA"
            : item.status === "Pending Validation"
            ? "#FFF7E5"
            : item.status === "Action Required"
            ? "#FFECEB"
            : item.status === "Locked"
            ? "#F5F7FA"
            : "gray";

        timelineChart.push({
          locked: item.locked,
          description: item.process_step,
          status: item.status,
          step: item.step_index,
          iconColor: iconColor,
          url: url,
          iconColorBg: iconColorBg
        });
        setStepData(timelineChart);
      });
    }
  }, [stateTimelineData, buyerInfo]);


  return (
    <Box>
      <Timeline sx={{ display: "flex", alignItems: "start", paddingLeft: "0" }}>
        {stepData.length > 0 &&
          stepData?.map((step, index) => {
            return (
              <TimelineItem
                sx={{
                  ":before": {
                    padding: "0"
                  },

                  height: "99px !important"
                }}
                key={index}
              >
                <TimelineOppositeContent
                  sx={{ width: "80px" }}
                  align="right"
                  variant="body2"
                  color="text.secondary"
                >
                  Step {step.step}
                </TimelineOppositeContent>
                <TimelineSeparator
                  sx={{
                    "& .MuiTimelineConnector-root": {
                      height: "50px  ",
                      width: "3px"
                    }
                  }}
                >
                  <TimelineDot
                    sx={{
                      height: "25px",
                      width: "25px",
                      margin: "auto",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      background:
                        step.locked === true && step.status === "Yet to Start"
                          ? "gray"
                          : step.iconColor
                    }}
                  >
                    {step.locked === true && step.status === "Yet to Start" ? (
                      <img src="/assets/image/icons2.png" alt="imgIcon"/>
                    ) : (
                      <img src={`${step.url}`} alt="imgIcon"/>
                    )}
                  </TimelineDot>
                  <TimelineConnector
                    sx={{
                      margin: "1px 0 0 0",
                      backgroundColor: "#F5F7FA",
                      width: "6px",
                      height: "105px",
                      background: step.textColor
                    }}
                  />
                </TimelineSeparator>
                <TimelineContent sx={{ paddingY: "0px" }}>
                  <Box
                    sx={{
                      minWidth: 399,
                      height: 120,

                      display: "flex",
                      marginLeft: "10px",
                      cursor: "pointer"
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "23px",
                          margin: "0px 0 2px 0"
                        }}
                      >
                        {step.description}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "12px",
                          lineHeight: "15px",
                          padding: "5px",
                          width: "100px",
                          borderRadius: "4px",
                          textAlign: "start"
                        }}
                        color={step.iconColor}
                        bgcolor={step.iconColorBg}
                      >
                        {step.status}
                      </Typography>
                    </Box>
                  </Box>
                </TimelineContent>
              </TimelineItem>
            );
          })}
      </Timeline>
    </Box>
  );
}
