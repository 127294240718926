import {
  Badge,
  Box,
  CircularProgress,
  Grid,
  Tooltip,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  BuyerTable,
  DocumentTable,
  DownloadCardsAdmin,
  DownloadCardsFileAdmin
} from "../lib/query/query";

export default function Downloads() {
  const [downloadData, setDownloadData] = useState({});
  const [downloadDataFile, setDownloadDataFile] = useState({});
  const [isLoadingMap, setIsLoadingMap] = useState({});
  const [isLoadingMapDoc, setIsLoadingMapDoc] = useState({});

  const { salesId } = useParams();

  const { data, loading, error } = useQuery(DownloadCardsAdmin, {
    variables: { sale_transaction_id: salesId }
  });

  const { data: dataFile, error: errorDownload } = useQuery(DownloadCardsFileAdmin, {
    variables: { sale_transaction_id: salesId }
  });

  if(error || errorDownload){
    Sentry.captureException(error || errorDownload);
  }

  const [getDocumentData] = useLazyQuery(DocumentTable);

  const handleDocumentClick = async (documentData) => {
    setIsLoadingMapDoc((prevLoadingMap) => ({
      ...prevLoadingMap,
      [documentData.document_id]: true,
    }));
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await delay(2000);

    setIsLoadingMapDoc((prevLoadingMap) => ({
      ...prevLoadingMap,
      [documentData.document_id]: false,
    }));

    const documentId = documentData?.document_id;
    const response = await getDocumentData({
      variables: { document_id: documentId }
    });

    const fetchedDocumentData = response.data;
    const output =
      fetchedDocumentData &&
      fetchedDocumentData.document &&
      fetchedDocumentData.document[0].document
        .replace(/\\x/g, "")
        .match(/.{2}/g)
        .map((byte) => String.fromCharCode(parseInt(byte, 16)))
        .join("");
    const hexString =
      output &&
      output
        .split(",")
        .map((hex) => parseInt(hex, 16))
        .map((byte) => byte.toString(16).padStart(2, "0"))
        .join("")
        .toUpperCase();
    const byteArray = new Uint8Array(
      hexString && hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );
    const blob = new Blob([byteArray], { type: "application/octet-stream" });
    const fileDownloadUrl = URL.createObjectURL(blob);
    const downloadLink = document.createElement("a");
    downloadLink.href = fileDownloadUrl;
    downloadLink.download = `${documentData?.file_name}.${documentData?.file_extension}`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(fileDownloadUrl);
  };

  const [getDocumentDataDoc] = useLazyQuery(BuyerTable);

  const handleDocumentClickDoc = async (documentData) => {
    setIsLoadingMap((prevLoadingMap) => ({
      ...prevLoadingMap,
      [documentData.buyer_identity_doc_id]: true,
    }));

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await delay(2000);

    setIsLoadingMap((prevLoadingMap) => ({
      ...prevLoadingMap,
      [documentData.buyer_identity_doc_id]: false,
    }));

    const buyer_identity_docid = documentData.buyer_identity_doc_id;
    const response = await getDocumentDataDoc({
      variables: { buyer_identity_doc_id: buyer_identity_docid }
    });

    const fetchedDocumentDataDoc = response.data;

    const output =
      fetchedDocumentDataDoc &&
      fetchedDocumentDataDoc.buyer_identity_doc &&
      fetchedDocumentDataDoc.buyer_identity_doc[0].document
        .replace(/\\x/g, "")
        .match(/.{2}/g)
        .map((byte) => String.fromCharCode(parseInt(byte, 16)))
        .join("");
    const hexString =
      output &&
      output
        .split(",")
        .map((hex) => parseInt(hex, 16))
        .map((byte) => byte.toString(16).padStart(2, "0"))
        .join("")
        .toUpperCase();
    const byteArray = new Uint8Array(
      hexString && hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );
    const blob = new Blob([byteArray], { type: "application/octet-stream" });
    const fileDownloadUrl = URL.createObjectURL(blob);
    const downloadLink = document.createElement("a");
    downloadLink.href = fileDownloadUrl;
    downloadLink.download = `${documentData?.file_name}.${documentData?.file_extension}`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(fileDownloadUrl);
  };

  useEffect(() => {
    if (data?.sale_transaction && data?.sale_transaction?.length > 0) {
      setDownloadData(data.sale_transaction);
    }
  }, [data]);

  useEffect(() => {
    if (dataFile?.sale_transaction && dataFile?.sale_transaction?.length > 0) {
      setDownloadDataFile(dataFile.sale_transaction);
    }
  }, [dataFile]);

  let DocumenstData = [];

  downloadData.length > 0 &&
    downloadData?.forEach((element) => {
      if (element.documents.length > 0) {
        const propertyData = element.property;
        const documentsWithPropertyData = element.documents.map((document) => ({
          ...document,
          property: propertyData
        }));
        DocumenstData.push(...documentsWithPropertyData);
      }
    });

  let DocumenstDataFile = [];

  downloadDataFile?.length > 0 &&
    downloadDataFile?.forEach((element) => {
      if (element?.buyers[0]?.buyer_identity_docs?.length > 0) {
        const propertyData = element.property;
        const buyersWithPropertyData =
          element?.buyers[0]?.buyer_identity_docs.map((document) => ({
            ...document,
            property: propertyData,
            sale_transaction_id: element.sale_transaction_id
          }));
        DocumenstDataFile.push(...buyersWithPropertyData);
      }
    });

  const getMonthName = (month) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    return monthNames[month];
  };
  const getDayWithSuffix = (day) => {
    if (day > 3 && day < 21) return `${day}`;
    switch (day % 10) {
      case 1:
        return `${day}`;
      case 2:
        return `${day}`;
      case 3:
        return `${day}`;
      default:
        return `${day}`;
    }
  };
  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const month = getMonthName(dateObj.getMonth());
    const day = getDayWithSuffix(dateObj.getDate());
    const year = dateObj.getFullYear();
    return `${month} ${day}, ${year}`;
  };

  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh"
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <>
      <Box>
        <Typography
          sx={{
            color: "#212121",
            fontSize: "25px",
            fontWeight: 600,
            marginBottom: "13px"
          }}
        >
          Downloads
        </Typography>
      </Box>
      <Box>
        {!loading && (
          <Box>
            <Box>
              <Grid container spacing={2}>
                {DocumenstData &&
                  DocumenstData?.sort((a, b) => new Date(b.created_date) - new Date(a.created_date)).map((document, index) => {
                    const loadingKey = document.document_id;
                    const isDocLoadingDoc = isLoadingMapDoc[loadingKey] || false;
                    return (
                      <Grid item xs={3} key={index}>
                        <Box
                          sx={{
                            width: "100%",
                            height: 175,
                            boxShadow: "none",
                            border: "1px solid #E8E8EA",
                            borderRadius: "5px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            "&:hover": {
                              border: "1px solid #0671E0"
                            },
                            padding: "10px",
                            cursor: "pointer"
                          }}
                          onClick={() => handleDocumentClick(document)}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              marginBottom: "14px"
                            }}
                          >
                            <Box
                              sx={{
                                marginLeft: "20px",
                                flexGrow: 0
                              }}
                            >
                              <img
                                src="/assets/image/download.png"
                                alt="Document"
                              />
                            </Box>
                            {isDocLoadingDoc && <Box
                              sx={{
                                width: "50% !important",
                                height: "50% !important",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                zIndex: 1000
                              }}
                            >
                              <CircularProgress sx={{
                                width: '20px !important', height: '20px !important'
                              }} />
                            </Box>}
                            <Badge
                              sx={{
                                fontSize: 12,
                                backgroundColor:
                                  document.document_type === "drawing"
                                    ? "#F1FBF8"
                                    : document.document_type === "Agreement"
                                      ? "#FFF8EC"
                                      : document.document_type === "User-Upload"
                                        ? "#FFF1F0"
                                        : document.document_type === "Receipt"
                                          ? "#DBEDFF"
                                          : document.document_type === "Other"
                                            ? "#F7EDFF"
                                            : "",
                                color:
                                  document.document_type === "drawing"
                                    ? "#115B43"
                                    : document.document_type === "Agreement"
                                      ? "#E48900"
                                      : document.document_type === "User-Upload"
                                        ? "#C33025"
                                        : document.document_type === "Receipt"
                                          ? "#0053AD"
                                          : document.document_type === "Other"
                                            ? "#7B05D8"
                                            : "",
                                width: "85px",
                                height: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "4px"
                              }}
                            >
                              {document.document_type}
                            </Badge>
                          </Box>
                          <Box sx={{ padding: "0 20px" }}>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                              sx={{
                                fontSize: "18px",
                                fontWeight: 600,
                                lineHeight: "23px",
                                color: "#212121",
                                width: "254px",
                                wordWrap: "break-word",
                                display: "inline-block",
                                whiteSpace: "nowrap",
                                overflow: "hidden !important",
                                textOverflow: "ellipsis"
                              }}
                            >
                              {document.document_type === "receipt"
                                ? `${document.document_type} #${document.file_name}`
                                : `${document.file_name}`}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "12px",
                                fontWeight: 400,
                                lineHeight: "20px",
                                color: "#89939E"
                              }}
                            >
                              {formatDate(document.created_date)}
                            </Typography>
                          </Box>
                          <Tooltip
                            title={
                              document.property === null ||
                                document.property === undefined
                                ? ""
                                : `${document?.property?.project_building?.tower_name}
                                    ${document?.property?.unit_number},
                                    ${document?.property?.project?.project_name},
                                    ${document?.property?.project?.city}`
                            }
                            arrow
                          >
                            <Box sx={{ padding: "0 20px" }}>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  color: "#4D4D4D",
                                  display: "inline-block",
                                  width: 280,
                                  whiteSpace: "nowrap",
                                  overflow: " hidden !important",
                                  textOverflow: "ellipsis"
                                }}
                              >
                                {document.property === null
                                  ? "-"
                                  : `${document?.property?.project_building?.tower_name}
                                        ${document?.property?.unit_number},
                                        ${document?.property?.project?.project_name},
                                        ${document?.property?.project?.city}`}
                              </Typography>
                            </Box>
                          </Tooltip>
                        </Box>
                      </Grid>
                    );
                  })}
                {DocumenstDataFile &&
                  DocumenstDataFile?.sort((a, b) => new Date(b.created_date) - new Date(a.created_date)).map((documentData, index) => {
                    const loadingKey = documentData.buyer_identity_doc_id;
                    const isDocLoading = isLoadingMap[loadingKey] || false;
                    return (
                      <Grid item xs={3} key={index}>
                        <Box
                          sx={{
                            width: "100%",
                            height: 175,
                            boxShadow: "none",
                            border: "1px solid #E8E8EA",
                            borderRadius: "5px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            "&:hover": {
                              border: "1px solid #0671E0"
                            },
                            padding: "10px",
                            cursor: "pointer"
                          }}
                          onClick={() => handleDocumentClickDoc(documentData)}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              marginBottom: "14px"
                            }}
                          >
                            <Box
                              sx={{
                                marginLeft: "20px",
                                flexGrow: 0
                              }}
                            >
                              <img
                                src="/assets/image/download.png"
                                alt="Document"
                              />
                            </Box>
                            {isDocLoading && <Box
                              sx={{

                                width: "50% !important",
                                height: "50% !important",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                zIndex: 1000
                              }}
                            >
                              <CircularProgress sx={{
                                width: '20px !important', height: '20px !important'
                              }} />
                            </Box>}
                            <Badge
                              sx={{
                                fontSize: 12,
                                backgroundColor:
                                  documentData.document_type === "Passport"
                                    ? "#F1FBF8"
                                    : documentData.document_type ===
                                      "Abhyasi ID"
                                      ? "#FFF8EC"
                                      : documentData.document_type ===
                                        "Aadhaar Card"
                                        ? "#FFF1F0"
                                        : documentData.document_type === "Photo"
                                          ? "#DBEDFF"
                                          : documentData.document_type ===
                                            "PAN Card (optional)"
                                            ? "#F7EDFF"
                                            : "#F7EDFF",
                                color:
                                  documentData.document_type === "Passport"
                                    ? "#115B43"
                                    : documentData.document_type ===
                                      "Abhyasi ID"
                                      ? "#E48900"
                                      : documentData.document_type ===
                                        "Aadhaar Card"
                                        ? "#C33025"
                                        : documentData.document_type === "Photo"
                                          ? "#0053AD"
                                          : documentData.document_type ===
                                            "PAN Card (optional)"
                                            ? "#7B05D8"
                                            : "#7B05D8",
                                width: "85px",
                                height:
                                  documentData.document_type ===
                                    "PAN Card (optional)" ||
                                    documentData.document_type ===
                                    "Aadhaar (optional)"
                                    ? "40px"
                                    : "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "4px",
                                paddingLeft:
                                  documentData.document_type ===
                                    "PAN Card (optional)" ||
                                    documentData.document_type ===
                                    "Aadhaar (optional)"
                                    ? "13px"
                                    : "0px"
                              }}
                            >
                              {documentData.document_type}
                            </Badge>
                          </Box>
                          <Box sx={{ padding: "0 20px" }}>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                              sx={{
                                fontSize: "18px",
                                fontWeight: 600,
                                lineHeight: "23px",
                                color: "#212121",
                                width: "254px",
                                wordWrap: "break-word",
                                display: "inline-block",
                                whiteSpace: "nowrap",
                                overflow: "hidden !important",
                                textOverflow: "ellipsis"
                              }}
                            >
                              {documentData.file_name}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "12px",
                                fontWeight: 400,
                                lineHeight: "20px",
                                color: "#89939E"
                              }}
                            >
                              {formatDate(documentData.created_date)}
                            </Typography>
                          </Box>
                          <Tooltip
                            title={
                              documentData.property === null
                                ? ""
                                : `${documentData?.property?.project_building?.tower_name}
                                        ${documentData?.property?.unit_number},
                                        ${documentData?.property?.project?.project_name},
                                        ${documentData?.property?.project?.city}`
                            }
                            arrow
                          >
                            <Box sx={{ padding: "0 20px" }}>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  color: "#4D4D4D",
                                  display: "inline-block",
                                  width: 280,
                                  whiteSpace: "nowrap",
                                  overflow: " hidden !important",
                                  textOverflow: "ellipsis"
                                }}
                              >
                                {documentData.property === null
                                  ? "-"
                                  : `${documentData?.property?.project_building?.tower_name}
                                        ${documentData?.property?.unit_number},
                                        ${documentData?.property?.project?.project_name},
                                        ${documentData?.property?.project?.city}`}
                              </Typography>
                            </Box>
                          </Tooltip>
                        </Box>
                      </Grid>
                    );
                  })}
              </Grid>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}
