import { createSlice } from "@reduxjs/toolkit";
import * as AuthService from "./auth-service";

const initialState = {
  isTokenValid: true,
};

const slice = createSlice({
  name: "activeRules",
  initialState,
  reducers: {
    setIsTokenValid: (store, action) => {
      const isValid = action.payload;

      if (!isValid) {
        localStorage.removeItem("cognitoAuthDetails");
      }

      store.isTokenValid = action.payload;
    },
  },
});

export const listToken = (tokenData) => async (dispatch, store) => {
  localStorage.setItem("cognitoAuthDetails", JSON.stringify(tokenData));
  const loginTime = new Date().getTime();
  localStorage.setItem('loginTime', loginTime);
  dispatch(setIsTokenValid(true));
};

export const { reducer } = slice;
export const { setIsTokenValid } = slice.actions;
