import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import TableData from "../../../../components/TableData";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  inventoryCity,
  inventoryCityEachRow,
  inventoryCityList,
  inventoryCityTotal,
  inventoryEachRows,
  inventoryProject,
  inventoryProjectEachRow,
  inventoryProjectList,
  inventoryProjectTotal,
  inventoryTotal,
  inventoryTower,
  inventoryTowerEachRow,
  inventoryTowerList,
  inventoryTowerTotal,
  inventoryUniqueList
} from "../../../../lib/query/query";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import ErrorToast from "../../../../components/ErrorToast";
import moment from "moment/moment";
import * as Sentry from "@sentry/react";

export const Inventory = () => {
  const [city, setCity] = useState("");
  const [project, setProject] = useState("");
  const [tower, setTower] = useState("");
  const [cityData, setCityData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [projectID, setProjectID] = useState("");
  const [projectName, setProjectName] = useState("");
  const [towerName, setTowerName] = useState("");
  const [towerData, setTowerData] = useState([]);
  const [inventorySet, setInventorySet] = useState([]);
  const [inventoryCitySet, setInventoryCitySet] = useState([]);
  const [inventoryProjectSet, setInventoryProjectSet] = useState([]);
  const [inventoryTowerSet, setInventoryTowerSet] = useState([]);

  const { data, error } = useQuery(inventoryCity);
  const { data: inventoryData, errorInventory } = useQuery(inventoryUniqueList);
  const { data: invTotal, errorInvTotal } = useQuery(inventoryTotal);

  const [getInventoryEachRows, { error: errorInvEachRows }] =
    useLazyQuery(inventoryEachRows);

  const [getInventoryProject, { data: dataProject, error: errorDataProject }] =
    useLazyQuery(inventoryProject, {
      variables: { city: city }
    });

  const [getInventoryTower, { data: dataTower, error: errorDataTower }] =
    useLazyQuery(inventoryTower, {
      variables: { project_id: projectID }
    });

  const [getInventoryCityList, { data: dataCityList, error: errorCityList }] =
    useLazyQuery(inventoryCityList, {
      variables: { city: city }
    });

  const [getInventoryCityEachRow, { error: errorInvet }] =
    useLazyQuery(inventoryCityEachRow);

  const [
    getInventoryCityTotal,
    { data: dataCityTotal, error: errorcityTotal }
  ] = useLazyQuery(inventoryCityTotal, {
    variables: { city: city }
  });

  const [
    getInventoryProjectList,
    {
      data: dataProjectList,
      refetch: refetchProjectList,
      error: errorProjectList
    }
  ] = useLazyQuery(inventoryProjectList, {
    variables: { city: city, project_name: projectName }
  });

  const [
    getInventoryProjectTotal,
    {
      data: dataProjectTotal,
      refetch: refetchProjectTotal,
      error: errorProjectTotal
    }
  ] = useLazyQuery(inventoryProjectTotal, {
    variables: { city: city, project_name: projectName }
  });

  const [getInventoryProjectEachRow, { error: errorProjectEachRow }] =
    useLazyQuery(inventoryProjectEachRow);

  const [
    getInventoryTowerList,
    { data: dataTowerList, refetch: refetchTowerList, error: errorTowerList }
  ] = useLazyQuery(inventoryTowerList, {
    variables: {
      city: city,
      project_name: projectName,
      tower_name: towerName
    }
  });

  const [
    getInventoryTowerTotal,
    { data: dataTowerTotal, refetch: refetchTowerTotal, error: errorTowerTotal }
  ] = useLazyQuery(inventoryTowerTotal, {
    variables: {
      city: city,
      project_name: projectName,
      tower_name: towerName
    }
  });

  const [getInventoryTowerEachRow, { error: errorTowerEachRow }] = useLazyQuery(
    inventoryTowerEachRow
  );

  useEffect(() => {
    if (inventoryData?.v_inventory.length > 0) {
      const modalIdToFetch = inventoryData.v_inventory.map(
        async (item) =>
          await getInventoryEachRows({
            variables: { model_id: item?.model_id }
          })
      );
      Promise.all(modalIdToFetch)
        .then((results) => {
          setInventorySet(results);
        })
        .catch((err) => {
          Sentry.captureException(err);
          console.error("Error while fetching data: ", err);
        });
    }
  }, [inventoryData, getInventoryEachRows]);

  let inventorySetData = [];
  inventorySet.map((item) => {
    inventorySetData.push(item.data);
  });

  useEffect(() => {
    if (dataCityList?.v_inventory.length > 0) {
      const modalIdToFetch =
        dataCityList &&
        dataCityList.v_inventory.map(
          async (item) =>
            await getInventoryCityEachRow({
              variables: { model_id: item?.model_id, city: city }
            })
        );
      Promise.all(modalIdToFetch)
        .then((results) => {
          setInventoryCitySet(results);
        })
        .catch((err) => {
          Sentry.captureException(err);
          console.error("Error while fetching data: ", err);
        });
    }
  }, [dataCityList, getInventoryCityEachRow]);

  let inventoryCityData = [];
  inventoryCitySet.map((item) => {
    inventoryCityData.push(item.data);
  });

  useEffect(() => {
    if (dataProjectList?.v_inventory.length > 0) {
      const modalIdToFetch = dataProjectList.v_inventory.map(
        async (item) =>
          await getInventoryProjectEachRow({
            variables: {
              model_id: item?.model_id,
              city: city,
              project_name: projectName
            }
          })
      );
      Promise.all(modalIdToFetch)
        .then((results) => {
          setInventoryProjectSet(results);
        })
        .catch((err) => {
          Sentry.captureException(err);
          console.error("Error while fetching data: ", err);
        });
    }
  }, [dataProjectList, getInventoryProjectEachRow]);

  let inventoryProjectData = [];
  inventoryProjectSet.map((item) => {
    inventoryProjectData.push(item.data);
  });

  useEffect(() => {
    if (dataTowerList?.v_inventory.length > 0) {
      const modalIdToFetch = dataTowerList.v_inventory.map(
        async (item) =>
          await getInventoryTowerEachRow({
            variables: {
              model_id: item?.model_id,
              city: city,
              project_name: projectName,
              tower_name: towerName
            }
          })
      );
      Promise.all(modalIdToFetch)
        .then((results) => {
          setInventoryTowerSet(results);
        })
        .catch((err) => {
          Sentry.captureException(err);
          console.error("Error while fetching data: ", err);
        });
    }
  }, [dataTowerList, getInventoryTowerEachRow]);

  let inventoryTowerData = [];
  inventoryTowerSet.map((item) => {
    inventoryTowerData.push(item.data);
  });

  useEffect(() => {
    if (city) {
      getInventoryCityList();
      getInventoryCityTotal();
    }
  }, [city]);

  useEffect(() => {
    if (city && project) {
      getInventoryProjectList();
      getInventoryProjectTotal();
    }
  }, [city, project]);

  useEffect(() => {
    if (city && project && tower) {
      getInventoryTowerList();
      getInventoryTowerTotal();
    }
  }, [city, project, tower]);

  useEffect(() => {
    if (data && data.project.length > 0) {
      setCityData(data && data.project);
    }
  }, [data]);

  useEffect(() => {
    if (city) {
      getInventoryProject();
    }
  }, [city]);

  useEffect(() => {
    if (dataProject && dataProject.project.length > 0) {
      setProjectData(dataProject.project);
    }
  }, [dataProject]);

  useEffect(() => {
    if (projectID) {
      getInventoryTower();
    }
  }, [projectID]);

  useEffect(() => {
    if (dataTower && dataTower.project_building.length > 0) {
      setTowerData(dataTower.project_building);
    }
  }, [dataTower]);

  useEffect(() => {
    if (projectName) {
      refetchProjectList();
      refetchProjectTotal();
    }
  }, [projectName]);

  useEffect(() => {
    if (towerName) {
      refetchTowerList();
      refetchTowerTotal();
    }
  }, [towerName]);

  const columns = [
    {
      field: "property",
      headerName: "Property",
      width: 240,
      align: "center",
      cellClassName: (params) =>
        params.row.property === "Total" ? "total-cell" : ""
    },
    {
      field: "inventory",
      headerName: "Inventory",
      width: 220,
      align: "center",
      cellClassName: (params) =>
        params.row.property === "Total" ? "total-cell" : ""
    },
    {
      field: "available",
      headerName: "Available",
      width: 240,
      align: "center",
      cellClassName: (params) =>
        params.row.property === "Total" ? "total-cell" : ""
    },
    {
      field: "booked",
      headerName: "Sold/Booked",
      width: 230,
      align: "center",
      cellClassName: (params) =>
        params.row.property === "Total" ? "total-cell" : ""
    },
    {
      field: "fullyPaid",
      headerName: "Fully Paid",
      width: 250,
      align: "center",
      cellClassName: (params) =>
        params.row.property === "Total" ? "total-cell" : ""
    },
    {
      field: "partiallyPaid",
      headerName: "Partially Paid",
      width: 256,
      align: "center",
      cellClassName: (params) =>
        params.row.property === "Total" ? "total-cell" : ""
    }
  ];

  let rows = [];
  if (inventoryData?.v_inventory.length > 0) {
    inventoryData?.v_inventory.map((item, index) => {
      const obj = {
        id: index + 1,
        model_id: item?.model_id,
        property: item.model_name,
        inventory:
          inventorySetData[index]?.v_inventory_aggregate?.aggregate?.sum?.total,
        available:
          inventorySetData[index]?.v_inventory_aggregate?.aggregate?.sum
            ?.available,
        booked:
          inventorySetData[index]?.v_inventory_aggregate?.aggregate?.sum
            ?.booked,
        fullyPaid:
          inventorySetData[index]?.v_inventory_aggregate?.aggregate?.sum
            ?.fully_paid,
        partiallyPaid:
          inventorySetData[index]?.v_inventory_aggregate?.aggregate?.sum
            ?.partial_paid
      };
      rows.push(obj);
    });
  }

  let rowsCity = [];
  if (dataCityList?.v_inventory.length > 0) {
    dataCityList?.v_inventory.map((item, index) => {
      const obj = {
        id: index + 1,
        model_id: item?.model_id,
        property: item.model_name,
        inventory:
          inventoryCityData[index]?.v_inventory_aggregate?.aggregate?.sum
            ?.total,
        available:
          inventoryCityData[index]?.v_inventory_aggregate?.aggregate?.sum
            ?.available,
        booked:
          inventoryCityData[index]?.v_inventory_aggregate?.aggregate?.sum
            ?.booked,
        fullyPaid:
          inventoryCityData[index]?.v_inventory_aggregate?.aggregate?.sum
            ?.fully_paid,
        partiallyPaid:
          inventoryCityData[index]?.v_inventory_aggregate?.aggregate?.sum
            ?.partial_paid
      };
      rowsCity.push(obj);
    });
  }

  let rowsProject = [];

  if (dataProjectList?.v_inventory.length > 0) {
    dataProjectList?.v_inventory.map((item, index) => {
      const obj = {
        id: index + 1,
        model_id: item?.model_id,
        property: item.model_name,
        inventory:
          inventoryProjectData[index]?.v_inventory_aggregate?.aggregate?.sum
            ?.total,
        available:
          inventoryProjectData[index]?.v_inventory_aggregate?.aggregate?.sum
            ?.available,
        booked:
          inventoryProjectData[index]?.v_inventory_aggregate?.aggregate?.sum
            ?.booked,
        fullyPaid:
          inventoryProjectData[index]?.v_inventory_aggregate?.aggregate?.sum
            ?.fully_paid,
        partiallyPaid:
          inventoryProjectData[index]?.v_inventory_aggregate?.aggregate?.sum
            ?.partial_paid
      };
      rowsProject.push(obj);
    });
  }

  let rowsTower = [];

  if (dataTowerList?.v_inventory.length > 0) {
    dataTowerList?.v_inventory.map((item, index) => {
      const obj = {
        id: index + 1,
        model_id: item?.model_id,
        property: item.model_name,
        inventory:
          inventoryTowerData[index]?.v_inventory_aggregate?.aggregate?.sum
            ?.total,
        available:
          inventoryTowerData[index]?.v_inventory_aggregate?.aggregate?.sum
            ?.available,
        booked:
          inventoryTowerData[index]?.v_inventory_aggregate?.aggregate?.sum
            ?.booked,
        fullyPaid:
          inventoryTowerData[index]?.v_inventory_aggregate?.aggregate?.sum
            ?.fully_paid,
        partiallyPaid:
          inventoryTowerData[index]?.v_inventory_aggregate?.aggregate?.sum
            ?.partial_paid
      };
      rowsTower.push(obj);
    });
  }
  let sample = {
    id: 1212,
    property: "Total",
    inventory: invTotal?.v_inventory_aggregate?.aggregate?.sum?.total,
    available: invTotal?.v_inventory_aggregate?.aggregate?.sum?.available,
    booked: invTotal?.v_inventory_aggregate?.aggregate?.sum?.booked,
    fullyPaid: invTotal?.v_inventory_aggregate?.aggregate?.sum?.fully_paid,
    partiallyPaid: invTotal?.v_inventory_aggregate?.aggregate?.sum?.partial_paid
  };

  let sampleCity = {
    id: 12121,
    property: "Total",
    inventory: dataCityTotal?.v_inventory_aggregate?.aggregate?.sum?.total,
    available: dataCityTotal?.v_inventory_aggregate?.aggregate?.sum?.available,
    booked: dataCityTotal?.v_inventory_aggregate?.aggregate?.sum?.booked,
    fullyPaid: dataCityTotal?.v_inventory_aggregate?.aggregate?.sum?.fully_paid,
    partiallyPaid:
      dataCityTotal?.v_inventory_aggregate?.aggregate?.sum?.partial_paid
  };

  let sampleProject = {
    id: 2323,
    property: "Total",
    inventory: dataProjectTotal?.v_inventory_aggregate?.aggregate?.sum?.total,
    available:
      dataProjectTotal?.v_inventory_aggregate?.aggregate?.sum?.available,
    booked: dataProjectTotal?.v_inventory_aggregate?.aggregate?.sum?.booked,
    fullyPaid:
      dataProjectTotal?.v_inventory_aggregate?.aggregate?.sum?.fully_paid,
    partiallyPaid:
      dataProjectTotal?.v_inventory_aggregate?.aggregate?.sum?.partial_paid
  };

  let sampleTower = {
    id: 32324,
    property: "Total",
    inventory: dataTowerTotal?.v_inventory_aggregate?.aggregate?.sum?.total,
    available: dataTowerTotal?.v_inventory_aggregate?.aggregate?.sum?.available,
    booked: dataTowerTotal?.v_inventory_aggregate?.aggregate?.sum?.booked,
    fullyPaid:
      dataTowerTotal?.v_inventory_aggregate?.aggregate?.sum?.fully_paid,
    partiallyPaid:
      dataTowerTotal?.v_inventory_aggregate?.aggregate?.sum?.partial_paid
  };

  const handleChange = (event) => {
    setCity(event.target.value);
  };

  const handleChangeProject = (event) => {
    setProject(event.target.value);
    setProjectID(event.target.value);
    const selectedProjectId = event.target.value;
    const selectedProject = projectData.find(
      (item) => item.project_id === selectedProjectId
    );
    setProjectName(selectedProject.project_name);
  };

  const handleChangeTower = (event) => {
    setTower(event.target.value);
    const selectedTowerId = event.target.value;
    const selectedTower = towerData.find(
      (item) => item.building_id === selectedTowerId
    );
    setTowerName(selectedTower.tower_name);
  };

  const date = {
    id: "Date:",
    property: new Date()
  };

  const title = {
    id: "Title:",
    property: "Inventory"
  };

  const time = {
    id: "Time:",
    property: moment().format("hh:mm a")
  };

  let selectedCity = {
    id: "City:",
    property: city === "" ? "N/A" : city
  };

  let selectedProject = {
    id: "Project:",
    property: projectName === "" ? "N/A" : projectName
  };

  let selectedTower = {
    id: "Tower:",
    property: towerName === "" ? "N/A" : towerName
  };

  const generateExcelRow = (
    sample,
    rows,
    selectedCity,
    selectedProject,
    selectedTower
  ) => {
    return [
      title,
      date,
      time,
      selectedCity,
      selectedProject,
      selectedTower,
      "",
      sample,
      ...rows
    ];
  };

  const downloadExcel = () => {
    const sheet = XLSX.utils.json_to_sheet(
      (city
        ? project
          ? tower
            ? rowsTower &&
              generateExcelRow(
                sampleTower,
                rowsTower,
                selectedCity,
                selectedProject,
                selectedTower
              )
            : rowsProject &&
              generateExcelRow(
                sampleProject,
                rowsProject,
                selectedCity,
                selectedProject,
                selectedTower
              )
          : rowsCity &&
            generateExcelRow(
              sampleCity,
              rowsCity,
              selectedCity,
              selectedProject,
              selectedTower
            )
        : rows &&
          generateExcelRow(
            sample,
            rows,
            selectedCity,
            selectedProject,
            selectedTower
          )) || [],
      { skipHeader: true }
    );

    let Heading = [
      [
        "Id",
        "Property",
        "Inventory",
        "Available",
        "Booked",
        "Fully Paid",
        "Partially Paid",
        "Model Id"
      ]
    ];

    const wb = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(sheet, Heading, { origin: "A7" });
    XLSX.utils.book_append_sheet(wb, sheet, "Sheet1");
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array"
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    });
    saveAs(blob, "inventory.xlsx");
  };

  if (
    error ||
    errorInventory ||
    errorInvTotal ||
    errorInvEachRows ||
    errorDataProject ||
    errorDataTower ||
    errorCityList ||
    errorInvet ||
    errorcityTotal ||
    errorProjectList ||
    errorProjectTotal ||
    errorProjectEachRow ||
    errorTowerList ||
    errorTowerTotal ||
    errorTowerEachRow
  ) {
    toast.error(
      <ErrorToast
        email={"info@msvkanha.com"}
        errorMsg={
          (error && error?.message) ||
          (errorInventory && errorInventory?.message) ||
          (errorInvTotal && errorInvTotal?.message) ||
          (errorInvEachRows && errorInvEachRows?.message) ||
          (errorDataProject && errorDataProject?.message) ||
          (errorDataTower && errorDataTower?.message) ||
          (errorCityList && errorCityList?.message) ||
          (errorInvet && errorInvet?.message) ||
          (errorcityTotal && errorcityTotal?.message) ||
          (errorProjectList && errorProjectList?.message) ||
          (errorProjectTotal && errorProjectTotal?.message) ||
          (errorProjectEachRow && errorProjectEachRow?.message) ||
          (errorTowerList && errorTowerList?.message) ||
          (errorTowerTotal && errorTowerTotal?.message) ||
          (errorTowerEachRow && errorTowerEachRow?.message)
        }
      />
    );
    Sentry.captureException(
      (error && error?.message) ||
        (errorInventory && errorInventory?.message) ||
        (errorInvTotal && errorInvTotal?.message) ||
        (errorInvEachRows && errorInvEachRows?.message) ||
        (errorDataProject && errorDataProject?.message) ||
        (errorDataTower && errorDataTower?.message) ||
        (errorCityList && errorCityList?.message) ||
        (errorInvet && errorInvet?.message) ||
        (errorcityTotal && errorcityTotal?.message) ||
        (errorProjectList && errorProjectList?.message) ||
        (errorProjectTotal && errorProjectTotal?.message) ||
        (errorProjectEachRow && errorProjectEachRow?.message) ||
        (errorTowerList && errorTowerList?.message) ||
        (errorTowerTotal && errorTowerTotal?.message) ||
        (errorTowerEachRow && errorTowerEachRow?.message)
    );
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6} md={9}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <Box>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    value={city}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{ color: "inherit" }}
                  >
                    <MenuItem selected disabled value="">
                      Select City
                    </MenuItem>
                    {cityData.length > 0 &&
                      cityData.map((item, index) => {
                        return (
                          <MenuItem value={item.city} key={index}>
                            {item.city}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    value={project}
                    onChange={handleChangeProject}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{ color: "inherit" }}
                  >
                    <MenuItem selected disabled value="">
                      Select Project
                    </MenuItem>

                    {projectData.length > 0 &&
                      projectData.map((item) => {
                        return (
                          <MenuItem value={item.project_id}>
                            {item.project_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    value={tower}
                    onChange={handleChangeTower}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{ color: "inherit" }}
                  >
                    <MenuItem selected disabled value="">
                      Select Tower
                    </MenuItem>
                    {towerData.length > 0 &&
                      towerData.map((item) => {
                        return (
                          <MenuItem value={item.building_id}>
                            {item.tower_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} md={3}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#0671E0",
                    color: "white",
                    fontWeight: 400,
                    lineHeight: "24px",
                    textTransform: "capitalize",
                    fontSize: "18px",
                    width: "164px",
                    height: "56px",
                    padding: "16px, 32px, 16px, 32px",
                    gap: "8px",
                    borderRadius: "4px",
                    "&:hover": {
                      backgrindColor: "#0671E0"
                    }
                  }}
                >
                  Search
                  <img src="/assets/image/Search.png" alt="SearchIcon" />
                </Button>
              </Box>
            </Grid>
            <Grid item xs={6} md={6}>
              <Box>
                <Button
                  variant="outlined"
                  sx={{
                    backgroundColor: "white",
                    color: "#0671E0",
                    fontWeight: 400,
                    lineHeight: "24px",
                    textTransform: "capitalize",
                    fontSize: "16px",
                    "&:hover": {
                      backgrindColor: "white"
                    },
                    padding: "16px, 32px, 16px, 32px",
                    width: "151px",
                    height: "56px",
                    gap: "10px",
                    border: "1px solid #0671E0",
                    textAlign: "Center"
                  }}
                  onClick={downloadExcel}
                >
                  Download XLS
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box sx={{ display: "flex", justifyContent: "space-between" }}></Box>
      <Box sx={{ marginTop: "30px" }}>
        <TableData
          rows={
            city
              ? project
                ? tower
                  ? rowsTower && [sampleTower, ...rowsTower]
                  : rowsProject && [sampleProject, ...rowsProject]
                : rowsCity && [sampleCity, ...rowsCity]
              : rows && [sample, ...rows]
          }
          columns={columns}
          HeaderbackgroundColor={"#F5F7FA"}
          tableHeaderAlign={"center !important"}
        />
      </Box>
    </Box>
  );
};
