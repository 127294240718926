import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormGroup,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import { Controller } from "react-hook-form";
import { CustomFormLabel, CustomTextField } from "./FamilyMembers";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import styled from "@emotion/styled";
import ModalCommon from "../../../components/ModalCommon";
import Steppercontext from "../../../lib/context/contextData";

const FamilyMemberForm = ({
  member,
  index,
  isLoading,
  control,
  errors,
  register,
  handleSubmit,
  onSubmit,
  formData,
  handleDelete,
  editMode,
  handleEditClick,
  data,
  isClickArray,
  setValues,
  salesId
}) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setValues("familyMembers", data);
  }, [data, setValues]);

  useEffect(() => {
    setValues(
      `familyMembers[${index}].id_type`,
      formData?.id_type || "Passport"
    );
      setValues(
      `familyMembers[${index}].relationship_buyer`,
      formData?.relationship_buyer || ""
    );
    setValues(
      `familyMembers[${index}].age`,
      formData?.age || ""
    );
  }, [formData, setValues]);

  const Header = () => {
    return (
      <Box>
        <Typography
          sx={{
            lineHeight: "28px",
            fontSize: "18px",
            fontWeight: "700",
            color: "#4D4D4D"
          }}
        >
          Are you sure you want to delete the selected legal-heir?
        </Typography>
      </Box>
    );
  };
  const Body = () => {
    return (
      <Box>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            sx={{
              color: "#fff",
              fontSize: "14px",
              fontWeight: "400",
              marginRight: "10px"
            }}
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            sx={{
              color: "#fff",
              fontSize: "14px",
              fontWeight: "400",
              backgroundColor: "#E02B1D"
            }}
            onClick={() => {
              handleDelete(index);
              setOpen(false);
            }}
          >
            Delete
          </Button>
        </Box>
      </Box>
    );
  };

  const { setSalesId, events } = useContext(Steppercontext);

  useEffect(() => {
    setSalesId(salesId);
  }, [salesId]);

  const params =
    events &&
    events.sale_transaction &&
    events.sale_transaction[0]?.sale_transaction_statuses;
  const sortedParams = params
    ?.slice()
    .sort((a, b) => a.step_index - b.step_index);
  const lockedValue = sortedParams && sortedParams[2]?.locked;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };

  return (
    <Box
      sx={{
        border: "1px solid #E8E8EA",
        borderRadius: "10px",
        marginTop: "40px"
      }}
    >
      <Box
        padding={"15px 30px"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        borderBottom={"1px solid #E8E8EA"}
      >
        <Typography
          sx={{
            lineHeight: "28px",
            fontSize: "18px",
            fontWeight: 600,
            color: "#212121"
          }}
        >
          Legal Heir
        </Typography>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Button
            sx={{
              lineHeight: "20px",
              fontSize: "16px",
              fontWeight: 400,

              display: "flex",
              alignItems: "center",
              marginRight: "30px",
              textTransform: "capitalize"
            }}
            onClick={() => {
              if (lockedValue !== true) {
                handleEditClick(index);
              }
            }}
          >
            <Box
              component="span"
              color={isClickArray ? "#0671E0" : "#717171"}
              marginRight={"10px"}
            >
              Edit
            </Box>
            <DriveFileRenameOutlineOutlinedIcon
              sx={{ fill: isClickArray ? "#0671E0" : "#717171" }}
            />
          </Button>

          <Button
            sx={{
              lineHeight: "20px",
              fontSize: "16px",
              fontWeight: 400,
              color: "#717171",
              display: "flex",
              alignItems: "center",
              textTransform: "capitalize"
            }}
            onClick={() => {
              if (lockedValue !== true) {
                setOpen(true);
              }
            }}
          >
            <Box marginRight={"10px"}>Delete</Box>
            <DeleteOutlinedIcon sx={{ fill: "#717171" }} />
          </Button>
        </Box>
      </Box>
      <ModalCommon
        open={open}
        setOpen={setOpen}
        header={<Header />}
        body={<Body />}
        width={551}
      />

      <Box padding={"40px 30px 30px"}>
        <form id={`familyMember-${index}`}>
          <Grid
            container
            spacing={2}
            marginBottom={"30px"}
            sx={{
              opacity: isClickArray ? 0.5 : 1
            }}
          >
            <Grid item xs={12} md={6}>
              <FormGroup>
                <CustomFormLabel component="legend" required>
                  Full Name
                </CustomFormLabel>
                <CustomTextField
                  {...register(`familyMembers[${index}].full_name`, {
                    required: "This field is required."
                  })}
                  defaultValue={formData?.full_name && formData.full_name}
                  id={`full_name_${index}`}
                  variant="outlined"
                  autoComplete="off"
                  error={errors?.familyMembers?.[index]?.full_name}
                  helperText={
                    errors?.familyMembers?.[index]?.full_name &&
                    errors.familyMembers[index].full_name.message
                  }
                  InputProps={{
                    readOnly:
                      (formData?.full_name &&
                        formData.full_name &&
                        !editMode) ||
                      lockedValue === true
                        ? true
                        : false
                  }}
                  autoFocus
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormGroup>
                <CustomFormLabel component="legend" required>
                  Relationship with the Buyer
                </CustomFormLabel>
                <Controller
                  name={`familyMembers[${index}].relationship_buyer`}
                  control={control}
                  rules={{ required: "This field is required." }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error }
                  }) => (
                    <>
                      <Select
                        defaultValue={
                          formData?.relationship_buyer
                            ? formData?.relationship_buyer
                            : value
                        }
                        value={value ? value : formData?.relationship_buyer}
                        onChange={onChange}
                        error={error}
                        inputProps={{
                          readOnly:
                            (formData?.relationship_buyer && !editMode) ||
                            lockedValue === true
                              ? true
                              : false,
                          "aria-label": "Without label"
                        }}
                      >
                        <MenuItem value={"Wife"}>Wife</MenuItem>
                        <MenuItem value={"Husband"}>Husband</MenuItem>
                        <MenuItem value={"Son"}>Son</MenuItem>
                        <MenuItem value={"Daughter"}>Daughter</MenuItem>
                        <MenuItem value={"Father"}>Father</MenuItem>
                        <MenuItem value={"Mother"}>Mother</MenuItem>
                        <MenuItem value={"Other"}>Other</MenuItem>
                      </Select>
                      {errors.familyMembers?.[index]?.relationship_buyer
                        ?.message && (
                        <FormHelperText
                          sx={{
                            color: "#d32f2f",
                            fontSize: "12px",
                            marginLeft: "14px"
                          }}
                        >
                          {
                            errors.familyMembers?.[index]?.relationship_buyer
                              ?.message
                          }
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12} md={2}>
              <FormGroup>
                <CustomFormLabel component="legend" required>
                  Age
                </CustomFormLabel>
                <Controller
                  name={`familyMembers[${index}].age`}
                  control={control}
                  rules={{ required: "This field is required." }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error }
                  }) => (
                    <>
                      <Select
                        defaultValue={formData?.age ? formData?.age : value}
                        value={value ? value : formData?.age}
                        onChange={onChange}
                        error={error}
                        MenuProps={MenuProps}
                        inputProps={{
                          readOnly:
                            (formData?.age && !editMode) || lockedValue === true
                              ? true
                              : false,
                          "aria-label": "Without label"
                        }}
                      >
                        {Array.from({ length: 100 }, (_, i) => i + 1).map(
                          (age) => (
                            <MenuItem key={age} value={age}>
                              {age}
                            </MenuItem>
                          )
                        )}
                      </Select>
                      {errors.familyMembers?.[index]?.age?.message && (
                        <FormHelperText
                          sx={{
                            color: "#d32f2f",
                            fontSize: "12px",
                            marginLeft: "14px"
                          }}
                        >
                          {errors.familyMembers?.[index]?.age?.message}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormGroup>
                <CustomFormLabel component="legend" required>
                  Select ID type
                </CustomFormLabel>
                <Controller
                  name={`familyMembers[${index}].id_type`}
                  control={control}
                  defaultValue={
                    formData?.id_type ? formData?.id_type : "Passport"
                  }
                  render={({
                    field: { value, onChange },
                    fieldState: { error }
                  }) => {
                    return (
                      <Select
                        value={value ? value : formData?.id_type}
                        onChange={onChange}
                        error={error}
                        inputProps={{
                          readOnly:
                            (formData?.id_type && !editMode) ||
                            lockedValue === true
                              ? true
                              : false,
                          "aria-label": "Without label"
                        }}
                      >
                        <MenuItem value={"Passport"}>Passport</MenuItem>
                        <MenuItem value={"Aadhaar"}>Aadhaar</MenuItem>
                      </Select>
                    );
                  }}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <CustomFormLabel component="legend" required>
                  ID number
                </CustomFormLabel>
                <CustomTextField
                  {...register(`familyMembers[${index}].id_number`, {
                    required: "This field is required."
                  })}
                  defaultValue={formData?.id_number}
                  id="outlined-basic"
                  variant="outlined"
                  autoComplete="off"
                  error={errors?.familyMembers?.[index]?.id_number}
                  helperText={
                    errors?.familyMembers?.[index]?.id_number &&
                    errors.familyMembers[index].id_number.message
                  }
                  InputProps={{
                    readOnly:
                      (formData?.id_number && !editMode) || lockedValue === true
                        ? true
                        : false
                  }}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </form>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={handleSubmit((data) => {
              if (lockedValue !== true) {
                onSubmit(data);
              }
            })}
            variant="contained"
            sx={{
              backgroundColor: "#0671E0",
              color: "white",
              lineHeight: "20px",
              fontWeightL: 400,
              textTransform: "capitalize",
              fontSize: "16px",
              width: "174px",
              height: "48px",
              opacity: lockedValue === true ? 0.5 : 1
            }}
            disabled={isClickArray}
          >
            {isLoading ? (
              <CircularProgress
                size={20}
                sx={{ color: "white", marginLeft: "10px" }}
              />
            ) : (
              "Save"
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default FamilyMemberForm;
