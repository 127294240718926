import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import BackButton from "../../../components/BackButton";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  PropertyRegistrationFile
  // PropertyRegistrationFileDownload
} from "../../../lib/query/query";
import Steppercontext from "../../../lib/context/contextData";
import { toast } from "react-toastify";
import ErrorToast from "../../../components/ErrorToast";
import * as Sentry from "@sentry/react";

export const PropertyRegistration = () => {
  const navigate = useNavigate();
  const { salesId } = useParams();

  const { loading, error } = useQuery(PropertyRegistrationFile, {
    variables: { document_type: "ABSENTIA-REGISTRATION" }
  });

  const { setSalesId, events } = useContext(Steppercontext);

  const params =
    events &&
    events.sale_transaction &&
    events.sale_transaction[0]?.sale_transaction_statuses;
  const sortedParams = params
    ?.slice()
    .sort((a, b) => a.step_index - b.step_index);
  const lockedValue = sortedParams && sortedParams[4]?.locked;

  useEffect(() => {
    setSalesId(salesId);
  }, [salesId]);

  // const [download, { data: downloadData }] = useLazyQuery(
  //   PropertyRegistrationFileDownload
  // );

  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh"
        }}
      >
        <CircularProgress />
      </Box>
    );

  if (error) {
    toast.error(
      <ErrorToast
        email={"info@msvkanha.com"}
        errorMsg={error && error?.message}
      />
    );
    Sentry.captureException(error && error?.message);
  }

  const handleDoc = async () => {
    const fetchedDocumentData =
      "https://arch-plans.s3.ap-south-1.amazonaws.com/Absentia+Registration+Form+-+Meera.docx";

    if (fetchedDocumentData) {
      const res = fetchedDocumentData;
      const outsideRes = await fetch(res);
      const blob = await outsideRes.blob();
      const fileDownloadUrl = URL.createObjectURL(blob);
      const downloadLink = document.createElement("a");
      downloadLink.href = fileDownloadUrl;
      downloadLink.download = `Absentia Registration Form - Meera.docx`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(fileDownloadUrl);
      return;
    }
  };

  return (
    <Container>
      {!loading && (
        <Box>
          <BackButton handleBack={() => navigate(-1)} />
          <Typography
            sx={{
              color: "#212121",
              fontSize: "25px",
              fontWeight: 600,
              lineHeight: "30px"
            }}
          >
            Property Registration{" "}
          </Typography>
          <Box
            sx={{
              width: "1026px",
              height: "600px",
              borderRadius: "10px",
              border: "1px solid #E8E8EA",
              padding: "30px",
              marginTop: "50px"
            }}
          >
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{
                color: "#212121",
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "23px"
              }}
            >
              For Buyers <span style={{ color: "#E48900" }}>without</span> a
              Bank Home Loan
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#717171",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "24px"
              }}
            >
              This information is required to determine the appropriate Property
              Registration Agreement.
            </Typography>
            <Box sx={{ py: 3 }}>
              <Typography
                sx={{
                  color: "#4D4D4D",
                  fontSize: "18px",
                  lineHeight: "28px",
                  fontWeight: 400,
                  pb: "20px"
                }}
              >
                1. Download, print, and complete this form.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  width: "400px",
                  justifyContent: "space-between"
                }}
              >
                <div style={{ display: "contents" }}>
                  <Box>
                    <Box sx={{ display: "flex" }}>
                      <Box>
                        <img src="/assets/image/fff.png" alt="Document" />
                      </Box>
                      <Box sx={{ paddingLeft: "10px" }}>
                        <Typography
                          sx={{
                            color: "#4D4D4D",
                            fontSize: "14px",
                            lineHeight: "20px",
                            fontWeight: 400
                          }}
                        >
                          Property Registration Form - Absentia
                        </Typography>
                        <Typography
                          sx={{
                            color: "#89939E",
                            fontSize: "13px",
                            lineHeight: "20px",
                            fontWeight: 400
                          }}
                        >
                          PDF
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box sx={{ opacity: lockedValue === true ? 0.5 : 1 }}>
                      <Button
                        variant="contained"
                        sx={{
                          color: "#0671E0",
                          textTransform: "capitalize",
                          fontSize: "14px",
                          lineHeight: "20px",
                          fontWeight: 600,
                          backgroundColor: "white",
                          "&:hover": {
                            backgroundColor: "white"
                          },
                          boxShadow: "none",
                          border: "1px solid #E8E8EA",
                          borderRadius: "4px",
                          padding: "7px 12px",
                          width: "91px",
                          height: "35px",
                          cursor:
                            lockedValue === true ? "not-allowed" : "pointer",
                          opacity: lockedValue === true ? 0.5 : 1
                        }}
                        onClick={() => {
                          if (lockedValue !== true) {
                            handleDoc();
                          }
                        }}
                        readOnly={lockedValue === true}
                      >
                        Download
                      </Button>
                    </Box>
                  </Box>
                </div>
              </Box>
            </Box>

            <Box>
              <Typography
                sx={{
                  color: "#4D4D4D",
                  fontSize: "18px",
                  lineHeight: "28px",
                  fontWeight: 400,
                  pt: "20px"
                }}
              >
                2. Send the duly completed form to the following adress:
              </Typography>
              <Box
                sx={{ padding: "20px 0", color: "#212121", fontSize: "18px" }}
              >
                <Typography>Flat No. C-103,</Typography>
                <Typography>Sy. No. 112/128,</Typography>
                <Typography>Meera Shantivanam,</Typography>
                <Typography>Kanha Village, Nandigama Mandal</Typography>
                <Typography>Ranga Reddy District - Telangana.</Typography>
                <Typography>Pin Code: 509 328</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "sapce-between",
                  border: "1px solid #E48900",
                  backgroundColor: "#FFF8EC",
                  color: "#E48900",
                  width: "721px",
                  height: "84px",
                  borderRadius: "10px",
                  padding: "20px"
                }}
              >
                <Box>
                  <img src="/assets/image/square.png" alt="InfoSquare" />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "25px",
                      paddingLeft: "10px"
                    }}
                  >
                    Once received, our team will work with you and the Telagana
                    State Goverment’s Registrar’s office to complete the
                    registration.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              border: "1px solid #E8E8EA",
              borderRadius: "10px",
              width: "1026px",
              height: "153px",
              padding: "30px",
              margin: "25px 0"
            }}
          >
            <Typography
              sx={{
                color: "#212121",
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "23px"
              }}
            >
              For Buyers with a Bank Home Loan
            </Typography>
            <Typography
              sx={{
                color: "#717171",
                fontSize: "18px",
                fontWeight: 500,
                lineHeight: "28px",
                pt: "10px"
              }}
            >
              For Buyers with a bank home loan, you are required to be present
              at the Registrar’s office in-person along with MSV representative,
              to complete the property registration. Please contact{" "}
              <Link to={`mailto:support@msv.com`} style={{ color: "#0671E0" }}>
                support@msv.com
              </Link>{" "}
              to schedule your visit.
            </Typography>
          </Box>
        </Box>
      )}
    </Container>
  );
};
