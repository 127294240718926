import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography
} from "@mui/material";
import { BuyInfo } from "../../stepper/BuyInfo";
import { CitizenshipResidency } from "../../stepper/CitizenshipResidency";
import { useQuery } from "@apollo/client";
import { Buyer_Data, kycInfoComment } from "../../../lib/query/query";
import BackButton from "../../../components/BackButton";
import { DocumentVerification } from "./DocumentVerification";
import HorizontalStepper from "../../../components/HorizontalStepper";
import { IdentityProof } from "../../stepper/IdentityProof ";
import { useNavigate, useParams } from "react-router-dom";
import Steppercontext from "../../../lib/context/contextData";
import { toast } from "react-toastify";
import ErrorToast from "../../../components/ErrorToast";
import * as Sentry from "@sentry/react";

export const VerifyId = () => {
  const { salesId } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [loadPage, setLoadPage] = useState(false);
  const [identityProof, setIdentityProof] = useState({ citizenship: "" });
  const [isEdit, setIsEdit] = useState(false);
  const [isEditIdentity, setIsIdentity] = useState(false);

  const { loading, data, refetch, error } = useQuery(Buyer_Data, {
    variables: { sale_transaction_id: salesId }
  });

  const { data: kycInfoCommentData, error: errorCommentData } = useQuery(
    kycInfoComment,
    {
      variables: { sale_transaction_id: salesId }
    }
  );
  const { setSalesId, events } = useContext(Steppercontext);

  const params =
    events &&
    events.sale_transaction &&
    events.sale_transaction[0]?.sale_transaction_statuses;

  const lockedStatus = params && params[0]?.status === "Action Required";

  useEffect(() => {
    setSalesId(salesId);
  }, [salesId]);

  useEffect(() => {
    refetch();
    if (data && data.buyer && data.buyer.length > 0) {
      const buyerId = data?.buyer[0]?.buyer_id;
      navigate(`/myProperties/allStates/${salesId}/verifyId/${buyerId}`);
    }
  }, [data]);

  useEffect(() => {
    if (loadPage) {
      refetch();
      setLoadPage(false);
    }
  }, [loadPage]);

  const totalSteps = () => {
    return horizontalData.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? horizontalData.findIndex((step, i) => !(i in completed))
        : activeStep + 1;

    setActiveStep(newActiveStep);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setLoadPage(true);
  };

  const horizontalData = [
    {
      id: 1,
      label: "Buyer Info",
      step: (
        <BuyInfo
          data={data}
          handleComplete={handleComplete}
          setLoadPage={setLoadPage}
          loadPage={loadPage}
        />
      )
    },
    {
      id: 2,
      label: "Citizenship & Residency",
      step: (
        <CitizenshipResidency
          data={data}
          activeStep={activeStep}
          handleBack={handleBack}
          handleComplete={handleComplete}
          setIdentityProof={setIdentityProof}
          setIsEdit={setIsEdit}
        />
      )
    },
    {
      id: 3,
      label: "Identity Proof",
      step: (
        <IdentityProof
          data={data}
          activeStep={activeStep}
          handleBack={handleBack}
          identityProof={identityProof}
          setIsIdentity={setIsIdentity}
        />
      )
    }
  ];
  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh"
        }}
      >
        <CircularProgress />
      </Box>
    );

  if (error || errorCommentData) {
    toast.error(
      <ErrorToast
        email={"info@msvkanha.com"}
        errorMsg={
          (error && error?.message) ||
          (errorCommentData && errorCommentData?.message)
        }
      />
    );
    Sentry.captureException(
      (error && error?.message) ||
        (errorCommentData && errorCommentData?.message)
    );
  }

  return (
    <Container>
      <Box>
        {!loading && (
          <Box>
            <BackButton
              handleBack={() => navigate(`/myProperties/allStates/${salesId}`)}
            />
            <Box sx={{ marginBottom: "30px" }}>
              <Typography
                sx={{ fontSize: "25px", fontWeight: 600 }}
                color={"#212121"}
                lineHeight={1.5}
                marginBottom={"10px"}
              >
                Verify your ID
              </Typography>
              <Box sx={{ display: "flex" }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 400,
                    color: "#717171"
                  }}
                >
                  Click here to see the documents you need to complete the
                  process.
                </Typography>
                <Button
                  size="small"
                  sx={{
                    fontSize: "16px",
                    fontWeight: 400,
                    color: "#0671E0",
                    textTransform: "capitalize",
                    mt: "-5px"
                  }}
                  onClick={() => setOpen(true)}
                >
                  {" "}
                  Learn More {">"}
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                border: "1px solid #E48900",
                borderRadius: "10px",
                backgroundColor: "#FFF8EC",
                color: "#E48900",
                maxWidth: "685px",
                marginBottom: "50px"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  p: 2
                }}
              >
                <Box className="!mr-3 !h-6 !w-6 min-w-[24px]">
                  <img
                    src="/assets/image/Infosquare.svg"
                    alt="Infosquare"
                    className="h-full w-full"
                  />
                </Box>
                <Typography variant="body1">
                  {lockedStatus
                    ? kycInfoCommentData &&
                      kycInfoCommentData?.buyer[0]?.kyc_verification_comment
                    : "Please note that the Buyer ID Information will be used for Property Registration and cannot be altered/ removed later on."}
                </Typography>
              </Box>
            </Box>
            {data && (
              <Box>
                <HorizontalStepper
                  horizontalData={horizontalData}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  completed={completed}
                  setCompleted={setCompleted}
                  handleNext={handleNext}
                  data={data}
                  isEdit={isEdit}
                  isEditIdentity={isEditIdentity}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
      {open && <DocumentVerification open={open} setOpen={setOpen} />}
    </Container>
  );
};
