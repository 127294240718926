import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";

export const SalesAgreement = () => {
  const navigate = useNavigate();
 
  return (
    <Container>
        <Box>
          <BackButton handleBack={() => navigate(-1)} />
          <Box>
            <Typography
              sx={{
                mt: 1,
                fontSize: "25px",
                color: "#212121",
                fontWeight: 600,
                lineHeight: "30px"
              }}
            >
              Sales Agreement{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 400,
                color: "#717171",
                marginTop: "16px",
                lineHeight: "28px"
              }}
            >
              The First Installment is due within two weeks of the booking date.
              Once the First Installment is paid, a Sale Agreement will be
              generated and sent to your email for you signature.
              <br />
              <br />
              The completed & counter signed Sale Agreement will be available in
              the Downloads section. If you have any questions, please reach out
              to <span style={{color:'#0671E0'}}>info@msvkanha.com</span>
            </Typography>
          </Box>
        </Box>
    </Container>
  );
};
