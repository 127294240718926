import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import PageWrapper from "../pages/PageWrapper";
import Payments from "../containers/tabView/Payments";
import Download from "../containers/tabView/Download";
import IdVerification from "../containers/tabView/IdVerification";
import Help from "../containers/tabView/Help";
import { AllStatesTimeline } from "../containers/tabView/MyProperties/AllStatesTimeline";
import TabLayout from "../components/Layouts/TabLayout";
import { VerifyId } from "../containers/tabView/MyProperties/VerifyId";
import { SalesAgreement } from "../containers/tabView/MyProperties/SalesAgreement";
import { BuyProperties } from "../containers/tabView/MyProperties/BuyProperties";
import { CancelModal } from "../containers/tabView/MyProperties/CancelModal";
import { CancellationPage } from "../containers/tabView/MyProperties/CancellationPage";
import { BookProperties } from "../containers/tabView/MyProperties/BookProperties";
import { FamilyMembers } from "../containers/tabView/MyProperties/FamilyMembers";
import { PropertyRegistration } from "../containers/tabView/MyProperties/PropertyRegistration";
import { PaymentSchedule } from "../containers/tabView/MyProperties/PaymentSchedule";
import { PaymentHistory } from "../containers/tabView/MyProperties/PaymentHistory";
import Downloads from "../components/Downloads";
import { TermsConditions } from "../containers/tabView/MyProperties/TermsConditions";
import NotAdmin from "../pages/NotAdmin";

const Router = () => {
  const verifyIDRoutes = [
    "/myProperties/allStates/:salesId/verifyId",
    "/myProperties/allStates/:salesId/verifyId/:buyerId"
  ];

  return (
    <>
      <PageWrapper>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/download" element={<Download />} />
          <Route path="/idVerification" element={<IdVerification />} />
          <Route path="/help" element={<Help />} />
          <Route
            path="/myProperties/allStates/:salesId"
            element={
              <TabLayout>
                <AllStatesTimeline />
              </TabLayout>
            }
          />
          {verifyIDRoutes.map((path, index) => (
            <Route
              path={path}
              element={
                <TabLayout>
                  <VerifyId />
                </TabLayout>
              }
              key={index}
            />
          ))}

          <Route
            path="/salesAgreement"
            element={
              <TabLayout>
                <SalesAgreement />
              </TabLayout>
            }
          />

          <Route
            path="/buyProperties"
            element={
              <TabLayout>
                <BuyProperties />
              </TabLayout>
            }
          />

          <Route
            path="/cancelModal"
            element={
              <TabLayout>
                <CancelModal />
              </TabLayout>
            }
          />

          <Route
            path="/cancellationPage/:salesId"
            element={
              <TabLayout>
                <CancellationPage />
              </TabLayout>
            }
          />

          <Route
            path="/myProperties/bookProperties/:salesId"
            element={
              <TabLayout>
                <BookProperties />
              </TabLayout>
            }
          />

          <Route
            path="/familyMembers/:salesId"
            element={
              <TabLayout>
                <FamilyMembers />
              </TabLayout>
            }
          />

          <Route
            path="/propertyRegistration/:salesId"
            element={
              <TabLayout>
                <PropertyRegistration />
              </TabLayout>
            }
          />

          <Route
            path="/payments/paymentSchedule/:salesId"
            element={
              <TabLayout>
                <PaymentSchedule />
              </TabLayout>
            }
          />

          <Route
            path="/payments/paymentHistory"
            element={
              <TabLayout>
                <PaymentHistory />
              </TabLayout>
            }
          />

          <Route
            path="/downloads"
            element={
              <TabLayout>
                <Downloads />
              </TabLayout>
            }
          />
          <Route
            path="/termsCondition"
            element={
              <TabLayout>
                <TermsConditions />
              </TabLayout>
            }
          />

          {/* <Route
            path="/dashboard"
            element={
              <DashboardTabLayout>
                <Dashboard />
              </DashboardTabLayout>
            }
          /> */}

          <Route path="*" element={<NotAdmin />} />
        </Routes>
      </PageWrapper>
    </>
  );
};

export default Router;
