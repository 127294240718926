import React, { useState, useEffect } from "react";
import ModalCommon from "../../../components/ModalCommon";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  CircularProgress,
  Typography,
  RadioGroup,
  useRadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import styled from "@emotion/styled";
import { useQuery, useMutation } from "@apollo/client";
import {
  cancelBookingRefundAmount,
  transactionApprover,
} from "../../../lib/query/query";
import { adminUpdateCancellationStatus, adminUpdateCancellationPropertyStatus, adminUpdateCancellationStatusLockedAll } from "../../../lib/mutation/mutation";

// import Cookies from 'universal-cookie';
import _ from "lodash";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ErrorToast from "../../../components/ErrorToast";
import * as Sentry from "@sentry/react";

// const cookies = new Cookies(null, { path: '/' });

const StyledFormControlLabel = styled((props) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
  ".MuiFormControlLabel-label": {
    color: "#000000",
    fontWeight: 400,
    lineHeight: "28px",
    fontSize: "14px",
  },
}));

function MyFormControlLabel(props) {
  const radioGroup = useRadioGroup();

  let checked = false;
  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
}

export const CancelPurchase = ({ open, setOpen, buyerInfo, statusRefetch }) => {
  const [reason, setReason] = useState("");
  const [approverData, setApproverData] = useState([]);
  const { salesId, propertyId } = useParams();

  const {
    data: RefundAmount,
    loading: RefundAmountLoading,
    error: errorRefundAmount,
  } = useQuery(cancelBookingRefundAmount, {
    variables: { sale_transaction_id: salesId },
    skip: !salesId,
  });

  const { data,error:errorData } = useQuery(transactionApprover);

  useEffect(() => {
    if (data?.transaction_approvers && data?.transaction_approvers.length > 0) {
      setApproverData(data?.transaction_approvers);
    }
  }, [data]);

  const approver =
    approverData?.length > 0 &&
    approverData.map((item) => {
      return {
        approver_id: item.approver_id,
        userName: `${item.first_name} ${item?.last_name}`,
      };
    });

  const handleClose = () => {
    setOpen(false);
    setReason("");
  };

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors, dirtyFields },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      inventory_status: "",
    },
  });

  // const formValues = getValues();
  // const inventoryStatus = formValues.inventory_status;
  // const approverId = formValues.approver_id;



  // const openPopup = () => {
  //   // Open the popup window
  //   const popupWindow = window.open('https://hasura.msvkanha.net/otp/generate', 'sample', 'width=100,height=100');

  //   // Listen for changes in the popup window's location
  //   const checkPopup = setInterval(() => {
  //     try {
  //       popupWindow.close();
  //       clearInterval(checkPopup);
  //     } catch (error) {
  //       popupWindow.close();
  //       clearInterval(checkPopup);
  //     }

  //   }, 2000);
  // };

  const handelOTP = async () => {
    // //     let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
    // // width=0,height=0,left=-1000,top=-1000`;
    // //     window.open('https://hasura.msvkanha.net/otp/generate', 'sample', params)
    //   openPopup();
  };

  const [update_cancellation_status,{error:errorStatus}] = useMutation(adminUpdateCancellationStatus);
  const [update_cancellation_Property_status,{error:errorPropertystatus}] = useMutation(adminUpdateCancellationPropertyStatus);
  const [update_cancellation_Locked_status,{error:errorLockedStatus}] = useMutation(adminUpdateCancellationStatusLockedAll);


if(errorRefundAmount || errorStatus || errorPropertystatus || errorLockedStatus || errorData){
  toast.error(
    <ErrorToast
      email={"info@msvkanha.com"}
      errorMsg={
       (errorRefundAmount && errorRefundAmount?.message) ||
        (errorStatus && errorStatus?.message) ||
        (errorPropertystatus && errorPropertystatus?.message) ||
        (errorLockedStatus && errorLockedStatus?.message) ||
        (errorData && errorData?.message)
      }
    />
  );
  Sentry.captureException( (errorRefundAmount && errorRefundAmount?.message) ||
    (errorStatus && errorStatus?.message) ||
    (errorPropertystatus && errorPropertystatus?.message) ||
    (errorLockedStatus && errorLockedStatus?.message) ||
    (errorData && errorData?.message));
}


  const onSubmit = async (data) => {

    await update_cancellation_Property_status({
      variables: {
        property_id: propertyId,
        status: data.inventory_status,
      }
    })


    await update_cancellation_status({
      variables: {
        sale_transaction_id: salesId,
      }
    })

    await update_cancellation_Locked_status({
      variables: {
        sale_transaction_id: salesId,
      }
    })
    statusRefetch();
    setOpen(false);

  };

  const Header = () => {
    return (
      <Box sx={{ justifyContent: "center", textAlign: "center", paddingBottom: "10px" }}>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "30px",
            color: "#212121",
          }}
        >
          Cancel Purchase
        </Typography>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "23px",
            color: "#4D4D4D",
            paddingTop: "10px",
          }}
        >
          {`${buyerInfo?.property?.project?.project_name} ${buyerInfo?.property?.project_building?.tower_name} ${buyerInfo?.property?.unit_number}`}
        </Typography>
      </Box>
    );
  };

  const Body = () => {
    return (
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Box>
              <Box sx={{ justifyContent: "center", textAlign: "center", paddingBottom: "10px" }}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "18px",
                    lineHeight: "30px",
                    color: "#212121",
                  }}
                >
                  Are you sure you want to Cancel this purchase? Cancelling this
                  purchase, cannot be undone.
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    mt: 2,
                    display: "flex",
                    fontWeight: 700,
                    fontSize: "14px",
                    lineHeight: "28px",
                    color: "#4D4D4D",
                  }}
                >
                  Transaction Refund Amount:{" "}
                  {RefundAmountLoading ? (
                    <CircularProgress
                      sx={{ height: "20px!important", width: "20px!important" }}
                    />
                  ) : (
                    RefundAmount?.payment_history_aggregate?.aggregate?.sum
                      ?.amount === null || RefundAmount?.payment_history_aggregate?.aggregate?.sum
                        ?.amount === undefined ? "0 INR" : RefundAmount?.payment_history_aggregate?.aggregate?.sum
                          ?.amount + " " + "INR"
                  )}
                </Typography>
              </Box>
              {/*<TextField
                placeholder="Reason for cancellation. (50 Character minimum)."
                multiline
                rows={3}
                maxRows={4}
                fullWidth
                value={reason}
                onChange={(e) => {
                  const inputText = e.target.value;
                  setReason(e.target.value);
                  setIsValidReason(inputText.length >= 50);
                }}
              />*/}
            </Box>
            <Box sx={{ pt: 2 }}>
              <FormControl className="!mb-3" sx={{ flexDirection: "row" }}>
                <FormLabel
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                    fontWeight: 700,
                    fontSize: "14px",
                    lineHeight: "28px",
                    color: "#4D4D4D",
                  }}
                >
                  Select New Inventory Status
                </FormLabel>
                <Controller
                  rules={{ required: "Select New Inventory field is required." }}
                  control={control}
                  name="inventory_status"
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      sx={{ flexDirection: "row", marginLeft: "10px" }}
                    >
                      <MyFormControlLabel
                        value="MGMT_HOLD"
                        label="MGMT_HOLD"
                        control={<Radio size="small" />}
                      />
                      <MyFormControlLabel
                        value="Available"
                        label="List For Sale"
                        sx={{ marginLeft: "10px" }}
                        control={<Radio size="small" />}
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Box>
            <Box>
              {/* <FormControl className="!mb-3">
                <FormLabel
                  sx={{
                    fontWeight: 700,
                    fontSize: "14px",
                    lineHeight: "28px",
                    color: "#4D4D4D",
                  }}
                >
                  Select Approver
                </FormLabel>
                <Controller
                  rules={{ required: "This field is required." }}
                  control={control}
                  name="approver_id"
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      onChange={(event) => {
                        const selectedApproverId = +event.target.value;
                        field.onChange(selectedApproverId);
                      }}
                    >
                      <Grid container spacing={2}>
                        {approver &&
                          approver.length > 0 &&
                          approver.map((approver) => (
                            <Grid item key={approver.approver_id} xs={6}>
                              <MyFormControlLabel
                                key={approver.approver_id}
                                value={approver.approver_id}
                                label={approver.userName}
                                control={<Radio size="small" />}
                              />
                            </Grid>
                          ))}
                      </Grid>
                    </RadioGroup>
                  )}
                />
                          </FormControl> */}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
              <span className="text-red-500 text-sm">
                {errors.inventory_status && errors.inventory_status.message}
              </span></Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", pt: 4 }}>
            <Button
              sx={{
                borderRadius: "4px",
                gap: "10px",
                backgroundColor: "#FFFFFF",
                fontSize: "16px",
                lineHeight: "24px",
                textAlign: "Center",
                fontWeight: 600,
                color: "#717171",
                textTransform: "capitalize",
                width: "209px",
                height: "56px",
              }}
              onClick={handleClose}
            >
              Do not cancel
            </Button>
            <Button
              type="submit"
              sx={{
                borderRadius: "4px",
                gap: "10px",
                backgroundColor: "#0671E0",
                fontSize: "16px",
                lineHeight: "24px",
                textAlign: "Center",
                fontWeight: 600,
                color: "#FFFFFF",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#0671E0",
                },
                width: "209px",
                height: "56px",
              }}
            >
              OK - Cancel Purchase
            </Button>
          </Box>
        </form>
      </>
    );
  };

  return (
    <>
      <ModalCommon
        open={open}
        setOpen={setOpen}
        header={<Header />}
        body={Body()}
        height={440}
        width={551}
        justifyContent={"center"}
        overflow={"auto"}
      />
    </>
  );
};
