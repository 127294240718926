import styled from "@emotion/styled";
import { FormLabel, OutlinedInput, TextField } from "@mui/material";

export const CustomTextField = styled(TextField)({
    marginBottom: "10px",
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#E8E8EA",
        },
    },
});

export const CustomOutlineTextField = styled(OutlinedInput)({
    marginBottom: "3px",
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#E8E8EA",
        },
    },
    
});

export const CustomFormLabel = styled(FormLabel)({
    fontSize: "16px",
    color: "#89939E",
    lineHeight: "1.5",
    fontWeight: 600,
    position: "relative",
    marginBottom: "5px",
    "& .MuiFormLabel-asterisk": {
        color: "red",
        fontSize: "16px",
        lineHeight: "1.5",
        fontWeight: 600,
    },
});