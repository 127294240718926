import React, { useEffect, useState } from "react";
import ModalCommon from "../../../components/ModalCommon";
import { Box, Button, FormGroup, Typography } from "@mui/material";
import {
  CustomFormLabel,
  CustomTextField
} from "../../../lib/constants/textField";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { useMutation, useQuery } from "@apollo/client";
import {
  adminAddAmenity,
  adminAddAmenityPaymentSchedule
} from "../../../lib/mutation/mutation";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ErrorToast from "../../../components/ErrorToast";
import { successToast } from "../../../pages/Toasts/showToasts";
import {
  AdminAdjustmentsCredits,
  AdminPaymentScheduleTable,
  addAmentiesLastDate
} from "../../../lib/query/query";
import * as Sentry from "@sentry/react";

export const AddAmenties = ({ open, setOpen, buyerInfo }) => {
  const { salesId, propertyId } = useParams();

  const [lastDueDate, setLastDueDate] = useState("");
  const [insertPrice, { error: errorPrice }] = useMutation(adminAddAmenity);

  const [paymentSchedule, { error: paymentError }] = useMutation(
    adminAddAmenityPaymentSchedule
  );

  const { data: getData, error: getDataError } = useQuery(addAmentiesLastDate, {
    variables: { sale_transaction_id: salesId }
  });

  const { refetch: refetchPaymentScheduleTable, error: adminPaymentError } =
    useQuery(AdminPaymentScheduleTable, {
      variables: { sale_transaction_id: salesId }
    });

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    mode: "onChange"
  });

  const { refetch: adjustmentsCreditsCall, error: adminAdjustmentsError } =
    useQuery(AdminAdjustmentsCredits, {
      variables: { property_id: propertyId }
    });

  if (
    errorPrice ||
    paymentError ||
    getDataError ||
    adminPaymentError ||
    adminAdjustmentsError
  ) {
    toast.error(
      <ErrorToast
        email={"info@msvkanha.com"}
        errorMsg={
          (errorPrice && errorPrice?.message) ||
          (paymentError && paymentError?.message) ||
          (getDataError && getDataError?.message) ||
          (adminPaymentError && adminPaymentError?.message) ||
          (adminAdjustmentsError && adminAdjustmentsError?.message)
        }
      />
    );
    Sentry.captureException(
      (errorPrice && errorPrice?.message) ||
        (paymentError && paymentError?.message) ||
        (getDataError && getDataError?.message) ||
        (adminPaymentError && adminPaymentError?.message) ||
        (adminAdjustmentsError && adminAdjustmentsError?.message)
    );
  }

  useEffect(() => {
    if (getData && getData.sale_transaction_payment_schedule) {
      const paymentSchedule = [...getData.sale_transaction_payment_schedule];

      if (paymentSchedule.length > 0) {
        const sortedPaymentSchedule = paymentSchedule.sort((a, b) =>
          a.due_date.localeCompare(b.due_date)
        );

        setLastDueDate(
          sortedPaymentSchedule[sortedPaymentSchedule.length - 1].due_date
        );
      } else {
        setLastDueDate(null);

        console.error("Payment schedule array is empty");
      }
    } else {
      setLastDueDate(null);

      console.error("Invalid or missing data in getData response");
    }
  }, [getData]);

  const onSubmit = async (data) => {
    await insertPrice({
      variables: {
        property_id: propertyId,
        price_amount: data?.price_amount,
        price_type: data?.price_type
      }
    })
      .then((response) => {
        setOpen(false);
        propertyId && adjustmentsCreditsCall();
        successToast("Amenities Add successfully");
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
    if (lastDueDate === null) {
      await paymentSchedule({
        variables: {
          amount: data?.price_amount,
          item: `ADD ON: ${data?.price_type}`,
          sale_transaction_id: salesId,
          status: "Pending"
        }
      })
        .then((response) => {
          //response
        })
        .catch((error) => {
          console.error(error);
          Sentry.captureException(error);
        });
    } else {
      await paymentSchedule({
        variables: {
          amount: data?.price_amount,
          due_date: lastDueDate,
          item: `ADD ON: ${data?.price_type}`,
          sale_transaction_id: salesId,
          status: "Pending"
        }
      })
        .then((response) => {
          //response
        })
        .catch((error) => {
          console.error(error);
          Sentry.captureException(error);
        });
    }
    refetchPaymentScheduleTable();
  };

  const handleClose = () => {
    setOpen(false);
  };
  const Header = () => {
    return (
      <Box sx={{ justifyContent: "center", textAlign: "center" }}>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "30px",
            color: "#212121"
          }}
        >
          Add Amenities
        </Typography>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "23px",
            color: "#4D4D4D",
            paddingTop: "10px"
          }}
        >
          {`${buyerInfo?.property?.project?.project_name} ${buyerInfo?.property?.project_building?.tower_name} ${buyerInfo?.property?.unit_number}`}
        </Typography>
      </Box>
    );
  };

  const Body = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Box sx={{ marginBottom: "12px" }}>
            <FormGroup>
              <CustomFormLabel component="legend" required>
                Amenity
              </CustomFormLabel>
              <CustomTextField
                {...register("price_type", {
                  required: "This field is required."
                })}
                placeholder="Title"
                id="outlined-basic"
                variant="outlined"
                autoComplete="off"
                error={errors.price_type}
                helperText={errors.price_type && errors.price_type.message}
              />
            </FormGroup>
          </Box>
          <Box>
            <FormGroup>
              <CustomFormLabel component="legend" required>
                Price (INR)
              </CustomFormLabel>
              <CustomTextField
                {...register("price_amount", {
                  required: "This field is required."
                })}
                placeholder="Title"
                id="outlined-basic"
                variant="outlined"
                autoComplete="off"
                error={errors.price_amount}
                helperText={errors.price_amount && errors.price_amount.message}
              />
            </FormGroup>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", pt: 4 }}>
          <Button
            sx={{
              borderRadius: "4px",
              gap: "10px",
              backgroundColor: "#FFFFFF",
              fontSize: "16px",
              lineHeight: "24px",
              textAlign: "Center",
              fontWeight: 600,
              color: "#717171",
              textTransform: "capitalize",
              width: "209px",
              height: "56px"
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            sx={{
              borderRadius: "4px",
              gap: "10px",
              backgroundColor: "#0671E0",
              fontSize: "16px",
              lineHeight: "24px",
              textAlign: "Center",
              fontWeight: 600,
              color: "#FFFFFF",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#0671E0"
              },
              width: "209px",
              height: "56px"
            }}
          >
            Submit
          </Button>
        </Box>
      </form>
    );
  };

  return (
    <>
      <ModalCommon
        open={open}
        setOpen={setOpen}
        header={<Header />}
        body={Body()}
        justifyContent={"center"}
        height={464}
        width={551}
      />
    </>
  );
};
