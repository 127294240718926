import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Typography
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { makeStyles } from "@material-ui/core";
import {
  CancelationFee,
  CancellationAmount,
  cancelationPreFillData
} from "../../../lib/query/query";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {
  CustomFormLabel,
  CustomOutlineTextField,
  CustomTextField
} from "../../../lib/constants/textField";
import _ from "lodash";
import {
  cancelationStatus,
  cancellationLocked,
  cancellation_insert_buyer_bank_account,
  cancellation_update_buyer_bank_account
} from "../../../lib/mutation/mutation";
import BackButton from "../../../components/BackButton";
import * as Sentry from "@sentry/react";
import ErrorToast from "../../../components/ErrorToast";
import { toast } from "react-toastify";

export const CancellationPage = () => {
  const clx = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRetype, setShowPasswordRetype] = useState(false);
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { salesId } = useParams();
  const navigate = useNavigate();

  const { data, error: errorData } = useQuery(CancellationAmount, {
    variables: { sale_transaction_id: salesId }
  });

  const { data: dataFee, error: errorDataFee } = useQuery(CancelationFee, {
    variables: { sale_transaction_id: salesId }
  });

  const { data: dataPreFill, error: errorDataPreFill } = useQuery(
    cancelationPreFillData,
    {
      variables: { sale_transaction_id: salesId }
    }
  );

  const [cancellation_insert_buyer, { error: errorInserBuyer }] = useMutation(
    cancellation_insert_buyer_bank_account
  );
  const [cancellation_update_buyer, { error: errorUpdateBuyer }] = useMutation(
    cancellation_update_buyer_bank_account
  );
  const [CancellationStatus, { error: errorStatus }] =
    useMutation(cancelationStatus);
  const [CanCellationLocked, { error: errorLocked }] =
    useMutation(cancellationLocked);

  const {
    control,
    handleSubmit,
    register,
    setValue,
    getValues,
    formState: { errors, dirtyFields }
  } = useForm({
    mode: "onChange"
  });

  useEffect(() => {
    if (dataPreFill && dataPreFill?.buyer_bank_account[0]) {
      setFormData(dataPreFill?.buyer_bank_account[0]);
    }
  }, [dataPreFill]);

  useEffect(() => {
    if (!_.isEmpty(formData)) {
      Object.keys(formData).forEach((key) => {
        setValue(key, formData[key] || "");
      });
    }
  }, [formData, setValue]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswordRetype = () =>
    setShowPasswordRetype((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const TotalAmountPaid =
    data?.payment_history_aggregate?.aggregate?.sum?.amount;
  const cancelationFee =
    dataFee?.sale_transaction[0]?.property?.cancellation_fee;

  const RefundAmount = TotalAmountPaid - cancelationFee || 0;

  const validateAccountNumber = (value) => {
    const retypeAccountNumber = getValues("account_number");
    return value === retypeAccountNumber || "Account numbers do not match.";
  };

  const insertFormText = async (updateData) => {
    const { account_type } = getValues();
    await cancellation_insert_buyer({
      variables: {
        account_number: updateData?.account_number,
        account_type: account_type,
        accountholder_name: updateData?.accountholder_name,
        bank_name: updateData?.bank_name,
        branch_name: updateData?.branch_name,
        buyer_bank_account_id: updateData?.buyer_bank_account_id,
        ifsc_code: updateData?.ifsc_code,
        refund_agreement_flag: updateData?.refund_agreement_flag,
        sale_transaction_id: salesId
      }
    })
      .then((response) => {})
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });
  };

  const updateFormText = async (updateData) => {
    const { account_type } = getValues();
    if (
      dirtyFields.account_number ||
      dirtyFields.account_type ||
      dirtyFields.accountholder_name ||
      dirtyFields.bank_name ||
      dirtyFields.branch_name ||
      dirtyFields.ifsc_code ||
      dirtyFields.refund_agreement_flag
    ) {
      await cancellation_update_buyer({
        variables: {
          buyer_bank_account_id: formData?.buyer_bank_account_id,
          account_type: account_type,
          account_number: updateData?.account_number,
          accountholder_name: updateData?.accountholder_name,
          bank_name: updateData?.bank_name,
          branch_name: updateData?.branch_name,
          ifsc_code: updateData?.ifsc_code,
          refund_agreement_flag: updateData?.refund_agreement_flag,
          sale_transaction_id: salesId
        }
      })
        .then((response) => {})
        .catch((error) => {
          console.error(error);
          Sentry.captureException(error);
        });
    }
  };

  const onSubmit = async (updateData) => {
    if (
      dataPreFill?.buyer_bank_account &&
      dataPreFill?.buyer_bank_account?.length > 0
    ) {
      setIsLoading(true);
      await updateFormText(updateData);
      setIsLoading(false);
    } else {
      setIsLoading(true);
      await insertFormText(updateData);
      setIsLoading(false);
    }
    await CancellationStatus({
      variables: {
        sale_transaction_id: salesId,
        status: "Cancellation Requested"
      }
    })
      .then((response) => {})
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });
    await CanCellationLocked({
      variables: {
        sale_transaction_id: salesId,
        locked: true
      }
    })
      .then((response) => {})
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });

    navigate("/");
  };

  if (
    errorData ||
    errorDataFee ||
    errorDataPreFill ||
    errorInserBuyer ||
    errorUpdateBuyer ||
    errorStatus ||
    errorLocked
  ) {
    toast.error(
      <ErrorToast
        email={"info@msvkanha.com"}
        errorMsg={
          (errorData && errorData?.message) ||
          (errorDataFee && errorDataFee?.message) ||
          (errorDataPreFill && errorDataPreFill?.message) ||
          (errorInserBuyer && errorInserBuyer?.message) ||
          (errorUpdateBuyer && errorUpdateBuyer?.message) ||
          (errorStatus && errorStatus?.message) ||
          (errorLocked && errorLocked?.message)
        }
      />
    );
    Sentry.captureException(
      (errorData && errorData?.message) ||
        (errorDataFee && errorDataFee?.message) ||
        (errorDataPreFill && errorDataPreFill?.message) ||
        (errorInserBuyer && errorInserBuyer?.message) ||
        (errorUpdateBuyer && errorUpdateBuyer?.message) ||
        (errorStatus && errorStatus?.message) ||
        (errorLocked && errorLocked?.message)
    );
  }

  return (
    <Box>
      <Container>
        <BackButton handleBack={() => navigate(-1)} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "25px",
                  fontWeight: 600,
                  lineHeight: "30px",
                  paddingBottom: "10px"
                }}
              >
                Cancellation
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 400,
                  color: "#717171",
                  lineHeight: "20px"
                }}
              >
                You can cancel your purchase anytime prior to Registration. Your
                payments will be refunded to the account details you provide
                below, after deducting a cancellation fee.
              </Typography>
            </Box>
            <Box
              sx={{
                border: "1px solid #0663C7",
                borderRadius: "10px",
                backgroundColor: "#EEF5FC",
                margin: "26px 0 50px 0",
                width: "675px"
              }}
            >
              <Box
                sx={{
                  justifyContent: "space-between",
                  textAlign: "center",
                  alignItems: "center",
                  margin: "18px"
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <span>
                    <img
                      src="/assets/image/Infosquare.png"
                      alt="Info square"
                      height={24}
                      width={24}
                    />
                  </span>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#0663C7",
                      textAlign: "start",
                      paddingLeft: "18px",
                      lineHeight: "24px"
                    }}
                  >
                    Taxes paid on the purchases are not reflected below. Amounts
                    paid towards
                    <br /> taxes will be refunded upon receipt of a refund from
                    the Government.
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                backgroundColor: "#F5F7FA",
                border: "1px solid #E8E8EA",
                width: "465px",
                height: "164px",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "20px"
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography className={clx.amount}>
                    Total Amount Paid
                  </Typography>
                  <Typography className={clx.amount}>
                    Cancelation Fee
                  </Typography>
                  <Typography
                    className={clx.amountTotal}
                    sx={{ paddingBottom: "0" }}
                  >
                    Refund Amount
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    textAlign: "right"
                  }}
                >
                  <Typography className={clx.amount}>
                    {" "}
                    {TotalAmountPaid?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}{" "}
                    INR
                  </Typography>
                  <Typography className={clx.amount}>
                    {" "}
                    {cancelationFee?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}{" "}
                    INR
                  </Typography>
                  <Typography
                    className={clx.amountTotal}
                    sx={{ paddingBottom: "0" }}
                  >
                    {" "}
                    {RefundAmount?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}{" "}
                    INR
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <FormGroup>
                    <CustomFormLabel
                      component="legend"
                      className={clx.formLabel}
                      required
                    >
                      Account holder name
                    </CustomFormLabel>
                    <CustomTextField
                      {...register("accountholder_name", {
                        required: "This field is required."
                      })}
                      id="outlined-basic"
                      variant="outlined"
                      autoComplete="off"
                      error={errors.accountholder_name}
                      helperText={
                        errors.accountholder_name &&
                        errors.accountholder_name.message
                      }
                      sx={{ width: "100%" }}
                    />
                  </FormGroup>
                  <CustomFormLabel
                    component="legend"
                    className={clx.formLabel}
                    required
                  >
                    Account number
                  </CustomFormLabel>

                  <CustomOutlineTextField
                    {...register("account_number", {
                      required: "This field is required.",
                      validate: validateAccountNumber
                    })}
                    id="outlined-adornment-password"
                    variant="outlined"
                    autoComplete="off"
                    error={errors.account_number}
                    helperText={
                      errors.account_number && errors.account_number.message
                    }
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    sx={{ width: "100%" }}
                  />
                  {!!errors.account_number && (
                    <FormHelperText
                      error
                      sx={{ marginLeft: "12px", marginTop: 0 }}
                    >
                      {errors.account_number.message}
                    </FormHelperText>
                  )}
                  <CustomFormLabel
                    component="legend"
                    className={clx.formLabel}
                    required
                  >
                    Retype account number
                  </CustomFormLabel>
                  <CustomOutlineTextField
                    {...register("account_number1", {
                      required: "This field is required.",
                      validate: validateAccountNumber
                    })}
                    error={errors.account_number1}
                    helperText={
                      errors.account_number1 && errors.account_number1.message
                    }
                    id="outlined-adornment-password"
                    variant="outlined"
                    autoComplete="off"
                    type={showPasswordRetype ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPasswordRetype}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPasswordRetype ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    sx={{ width: "100%" }}
                  />
                  {!!errors.account_number1 && (
                    <FormHelperText
                      error
                      sx={{ marginLeft: "12px", marginTop: 0 }}
                    >
                      {errors.account_number1.message}
                    </FormHelperText>
                  )}
                  <CustomFormLabel
                    component="legend"
                    className={clx.formLabel}
                    required
                  >
                    IFSC code
                  </CustomFormLabel>
                  <CustomTextField
                    {...register("ifsc_code", {
                      required: "This field is required."
                    })}
                    error={errors.ifsc_code}
                    helperText={errors.ifsc_code && errors.ifsc_code.message}
                    id="outlined-basic"
                    variant="outlined"
                    autoComplete="off"
                    sx={{ width: "100%" }}
                  />

                  <CustomFormLabel
                    component="legend"
                    className={clx.formLabel}
                    required
                  >
                    Bank name
                  </CustomFormLabel>
                  <CustomTextField
                    {...register("bank_name", {
                      required: "This field is required."
                    })}
                    error={errors.bank_name}
                    helperText={errors.bank_name && errors.bank_name.message}
                    id="outlined-basic"
                    variant="outlined"
                    autoComplete="off"
                    sx={{ width: "100%" }}
                  />

                  <CustomFormLabel
                    component="legend"
                    className={clx.formLabel}
                    required
                  >
                    {" "}
                    Branch name
                  </CustomFormLabel>
                  <CustomTextField
                    {...register("branch_name", {
                      required: "This field is required."
                    })}
                    error={errors.branch_name}
                    helperText={
                      errors.branch_name && errors.branch_name.message
                    }
                    id="outlined-basic"
                    variant="outlined"
                    autoComplete="off"
                    sx={{ width: "100%" }}
                  />

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "start",
                      paddingTop: "20px"
                    }}
                  >
                    <CustomFormLabel
                      component="legend"
                      className={clx.formLabel}
                      sx={{ width: "55%", marginTop: "9px" }}
                      required
                    >
                      Account Type
                    </CustomFormLabel>

                    <Box sx={{ color: "#717171" }}>
                      <Controller
                        name="account_type"
                        control={control}
                        defaultValue=""
                        rules={{ required: "This field is required." }}
                        render={({ field: { onChange, value } }) => (
                          <Box>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    value === "Checking / Current Account"
                                  }
                                  onChange={() =>
                                    onChange("Checking / Current Account")
                                  }
                                />
                              }
                              label="Checking / Current Account"
                              labelPlacement="end"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={value === "Savings Account"}
                                  onChange={() => onChange("Savings Account")}
                                />
                              }
                              label="Savings Account"
                              labelPlacement="end"
                              sx={{ color: "#717171", fontSize: "14px" }}
                            />
                          </Box>
                        )}
                      />

                      <Typography sx={{ color: "#d32f2f", fontSize: "12px" }}>
                        {errors.account_type && errors.account_type.message}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Controller
                      name="refund_agreement_flag"
                      control={control}
                      defaultValue=""
                      rules={{ required: "This field is required." }}
                      render={({ field: { onChange, value } }) => (
                        <FormControlLabel
                          control={
                            <Checkbox checked={value} onChange={onChange} />
                          }
                          label="I authorise M/S MSV Kahna Santhivanam Pvt. Ltd. to use this account for any refunds.*"
                          labelPlacement="end"
                          sx={{ color: "#717171", fontSize: "14px" }}
                        />
                      )}
                    />
                    <Typography sx={{ color: "#d32f2f", fontSize: "12px" }}>
                      {errors.refund_agreement_flag &&
                        errors.refund_agreement_flag.message}
                    </Typography>
                  </Box>
                  <Box>
                    <Button
                      type="submit"
                      variant="contained"
                      className={clx.btnSubmit}
                      sx={{ margin: "20px 0", textTransform: "capitalize" }}
                    >
                      {isLoading ? (
                        <CircularProgress
                          size={20}
                          sx={{ color: "white", marginLeft: "10px" }}
                        />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </form>
      </Container>
    </Box>
  );
};
const useStyles = makeStyles((theme) => ({
  amount: {
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    color: "#717171",
    paddingBottom: "20px"
  },
  amountTotal: {
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "23px",
    color: "#212121",
    paddingBottom: "20px"
  },
  formLabel: {
    color: "#89939E",
    FontWeight: 600,

    fontSize: "16px",
    lineHeight: "23px",
    marginTop: "22px"
  },
  btnSubmit: {
    display: "inline-block",
    fontSize: "16px",
    fontWeight: 400,
    textTransform: "capitalize",
    color: "white",
    width: "174px",
    height: "48px",

    float: "right",
    backgroundColor: "#0671E0",
    "&:hover": {
      backgroundColor: "#0671E0"
    }
  }
}));
