import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import TableData from "../../../../components/TableData";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import * as Sentry from "@sentry/react";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useQuery } from "@apollo/client";
import { successToast } from "../../../../pages/Toasts/showToasts";
import ErrorToast from "../../../../components/ErrorToast";
import { toast } from "react-toastify";
import moment from "moment";
import { availableProperties } from "../../../../lib/query/query";

export const AvailableProperties = () => {
  const [availableProperty, setAvailableProperty] = useState({});
  const { data, loading, refetch, error } = useQuery(availableProperties, {
    variables: { sale_status: "Available" }
  });

  useEffect(() => {
    if (data) {
      setAvailableProperty(data.property);
    }
  }, [data]);

  if (error) {
    toast.error(
      <ErrorToast
        email={"info@msvkanha.com"}
        errorMsg={error && error?.message}
      />
    );
    Sentry.captureException(error);
  }


  const columns = [
    {
      field: "propertyID",
      headerName: "Property ID",
      width: 150,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "towerName",
      headerName: "Tower Name",
      width: 150,
      align: "center",
      headerAlign: "center"
    },
    {
      field: "unitNumber",
      headerName: "Unit Number",
      width: 150,
      align: "center",
      headerAlign: "center"
    },
    {
      field: "modelName",
      headerName: "Model Name",
      width: 200,
      align: "center",
      headerAlign: "center"
    },
    {
      field: "projectName",
      headerName: "Project Name",
      width: 180,
      align: "center",
      headerAlign: "center"
    },
    {
      field: "beds",
      headerName: "Beds",
      width: 150,
      align: "center",
      headerAlign: "center"
    },
    {
      field: "bath",
      headerName: "Bath",
      width: 130,
      align: "center",
      headerAlign: "center"
    },
    {
      field: "sqFt",
      headerName: "Sq. Ft.",
      width: 150,
      align: "center",
      headerAlign: "center"
    },
    {
      field: "saleStatus",
      headerName: "Sale Status",
      width: 150,
      align: "center",
      headerAlign: "center"
    }
  ];

  const rows =
    availableProperty.length > 0 &&
    availableProperty.map((item, index) => {
      return {
        id: index,
        propertyID: item?.property_id,
        towerName: item?.project_building?.tower_name,
        unitNumber: item?.unit_number,
        modelName: item?.property_model?.model_name,
        projectName: item?.project?.project_name,
        beds: item?.property_model?.beds_count,
        bath: item?.property_model?.baths_count,
        sqFt: item?.property_model?.sqft_area,
        saleStatus: item?.sale_status
      };
    });

  const date = {
    id: "Date:",
    propertyID: new Date()
  };

  const title = {
    id: "Title:",
    propertyID: "Available Properties"
  };

  const time = {
    id: "Time:",
    propertyID: moment().format("hh:mm a")
  };

  const downloadExcel = () => {
    const sheet = XLSX.utils.json_to_sheet(
      (rows && [title, date, time, "", ...rows]) || [],
      { skipHeader: true }
    );
    let Heading = [
      [
        "Id",
        "Property ID",
        "Tower Name",
        "Unit Number",
        "Model Name",
        "Project Name",
        "Beds",
        "Bath",
        "Sq. Ft.",
        "Sale Status"
      ]
    ];
    XLSX.utils.sheet_add_aoa(sheet, Heading, { origin: "A4" });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, sheet, "Sheet1");
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array"
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    });
    saveAs(blob, "AvailableProperties.xlsx");
  };

  const handleRefresh = () => {
    refetch();
    successToast("Available Properties refreshed successfully");
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Box sx={{ paddingLeft: "14px !important" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "25px",
                  lineHeight: "30px",
                  color: "#212121",
                  margin: "30px 0px 30px 0px"
                }}
              >
                Available Properties
              </Typography>
            </Box>
            <RefreshIcon
              sx={{ cursor: "pointer", marginLeft: "5px" }}
              onClick={handleRefresh}
            />
            <Box sx={{ display: "flex", marginLeft: "auto" }}>
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: "white",
                  color: "#0671E0",
                  fontWeight: 400,
                  lineHeight: "24px",
                  textTransform: "capitalize",
                  fontSize: "16px",
                  "&:hover": {
                    backgrindColor: "white"
                  },
                  padding: "16px, 32px, 16px, 32px",
                  width: "151px",
                  height: "40px",
                  gap: "10px",
                  border: "1px solid #0671E0",
                  textAlign: "Center"
                }}
                onClick={downloadExcel}
              >
                Download XLS
              </Button>
            </Box>
          </Box>
          {loading ? (
            <CircularProgress
              sx={{
                width: "40px !important",
                height: "40px !important",
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            />
          ) : (
            <>
              {!loading && rows.length > 0 && (
                <TableData
                  rows={rows}
                  columns={columns}
                  HeaderbackgroundColor={"#F5F7FA"}
                  tableHeaderAlign={"center !important"}
                  rowPerPage={50}
                  noDataFound={"No records found"}
                />
              )}
            </>
          )}
        </Box>
      </Grid>
    </Box>
  );
};
