import React from "react";
import { toast } from "react-toastify";

const ErrorToast = ({ email, errorMsg }) => {
  const handleClick = () => {
    window.location.href = `mailto:${email}`;
    toast.dismiss();
  };

  return (
    <div>
      <div
        style={{
          backgroundColor: "red",
          padding: "10px",
          color: "white",
          borderRadius: "6px"
        }}
      >
        {errorMsg}
      </div>
      Please try again. If it fails, you may reach out to help desk at&nbsp;
      <a
        href={`mailto:info@msvkanha.com`}
        onClick={handleClick}
        style={{ color: "blue" }}
      >
        info@msvkanha.com
      </a>
      . Please include a screenshot of this error server error message below.
    </div>
  );
};

export default ErrorToast;
