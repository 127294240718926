import React from "react";
import { Routes, Route } from "react-router-dom";
import PageWrapper from "../pages/PageWrapper";
import DashboardTabLayout from "../components/Layouts/DashboardTabLayout";
import BuyerProfile from "../containers/tabView/Dashboard/BuyerProfile";
import { IdVerificationRequest } from "../containers/tabView/Dashboard/IdVerificationRequest";
import { CancellationRequests } from "../containers/tabView/Dashboard/CancellationRequests";
import { BookingRequests } from "../containers/tabView/Dashboard/BookingRequests";
import { BuyerSearch } from "../containers/tabView/Dashboard/BuyerSearch";
import AdminHome from "../containers/tabView/Dashboard/Home";
import ReportsTabs from "../containers/tabView/Dashboard/ReporsTabs/ReportsTabs";
import { DashboardChart } from "../containers/tabView/Dashboard/DashboardChart";

const AdminRouter = () => {

  return (
    <>
      <PageWrapper>
        <Routes>
          {/* <Route
            path="/dashboard"
            element={
              <DashboardTabLayout>
                <Dashboard />
              </DashboardTabLayout>
            }
          /> */}
          <Route path="/" element={<AdminHome />} />

          <Route
            path="/admin/dashboardIdVerification"
            element={
              <DashboardTabLayout>
                <IdVerificationRequest />
              </DashboardTabLayout>
            }
          />
          

          <Route
            path="/admin/cancellation"
            element={
              <DashboardTabLayout>
                <CancellationRequests />
              </DashboardTabLayout>
            }
          />

          <Route
            path="/admin/bookings"
            element={
              <DashboardTabLayout>
                <BookingRequests />
              </DashboardTabLayout>
            }
          />

          <Route
            path="/admin/transactionSearch"
            element={
              <DashboardTabLayout>
                <BuyerSearch />
              </DashboardTabLayout>
            }
          />

          <Route
            path="/admin/reports"
            element={
              <DashboardTabLayout>
                <ReportsTabs />
              </DashboardTabLayout>
            }
          />

          <Route
            path="/admin/dashboard"
            element={
              <DashboardTabLayout>
                <DashboardChart />
              </DashboardTabLayout>
            }
          />

          {/* <Route
            path="/admin/buyerProfile/:buyerId/:salesId/:propertyId"
            element={
              <DashboardTabLayout>
                <BuyerProfile />
              </DashboardTabLayout>
            }
          /> */}

          <Route
            path="/admin/buyerProfile/"
            element={
              <DashboardTabLayout>
                <BuyerProfile />
              </DashboardTabLayout>
            }
          >
            <Route
              path=":buyerId"
              element={
                <DashboardTabLayout>
                </DashboardTabLayout>
              }
            />
            <Route
              path=":buyerId/:salesId"
              element={
                <DashboardTabLayout>
                  <BuyerProfile />
                </DashboardTabLayout>
              }
            />
            <Route
              path=":buyerId/:salesId/:propertyId"
              element={
                <DashboardTabLayout>
                  <BuyerProfile />
                </DashboardTabLayout>
              }
            />
          </Route>
        </Routes>
      </PageWrapper>
    </>
  );
};

export default AdminRouter;
