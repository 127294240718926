import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {
  Container,
  Menu,
  MenuItem,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const TabContainer = ({ tabsData, maxWidthContainer }) => {
  const navigate = useNavigate();
  const { salesId } = useParams();
  const [activeIndex, setActiveIndex] = useState(0);
  const [tabValue, setTabValue] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeIndexGlobal, setActiveIndexGlobal] = useState(0);


  const location = window.location.pathname;
  useEffect(() => {
    const paymentFiter = location.startsWith("/payments");
    const buyerProfile = location.startsWith(`/admin/buyerProfile/`);
    if (paymentFiter) {
      setActiveIndexGlobal(1);
    }
    else if (buyerProfile) {
      setActiveIndexGlobal(activeIndex);
    }
    else {
      const count = tabsData.filter(
        (item) => item.path === window.location.pathname
      );
      setActiveIndexGlobal(count.length > 0 ? count[0].id : 0);
    }
  }, [location, activeIndexGlobal]);

  useEffect(() => {
    setActiveIndex(activeIndexGlobal);
  }, [activeIndexGlobal]);

  const handleTab = (newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (tabValue.path) {
      setActiveIndexGlobal(tabValue.id);
      navigate(tabValue.path);
      setTabValue({});
    }
  }, [tabValue]);

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const NavigateSchedule = () => {
    const pathName = `/payments/paymentSchedule/${salesId}`;
    navigate(pathName);
    handleClose();
  };
  const NavigateHistory = () => {
    const pathName = "/payments/paymentHistory";
    navigate(pathName);
    handleClose();
  };


  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <Box sx={{ width: "100%", mb: "40px" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Container maxWidth={maxWidthContainer ? maxWidthContainer : "lg"}>
            <Tabs
              value={activeIndex}
              aria-label="basic tabs example"
              sx={{ height: "55px" }}
              className="tab-space"
            >
              {tabsData.map((item, index) => (
                <Box key={index} sx={{
                  "& .MuiTab-textColorInherit": {
                    opacity: "1 !important"
                  }
                }}>
                  <Tab
                    key={index}
                    aria-owns={anchorEl ? "simple-menu" : undefined}
                    aria-haspopup="true"
                    onMouseOver={
                      item.label === "Payments"
                        ? handleClick
                        : handlePopoverClose
                    }
                    label={item.label}
                    icon={item.icon}
                    iconPosition={item.iconPosition}
                    {...a11yProps(index)}
                    sx={{
                      textTransform: "capitalize",
                      color: "#4D4D4D",
                      minHeight: "55px",

                    }}
                    onClick={
                      item.label === "Payments"
                        ? handleClick
                        : () => handleTab(item)
                    }
                  />

                  <Menu
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    transformOrigin={{ vertical: "top", horizontal: "left" }}
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    MenuListProps={{ onMouseLeave: handleClose }}
                    getContentAnchorEl={null}
                    sx={{
                      "& .MuiPaper-root": {
                        boxShadow: "none !important",
                        border: "1px solid #E5E5E5"
                      },
                    }}
                  >
                    <MenuItem
                      onClick={NavigateSchedule}
                      sx={{
                        color: "#4D4D4D",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "19.6px",
                        boxShadow: "none !important",
                        padding: "14px 15px"
                      }}
                    >
                      <img
                        src="/assets/icons/ant-design_schedule-outlined.svg"
                        alt="payment Schedule"
                        style={{ marginRight: "10px", color: "#4D4D4D", }}
                      />{" "}
                      Payment Schedule
                    </MenuItem>
                    <hr />
                    <MenuItem
                      onClick={NavigateHistory}
                      sx={{
                        color: "#4D4D4D",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "19.6px",
                        boxShadow: "none !important",
                        padding: "14px 15px"
                      }}
                    >
                      <img
                        src="/assets/icons/solar_history-outline.svg"
                        alt="payment History"
                        style={{ marginRight: "10px", color: "#4D4D4D", }}
                      />{" "}
                      Payment History
                    </MenuItem>
                  </Menu>
                </Box>
              ))}
            </Tabs>

            {/* <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
            >
              <MenuItem onClick={NavigateSchedule} sx={{ color: "#4D4D4D", fontSize: "14px", fontWeight: "400", lineHeight:"19.6px"}}>
                <img src="/assets/icons/ant-design_schedule-outlined.svg" alt="payment Schedule" style={{ marginRight: '10px' }} /> Payment Schedule
              </MenuItem>
              <MenuItem onClick={NavigateHistory} sx={{ color: "#4D4D4D", fontSize: "14px", fontWeight: "400", lineHeight: "19.6px" }}>
                <img src="/assets/icons/solar_history-outline.svg" alt="payment History" style={{marginRight: '10px'}}/> Payment History
              </MenuItem>
              
            </Popover> */}
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default TabContainer;
