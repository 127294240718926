import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select
} from "@mui/material";
import TableData from "../../../../components/TableData";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  cashFlowCity,
  cashFlowProject,
  cashFlowTowerTotal1,
  cashFlowUniqueListTowers
} from "../../../../lib/query/query";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import ErrorToast from "../../../../components/ErrorToast";
import moment from "moment";
import * as Sentry from "@sentry/react";

export const CashFlowbyTower = () => {
  const IndianCurrency = new Intl.NumberFormat("en-IN");

  const [city, setCity] = useState("");
  const [project, setProject] = useState("");
  const [cityData, setCityData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [cashFlowSet, setCashFlowSet] = useState([]);

  const { data, error } = useQuery(cashFlowCity);

  const [getCashFlowProject, { data: dataProject, error: errorProject }] =
    useLazyQuery(cashFlowProject, {
      variables: { city: city }
    });

  const [
    getcashFlowTowers,
    {
      refetch: refetchCashFlowTowers,
      data: cashFlowTowersData,
      error: errorCashFlowTowers
    }
  ] = useLazyQuery(cashFlowUniqueListTowers, {
    variables: { city: city, project_name: project }
  });

  const [cashFlowTowerEachRows, { error: errorTowerEachRow }] =
    useLazyQuery(cashFlowTowerTotal1);

  const columns = [
    {
      field: "tower",
      headerName: "Tower",
      width: 350,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "totalReceivables",
      headerName: "Total Receivables",
      width: 350,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <span>{IndianCurrency.format(params?.value)}</span>
      )
    },
    {
      field: "amountReceived",
      headerName: "Amount Received",
      width: 350,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <span>{IndianCurrency.format(params?.value)}</span>
      )
    },
    {
      field: "outstanding",
      headerName: "Outstanding",
      width: 350,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <span>{IndianCurrency.format(params?.value)}</span>
      )
    }
  ];

  const handleChange = (event) => {
    setCity(event.target.value);
  };
  const handleChangeProject = (event) => {
    setProject(event.target.value);
  };

  useEffect(() => {
    if (data && data.project.length > 0) {
      setCityData(data && data.project);
    }
  }, [data]);

  useEffect(() => {
    if (city) {
      getCashFlowProject();
    }
  }, [city]);

  useEffect(() => {
    if (dataProject && dataProject.project.length > 0) {
      setProjectData(dataProject.project);
    }
  }, [dataProject]);

  useEffect(() => {
    if (city && project) {
      getcashFlowTowers();
      refetchCashFlowTowers();
    }
  }, [city, project]);

  useEffect(() => {
    if (cashFlowTowersData?.v_cash_flow.length > 0) {
      const towersFetch = cashFlowTowersData?.v_cash_flow.map(
        async (item, index) =>
          await cashFlowTowerEachRows({
            variables: {
              city: city,
              project_name: project,
              tower_name: item.tower_name
            }
          })
      );

      Promise.all(towersFetch)
        .then((results) => {
          setCashFlowSet(results);
        })
        .catch((err) => {
          Sentry.captureException(err);
          console.error("Error while fetching data: ", err);
        });
    }
  }, [cashFlowTowersData, cashFlowTowerEachRows]);

  let cashFlowSetData = [];
  cashFlowSet.map((item) => {
    cashFlowSetData.push(item.data);
  });

  let rows = [];
  if (cashFlowTowersData?.v_cash_flow.length > 0) {
    cashFlowTowersData?.v_cash_flow.map((item, index) => {
      const obj = {
        id: index + 1,
        tower: item?.tower_name,
        totalReceivables:
          cashFlowSetData[index]?.v_cash_flow_aggregate?.aggregate?.sum?.booked,
        amountReceived:
          cashFlowSetData[index]?.v_cash_flow_aggregate?.aggregate?.sum
            ?.received,
        outstanding:
          cashFlowSetData[index]?.v_cash_flow_aggregate?.aggregate?.sum
            ?.outstanding
      };
      rows.push(obj);
    });
  }
  const date = {
    id: "Date:",
    tower: new Date()
  };

  const title = {
    id: "Title:",
    tower: "Cash Flow By Tower"
  };

  const time = {
    id: "Time:",
    tower: moment().format("hh:mm a")
  };

  let selectedCity = {
    id: "City:",
    tower: city === "" ? "N/A" : city
  };
  let selectedProject = {
    id: "Project:",
    tower: project === "" ? "N/A" : project
  };

  const generateExcelRow = (rows, selectedCity, selectedProject) => {
    return [title, date, time, selectedCity, selectedProject, "", ...rows];
  };
  const downloadExcel = () => {
    const sheet = XLSX.utils.json_to_sheet(
      generateExcelRow(rows, selectedCity, selectedProject),
      { skipHeader: true }
    );

    const wb = XLSX.utils.book_new();
    let Heading = [
      ["Id", "Tower", "Total Receivables", "Amount Received", "Outstanding", ""]
    ];
    XLSX.utils.sheet_add_aoa(sheet, Heading, { origin: "A6" });
    XLSX.utils.book_append_sheet(wb, sheet, "Sheet1");
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array"
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    });
    saveAs(blob, "inventory.xlsx");
  };

  if (error) {
    toast.error(
      <ErrorToast
        email={"info@msvkanha.com"}
        errorMsg={
          (error && error?.message) ||
          (errorProject && errorProject?.message) ||
          (errorCashFlowTowers && errorCashFlowTowers?.message) ||
          (errorTowerEachRow && errorTowerEachRow?.message)
        }
      />
    );
    Sentry.captureException(
      (error && error?.message) ||
        (errorProject && errorProject?.message) ||
        (errorCashFlowTowers && errorCashFlowTowers?.message) ||
        (errorTowerEachRow && errorTowerEachRow?.message)
    );
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6} md={5}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Box>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    value={city}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{ color: "inherit" }}
                  >
                    <MenuItem selected disabled value="">
                      Select City
                    </MenuItem>
                    {cityData.length > 0 &&
                      cityData.map((item) => {
                        return (
                          <MenuItem value={item.city}>{item.city}</MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6} md={6}>
              <Box>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    value={project}
                    onChange={handleChangeProject}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{ color: "inherit" }}
                  >
                    <MenuItem selected disabled value="">
                      Select Project
                    </MenuItem>
                    {projectData.length > 0 &&
                      projectData.map((item) => {
                        return (
                          <MenuItem value={item.project_name}>
                            {item.project_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} md={7}>
          <Grid container>
            <Grid item xs={6} md={3}>
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#0671E0",
                    color: "white",
                    fontWeight: 400,
                    lineHeight: "24px",
                    textTransform: "capitalize",
                    fontSize: "18px",
                    width: "164px",
                    height: "56px",
                    padding: "16px, 32px, 16px, 32px",
                    gap: "8px",
                    borderRadius: "4px",
                    "&:hover": {
                      backgrindColor: "#0671E0"
                    }
                  }}
                >
                  Search
                  <img src="/assets/image/Search.png" alt="SearchIcon" />
                </Button>
              </Box>
            </Grid>
            <Grid item xs={6} md={3}>
              <Box>
                <Button
                  variant="outlined"
                  sx={{
                    backgroundColor: "white",
                    color: "#0671E0",
                    fontWeight: 400,
                    lineHeight: "24px",
                    textTransform: "capitalize",
                    fontSize: "16px",
                    "&:hover": {
                      backgrindColor: "white"
                    },
                    padding: "16px, 32px, 16px, 32px",
                    width: "151px",
                    height: "56px",
                    gap: "10px",
                    border: "1px solid #0671E0",
                    textAlign: "Center"
                  }}
                  onClick={downloadExcel}
                >
                  Download XLS
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ marginTop: "30px" }}>
        <TableData
          rows={rows}
          columns={columns}
          HeaderbackgroundColor={"#F5F7FA"}
          noDataFound={"Select City And Project To Retrieve Data"}
        />
      </Box>
    </Box>
  );
};
