import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
} from "@mui/material";
import TableData from "../../../../components/TableData";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  cashFlowCity,
  cashFlowCityEachRow,
  cashFlowCityList,
  cashFLowCityTotal,
  cashFlowEachRows,
  cashFlowProject,
  cashFlowProjectEachRow,
  cashFlowProjectList,
  cashFlowProjectTotal,
  cashFlowTotal,
  cashFlowTower,
  cashFlowTowerEachRow,
  cashFlowTowerList,
  cashFlowTowerTotal,
  cashFlowUniqueList
} from "../../../../lib/query/query";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import ErrorToast from "../../../../components/ErrorToast";
import moment from "moment";
import * as Sentry from "@sentry/react";

export const CashFlowbyProject = () => {
  const [city, setCity] = useState("");
  const [project, setProject] = useState("");
  const [tower, setTower] = useState("");
  const [cityData, setCityData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [projectID, setProjectID] = useState("");
  const [projectName, setProjectName] = useState("");
  const [towerName, setTowerName] = useState("");
  const [towerData, setTowerData] = useState([]);
  const [cashFlowSet, setCashFlowSet] = useState([]);
  const [cashFlowCitySet, setCashFlowCitySet] = useState([]);
  const [cashFlowProjectSet, setCashFlowProjectSet] = useState([]);
  const [cashFlowTowerSet, setCashFlowTowerSet] = useState([]);

  const IndianCurrency = new Intl.NumberFormat("en-IN");

  const { data, error } = useQuery(cashFlowCity);
  const { data: cashFlowData, error: errorCashFlow } =
    useQuery(cashFlowUniqueList);
  const { data: invTotal, error: errorInvTotal } = useQuery(cashFlowTotal);

  const [getCashFlowEachRows, { error: errorInvEachRows }] =
    useLazyQuery(cashFlowEachRows);

  const [getCashFlowProject, { data: dataProject, error: errorDataProject }] =
    useLazyQuery(cashFlowProject, {
      variables: { city: city }
    });

  const [getCashFlowTower, { data: dataTower, error: errorDataTower }] =
    useLazyQuery(cashFlowTower, {
      variables: { project_id: projectID }
    });

  const [getCashFlowCityList, { data: dataCityList, error: errorCityList }] =
    useLazyQuery(cashFlowCityList, {
      variables: { city: city }
    });
  const [
    getCashFlowCityEachRow,
    { error: errorCityEachRow }
  ] = useLazyQuery(cashFlowCityEachRow);

  const [getCashFlowCityTotal, { data: dataCityTotal, error: errorCityTotal }] =
    useLazyQuery(cashFLowCityTotal, {
      variables: { city: city }
    });

  const [
    getCashFlowProjectList,
    {
      data: dataProjectList,
      refetch: refetchProjectList,
      error: errorProjectList
    }
  ] = useLazyQuery(cashFlowProjectList, {
    variables: { city: city, project_name: projectName }
  });

  const [
    getCashFlowProjectTotal,
    {
      data: dataProjectTotal,
      refetch: refetchProjectTotal,
      error: errorProjectTotal
    }
  ] = useLazyQuery(cashFlowProjectTotal, {
    variables: { city: city, project_name: projectName }
  });

  const [
    getCashFlowProjectEachRow,
    {
      error: errorProjectEachRow
    }
  ] = useLazyQuery(cashFlowProjectEachRow);

  const [
    getCashFlowTowerList,
    { data: dataTowerList, refetch: refetchTowerList, error: errorTowerList }
  ] = useLazyQuery(cashFlowTowerList, {
    variables: {
      city: city,
      project_name: projectName,
      tower_name: towerName
    }
  });

  const [
    getCashFlowTowerTotal,
    { data: dataTowerTotal, refetch: refetchTowerTotal, error: errorTowerTotal }
  ] = useLazyQuery(cashFlowTowerTotal, {
    variables: {
      city: city,
      project_name: projectName,
      tower_name: towerName
    }
  });

  const [
    getCashFlowTowerEachRow,
    {
      error: errorTowerEachRow
    }
  ] = useLazyQuery(cashFlowTowerEachRow);

  useEffect(() => {
    if (cashFlowData?.v_cash_flow.length > 0) {
      const modalIdToFetch = cashFlowData.v_cash_flow.map(
        async (item) =>
          await getCashFlowEachRows({
            variables: { model_id: item?.model_id }
          })
      );
      Promise.all(modalIdToFetch)
        .then((results) => {
          setCashFlowSet(results);
        })
        .catch((err) => {
          Sentry.captureException(err);
          console.error("Error while fetching data: ", err);
        });
    }
  }, [cashFlowData, getCashFlowEachRows]);

  let cashFlowSetData = [];
  cashFlowSet.map((item) => {
    cashFlowSetData.push(item.data);
  });

  useEffect(() => {
    if (dataCityList?.v_cash_flow.length > 0) {
      const modalIdToFetch =
        dataCityList &&
        dataCityList.v_cash_flow.map(
          async (item) =>
            await getCashFlowCityEachRow({
              variables: { model_id: item?.model_id, city: city }
            })
        );
      Promise.all(modalIdToFetch)
        .then((results) => {
          setCashFlowCitySet(results);
        })
        .catch((err) => {
          Sentry.captureException(err);
          console.error("Error while fetching data: ", err);
        });
    }
  }, [dataCityList, getCashFlowCityEachRow]);

  let cashFlowCityData = [];
  cashFlowCitySet.map((item) => {
    cashFlowCityData.push(item.data);
  });

  useEffect(() => {
    if (dataProjectList?.v_cash_flow.length > 0) {
      const modalIdToFetch = dataProjectList.v_cash_flow.map(
        async (item) =>
          await getCashFlowProjectEachRow({
            variables: {
              model_id: item?.model_id,
              city: city,
              project_name: projectName
            }
          })
      );
      Promise.all(modalIdToFetch)
        .then((results) => {
          setCashFlowProjectSet(results);
        })
        .catch((err) => {
          Sentry.captureException(err);
          console.error("Error while fetching data: ", err);
        });
    }
  }, [dataProjectList, getCashFlowProjectEachRow]);

  let cashFlowProjectData = [];
  cashFlowProjectSet.map((item) => {
    cashFlowProjectData.push(item.data);
  });

  useEffect(() => {
    if (dataTowerList?.v_cash_flow.length > 0) {
      const modalIdToFetch = dataTowerList.v_cash_flow.map(
        async (item) =>
          await getCashFlowTowerEachRow({
            variables: {
              model_id: item?.model_id,
              city: city,
              project_name: projectName,
              tower_name: towerName
            }
          })
      );
      Promise.all(modalIdToFetch)
        .then((results) => {
          setCashFlowTowerSet(results);
        })
        .catch((err) => {
          Sentry.captureException(err);
          console.error("Error while fetching data: ", err);
        });
    }
  }, [dataTowerList, getCashFlowTowerEachRow]);

  let cashFlowTowerData = [];
  cashFlowTowerSet.map((item) => {
    cashFlowTowerData.push(item.data);
  });

  useEffect(() => {
    if (city) {
      getCashFlowCityList();
      getCashFlowCityTotal();
    }
  }, [city]);

  useEffect(() => {
    if (city && project) {
      getCashFlowProjectList();
      getCashFlowProjectTotal();
    }
  }, [city, project]);

  useEffect(() => {
    if (city && project && tower) {
      getCashFlowTowerList();
      getCashFlowTowerTotal();
    }
  }, [city, project, tower]);

  useEffect(() => {
    if (data && data.project.length > 0) {
      setCityData(data && data.project);
    }
  }, [data]);

  useEffect(() => {
    if (city) {
      getCashFlowProject();
    }
  }, [city]);

  useEffect(() => {
    if (dataProject && dataProject.project.length > 0) {
      setProjectData(dataProject.project);
    }
  }, [dataProject]);

  useEffect(() => {
    if (projectID) {
      getCashFlowTower();
    }
  }, [projectID]);

  useEffect(() => {
    if (dataTower && dataTower.project_building.length > 0) {
      setTowerData(dataTower.project_building);
    }
  }, [dataTower]);

  useEffect(() => {
    if (projectName) {
      refetchProjectList();
      refetchProjectTotal();
    }
  }, [projectName]);

  useEffect(() => {
    if (towerName) {
      refetchTowerList();
      refetchTowerTotal();
    }
  }, [towerName]);

  const columns = [
    {
      field: "property",
      headerName: "Property",
      width: 250,
      align: "center",
      cellClassName: (params) =>
        params.row.property === "Total" ? "total-cell" : ""
    },
    {
      field: "totalValue",
      headerName: "Total Value",
      width: 290,
      renderCell: (params) => <span>{IndianCurrency.format(params?.value)}</span>,
      align: "center",
      cellClassName: (params) =>
        params.row.property === "Total" ? "total-cell" : ""
    },
    {
      field: "receivablesBookedFlats",
      headerName: "Receivables-Booked Flats",
      width: 290,
      renderCell: (params) => <span>{IndianCurrency.format(params?.value)}</span>,
      align: "center",
      cellClassName: (params) =>
        params.row.property === "Total" ? "total-cell" : ""
    },
    {
      field: "amountReceived",
      headerName: "Amount Received",
      width: 280,
      renderCell: (params) => <span>{IndianCurrency.format(params?.value)}</span>,
      align: "center",
      cellClassName: (params) =>
        params.row.property === "Total" ? "total-cell" : ""
    },
    {
      field: "outstandingReceivables",
      headerName: "Outstanding Receivables",
      width: 328,
      renderCell: (params) => (
        <span>
          {params?.value
            ? IndianCurrency.format(Math.abs(params?.value))
            : IndianCurrency.format(params?.value)}
        </span>
      ),
      align: "center",
      cellClassName: (params) =>
        params.row.property === "Total" ? "total-cell" : ""
    }
  ];
  let rows = [];
  if (cashFlowData?.v_cash_flow.length > 0) {
    cashFlowData?.v_cash_flow.map((item, index) => {
      const obj = {
        id: index + 1,
        model_id: item?.model_id,
        property: item.model_name,
        totalValue:
          cashFlowSetData[index]?.v_cash_flow_aggregate?.aggregate?.sum?.total,
        receivablesBookedFlats:
          cashFlowSetData[index]?.v_cash_flow_aggregate?.aggregate?.sum?.booked,
        amountReceived:
          cashFlowSetData[index]?.v_cash_flow_aggregate?.aggregate?.sum
            ?.received,
        outstandingReceivables:
          cashFlowSetData[index]?.v_cash_flow_aggregate?.aggregate?.sum
            ?.outstanding
      };
      rows.push(obj);
    });
  }

  let rowsCity = [];
  if (dataCityList?.v_cash_flow.length > 0) {
    dataCityList?.v_cash_flow.map((item, index) => {
      const obj = {
        id: index + 1,
        model_id: item?.model_id,
        property: item.model_name,
        totalValue:
          cashFlowCityData[index]?.v_cash_flow_aggregate?.aggregate?.sum?.total,
        receivablesBookedFlats:
          cashFlowCityData[index]?.v_cash_flow_aggregate?.aggregate?.sum
            ?.booked,
        amountReceived:
          cashFlowCityData[index]?.v_cash_flow_aggregate?.aggregate?.sum
            ?.received,
        outstandingReceivables:
          cashFlowCityData[index]?.v_cash_flow_aggregate?.aggregate?.sum
            ?.outstanding
      };
      rowsCity.push(obj);
    });
  }

  let rowsProject = [];

  if (dataProjectList?.v_cash_flow.length > 0) {
    dataProjectList?.v_cash_flow.map((item, index) => {
      const obj = {
        id: index + 1,
        model_id: item?.model_id,
        property: item.model_name,
        totalValue:
          cashFlowProjectData[index]?.v_cash_flow_aggregate?.aggregate?.sum
            ?.total,
        receivablesBookedFlats:
          cashFlowProjectData[index]?.v_cash_flow_aggregate?.aggregate?.sum
            ?.booked,
        amountReceived:
          cashFlowProjectData[index]?.v_cash_flow_aggregate?.aggregate?.sum
            ?.received,
        outstandingReceivables:
          cashFlowProjectData[index]?.v_cash_flow_aggregate?.aggregate?.sum
            ?.outstanding
      };
      rowsProject.push(obj);
    });
  }

  let rowsTower = [];

  if (dataTowerList?.v_cash_flow.length > 0) {
    dataTowerList?.v_cash_flow.map((item, index) => {
      const obj = {
        id: index + 1,
        model_id: item?.model_id,
        property: item.model_name,
        totalValue:
          cashFlowTowerData[index]?.v_cash_flow_aggregate?.aggregate?.sum
            ?.total,
        receivablesBookedFlats:
          cashFlowTowerData[index]?.v_cash_flow_aggregate?.aggregate?.sum
            ?.booked,
        amountReceived:
          cashFlowTowerData[index]?.v_cash_flow_aggregate?.aggregate?.sum
            ?.received,
        outstandingReceivables:
          cashFlowTowerData[index]?.v_cash_flow_aggregate?.aggregate?.sum
            ?.outstanding
      };
      rowsTower.push(obj);
    });
  }

  let sample = {
    id: 1212,
    property: "Total",
    totalValue: invTotal?.v_cash_flow_aggregate?.aggregate?.sum?.total,
    receivablesBookedFlats:
      invTotal?.v_cash_flow_aggregate?.aggregate?.sum?.booked,
    amountReceived: invTotal?.v_cash_flow_aggregate?.aggregate?.sum?.received,
    outstandingReceivables:
      invTotal?.v_cash_flow_aggregate?.aggregate?.sum?.outstanding
  };

  let sampleCity = {
    id: 12121,
    property: "Total",
    totalValue: dataCityTotal?.v_cash_flow_aggregate?.aggregate?.sum?.total,
    receivablesBookedFlats:
      dataCityTotal?.v_cash_flow_aggregate?.aggregate?.sum?.booked,
    amountReceived:
      dataCityTotal?.v_cash_flow_aggregate?.aggregate?.sum?.received,
    outstandingReceivables:
      dataCityTotal?.v_cash_flow_aggregate?.aggregate?.sum?.outstanding
  };

  let sampleProject = {
    id: 2323,
    property: "Total",
    totalValue: dataProjectTotal?.v_cash_flow_aggregate?.aggregate?.sum?.total,
    receivablesBookedFlats:
      dataProjectTotal?.v_cash_flow_aggregate?.aggregate?.sum?.booked,
    amountReceived:
      dataProjectTotal?.v_cash_flow_aggregate?.aggregate?.sum?.received,
    outstandingReceivables:
      dataProjectTotal?.v_cash_flow_aggregate?.aggregate?.sum?.outstanding
  };

  let sampleTower = {
    id: 32324,
    property: "Total",
    totalValue: dataTowerTotal?.v_cash_flow_aggregate?.aggregate?.sum?.total,
    receivablesBookedFlats:
      dataTowerTotal?.v_cash_flow_aggregate?.aggregate?.sum?.booked,
    amountReceived:
      dataTowerTotal?.v_cash_flow_aggregate?.aggregate?.sum?.received,
    outstandingReceivables:
      dataTowerTotal?.v_cash_flow_aggregate?.aggregate?.sum?.outstanding
  };

  const handleChange = (event) => {
    setCity(event.target.value);
  };

  const handleChangeProject = (event) => {
    setProject(event.target.value);
    setProjectID(event.target.value);
    const selectedProjectId = event.target.value;
    const selectedProject = projectData.find(
      (item) => item.project_id === selectedProjectId
    );
    setProjectName(selectedProject.project_name);
  };

  const handleChangeTower = (event) => {
    setTower(event.target.value);
    const selectedTowerId = event.target.value;
    const selectedTower = towerData.find(
      (item) => item.building_id === selectedTowerId
    );
    setTowerName(selectedTower.tower_name);
  };


  const date = {
    id: "Date:",
    property: new Date(),
  };

  const title = {
    id: "Title:",
    property: "Cash Flow By Project",
  };

  const time = {
    id: "Time:",
    property: moment().format('hh:mm a'),
  };

  let selectedCity = {
    id: "City:",
    property: city === "" ? 'N/A' : city,
  }

  let selectedProject = {
    id: "Project:",
    property: projectName === "" ? 'N/A' : projectName,
  }

  let selectedTower = {
    id: "Tower:",
    property: towerName === "" ? 'N/A' : towerName,
  }

  const generateExcelRow = (sample, rows, selectedCity, selectedProject, selectedTower) => {
    return [
      title,
      date,
      time,
      selectedCity,
      selectedProject,
      selectedTower,
      '',
      sample,
      ...rows,
    ];
  };
  

  const downloadExcel = () => {
    const sheet = XLSX.utils.json_to_sheet(
      (city
        ? project
          ? tower
            ? rowsTower && generateExcelRow(sampleTower, rowsTower, selectedCity, selectedProject, selectedTower)
            : rowsProject && generateExcelRow(sampleProject, rowsProject, selectedCity, selectedProject, selectedTower)
          : rowsCity && generateExcelRow(sampleCity, rowsCity, selectedCity, selectedProject, selectedTower)
        : rows && generateExcelRow(sample, rows, selectedCity, selectedProject, selectedTower)) || []
      , { skipHeader: true });

    const wb = XLSX.utils.book_new();
    let Heading = [['Id', 'Property', 'TotalValue', 'Receivables Booked Flats', 'Amount Received', 'Outstanding Receivables', 'Model Id']];
    XLSX.utils.sheet_add_aoa(sheet, Heading, { origin: 'A7' });
    XLSX.utils.book_append_sheet(wb, sheet, "Sheet1");
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array"
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    });
    saveAs(blob, "CashFlow.xlsx");
  };

  if (
    error ||
    errorCashFlow ||
    errorInvTotal ||
    errorInvEachRows ||
    errorDataProject ||
    errorDataTower ||
    errorCityList ||
    errorCityEachRow ||
    errorCityTotal ||
    errorProjectList ||
    errorProjectTotal ||
    errorProjectEachRow ||
    errorTowerList ||
    errorTowerTotal ||
    errorTowerEachRow
  ) {
    toast.error(
      <ErrorToast
        email={"info@msvkanha.com"}
        errorMsg={
          (error && error?.message) ||
          (errorCashFlow && errorCashFlow?.message) ||
          (errorInvTotal && errorInvTotal?.message) ||
          (errorInvEachRows && errorInvEachRows?.message) ||
          (errorDataProject && errorDataProject?.message) ||
          (errorDataTower && errorDataTower?.message) ||
          (errorCityList && errorCityList?.message) ||
          (errorCityEachRow && errorCityEachRow?.message) ||
          (errorCityTotal && errorCityTotal?.message) ||
          (errorProjectList && errorProjectList?.message) ||
          (errorProjectTotal && errorProjectTotal?.message) ||
          (errorProjectEachRow && errorProjectEachRow?.message) ||
          (errorTowerList && errorTowerList?.message) ||
          (errorTowerTotal && errorTowerTotal?.message) ||
          (errorTowerEachRow && errorTowerEachRow?.message)
        }
      />
    );
    Sentry.captureException(
      (error && error?.message) ||
      (errorCashFlow && errorCashFlow?.message) ||
      (errorInvTotal && errorInvTotal?.message) ||
      (errorInvEachRows && errorInvEachRows?.message) ||
      (errorDataProject && errorDataProject?.message) ||
      (errorDataTower && errorDataTower?.message) ||
      (errorCityList && errorCityList?.message) ||
      (errorCityEachRow && errorCityEachRow?.message) ||
      (errorCityTotal && errorCityTotal?.message) ||
      (errorProjectList && errorProjectList?.message) ||
      (errorProjectTotal && errorProjectTotal?.message) ||
      (errorProjectEachRow && errorProjectEachRow?.message) ||
      (errorTowerList && errorTowerList?.message) ||
      (errorTowerTotal && errorTowerTotal?.message) ||
      (errorTowerEachRow && errorTowerEachRow?.message)
    );
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6} md={9}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <Box>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    value={city}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{ color: "inherit" }}
                  >
                    <MenuItem selected disabled value="">
                      Select City
                    </MenuItem>
                    {cityData.length > 0 &&
                      cityData.map((item) => {
                        return (
                          <MenuItem value={item.city}>{item.city}</MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    value={project}
                    onChange={handleChangeProject}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{ color:  "inherit" }}
                  >
                    <MenuItem selected disabled value="">
                      Select Project
                    </MenuItem>
                    {projectData.length > 0 &&
                      projectData.map((item) => {
                        return (
                          <MenuItem value={item.project_id}>
                            {item.project_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    value={tower}
                    onChange={handleChangeTower}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem selected disabled value="">
                      All
                    </MenuItem>

                    {towerData.length > 0 &&
                      towerData.map((item) => {
                        return (
                          <MenuItem value={item.building_id}>
                            {item.tower_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} md={3}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#0671E0",
                    color: "white",
                    fontWeight: 400,
                    lineHeight: "24px",
                    textTransform: "capitalize",
                    fontSize: "18px",
                    width: "164px",
                    height: "56px",
                    padding: "16px, 32px, 16px, 32px",
                    gap: "8px",
                    borderRadius: "4px",
                    "&:hover": {
                      backgrindColor: "#0671E0"
                    }
                  }}
                >
                  Search
                  <img src="/assets/image/Search.png" alt="SearchIcon" />
                </Button>
              </Box>
            </Grid>
            <Grid item xs={6} md={6}>
              <Box>
                <Button
                  variant="outlined"
                  sx={{
                    backgroundColor: "white",
                    color: "#0671E0",
                    fontWeight: 400,
                    lineHeight: "24px",
                    textTransform: "capitalize",
                    fontSize: "16px",
                    "&:hover": {
                      backgrindColor: "white"
                    },
                    padding: "16px, 32px, 16px, 32px",
                    width: "151px",
                    height: "56px",
                    gap: "10px",
                    border: "1px solid #0671E0",
                    textAlign: "Center"
                  }}
                  onClick={downloadExcel}
                >
                  Download XLS
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={{
          marginTop: "30px"
        }}
      >
        <TableData
          rows={
            city
              ? project
                ? tower
                  ? rowsTower && [sampleTower, ...rowsTower]
                  : rowsProject && [sampleProject, ...rowsProject]
                : rowsCity && [sampleCity, ...rowsCity]
              : rows && [sample, ...rows]
          }
          columns={columns}
          HeaderbackgroundColor={"#F5F7FA"}
          tableHeaderAlign={"center"}
        />
      </Box>
    </Box>
  );
};
