import React from 'react'
import { Box } from '@mui/material'
import Downloads from "../../../../components/Downloads";

export const DownloadsTab = () => {
  return (
        <Box>
            <Downloads />
        </Box>
  )
}
