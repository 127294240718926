import React, { useContext, useEffect, useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  useRadioGroup
} from "@mui/material";
import styled from "@emotion/styled";
import {
  CustomFormLabel,
  CustomTextField
} from "../../lib/constants/textField";
import { useMutation, useQuery } from "@apollo/client";
import { Update_Buyer } from "../../lib/mutation/mutation";
import { Controller, useForm } from "react-hook-form";
import _ from "lodash";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useParams } from "react-router-dom";
import Steppercontext from "../../lib/context/contextData";
import { saleTransactionStatus } from "../../lib/query/query";
import * as Sentry from "@sentry/react";

const StyledFormControlLabel = styled((props) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
  ".MuiFormControlLabel-label": {
    color: checked ? "#000" : "#717171",
    fontWeight: "bold"
  }
}));

function MyFormControlLabel(props) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
}

export const CitizenshipResidency = ({
  data,
  activeStep,
  handleBack,
  handleComplete,
  setIdentityProof,
  setIsEdit,
}) => {
  const { salesId, buyerId } = useParams();

  const [update_buyer] = useMutation(Update_Buyer);
  const [formData, setFormData] = useState({});
  const [citizenValue, setCitizenValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { setSalesId, events } = useContext(Steppercontext);
  const params =
    events &&
    events.sale_transaction &&
    events.sale_transaction[0]?.sale_transaction_statuses;

  const { data: transactionStatus,error } = useQuery(saleTransactionStatus, {
    variables: { sale_transaction_id: salesId }
  });
  const Status = transactionStatus?.sale_transaction[0].status;

  const lockedValue = params && params[0]?.locked;

  const lockedStatus = params && params[0]?.status === "Action Required" && (Status !== "Cancellation Requested" && Status !== "Cancelled");

  const {
    control,
    handleSubmit,
    register,
    setValue,
    formState: { errors, dirtyFields }
  } = useForm({
    mode: "onChange",
    defaultValues: {
      citizenship: ""
    }
  });

  if (error) {
    Sentry.captureException(error);
  }

  useEffect(() => {
    setSalesId(salesId);
  }, [salesId]);

  useEffect(() => {
    if (data && data.buyer[0]) {
      setFormData(data.buyer[0]);
    }
  }, [data]);

  useEffect(() => {
    if (!_.isEmpty(formData)) {
      Object.keys(formData).forEach((key) => {
        if (key === "citizenship") {
          setCitizenValue(formData[key]);
        } 
         if(key === 'citizenship' && formData['residency_type'] === 'Indian' && formData['citizenship'] === 'Indian'){
          setValue('citizenship', 'Indian1');
        }
        else if(key === 'citizenship' && formData['residency_type'] === 'Other' && formData['citizenship'] === 'Indian') {
          setValue('citizenship', 'NRI');
        }
        else {
          setValue(key, formData[key] || "");
        }
      });
    }
  }, [formData, setValue]);

  const onSubmit = async (data) => {
    setIsEdit(true);
    const citizenship =
      data.citizenship === "Indian1"
        ? "Indian"
        : data.citizenship === "NRI"
        ? "Indian"
        : "OCI";
    const residencyType =
      data.citizenship === "Indian1"
        ? "Indian"
        : data.citizenship === "NRI"
        ? "Other"
        : "Other";

    const finalData = {
      ...data,
      citizenship: citizenship,
      residency_type: residencyType
    };

    if (
      dirtyFields.citizenship ||
      dirtyFields.street ||
      dirtyFields.state ||
      dirtyFields.country ||
      dirtyFields.city ||
      dirtyFields.zip ||
      dirtyFields.residency_type
    ) {
      setIsLoading(true);
      Sentry.addBreadcrumb({
        category: "button_click",
        message: "Next button clicked on KYC Citizenship & Residency Screen",
        data: {
          loading_step: "Citizenship & Residency screen"
        }
      });
      await update_buyer({
        variables: {
          buyer_id: buyerId,
          residency_type: finalData.residency_type,
          citizenship: finalData.citizenship,
          street: finalData.street,
          state: finalData.state,
          country: finalData.country,
          city: finalData.city,
          zip: finalData.zip
        }
      })
        .then((res) => {
          setIdentityProof(res.data.update_buyer.returning[0]);
          setIsLoading(false);
        })
        .catch((err) => {
          Sentry.captureException(err);
        });
      setIsLoading(false);
    }
    handleComplete();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          opacity: lockedStatus === true ? 1 : lockedValue === true ? 0.5 : 1
          // opacity: lockedValue === true ? 0.5 : 1
        }}
      >
        <FormControl className="!mb-3">
          <Controller
            rules={{ required: "This field is required." }}
            control={control}
            name="citizenship"
            render={({ field }) => (
              <RadioGroup
                {...field}
                onChange={(event) => {
                  field.onChange(event.target.value);
                }}
              >
                <MyFormControlLabel
                  value="Indian1"
                  label="Indian Citizen & Indian Resident"
                  control={
                    <Radio
                      disabled={
                        lockedStatus === true
                          ? false
                          : lockedValue === true
                          ? true
                          : false
                      }
                    />
                  }
                  // control={<Radio disabled={lockedValue === true ? true : false}/>}
                />
                <MyFormControlLabel
                  value="NRI"
                  label="Indian Citizen & Non Resident of India (NRI)"
                  control={
                    <Radio
                      disabled={
                        lockedStatus === true
                          ? false
                          : lockedValue === true
                          ? true
                          : false
                      }
                    />
                  }
                />
                <MyFormControlLabel
                  value="OCI"
                  label="Overseas Citizen of India (OCI)"
                  control={
                    <Radio
                      disabled={
                        lockedStatus === true
                          ? false
                          : lockedValue === true
                          ? true
                          : false
                      }
                    />
                  }
                />
              </RadioGroup>
            )}
          />

          {!citizenValue && (
            <span className="text-red-500 text-sm">
              {errors.citizenship && errors.citizenship.message}
            </span>
          )}
        </FormControl>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormGroup>
              <CustomFormLabel component="legend" required>
                Current Adress
              </CustomFormLabel>
              <CustomTextField
                {...register("street", {
                  required: "This field is required."
                })}
                id="outlined-basic"
                variant="outlined"
                autoComplete="off"
                error={errors.street}
                helperText={errors.street && errors.street.message}
                InputProps={{
                  // readOnly: lockedValue === true ? true : false
                  readOnly:
                    lockedStatus === true
                      ? false
                      : lockedValue === true
                      ? true
                      : false
                }}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormGroup>
              <CustomFormLabel component="legend" required>
                City
              </CustomFormLabel>
              <CustomTextField
                {...register("city", {
                  required: "This field is required."
                })}
                id="outlined-basic"
                variant="outlined"
                autoComplete="off"
                error={errors.city}
                helperText={errors.city && errors.city.message}
                InputProps={{
                  readOnly:
                    lockedStatus === true
                      ? false
                      : lockedValue === true
                      ? true
                      : false
                }}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormGroup>
              <CustomFormLabel component="legend" required>
                State
              </CustomFormLabel>
              <CustomTextField
                {...register("state", {
                  required: "This field is required."
                })}
                id="outlined-basic"
                variant="outlined"
                autoComplete="off"
                error={errors.state}
                helperText={errors.state && errors.state.message}
                InputProps={{
                  readOnly:
                    lockedStatus === true
                      ? false
                      : lockedValue === true
                      ? true
                      : false
                }}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormGroup>
              <CustomFormLabel component="legend" required>
                Pin/Zip
              </CustomFormLabel>
              <CustomTextField
                {...register("zip", {
                  required: "This field is required."
                })}
                type="number"
                id="outlined-basic"
                variant="outlined"
                autoComplete="off"
                error={errors.zip}
                helperText={errors.zip && errors.zip.message}
                InputProps={{
                  readOnly:
                    lockedStatus === true
                      ? false
                      : lockedValue === true
                      ? true
                      : false
                }}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormGroup>
              <CustomFormLabel component="legend" required>
                Country
              </CustomFormLabel>
              <CustomTextField
                {...register("country", {
                  required: "This field is required."
                })}
                id="outlined-basic"
                variant="outlined"
                autoComplete="off"
                error={errors.country}
                helperText={errors.country && errors.country.message}
                InputProps={{
                  readOnly:
                    lockedStatus === true
                      ? false
                      : lockedValue === true
                      ? true
                      : false
                }}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Box>
      <Box display={"flex"} justifyContent={"end"} alignItems={"center"} mt={3}>
        {activeStep !== 0 && (
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1, width: "109px", height: "48px" }}
          >
            <KeyboardBackspaceIcon sx={{ paddingRight: "8px" }} />
            Back
          </Button>
        )}
        <Button
          type="submit"
          sx={{
            mr: 1,
            bgcolor: "#0671E0",
            color: "white",
            textTransform: "capitalize",
            width: "174px",
            height: "48px",
            borderRadius: "4px",
            "&:hover": {
              bgcolor: "#0671E0",
              color: "white"
            }
          }}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress
              size={20}
              sx={{ color: "white", marginLeft: "10px" }}
            />
          ) : (
            "Next"
          )}
        </Button>
      </Box>
    </form>
  );
};
