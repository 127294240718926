import * as React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import {
    Box,
    Container,
    Typography,
} from "@mui/material";
import ModalCommon from "../../../components/ModalCommon";
import RemoveIcon from "@mui/icons-material/Remove";

export function DocumentVerification({ open, setOpen }) {
    const handleClose = () => {
        setOpen(false);
    };

    const Header = () => {
        return (
            <DialogTitle
                sx={{
                    fontWeight: 600,
                    color: "#212121",
                    fontSize: "20px",
                    lineHeight: "23px",
                    p: 0,
                }}
            >
                Documents needed for verification
            </DialogTitle>
        );
    };

    const Body = () => {
        return (
            <Box>
                <Typography
                    sx={{ color: "#4D4D4D", fontWeight: 600, fontSize: "20px" }}
                >
                    Indian Citizen
                </Typography>
                <Typography
                    sx={{ color: "#717171", fontWeight: 400, fontSize: "18px" }}
                >
                    <RemoveIcon />
                    If Resident of India: Aadhaar Card{" "}
                    <span style={{ color: "#E02B1D" }}>(mandatory)</span> + PAN (optional)
                </Typography>
                <Typography
                    sx={{ color: "#717171", fontWeight: 400, fontSize: "18px" }}
                >
                    <RemoveIcon />
                    If Non-Resident of India: Passport{" "}
                    <span style={{ color: "#E02B1D" }}>(mandatory)</span> + Aadhaar
                    (optional)
                </Typography>
                <Typography
                    sx={{ color: "#4D4D4D", fontWeight: 600, mt: 2, fontSize: "20px" }}
                >
                    Non-Indian Citizen
                </Typography>
                <Typography
                    sx={{ color: "#717171", fontWeight: 400, fontSize: "18px" }}
                >
                    <RemoveIcon /> Passport <span style={{ color: "#E02B1D" }}>(mandatory)</span> + OCI Card{" "}
                    <span style={{ color: "#E02B1D" }}>(mandatory)</span>
                </Typography>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: "#0671E0",
                        color: "white",
                        textTransform: "capitalize",
                        fontSize: "18px",
                        padding: "9px 38px",
                        float: "right",
                        "&:hover": {
                            backgroundColor: "#0671E0",
                        },

                        fontWeight: 400,
                        marginTop: "37px",
                    }}
                    onClick={handleClose}
                >
                    Ok
                </Button>
            </Box>
        );
    };

    return (
        <Container>
            <ModalCommon
                open={open}
                setOpen={setOpen}
                closeModal={handleClose}
                header={<Header />}
                body={<Body />}
                height={363}
                width={662}
                justifyContent={"space-between"}
            />
        </Container>
    );
}
