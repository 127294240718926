import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import TableData from "../../../../components/TableData";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import * as Sentry from "@sentry/react";
import { reportsApprovedBooking } from "../../../../lib/query/query";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { successToast } from "../../../../pages/Toasts/showToasts";
import ErrorToast from "../../../../components/ErrorToast";
import { toast } from "react-toastify";
import moment from "moment";

export const ReportsApprovedBookings = () => {
  const [approveBooking, setApproveBooking] = useState({});
  const { data, loading, refetch, error } = useQuery(reportsApprovedBooking, {
    variables: { step_index: 2, status: "Complete" }
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      setApproveBooking(data.sale_transaction);
    }
  }, [data]);

  if (error) {
    toast.error(
      <ErrorToast
        email={"info@msvkanha.com"}
        errorMsg={error && error?.message}
      />
    );
    Sentry.captureException(error);
  }

  const columns = [
    {
      field: "transactionID",
      headerName: "Transaction ID",
      width: 100,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "modelName",
      headerName: "Model Name",
      width: 90,
      align: "center",
      headerAlign: "center"
    },
    {
      field: "towerName",
      headerName: "Tower Name",
      width: 80,
      align: "center",
      headerAlign: "center"
    },
    {
      field: "unitNumber",
      headerName: "Unit Number",
      width: 70,
      align: "center",
      headerAlign: "center"
    },
    {
      field: "buyerFirstName",
      headerName: "Buyer First Name",
      width: 100,
      align: "center",
      headerAlign: "center"
    },
    {
      field: "buyerLastName",
      headerName: "Buyer Last Name",
      width: 100,
      align: "center",
      headerAlign: "center"
    },
    {
      field: "buyerEmailID",
      headerName: "Buyer Email ID",
      width: 130,
      align: "center",
      headerAlign: "center"
    },
    {
      field: "buyerAbhyasiID",
      headerName: "Buyer Abhyasi ID",
      width: 90,
      align: "center",
      headerAlign: "center"
    },
    {
      field: "buyerResidency",
      headerName: "Buyer Residency",
      width: 90,
      align: "center",
      headerAlign: "center"
    },
    {
      field: "buyerCitizenship",
      headerName: "Buyer Citizenship",
      width: 90,
      align: "center",
      headerAlign: "center"
    },
    {
      field: "bankLoanRequested",
      headerName: "Bank Loan Requested",
      width: 100,
      align: "center",
      headerAlign: "center"
    },
    {
      field: "street",
      headerName: "Street",
      width: 100,
      align: "center",
      headerAlign: "center"
    },
    {
      field: "city",
      headerName: "City",
      width: 100,
      align: "center",
      headerAlign: "center"
    },
    {
      field: "state",
      headerName: "State",
      width: 100,
      align: "center",
      headerAlign: "center"
    },
    {
      field: "country",
      headerName: "Country",
      width: 90,
      align: "center",
      headerAlign: "center"
    },
    {
      field: "zip",
      headerName: "Zip",
      width: 90,
      align: "center",
      headerAlign: "center"
    }
  ];

  const rows =
    approveBooking.length > 0 &&
    approveBooking.map((item) => {
      return {
        id: item?.sale_transaction_id,
        propertyId: item?.property_id,
        buyerId: item?.buyers[0]?.buyer_id,
        transactionID: item?.sale_transaction_id,
        modelName: item?.property?.property_model?.model_name,
        towerName: item?.property?.project_building?.tower_name,
        unitNumber: item?.property?.unit_number,
        buyerFirstName: item?.buyers[0]?.first_name,
        buyerLastName: item?.buyers[0]?.last_name,
        buyerEmailID: item?.buyers[0]?.email,
        buyerAbhyasiID: item?.buyers[0]?.abhyasi_id,
        buyerResidency: item?.buyers[0]?.residency_type,
        buyerCitizenship: item?.buyers[0]?.citizenship,
        street: item?.buyers[0]?.street,
        city: item?.buyers[0]?.city,
        state: item?.buyers[0]?.state,
        country: item?.buyers[0]?.country,
        zip: item?.buyers[0]?.zip,
        bankLoanRequested: item?.bank_loan_flag
      };
    });

  const date = {
    id: "Date:",
    buyerId: new Date()
  };

  const title = {
    id: "Title:",
    buyerId: "Approved Bookings"
  };

  const time = {
    id: "Time:",
    buyerId: moment().format("hh:mm a")
  };

  const downloadExcel = () => {
    const sheet = XLSX.utils.json_to_sheet(
      (rows && [title, date, time, "", ...rows]) || [],
      { skipHeader: true }
    );
    let Heading = [
      [
        "Id",
        "Property Id",
        "Buyer Id",
        "Transaction ID",
        "Model Name",
        "Tower Name",
        "Unit Number",
        "Buyer First Name",
        "Buyer Last Name",
        "Buyer Email ID",
        "Buyer Abhyasi ID",
        "Buyer Residency",
        "Buyer Citizenship",
        "Street",
        "City",
        "State",
        "Country",
        "Zip",
        "Bank Loan Requested"
      ]
    ];
    XLSX.utils.sheet_add_aoa(sheet, Heading, { origin: "A4" });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, sheet, "Sheet1");
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array"
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    });
    saveAs(blob, "ApprovedBookings.xlsx");
  };

  const handleRowClick = (params) => {
    const rowData = params?.row;
    const buyerId = rowData?.buyerId;
    const salesId = rowData?.transactionID;
    const propertyId = rowData?.propertyId;
    let url = `/admin/buyerProfile/${buyerId}/${salesId}`;
    if (propertyId !== undefined) {
      url += `/${propertyId}`;
    }
    navigate(url);
  };

  const handleRefresh = () => {
    refetch();
    successToast("Approved Bookings refreshed successfully");
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Box sx={{ paddingLeft: "14px !important" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "25px",
                  lineHeight: "30px",
                  color: "#212121",
                  margin: "30px 0px 30px 0px"
                }}
              >
                Approved Bookings
              </Typography>
            </Box>
            <RefreshIcon
              sx={{ cursor: "pointer", marginLeft: "5px" }}
              onClick={handleRefresh}
            />
            <Box sx={{ display: "flex", marginLeft: "auto" }}>
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: "white",
                  color: "#0671E0",
                  fontWeight: 400,
                  lineHeight: "24px",
                  textTransform: "capitalize",
                  fontSize: "16px",
                  "&:hover": {
                    backgrindColor: "white"
                  },
                  padding: "16px, 32px, 16px, 32px",
                  width: "151px",
                  height: "40px",
                  gap: "10px",
                  border: "1px solid #0671E0",
                  textAlign: "Center"
                }}
                onClick={downloadExcel}
              >
                Download XLS
              </Button>
            </Box>
          </Box>
          {loading ? (
            <CircularProgress
              sx={{
                width: "40px !important",
                height: "40px !important",
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            />
          ) : (
            <>
              {!loading && rows.length > 0 && (
                <TableData
                  rows={rows}
                  columns={columns}
                  HeaderbackgroundColor={"#F5F7FA"}
                  tableHeaderAlign={"center !important"}
                  handleOnCellClick={handleRowClick}
                  rowPerPage={50}
                  noDataFound={"No records found"}
                />
              )}
            </>
          )}
        </Box>
      </Grid>
    </Box>
  );
};
