import { Box, Button, Grid, Typography } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { AprooveBooking } from "./AprooveBooking"
import { UploadDocument } from "./UploadDocument"
import { UploadPropertyDeed } from "./UploadPropertyDeed"
import { UploadSaleAgreement } from "./UploadSaleAgreement"
import { CancelPurchase } from "./CancelPurchase"
import { AddAmenties } from "./AddAmenties"
import { useQuery } from "@apollo/client"
import {
  cancelBookingRefundAmount,
  getTransactionApprover,
} from "../../../lib/query/query"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import ErrorToast from "../../../components/ErrorToast"
import * as Sentry from "@sentry/react"
import Steppercontext from "../../../lib/context/contextData"
import { UploadReceipt } from "./UploadReceipt"

const PropertyDetails = ({
  buyerInfo,
  Status,
  isCancelledStatus,
  statusRefetch,
}) => {
  const [openApprove, setOpenApprove] = useState(false)
  const [uploadDocument, setUploadDocument] = useState(false)
  const [paymentReceipt, setPaymentReceipt] = useState(false)
  const [regDeed, setRegDeed] = useState(false)
  const [salesAgreement, setSalesAgreement] = useState(false)
  const [cancelPurchase, setCancelPurchase] = useState(false)
  const [addAmenties, setAddAmenties] = useState(false)
  const [cancleBtnHide, SetCancleBtnHide] = useState(false)
  const [loggedInUser, setLoggedInUser] = useState({})

  const { userData } = useContext(Steppercontext)

  const { data: ApproversData, error: errorApproversData } = useQuery(
    getTransactionApprover,
    {
      // variables: { email_id: 'myteamwork81@gmail.com' },
      variables: { email_id: userData && userData?.users[0]?.email },
    }
  )

  const { salesId } = useParams()

  const { refetch: RefundAmountRefetch, error: errorRefundAmount } = useQuery(
    cancelBookingRefundAmount,
    {
      variables: { sale_transaction_id: salesId },
      skip: !salesId,
    }
  )

  useEffect(() => {
    if (userData) {
      setLoggedInUser(userData?.users[0])
    }
  }, [userData])

  useEffect(() => {
    if (ApproversData && loggedInUser) {
      if (ApproversData.transaction_approvers.length === 0) {
        SetCancleBtnHide(true)
      } else {
        SetCancleBtnHide(false)
      }
    }
  }, [ApproversData, loggedInUser])

  const statusKYC = buyerInfo && buyerInfo?.sale_transaction_statuses && buyerInfo?.sale_transaction_statuses[0]?.status
  const statusBookPro =
    buyerInfo && buyerInfo?.sale_transaction_statuses && buyerInfo?.sale_transaction_statuses[1]?.status

  const isApproveBookingDisabled = () => {
    if (buyerInfo && buyerInfo.sale_transaction_statuses?.length > 0) {
      if (statusKYC !== "Complete" && statusBookPro === "Pending Validation") {
        return true
      } else if (
        statusKYC === "Complete" &&
        statusBookPro === "Pending Validation"
      ) {
        return false
      } else if (
        (statusKYC === "Complete" || statusKYC === "Action Required" || statusKYC === "Pending Validation") &&
        statusBookPro === "Complete"
      ) {
        return true
      } else {
        return false
      }
    }
  }

  const UploadReceiptStatus = () => {
    if (buyerInfo && buyerInfo.sale_transaction_statuses?.length > 0) {
      if (statusKYC !== "Complete" && statusBookPro === "Pending Validation") {
        return true
      } else if (
        statusKYC === "Complete" &&
        statusBookPro === "Pending Validation"
      ) {
        return true
      } else if (
        (statusKYC === "Complete" || statusKYC === "Action Required") &&
        statusBookPro === "Complete"
      ) {
        return false
      } else {
        return false
      }
    }
  }

  // const stateTimelineData = buyerInfo?.sale_transaction_statuses

  // const pendingValidationStatus = stateTimelineData?.find(obj =>
  //   obj.process_step === "Book Property" && obj.status === "Pending Validation"
  // );

  if (errorApproversData || errorRefundAmount) {
    toast.error(
      <ErrorToast
        email={"info@msvkanha.com"}
        errorMsg={
          (errorApproversData && errorApproversData?.message) ||
          (errorRefundAmount && errorRefundAmount?.message)
        }
      />
    )
    Sentry.captureException(
      (errorApproversData && errorApproversData?.message) ||
        (errorRefundAmount && errorRefundAmount?.message)
    )
  }

  const municipalHold = buyerInfo && buyerInfo?.property?.municipal_hold
  const municipalHoldeFlag =
    buyerInfo?.property !== null
      ? municipalHold?.toString().charAt(0).toUpperCase() +
        municipalHold?.toString().slice(1)
      : ""

  return (
    <Grid item xs={8}>
      <Box
        sx={{
          border: "1px solid #E8E8EA",
          borderRadius: "4px",
          my: 5,
        }}>
        <Box
          sx={{ opacity: buyerInfo && buyerInfo?.property === null ? 0.5 : 1 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "20px 40px",
            }}>
            <Typography
              sx={{
                fontSize: "18px",
                color: "#212121",
                fontWeight: 600,
                lineHeight: "24px",
              }}>
              {/* Meera Shantivanam Phase 3 C104 */}
              {buyerInfo?.property === null
                ? "Property yet to be selected by Buyer"
                : `${buyerInfo?.property?.project?.project_name} ${buyerInfo?.property?.project_building?.tower_name} ${buyerInfo?.property?.unit_number}`}
            </Typography>
            <Typography
              sx={{
                color: "#C33025",
                fontWeight: "bold",
                textTransform: "uppercase",
              }}>
              {isCancelledStatus && Status}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#0671E0",
                  color: "white",
                  textTransform: "capitalize",
                  fontSize: "18px",
                  padding: "9px 38px",
                  "&:hover": {
                    backgroundColor: "#0671E0",
                  },
                  fontWeight: 400,
                }}
                onClick={() =>
                  buyerInfo?.property !== null && setOpenApprove(true)
                }
                disabled={isApproveBookingDisabled()}>
                Approve Booking
              </Button>

              {statusKYC !== "Complete" &&
                statusBookPro === "Pending Validation" && (
                  <Typography
                    sx={{
                      color: "#737272",
                      fontWeight: "bold",
                      fontSize: "14px",
                      mt: "5px",
                    }}>
                   Complete KYC to Enable Button
                  </Typography>
                )}
            </Box>

            {openApprove && (
              <AprooveBooking
                open={openApprove}
                setOpen={setOpenApprove}
                buyerInfo={buyerInfo}
              />
            )}
          </Box>
          <hr />
          <Box sx={{ padding: "20px 40px" }}>
            <Box>
              <Box sx={{ display: "flex", marginBottom: "10px" }}>
                <Typography
                  sx={{
                    color: "#212121",
                    fontWeight: 600,
                    fontSize: "16px",
                    lineHeight: "24px",
                    marginRight: "5px",
                  }}>
                  Project Location:{" "}
                </Typography>
                <Typography
                  sx={{
                    color: "#4D4D4D",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}>
                  {" "}
                  {buyerInfo?.property === null
                    ? "Yet to select"
                    : `${buyerInfo?.property?.project?.street}, ${buyerInfo?.property?.project?.state}, ${buyerInfo?.property?.project?.city}`}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", marginBottom: "10px" }}>
                <Typography
                  sx={{
                    color: "#212121",
                    fontWeight: 600,
                    fontSize: "16px",
                    lineHeight: "24px",
                    marginRight: "5px",
                  }}>
                  Tower:{" "}
                </Typography>
                <Typography
                  sx={{
                    color: "#4D4D4D",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}>
                  {" "}
                  {buyerInfo?.property?.project_building?.tower_name}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", marginBottom: "10px" }}>
                <Typography
                  sx={{
                    color: "#212121",
                    fontWeight: 600,
                    fontSize: "16px",
                    lineHeight: "24px",
                    marginRight: "5px",
                  }}>
                  Unit Number:{" "}
                </Typography>
                <Typography
                  sx={{
                    color: "#4D4D4D",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}>
                  {" "}
                  {buyerInfo?.property?.unit_number}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", marginBottom: "10px" }}>
                <Typography
                  sx={{
                    color: "#212121",
                    fontWeight: 600,
                    fontSize: "16px",
                    lineHeight: "24px",
                    marginRight: "5px",
                  }}>
                  Model Name:{" "}
                </Typography>
                <Typography
                  sx={{
                    color: "#4D4D4D",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}>
                  {" "}
                  {buyerInfo?.property?.property_model?.model_name}
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Typography
                  sx={{
                    color: "#212121",
                    fontWeight: 600,
                    fontSize: "16px",
                    lineHeight: "24px",
                    marginRight: "5px",
                  }}>
                  Municipal Hold:{" "}
                </Typography>
                <Typography
                  sx={{
                    color: "#4D4D4D",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}>
                  {" "}
                  {`${municipalHoldeFlag}`}
                </Typography>
              </Box>
            </Box>

            <Grid
              container
              spacing={2}
              sx={{ pt: 2, alignItems: "center", display: "flex" }}>
              <Grid item xs={2}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <img src="/assets/image/car.png" alt="bedrooms" />

                  <Typography
                    variant="body2"
                    sx={{
                      paddingLeft: "8px",
                      color: "#4D4D4D",
                      fontSize: "16px",
                    }}>
                    {buyerInfo?.property?.property_model?.beds_count} bed
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  <img src="/assets/image/floor.png" alt="bathroomsfloor" />
                  <Typography
                    variant="body2"
                    sx={{
                      paddingLeft: "8px",
                      color: "#4D4D4D",
                      fontSize: "16px",
                    }}>
                    {buyerInfo?.property?.property_model?.baths_count} bath
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  <img src="/assets/image/thirdfloor.png" alt="thirdfloor" />
                  <Typography
                    variant="body2"
                    sx={{
                      paddingLeft: "8px",
                      color: "#4D4D4D",
                      fontSize: "16px",
                    }}>
                    {buyerInfo?.property?.floor_number} floor
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  <img src="/assets/image/squarefeet.png" alt="squarefeet" />
                  <Typography
                    variant="body2"
                    sx={{
                      paddingLeft: "8px",
                      color: "#4D4D4D",
                      fontSize: "16px",
                    }}>
                    {buyerInfo?.property?.property_model?.sqft_area} sq. ft.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  <img src="/assets/image/CarParking.png" alt="squarefeet" />
                  <Typography
                    variant="body2"
                    sx={{
                      paddingLeft: "8px",
                      color: "#4D4D4D",
                      fontSize: "16px",
                    }}>
                    {buyerInfo?.property?.property_model?.car_parking_count} Car
                    Parking
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Box>
              <Typography
                sx={{
                  fontSize: "18px",
                  color: "#89939E",
                  fontWeight: 600,
                  lineHeight: "23px",
                  pt: 2,
                }}>
                Actions
              </Typography>
              <Box sx={{ pt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "white",
                        color: "#0671E0",
                        textTransform: "capitalize",
                        fontSize: "16px",
                        width: "100%",
                        height: "48px",
                        padding: "6px 8px !important",
                        "&:hover": {
                          backgroundColor: "white",
                        },

                        fontWeight: 400,
                        border: "1px solid #717171",
                        boxShadow: "none",
                      }}
                      onClick={() =>
                        buyerInfo?.property !== null && setUploadDocument(true)
                      }>
                      Upload Documents
                    </Button>
                    {uploadDocument && (
                      <UploadDocument
                        open={uploadDocument}
                        setOpen={setUploadDocument}
                        buyerInfo={buyerInfo}
                      />
                    )}
                    <Button
                      variant="contained"
                      sx={{
                        color: "#0671E0",
                        textTransform: "capitalize",
                        fontSize: "16px",
                        width: "100%",
                        height: "48px",

                        fontWeight: 400,
                        backgroundColor: "white",
                        padding: "6px 8px !important",

                        "&:hover": {
                          backgroundColor: "white",
                        },
                        border: "1px solid #717171",
                        boxShadow: "none",
                        mt: 2,
                      }}
                      onClick={() =>
                        buyerInfo?.property !== null && setRegDeed(true)
                      }>
                      Registration Deed
                    </Button>
                    {regDeed && (
                      <UploadPropertyDeed
                        open={regDeed}
                        setOpen={setRegDeed}
                        buyerInfo={buyerInfo}
                      />
                    )}
                  </Grid>

                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      sx={{
                        color: "#0671E0",
                        textTransform: "capitalize",
                        fontSize: "16px",
                        width: "100%",
                        height: "48px",
                        padding: "6px 8px !important",

                        fontWeight: 400,
                        backgroundColor: "white",
                        "&:hover": {
                          backgroundColor: "white",
                        },
                        border: "1px solid #717171",
                        boxShadow: "none",
                      }}
                      onClick={() =>
                        buyerInfo?.property !== null && setPaymentReceipt(true)
                      }
                      disabled={UploadReceiptStatus()}>
                      Upload Payments Receipt
                    </Button>
                    {paymentReceipt && (
                      <UploadReceipt
                        open={paymentReceipt}
                        setOpen={setPaymentReceipt}
                        buyerInfo={buyerInfo}
                      />
                    )}

                    <Button
                      variant="contained"
                      sx={{
                        color: "#0671E0",
                        textTransform: "capitalize",
                        fontSize: "16px",
                        width: "100%",
                        height: "48px",
                        padding: "6px 8px !important",

                        fontWeight: 400,
                        backgroundColor: "white",
                        border: "1px solid #717171",
                        "&:hover": {
                          backgroundColor: "white",
                        },
                        boxShadow: "none",
                        mt: 2,
                      }}
                      onClick={() =>
                        buyerInfo?.property !== null && setSalesAgreement(true)
                      }>
                      Upload Sale Agreement
                    </Button>
                    {salesAgreement && (
                      <UploadSaleAgreement
                        open={salesAgreement}
                        setOpen={setSalesAgreement}
                        buyerInfo={buyerInfo}
                      />
                    )}
                  </Grid>

                  <Grid item xs={3}>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "white",
                        color: "#0671E0",
                        textTransform: "capitalize",
                        fontSize: "16px",
                        width: "100%",
                        height: "48px",
                        padding: "6px 8px !important",
                        "&:hover": {
                          backgroundColor: "white",
                        },

                        fontWeight: 400,
                        border: "1px solid #717171",
                        boxShadow: "none",
                      }}
                      onClick={() =>
                        buyerInfo?.property !== null && setAddAmenties(true)
                      }>
                      Add Amenties
                    </Button>
                    {addAmenties && (
                      <AddAmenties
                        open={addAmenties}
                        setOpen={setAddAmenties}
                        buyerInfo={buyerInfo}
                      />
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
        <hr />
        {!cancleBtnHide && (
          <Box
            sx={{
              padding: "20px 40px",
              display: "flex",
              justifyContent: "end",
            }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "white",
                color: "#E02B1D",
                textTransform: "capitalize",
                fontSize: "16px",
                width: "166px",
                height: "48px",
                padding: "6px 8px !important",
                "&:hover": {
                  backgroundColor: "white",
                },
                fontWeight: 400,
                border: "1px solid #717171",
                boxShadow: "none",
              }}
              // onClick={() => {(buyerInfo?.property !== null) && setCancelPurchase(true); RefundAmountRefetch();}}
              onClick={() => {
                if (
                  (buyerInfo?.property === null && isCancelledStatus) ||
                  buyerInfo?.property !== null
                ) {
                  setCancelPurchase(true)
                  RefundAmountRefetch()
                }
              }}
              // disabled={isCancelledStatus}
              // disabled={(Status !== "Cancellation Requested" && Status !== "Cancelled")}
              disabled={Status === "Completed"}>
              Cancel Purchase
            </Button>

            {cancelPurchase && (
              <CancelPurchase
                open={cancelPurchase}
                setOpen={setCancelPurchase}
                buyerInfo={buyerInfo}
                statusRefetch={statusRefetch}
              />
            )}
          </Box>
        )}
      </Box>
    </Grid>
  )
}

export default PropertyDetails
