import React from "react";
import { Box, Container } from "@mui/material";
import BackButton from "../../../components/BackButton";
import { useNavigate } from "react-router-dom";
import { termsCondition } from "../../../lib/query/query";
import { useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import ErrorToast from "../../../components/ErrorToast";
import * as Sentry from "@sentry/react";

export const TermsConditions = () => {

  const { data,error } = useQuery(termsCondition, {
    variables: { page: "termsandconditions" },
  })

  const navigate = useNavigate();

  if (error) {
    toast.error(
      <ErrorToast
        email={"info@msvkanha.com"}
        errorMsg={error && error?.message}
      />
    );
    Sentry.captureException(error && error?.message);
  }
  
  return (
    <Container>
      <BackButton handleBack={() => navigate(-1)} />
    <Box>
        <div dangerouslySetInnerHTML={{ __html: data?.static_html[0]?.content }} />
    </Box>
    </Container>
  );
};
