import React, { useState } from "react";
import ModalCommon from "../../../components/ModalCommon";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  FormControl,
  FormGroup,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import { UploadFile } from "../../../components/UploadFile";
import { useMutation, useQuery } from "@apollo/client";
import {
  ApproveBookingMutation,
  saleTransactionStatus,
  ApproveUploadDoc
} from "../../../lib/mutation/mutation";
import useFileToHexString from "../../../hook/useFileToHexString";
import { Controller, useForm } from "react-hook-form";
import DeleteIcon from "@mui/icons-material/Delete";
import _ from "lodash";
import { useParams } from "react-router-dom";
import {
  CustomFormLabel,
  CustomTextField
} from "../../../lib/constants/textField";
import { errorToast, successToast } from "../../../pages/Toasts/showToasts";
import moment from "moment";
import { buyerProfileData } from "../../../lib/query/query";
import { toast } from "react-toastify";
import ErrorToast from "../../../components/ErrorToast";
import * as Sentry from "@sentry/react";

export const AprooveBooking = ({ open, setOpen, buyerInfo }) => {
  const [passportPic, setPassportPic] = useState({});
  const [loading, setLoading] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  const {
    control,
    handleSubmit,
    register,
    setValue,
    formState: { errors }
  } = useForm({
    mode: "onChange"
  });

  const [Sale_Transaction_Status, { error: errorStatus }] = useMutation(
    saleTransactionStatus
  );
  const [Approve_uploadDoc, { error: errorUpload }] =
    useMutation(ApproveUploadDoc);
  const [Approve_Booking, { error: errorBooking }] = useMutation(
    ApproveBookingMutation
  );
  const { convertFileToHexStringPromise } = useFileToHexString();

  const { salesId } = useParams();

  const { refetch, error: errorProfileData } = useQuery(buyerProfileData, {
    variables: { sale_transaction_id: salesId }
  });

  const handleUploadPassportPic = async (e) => {
    const file = e.target.files[0];
    try {
      setLoading(true);
      const hexData = await convertFileToHexStringPromise(file);
      const fileStore = {
        file_name: file.name.split(".").shift(),
        file_extension: file.name.split(".").pop(),
        haxContent: hexData
      };
      setPassportPic(fileStore);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    } catch (err) {}
  };

  const handlePassportPicDelete = (passportPic) => {
    if (passportPic) {
      setValue("upload_passport_pic", "");
      setPassportPic({});
    }
  };

  const onSubmit = async (updateData) => {
    // const formDataObject = {
    //   amount: updateData.amount,
    //   payment_medium: updateData.payment_medium,
    //   payment_date: updateData.payment_date,
    //   passportPic: passportPic
    // };
    const payment_date = moment(updateData.payment_date).format("MM-DD-YYYY");
    setIsFileUploaded(true);
    await Approve_uploadDoc({
      variables: {
        file_name: passportPic?.file_name,
        sale_transaction_id: salesId,
        file_extension: passportPic?.file_extension,
        document_type: "Receipt",
        document: passportPic?.haxContent
      }
    })
      .then((response) => {
        setIsFileUploaded(false);
        successToast("File upload successful!");
        Approve_Booking({
          variables: {
            document_id:
              response?.data?.insert_document?.returning[0]?.document_id,
            payment_date: payment_date,
            sale_transaction_id: salesId,
            amount: updateData?.amount,
            status: "Verified",
            payment_medium: updateData?.payment_medium
          }
        })
          .then((response) => {})
          .catch((error) => {
            console.error(error);
            Sentry.captureException(error);
            errorToast("Something went wrong!");
          });
      })
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
        errorToast("Something went wrong!");
      });

    await Sale_Transaction_Status({
      variables: {
        sale_transaction_id: salesId,
        step_index: 2,
        status: "Complete"
      }
    })
      .then((response) => {
        refetch();
        setOpen(false);
      })
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
        errorToast("Something went wrong!");
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Header = () => {
    return (
      <Box
        sx={{
          justifyContent: "center",
          textAlign: "center",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "30px",
            color: "#212121"
          }}
        >
          Aproove Booking
        </Typography>

        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "23px",
            color: "#4D4D4D"
          }}
        >
          {`${buyerInfo?.property?.project?.project_name} ${buyerInfo?.property?.project_building?.tower_name} ${buyerInfo?.property?.unit_number}`}
        </Typography>
      </Box>
    );
  };

  const Body = () => {
    return (
      <>
        {isFileUploaded && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "110%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              zIndex: 1000
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ padding: "20px 0 0 0 " }}>
            <Box sx={{ marginBottom: "12px" }}>
              <FormGroup>
                <CustomFormLabel component="legend" required>
                  Transaction Amount
                </CustomFormLabel>
                <CustomTextField
                  {...register("amount", {
                    required: "This field is required."
                  })}
                  type="number"
                  id="outlined-basic"
                  variant="outlined"
                  autoComplete="off"
                  error={errors.amount}
                  helperText={errors.amount && errors.amount.message}
                />
              </FormGroup>
            </Box>

            <Box sx={{ marginBottom: "12px" }}>
              <FormGroup>
                <CustomFormLabel component="legend" required>
                  Payment Medium
                </CustomFormLabel>
                <Controller
                  name="payment_medium"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "This field is required."
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      id="payment-medium-select"
                      variant="outlined"
                      autoComplete="off"
                      error={Boolean(errors.payment_medium)}
                    >
                      <MenuItem selected disabled value="Title">
                        Select an option
                      </MenuItem>
                      <MenuItem value="ACH">ACH</MenuItem>
                      <MenuItem value="Check">Check</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </Select>
                  )}
                />
                {errors.payment_medium && (
                  <Typography
                    color="error"
                    sx={{ fontSize: "12px", marginLeft: "15px" }}
                  >
                    {errors.payment_medium.message}
                  </Typography>
                )}
              </FormGroup>
            </Box>

            <Box sx={{ marginBottom: "12px" }}>
              <FormGroup>
                <CustomFormLabel component="legend" required>
                  Transaction Date
                </CustomFormLabel>
                <CustomTextField
                  {...register("payment_date", {
                    required: "This field is required."
                  })}
                  type="date"
                  id="outlined-basic"
                  variant="outlined"
                  autoComplete="off"
                  error={errors.payment_date}
                  helperText={
                    errors.payment_date && errors.payment_date.message
                  }
                />
              </FormGroup>
            </Box>
            <Box sx={{ marginBottom: "12px" }}>
              <FormControl sx={{ width: "100%" }}>
                <CustomFormLabel component="legend" required>
                  Upload Receipt
                </CustomFormLabel>

                {loading ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "178px"
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    {_.isEmpty(passportPic) ? (
                      <Controller
                        control={control}
                        name="upload_passport_pic"
                        rules={{
                          required: "This field is required."
                        }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error }
                        }) => (
                          <UploadFile
                            onChange={(e) => {
                              onChange(e);
                              handleUploadPassportPic(e);
                            }}
                            acceptFile="image/*, .pdf, .xls, .xlsx, .docx, .doc"
                            error={error}
                            setSelectedFile={setPassportPic}
                          />
                        )}
                      />
                    ) : (
                      <Card
                        sx={{
                          padding: "30px 25px",
                          background: "#F5F7FA",
                          boxShadow: "none",
                          borderRadius: "5px",
                          position: "relative"
                        }}
                      >
                        <DeleteIcon
                          on
                          sx={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            cursor: "pointer"
                          }}
                          onClick={() => {
                            handlePassportPicDelete(passportPic);
                          }}
                        />
                        <CardActionArea>
                          <CardContent
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column"
                            }}
                          >
                            <img
                              src="/assets/icons/FileIcon.svg"
                              className="mb-5"
                              alt="file icon"
                            />
                            <Typography
                              variant="body2"
                              fontSize={14}
                              color="#4D4D4D"
                              sx={{ marginBottom: "5px" }}
                            >
                              {`${passportPic?.file_name}.${passportPic?.file_extension}`}
                            </Typography>
                            <Typography
                              variant="body2"
                              fontSize={14}
                              color="#89939E"
                            >
                              35 KB
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    )}
                  </>
                )}
              </FormControl>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              sx={{
                borderRadius: "4px",
                gap: "10px",
                backgroundColor: "#FFFFFF",
                fontSize: "16px",
                lineHeight: "24px",
                textAlign: "Center",
                fontWeight: 600,
                color: "#717171",
                textTransform: "capitalize",
                width: "209px",
                height: "56px"
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              sx={{
                borderRadius: "4px",
                gap: "10px",
                backgroundColor: "#0671E0",
                fontSize: "16px",
                lineHeight: "24px",
                textAlign: "Center",
                fontWeight: 600,
                color: "#FFFFFF",
                width: "209px",
                height: "56px",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#0671E0"
                }
              }}
              disabled={isFileUploaded || loading}
            >
              Approve Booking
            </Button>
          </Box>
        </form>
      </>
    );
  };

  if (errorUpload || errorBooking || errorStatus || errorProfileData) {
    toast.error(
      <ErrorToast
        email={"info@msvkanha.com"}
        errorMsg={
          (errorUpload && errorUpload?.message) ||
          (errorBooking && errorBooking?.message) ||
          (errorStatus && errorStatus?.message) ||
          (errorProfileData && errorProfileData?.message)
        }
      />
    );
    Sentry.captureException(
      (errorUpload && errorUpload?.message) ||
        (errorBooking && errorBooking?.message) ||
        (errorStatus && errorStatus?.message) ||
        (errorProfileData && errorProfileData?.message)
    );
  }

  return (
    <>
      <ModalCommon
        open={open}
        setOpen={setOpen}
        header={<Header />}
        body={Body()}
        width={551}
        justifyContent={"center"}
        height={"90%"}
        maxheight={"calc(100vh - 100px)"}
        overflow={"auto"}
      />
    </>
  );
};
