import { Box, Grid, Typography } from '@mui/material';
import React from 'react'

const ChartBoxData = ({chartBoxData}) => {
 
  return (
    <Box>
      <Box
        sx={{
          borderRadius: "10px",
          border: "1px solid #E8E8EA",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "32px 0",
            height:'225px'
          }}
        >
          {chartBoxData.map((item, index) => {
            return (
              <Grid
                container
                spacing={2}
                key={index}
                sx={{
                  display: "flex",

                  justifyContent: "center",
                  alignItems: "center",
                  padding: "15px 0",
                }}
              >
                <Grid
                  item
                  xs={4}
                  sx={{
                    padding: "0 !important",
                    display: "flex",

                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: "50px",
                      lineHeight: "44px",
                      color: "#212121",
                    }}
                  >
                    {item.value}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "18px",
                      lineHeight: "28px",
                      color: "#717171",
                      width: "150px",
                      paddingTop: "45px",
                      textAlign: "center",
                    }}
                  >
                    {item.label}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}

export default ChartBoxData