export const parseInput = (input) => {
    const match = input.match(/^(.*?)(\d+)$/);

    if (match) {
        return {
            prefix: match[1].trim(),  
            suffix: match[2].trim()  
        };
    }

    return {
        prefix: '',
        suffix: input.trim()  
    };
};
