import React, { useContext, useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import {
  Box,
  Button,
  Container,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import TableData from "../../../components/TableData";
import { useLazyQuery } from "@apollo/client";
import {
  BuyerSearchDropDownAadhaar,
  BuyerSearchDropDownAbhyasi,
  BuyerSearchDropDownCity,
  BuyerSearchDropDownCountry,
  BuyerSearchDropDownEmail,
  BuyerSearchDropDownFirst,
  BuyerSearchDropDownLast,
  BuyerSearchDropDownOci,
  BuyerSearchDropDownPan,
  BuyerSearchDropDownPass,
  BuyerSearchDropDownState,
  BuyerSearchDropDownStatus,
  BuyerSearchDropDownTransaction,
  BuyerSearchUnitNumber
} from "../../../lib/query/query";
import BackButton from "../../../components/BackButton";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ErrorToast from "../../../components/ErrorToast";
import moment from "moment";
import Steppercontext from "../../../lib/context/contextData";
import _ from "lodash";
import * as Sentry from "@sentry/react";
import { parseInput } from "../../../lib/helper/helperFunction";

export const BuyerSearch = () => {
  const { setSearchData, searchData } = useContext(Steppercontext);
  const [buyerTable, setBuyerTable] = useState([]);
  const [selectedValue, setSelectedValue] = useState(
    searchData?.selectedValue || "abhyasi_id"
  );
  const [selectText, setSelectText] = useState(searchData?.selectText || "");

  const navigate = useNavigate();

  useEffect(() => {
    if (!_.isEmpty(searchData)) {
      setSelectedValue(searchData?.selectedValue);
      setSelectText(searchData?.selectText);
      handleSearch();
    }
  }, []);

  const [
    buyerSearchPass,
    { data: dataPass, refetch: refetchPass, error: errorPass }
  ] = useLazyQuery(BuyerSearchDropDownPass);

  const [
    buyerSearchFirst,
    { data: dataFirst, refetch: refetchFirst, error: errorFirst }
  ] = useLazyQuery(BuyerSearchDropDownFirst);

  const [
    buyerSearchLast,
    { data: dataLast, refetch: refetchLast, error: errorLast }
  ] = useLazyQuery(BuyerSearchDropDownLast);

  const [
    buyerSearchEmail,
    { data: dataEmail, refetch: refetchEmail, error: errorEmail }
  ] = useLazyQuery(BuyerSearchDropDownEmail);

  const [
    buyerSearchOci,
    { data: dataOci, refetch: refetchOci, error: errorOci }
  ] = useLazyQuery(BuyerSearchDropDownOci);

  const [
    buyerSearchAbhyasi,
    { data: dataAbhyasi, refetch: refetchAbhyasi, error: errorAbhyasi }
  ] = useLazyQuery(BuyerSearchDropDownAbhyasi);

  const [
    buyerSearchAadhaar,
    { data: dataAadhaar, refetch: refetchAadhaar, error: errorAadhaar }
  ] = useLazyQuery(BuyerSearchDropDownAadhaar);

  const [
    buyerSearchPan,
    { data: dataPan, refetch: refetchPan, error: errorPan }
  ] = useLazyQuery(BuyerSearchDropDownPan);

  const [
    buyerSearchCity,
    { data: dataCity, refetch: refetchCity, error: errorCity }
  ] = useLazyQuery(BuyerSearchDropDownCity);

  const [
    buyerSearchState,
    { data: dataState, refetch: refetchState, error: errorState }
  ] = useLazyQuery(BuyerSearchDropDownState);

  const [
    buyerSearchCountry,
    { data: dataCountry, refetch: refetchCountry, error: errorCountry }
  ] = useLazyQuery(BuyerSearchDropDownCountry);

  const [
    buyerSearchUnitNumber,
    { data: dataUnitNumber, refetch: refetchUnitNumber, error: errorUnitNumber }
  ] = useLazyQuery(BuyerSearchUnitNumber);

  const [
    buyerSearchTransaction,
    {
      data: dataTransaction,
      refetch: refetchTransaction,
      error: errorTransaction
    }
  ] = useLazyQuery(BuyerSearchDropDownTransaction);

  const [
    buyerSearchStatus,
    { data: datStatus, refetch: refetchStatus, error: errorStatus }
  ] = useLazyQuery(BuyerSearchDropDownStatus);

  if (
    errorPass ||
    errorFirst ||
    errorLast ||
    errorEmail ||
    errorOci ||
    errorAbhyasi ||
    errorAadhaar ||
    errorPan ||
    errorCity ||
    errorState ||
    errorCountry ||
    errorTransaction ||
    errorStatus ||
    errorUnitNumber
  ) {
    toast.error(
      <ErrorToast
        email={"info@msvkanha.com"}
        errorMsg={
          (errorPass && errorPass?.message) ||
          (errorFirst && errorFirst?.message) ||
          (errorLast && errorLast?.message) ||
          (errorEmail && errorEmail?.message) ||
          (errorOci && errorOci?.message) ||
          (errorAbhyasi && errorAbhyasi?.message) ||
          (errorAadhaar && errorAadhaar?.message) ||
          (errorPan && errorPan?.message) ||
          (errorCity && errorCity?.message) ||
          (errorState && errorState?.message) ||
          (errorCountry && errorCountry?.message) ||
          (errorTransaction && errorTransaction?.message) ||
          (errorStatus && errorStatus?.message) ||
          (errorUnitNumber && errorUnitNumber?.message)
        }
      />
    );
    Sentry.captureException(
      (errorPass && errorPass?.message) ||
        (errorFirst && errorFirst?.message) ||
        (errorLast && errorLast?.message) ||
        (errorEmail && errorEmail?.message) ||
        (errorOci && errorOci?.message) ||
        (errorAbhyasi && errorAbhyasi?.message) ||
        (errorAadhaar && errorAadhaar?.message) ||
        (errorPan && errorPan?.message) ||
        (errorCity && errorCity?.message) ||
        (errorState && errorState?.message) ||
        (errorCountry && errorCountry?.message) ||
        (errorTransaction && errorTransaction?.message) ||
        (errorStatus && errorStatus?.message) ||
        (errorUnitNumber && errorUnitNumber?.message)
    );
  }

  useEffect(() => {
    switch (selectedValue) {
      case "first_name":
        if (dataFirst && dataFirst.sale_transaction.length > 0) {
          setBuyerTable(dataFirst.sale_transaction);
        }else{
          setBuyerTable([]);
        }
        break;
      case "passport_number":
        if (dataPass && dataPass.sale_transaction.length > 0) {
          setBuyerTable(dataPass.sale_transaction);
        }else{
          setBuyerTable([]);
        }
        break;
      case "last_name":
        if (dataLast && dataLast.sale_transaction.length > 0) {
          setBuyerTable(dataLast.sale_transaction);
        }else{
          setBuyerTable([]);
        }
        break;
      case "email":
        if (dataEmail && dataEmail.sale_transaction.length > 0) {
          setBuyerTable(dataEmail.sale_transaction);
        }else{
          setBuyerTable([]);
        }
        break;
      case "oci_number":
        if (dataOci && dataOci.sale_transaction.length > 0) {
          setBuyerTable(dataOci.sale_transaction);
        }else{
          setBuyerTable([]);
        }
        break;
      case "abhyasi_id":
        if (dataAbhyasi && dataAbhyasi.sale_transaction.length > 0) {
          setBuyerTable(dataAbhyasi.sale_transaction);
        }else{
          setBuyerTable([]);
        }
        break;
      case "aadhar_number":
        if (dataAadhaar && dataAadhaar.sale_transaction.length > 0) {
          setBuyerTable(dataAadhaar.sale_transaction);
        }else{
          setBuyerTable([]);
        }
        break;
      case "pan_number":
        if (dataPan && dataPan.sale_transaction.length > 0) {
          setBuyerTable(dataPan.sale_transaction);
        }else{
          setBuyerTable([]);
        }
        break;
      case "city":
        if (dataCity && dataCity.sale_transaction.length > 0) {
          setBuyerTable(dataCity.sale_transaction);
        }else{
          setBuyerTable([]);
        }
        break;
      case "state":
        if (dataState && dataState.sale_transaction.length > 0) {
          setBuyerTable(dataState.sale_transaction);
        }else{
          setBuyerTable([]);
        }
        break;
      case "country":
        if (dataCountry && dataCountry.sale_transaction.length > 0) {
          setBuyerTable(dataCountry.sale_transaction);
        }else{
          setBuyerTable([]);
        }
        break;
      case "salesId":
        if (dataTransaction && dataTransaction.sale_transaction.length > 0) {
          setBuyerTable(dataTransaction.sale_transaction);
        }else{
          setBuyerTable([]);
        }
        break;
      case "status":
        if (datStatus && datStatus.sale_transaction.length > 0) {
          setBuyerTable(datStatus.sale_transaction);
        }else{
          setBuyerTable([]);
        }
        break;
      case "unitNumber":
        if (dataUnitNumber && dataUnitNumber.sale_transaction.length > 0) {
          setBuyerTable(dataUnitNumber.sale_transaction);
        }else{
          setBuyerTable([]);
        }
        break;
      default:
        break;
    }
  }, [
    dataFirst,
    dataPass,
    dataLast,
    dataEmail,
    dataOci,
    dataAbhyasi,
    dataAadhaar,
    dataPan,
    dataCity,
    dataState,
    dataCountry,
    dataTransaction,
    datStatus,
    dataUnitNumber
  ]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleSearch = async() => {
    const { prefix,suffix } = parseInput(selectText);
    
    switch (selectedValue) {
      case "first_name":
        buyerSearchFirst({
          variables: {
            first_name: `%${selectText}%`
          }
        });
        break;
      case "passport_number":
         buyerSearchPass({
          variables: {
            passport_number: `%${selectText}%`
          }
        });
        break;
      case "last_name":
        buyerSearchLast({
          variables: {
            last_name: `%${selectText}%`
          }
        });
        break;
      case "email":
        buyerSearchEmail({
          variables: {
            email: `%${selectText}%`
          }
        });
        break;
      case "oci_number":
        buyerSearchOci({
          variables: {
            oci_number: `%${selectText}%`
          }
        });
        break;
      case "abhyasi_id":
         buyerSearchAbhyasi({
          variables: {
            abhyasi_id: `%${selectText}%`
          }
        });
        break;
      case "aadhar_number":
        buyerSearchAadhaar({
          variables: {
            aadhar_number: `%${selectText}%`
          }
        });
        break;
      case "pan_number":
         buyerSearchPan({
          variables: {
            pan_number: `%${selectText}%`
          }
        });
        break;
      case "city":
        buyerSearchCity({
          variables: {
            city: `%${selectText}%`
          }
        });
        break;
      case "state":
        buyerSearchState({
          variables: {
            state: `%${selectText}%`
          }
        });
        break;
      case "country":
        buyerSearchCountry({
          variables: {
            country: `%${selectText}%`
          }
        });
        break;
      case "salesId":
        buyerSearchTransaction({
          variables: {
            id: selectText
          }
        });
        break;
      case "status":
        buyerSearchStatus({
          variables: {
            status: `%${selectText}%`
          }
        });
        break;
      case "unitNumber":
        buyerSearchUnitNumber({
          variables: {
            unit_number: parseInt(suffix),
            tower_name: `%${prefix}%`
          }
        });
        break;
      default:
        break;
    }

    // switch (selectedValue) {
    //   case "first_name":
    //     if (dataFirst && dataFirst.sale_transaction.length > 0) {
    //       setBuyerTable(dataFirst.sale_transaction);
    //     } else {
    //       setBuyerTable([]);
    //     }
    //     break;
    //   case "passport_number":
    //     if (dataPass && dataPass.sale_transaction.length > 0) {
    //       setBuyerTable(dataPass.sale_transaction);
    //     } else {
    //       setBuyerTable([]);
    //     }
    //     break;
    //   case "last_name":
    //     if (dataLast && dataLast.sale_transaction.length > 0) {
    //       setBuyerTable(dataLast.sale_transaction);
    //     } else {
    //       setBuyerTable([]);
    //     }
    //     break;
    //   case "email":
    //     if (dataEmail && dataEmail.sale_transaction.length > 0) {
    //       setBuyerTable(dataEmail.sale_transaction);
    //     } else {
    //       setBuyerTable([]);
    //     }
    //     break;
    //   case "oci_number":
    //     if (dataOci && dataOci.sale_transaction.length > 0) {
    //       setBuyerTable(dataOci.sale_transaction);
    //     } else {
    //       setBuyerTable([]);
    //     }
    //     break;
    //   case "abhyasi_id":
    //     if (dataAbhyasi && dataAbhyasi.sale_transaction.length > 0) {
    //       setBuyerTable(dataAbhyasi.sale_transaction);
    //     } else {
    //       setBuyerTable([]);
    //     }
    //     break;
    //   case "aadhar_number":
    //     if (dataAadhaar && dataAadhaar.sale_transaction.length > 0) {
    //       setBuyerTable(dataAadhaar.sale_transaction);
    //     } else {
    //       setBuyerTable([]);
    //     }
    //     break;
    //   case "pan_number":
    //     if (dataPan && dataPan.sale_transaction.length > 0) {
    //       setBuyerTable(dataPan.sale_transaction);
    //     } else {
    //       setBuyerTable([]);
    //     }
    //     break;
    //   case "city":
    //     if (dataCity && dataCity.sale_transaction.length > 0) {
    //       setBuyerTable(dataCity.sale_transaction);
    //     } else {
    //       setBuyerTable([]);
    //     }
    //     break;
    //   case "state":
    //     console.log("dataState",dataState);
    //     if (dataState && dataState.sale_transaction.length !== 0) {
    //       console.log(dataState?.sale_transaction,"dataState.sale_transaction");

    //       setBuyerTable(dataState.sale_transaction);
    //     } else {
    //       console.log(dataState?.sale_transaction,"435345325345345dataState.sale_transaction");

    //       setBuyerTable([]);
    //     }
    //     break;
    //   case "country":
    //     if (dataCountry && dataCountry.sale_transaction.length > 0) {
    //       setBuyerTable(dataCountry.sale_transaction);
    //     } else {
    //       setBuyerTable([]);
    //     }
    //     break;
    //   case "salesId":
    //     if (dataTransaction && dataTransaction.sale_transaction.length > 0) {
    //       setBuyerTable(dataTransaction.sale_transaction);
    //     } else {
    //       setBuyerTable([]);
    //     }
    //     break;
    //   case "status":
    //     if (datStatus && datStatus.sale_transaction.length > 0) {
    //       setBuyerTable(datStatus.sale_transaction);
    //     } else {
    //       setBuyerTable([]);
    //     }
    //     break;
    //   case "unitNumber":
    //     if (dataUnitNumber && dataUnitNumber.sale_transaction.length > 0) {
    //       console.log(dataUnitNumber?.sale_transaction,"dataUnitNumber.sale_transaction");
    //       setBuyerTable(dataUnitNumber.sale_transaction);
    //     } else {
    //       console.log(dataUnitNumber?.sale_transaction,"1112222222dataUnitNumber.sale_transaction");
    //       setBuyerTable([]);
    //     }
    //     break;
    //   default:
    //     break;
    // }

    switch (selectedValue) {
      case "first_name":
        refetchFirst();
        break;
      case "passport_number":
        refetchPass();
        break;
      case "last_name":
        refetchLast();
        break;
      case "email":
        refetchEmail();
        break;
      case "oci_number":
         refetchOci();
        break;
      case "abhyasi_id":
        refetchAbhyasi();
        break;
      case "aadhar_number":
        refetchAadhaar();
        break;
      case "pan_number":
         refetchPan();
        break;
      case "city":
         refetchCity();
        break;
      case "state":
        refetchState();
        break;
      case "country":
        refetchCountry();
        break;
      case "salesId":
        refetchTransaction();
        break;
      case "status":
        refetchStatus();
        break;
      case "unitNumber":
         refetchUnitNumber();
        break;
      default:
        break;
    }

    setSearchData({
      selectedValue,
      selectText
    });
  };

  const columns = [
    {
      field: "salesId",
      headerName: "Sales Id",
      width: 80,
      align: "center"
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      align: "center"
    },
    {
      field: "emailid",
      headerName: "Email ID",
      width: 200,
      align: "center"
    },
    {
      field: "project",
      headerName: "Project Name",
      width: 150,
      align: "center"
    },
    {
      field: "propertyType",
      headerName: "Property Type",
      width: 200,
      align: "center"
    },
    {
      field: "city",
      headerName: "City",
      width: 150,
      align: "center"
    },
    {
      field: "state",
      headerName: "State",
      width: 150,
      align: "center"
    },
    {
      field: "country",
      headerName: "Country",
      width: 150,
      align: "center"
    },
    {
      field: "oci",
      headerName: "OCI",
      width: 93,
      align: "center"
    }
  ];

  const rows =
    buyerTable?.length > 0 &&
    buyerTable?.map((item) => {
      return {
        id: item?.sale_transaction_id,
        buyerId: item?.buyers[0]?.buyer_id,
        salesId: item?.sale_transaction_id,
        propertyId: item?.property_id ? item.property_id : item?.property?.property_id,
        name: `${
          item?.buyers[0] === null
            ? ""
            : item?.buyers[0]?.first_name + " " + item?.buyers[0]?.last_name
        }`,
        emailid: item?.user?.email,
        project:
          item?.property === null
            ? "Yet to select"
            : item?.property?.project?.project_name,
        propertyType: `${
          item.property === null
            ? "Yet to select"
            : item?.property?.project_building?.tower_name +
              " " +
              item?.property?.unit_number
        }`,
        city: item?.buyers[0]?.city,
        state: item?.buyers[0]?.state,
        country: item?.buyers[0]?.country,
        oci:
          item?.buyers[0]?.oci_number === null ||
          item?.buyers[0]?.oci_number === "" ||
          item?.buyers.length === 0
            ? "N"
            : item?.buyers[0]?.oci_number
      };
    });

  const date = {
    id: "Date:",
    buyerId: new Date()
  };

  const title = {
    id: "Title:",
    buyerId: "Transaction Search"
  };

  const time = {
    id: "Time:",
    buyerId: moment().format("hh:mm a")
  };

  const downloadExcel = () => {
    const sheet = XLSX.utils.json_to_sheet(
      (rows && [title, date, time, "", ...rows]) || [],
      { skipHeader: true }
    );
    let Heading = [
      [
        "Id",
        "Buyer Id",
        "Sales Id",
        "Name",
        "Email Id",
        "Project",
        "Property Type",
        "City",
        "State",
        "Country",
        "OCI"
      ]
    ];
    XLSX.utils.sheet_add_aoa(sheet, Heading, { origin: "A4" });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, sheet, "Sheet1");
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array"
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    });
    saveAs(blob, "buyers.xlsx");
  };

  const handleRowClick = (params) => {
    const rowData = params?.row;
    const buyerId = rowData?.buyerId;
    const salesId = rowData?.salesId;
    const propertyId = rowData?.propertyId;
    let url = `/admin/buyerProfile/${buyerId}/${salesId}`;
    if (propertyId !== undefined) {
      url += `/${propertyId}`;
    }
    navigate(url);
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ paddingLeft: "14px !important" }}>
        <BackButton handleBack={() => navigate(-1)} />
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "25px",
            lineHeight: "30px",
            color: "#212121",
            margin: "30px 0px 30px 0px"
          }}
        >
          Transaction Search
        </Typography>
        <Box sx={{ display: "flex", gap: "24px" }}>
          <Box sx={{ display: "flex", gap: "12px" }}>
            <Box>
              <FormControl sx={{ height: "56px", width: "200px" }}>
                <Select
                  value={selectedValue}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem selected disabled value="">
                    None
                  </MenuItem>
                  <MenuItem value={"first_name"}>First Name</MenuItem>
                  <MenuItem value={"last_name"}>Last Name</MenuItem>
                  <MenuItem value={"email"}> Email ID</MenuItem>
                  <MenuItem value={"oci_number"}>OCI</MenuItem>
                  <MenuItem value={"abhyasi_id"}>Abhyasi ID</MenuItem>
                  <MenuItem value={"aadhar_number"}> Aadhaar ID</MenuItem>
                  <MenuItem value={"pan_number"}>PAN Number</MenuItem>
                  <MenuItem value={"passport_number"}>Passport Number</MenuItem>
                  <MenuItem value={"city"}>City</MenuItem>
                  <MenuItem value={"state"}>State</MenuItem>
                  <MenuItem value={"country"}>Country</MenuItem>
                  <MenuItem value={"salesId"}>Transaction Id</MenuItem>
                  <MenuItem value={"status"}>Transaction Status</MenuItem>
                  <MenuItem value={"unitNumber"}>Unit Number</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box>
              <TextField
                sx={{ width: "228px", height: "56px" }}
                value={selectText}
                onChange={(e) => setSelectText(e.target.value)}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: "24px" }}>
            <Box>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#0671E0",
                  color: "white",
                  fontWeight: 400,
                  lineHeight: "24px",
                  textTransform: "capitalize",
                  fontSize: "18px",
                  width: "156px",
                  height: "56px",
                  padding: "16px, 32px, 16px, 32px",
                  gap: "8px",
                  borderRadius: "4px",
                  "&:hover": {
                    backgrindColor: "#0671E0"
                  }
                }}
                onClick={handleSearch}
              >
                Search
                <img src="/assets/image/Search.png" alt="SearchIcon" />
              </Button>
            </Box>
            <Box>
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: "white",
                  color: "#0671E0",
                  fontWeight: 400,
                  lineHeight: "24px",
                  textTransform: "capitalize",
                  fontSize: "16px",
                  "&:hover": {
                    backgrindColor: "white"
                  },
                  padding: "16px, 32px, 16px, 32px",
                  width: "151px",
                  height: "56px",
                  gap: "10px",
                  border: "1px solid #0671E0",
                  textAlign: "Center"
                }}
                onClick={downloadExcel}
              >
                Download XLS
              </Button>
            </Box>
          </Box>
        </Box>
        <Box sx={{ marginTop: "30px" }}>
          <TableData
            rows={rows}
            columns={columns}
            HeaderbackgroundColor={"#F5F7FA"}
            tableHeaderAlign={"center !important"}
            handleOnCellClick={handleRowClick}
            rowPerPage={50}
          />
        </Box>
      </Box>
    </Container>
  );
};
