import { useEffect, useState } from "react";
import jwtDecode from "jwt-decode";

let token;

const useToken = () => {
  // const [token, setToken] = useState('');
  const [tokenExpireModel, setTokenExpireModel] = useState(false);

  useEffect(() => {
    const storedToken = JSON.parse(
      localStorage.getItem("cognitoAuthDetails")
    )?.idToken;
    if (storedToken) {
      const decodedToken = jwtDecode(storedToken);
      const currentTimestamp = Math.floor(Date.now() / 1000); // Convert to seconds
      const twoMinutes = 2 * 60; // 2 minutes in seconds

      if (decodedToken.exp < currentTimestamp) {
        // Token has expired
        localStorage.clear();
        token = "";
        // setToken('');
      } else if (decodedToken.exp - currentTimestamp <= twoMinutes) {
        // Token is about to expire in 2 minutes
        setTokenExpireModel(true);
        token = storedToken;
        // setToken(storedToken);
      } else {
        // setToken(storedToken);
        token = storedToken;
      }
    }
  }, []);

  return { token, tokenExpireModel, setTokenExpireModel };
};

export default useToken;
