import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import ChartCommon from "../../../components/ChartCommon";
import ChartBoxData from "../../../components/ChartBoxData";

export const SaleAgreements = () => {

  const salesData = [
    {
      value: 5,
      label: "Overdue",
    },
    {
      value: 2,
      label: "Due Today",
    },
    {
      value: 28,
      label: "Average Time To completion (Days)",
    },
  ];

  return (
    <Box>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: "20px",
          lineHeight: "23px",
          color: "#212121",
          paddingBottom: "20px",
          paddingTop: "8px",
        }}
      >
        Sale Agreements{" "}
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "space-between", height:'225px' }}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Box>
              <ChartBoxData chartBoxData={salesData}/>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              sx={{
               
                height: "225px",
                borderRadius: "10px",
                border: "1px solid #E8E8EA",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "28px",
                  color: "#212121",
                  padding: "5px 5px 0px 5px",
                }}
              >
                Total Agreements (90 Days)
              </Typography>
              <hr />
              <Box sx={{ padding: "0 20px " }}>
                <ChartCommon />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
