import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { AdminlegalHeirsData } from "../../../../lib/query/query";
import TableData from "../../../../components/TableData";
import * as Sentry from "@sentry/react";

export const LegalHeirs = () => {
  const [legalHeirsData, setLegalHeirsData] = useState({});
  const { salesId } = useParams();

  const { data,error } = useQuery(AdminlegalHeirsData, {
    variables: { sale_transaction_id: salesId }
  });

  useEffect(() => {
    if (data?.legal_heir && data?.legal_heir.length > 0) {
      setLegalHeirsData(data?.legal_heir);
    }
  }, [data]);

  if(error){
    Sentry.captureException(error)
  }

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
      align: "center"
    },
    {
      field: "age",
      headerName: "Age",
      width: 200,
      align: "center"
    },
    {
      field: "relationship",
      headerName: "Relationship",
      width: 200,
      align: "center"
    },
    {
      field: "idType",
      headerName: "ID Type",
      width: 200,
      align: "center"
    },
    {
      field: "idNumber",
      headerName: "ID Number",
      width: 200,
      align: "center"
    }
  ];

  const rows =
    legalHeirsData.length > 0 &&
    legalHeirsData.map((item) => {
      return {
        id: item?.legal_heir_id,
        salesId: item?.sale_transaction_id,
        name: `${item?.full_name === null ? "" : item?.full_name}`,
        age: item?.age,
        relationship: item?.relationship_buyer,
        idType: item?.id_type,
        idNumber: item?.id_number
      };
    });

  return (
    <Box>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: "25px",
          lineHeight: "30px",
          color: "#212121",
          margin: "0px 0px 20px 0px"
        }}
      >
        Legal Heirs
      </Typography>
      <TableData
        rows={rows}
        columns={columns}
        HeaderbackgroundColor={"#F5F7FA"}
        tableHeaderAlign={"center !important"}
        width={1050}
        noDataFound={"No Records Found"}
      />
    </Box>
  );
};
