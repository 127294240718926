import React, { useContext, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  CircularProgress
} from "@mui/material";
import CardStepper from "../../../components/CardStepper";
import EastIcon from "@mui/icons-material/East";
import { useQuery } from "@apollo/client";
import { saleTransactionStatus } from "../../../lib/query/query";
import BackButton from "../../../components/BackButton";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { CancelModal } from "./CancelModal";
import Steppercontext from "../../../lib/context/contextData";
import { toast } from "react-toastify";
import ErrorToast from "../../../components/ErrorToast";
import * as Sentry from "@sentry/react";

let timelineChart = [];

export const AllStatesTimeline = () => {
  const [open, setOpen] = React.useState(false);
  const [projectName, setProjectName] = useState("");

  const { setSalesId, events, loading, refetch } = useContext(Steppercontext);

  const navigate = useNavigate();
  const { salesId } = useParams();

  const { error: errorTransactionStatus } = useQuery(saleTransactionStatus, {
    variables: { sale_transaction_id: salesId }
  });

  useEffect(() => {
    setSalesId(salesId);
  }, [salesId]);

  const stateTimelineData =
    events &&
    events.sale_transaction &&
    events.sale_transaction[0]?.sale_transaction_statuses;

  useEffect(() => {
    if (stateTimelineData && stateTimelineData?.length > 0) {
      refetch();
      timelineChart = [];
      stateTimelineData.map((item, index) => {
        const url =
          item.status === "Complete"
            ? "/assets/image/icons1.png"
            : item.status === "Pending Validation"
            ? "/assets/image/icons3.png"
            : item.status === "Action Required"
            ? "/assets/image/icons4.png"
            : item.status === "In Progress"
            ? "/assets/image/icons5.png"
            : item.status === "Yet to Start"
            ? "/assets/icons/YetToStart.svg"
            : "/assets/image/icons2.png";

        const iconColor =
          item.status === "Complete"
            ? "#227231"
            : item.status === "Pending Validation"
            ? "#FFA826"
            : item.status === "Action Required"
            ? "#E02B1D"
            : item.status === "In Progress"
            ? "#7B05D8"
            : item.status === "Yet to Start"
            ? "#0671E0"
            : "gray";

        timelineChart.push({
          locked: item.locked,
          description: item.process_step,
          status: item.status,
          step: item.step_index,
          iconColor: iconColor,
          url: url
        });
      });
    }
  }, [stateTimelineData]);

  useEffect(() => {
    if (projectName) {
      const projectNameData = `${
        events?.sale_transaction[0]?.property?.project?.project_name ===
        undefined
          ? "Purchase"
          : events?.sale_transaction[0]?.property?.project?.project_name
      } - ${
        events?.sale_transaction[0]?.property?.project_building?.tower_name ===
        undefined
          ? " Process"
          : events?.sale_transaction[0]?.property?.project_building?.tower_name
      } ${
        events?.sale_transaction[0]?.property?.unit_number === undefined
          ? ""
          : events?.sale_transaction[0]?.property?.unit_number
      }`;

      setProjectName(projectNameData);
    } else {
      setProjectName("Purchase Process");
    }
  }, [events]);

  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh"
        }}
      >
        <CircularProgress />
      </Box>
    );

  if (errorTransactionStatus) {
    toast.error(
      <ErrorToast
        email={"info@msvkanha.com"}
        errorMsg={errorTransactionStatus && errorTransactionStatus?.message}
      />
    );
    Sentry.captureException(
      errorTransactionStatus && errorTransactionStatus?.message
    );
  }

  const handleCardStepper = (card) => {
    if (card.status !== "Locked") {
      if (card.description === "Verify Your ID (KYC)") {
        const pathName = window.location.pathname;
        navigate(`${pathName}/verifyId`);
      } else if (card.description === "Book Property") {
        navigate(`/myProperties/bookProperties/${salesId}`);
      } else if (card.description === "Family Members") {
        navigate(`/familyMembers/${salesId}`);
      } else if (card.description === "Complete Sale Agreement") {
        navigate("/salesAgreement");
      } else if (card.description === "Property Registration") {
        navigate(`/propertyRegistration/${salesId}`);
      }
    }
  };

  return (
    <>
      <Container>
        {!loading && (
          <Box sx={{ marginBottom: "100px" }}>
            <BackButton handleBack={() => navigate("/")} />

            <Typography
              sx={{
                mt: 1,
                fontSize: "25px",
                lineHeight: "30px",
                fontWeight: 600
              }}
            >
              Properties
            </Typography>
            <Box
              sx={{
                border: "1px solid #0663C7",
                borderRadius: "10px",

                backgroundColor: "#EEF5FC",
                margin: "26px 0 50px 0"
              }}
            >
              <Box
                sx={{
                  justifyContent: "space-between",
                  textAlign: "center",
                  alignItems: "center",
                  margin: "18px"
                }}
              >
                <Box sx={{ display: "flex", color: "#0663C7" }}>
                  <span>
                    <img
                      src="/assets/image/Infosquarehome.svg"
                      alt="Info square"
                      height={24}
                      width={50}
                      style={{ color: "#0663C7" }}
                    />
                  </span>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#717171",
                      textAlign: "start",
                      paddingLeft: "8px",
                      lineHeight: "24px",
                      // color: "#0663C7"
                    }}
                  >
                    To view and book available properties, please provide the
                    Buyer information in the KYC section below. The details of
                    the Buyer can be provided only once and can not be changed
                    afterwards. The Sale Agreement and Property Deed will be
                    executed in favor of the Buyer provided in KYC section.
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "#212121",
                  fontWeight: 600,
                  fontSize: "20px",
                  lineHeight: "23px"
                }}
              >
                {loading ? <span>Loading...</span> : projectName}
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 400,
                  color: "#717171",
                  lineHeight: "24px",
                  padding: "6px 0 15px"
                }}
              >
                To purchase a property, click through each of the boxes below,
                starting with Book Property.
                <br />
                All steps are mandatory and will be unlocked once the
                pre-requisite steps are completed.
              </Typography>
            </Box>
            <Box>
              <CardStepper
                cardData={timelineChart}
                handleClick={handleCardStepper}
              />
            </Box>

            <Box
              sx={{
                border: "1px solid #E8E8EA",
                borderRadius: "10px",
                marginTop: "32px"
              }}
            >
              <Box
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <Box
                  sx={{
                    m: 4
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "25px",
                      fontWeight: 600,
                      lineHeight: "23px"
                    }}
                  >
                    Cancel Property Purchase
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#717171",
                      lineHeight: "24px",
                      mt: "10px"
                    }}
                  >
                    You can cancel your purchase at any time. Once cancelled,
                    the same property may not be available for <br />
                    booking. A cancellation fee will be levied.
                  </Typography>
                </Box>
                <hr />

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end"
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#E02B1D",
                      color: "white",
                      textTransform: "capitalize",
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "20px",
                      padding: "14px 32px",
                      m: 2,

                      "&:hover": {
                        backgroundColor: "#E02B1D"
                      }
                    }}
                    onClick={() => setOpen(true)}
                  >
                    Cancel Purchase{" "}
                    <EastIcon sx={{ ml: 1, fontSize: "18px" }} />
                  </Button>
                </Box>
              </Box>
              {open && <CancelModal open={open} setOpen={setOpen} />}
            </Box>
          </Box>
        )}
      </Container>
    </>
  );
};
