import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Home = () => {
    const navigate = useNavigate();
    useEffect(() => {
        // navigate("/admin/dashboard")
        navigate("/admin/dashboardIdVerification")
    }, [])
  return (
  <></>
  )
}

export default Home