import React from 'react';
import TabContainer from '../TabContainer';
import { homeTab } from '../../lib/constants/homeTab';

const TabLayout = ({ children }) => {
  return (
    <>
      <TabContainer tabsData={homeTab} />
      {children}
    </>
  )
}

export default TabLayout