import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Container,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import {
  Delete_File,
  // IdentityProofUpdateStatus,
  // IdentityProofUpdateUnlocked,
  Insert_Buyer_Identity_Doc,
  Insert_Buyer_Identity_DocFile,
  Insert_Document,
} from "../../lib/mutation/mutation";
import { useMutation, useQuery } from "@apollo/client";
import { UploadFile } from "../../components/UploadFile";
import {
  CustomFormLabel,
  CustomTextField,
} from "../../lib/constants/textField";
import { Controller, useForm } from "react-hook-form";
import _ from "lodash";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useParams } from "react-router-dom";
import { errorToast, successToast } from "../../pages/Toasts/showToasts";
import useFileToHexString from "../../hook/useFileToHexString";
import ModalCommon from "../../components/ModalCommon";
import Steppercontext from "../../lib/context/contextData";
import { saleTransactionStatus } from "../../lib/query/query";
import * as Sentry from "@sentry/react";

export const IdentityProof = ({
  activeStep,
  handleBack,
  data,
  identityProof,
  setIsIdentity,
}) => {
  const { salesId, buyerId } = useParams();
  const [insert_document] = useMutation(Insert_Document);
  const [insert_buyer_identity_doc] = useMutation(Insert_Buyer_Identity_Doc);
  const [insert_buyer_identity_doc_file] = useMutation(
    Insert_Buyer_Identity_DocFile
  );
  // const [update_status] = useMutation(IdentityProofUpdateStatus);
  // const [update_unlocked] = useMutation(IdentityProofUpdateUnlocked);

  const [formData, setFormData] = useState({});
  const [idProofFile, setIdProofFile] = useState({});
  const [idProofFile2, setIdProofFile2] = useState({});
  const [delete_File] = useMutation(Delete_File);
  const [isLoading, setIsLoading] = useState(false);
  const [removeFile, setRemoveFile] = useState(false);
  const [removeFileCard, setRemoveFileCard] = useState(false);

  const { setSalesId, events } = useContext(Steppercontext);

  const Status =
    events.sale_transaction[0]?.sale_transaction_statuses[0].status;

  const { data: transactionStatus,error } = useQuery(saleTransactionStatus, {
    variables: { sale_transaction_id: salesId },
  });
  const TransactionStatus = transactionStatus?.sale_transaction[0].status;

  const params =
    events &&
    events.sale_transaction &&
    events.sale_transaction[0]?.sale_transaction_statuses;
  const lockedValue = params && params[0]?.locked;
  const lockedStatus =
    params &&
    params[0]?.status === "Action Required" &&
    TransactionStatus !== "Cancellation Requested" &&
    TransactionStatus !== "Cancelled";

  const { convertFileToHexStringPromise } = useFileToHexString();

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    register,
    setValue,
    formState: { errors, dirtyFields },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      aadhar_number: "",
      pan_number: "",
      oci_number: "",
      passport_number: "",
    },
  });

  if (error) {
    Sentry.captureException(error);
  }

  useEffect(() => {
    setSalesId(salesId);
  }, [salesId]);

  useEffect(() => {
    if (Status !== "Action Required" && data && data.buyer[0]) {
      setFormData(data.buyer[0]);
    }
  }, [data, Status]);

  const IdName =
    identityProof.citizenship === ""
      ? data && data.buyer[0] && data.buyer[0].citizenship === "Indian"
        ? "Aadhaar Card"
        : data && data.buyer[0] && data.buyer[0].citizenship === "NRI"
        ? "Passport"
        : "Passport"
      : identityProof.citizenship === "Indian"
      ? "Aadhaar Card"
      : identityProof.citizenship === "NRI"
      ? "Passport"
      : "Passport";

  const cardName =
    identityProof.citizenship === ""
      ? data && data.buyer[0] && data.buyer[0].citizenship === "Indian"
        ? "PAN Card Number (optional)"
        : data && data.buyer[0] && data.buyer[0].citizenship === "NRI"
        ? "Aadhaar Number (optional)"
        : "OCI Card"
      : identityProof.citizenship === "Indian"
      ? "PAN Card Number (optional)"
      : identityProof.citizenship === "NRI"
      ? "Aadhaar Number (optional)"
      : "OCI Card";

  const IdField =
    IdName === "Aadhaar Card" ? "aadhar_number" : "passport_number";

  const cardField =
    cardName === "PAN Card Number (optional)"
      ? "pan_number"
      : cardName === "Aadhaar Number (optional)"
      ? "aadhar_number"
      : "oci_number";

  const docType1 =
    IdName === "Aadhaar Card"
      ? "Aadhaar"
      : IdName === "Passport"
      ? "Passport"
      : "OCI";
  const docType2 =
    cardName === "PAN Card Number (optional)"
      ? "Pancard"
      : cardName === "Aadhaar Number (optional)"
      ? "Aadhaar"
      : "OCI";

  useEffect(() => {
    if (!_.isEmpty(formData)) {
      const proof1 = formData?.buyer_identity_docs?.filter(
        (item) => item.document_type === docType1
      )[0];
      setIdProofFile(proof1);

      const proof2 = formData?.buyer_identity_docs?.filter(
        (item) => item.document_type === docType2
      )[0];
      setIdProofFile2(proof2);
    }
  }, [formData, docType1, docType2]);

  useEffect(() => {
    if (!_.isEmpty(formData)) {
      const formDataUpdate = {
        ...formData,
        upload_file_one: !_.isEmpty(idProofFile)
          ? `${idProofFile.file_name}.${idProofFile.file_extension}`
          : "",
        upload_file_two: !_.isEmpty(idProofFile2)
          ? `${idProofFile2.file_name}.${idProofFile2.file_extension}`
          : "",
      };

      Object.keys(formDataUpdate).forEach((key) => {
        setValue(key, formData[key] || "");
      });
    }
  }, [formData, setValue]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    Sentry.addBreadcrumb({
      category: "button_click",
      message: "Next button clicked on KYC Identity Proof Screen",
      data: {
        loading_step: "Identity Proof screen"
      }
    });
    if (
      dirtyFields?.aadhar_number ||
      dirtyFields?.pan_number ||
      dirtyFields?.oci_number ||
      dirtyFields?.passport_number
    ) {
      setIsLoading(true);
      await insert_document({
        variables: {
          buyer_id: buyerId,
          aadhar_number: data.aadhar_number,
          pan_number: data.pan_number,
          oci_number: data.oci_number,
          passport_number: data.passport_number,
        },
      })
        .then((res) => {
          //res
          setIsLoading(false);
        })
        .catch((err) => {
          Sentry.captureException(err);
        });
    }

    if (dirtyFields.upload_file_one) {
      setIsLoading(true);
      await insert_buyer_identity_doc({
        variables: {
          buyer_id: buyerId,
          document_number: data[`${IdField}`],
          file_name: idProofFile?.file_name,
          file_extension: idProofFile?.file_extension,
          document_type: docType1,
          document: idProofFile?.haxContent,
        },
      })
        .then((response) => {
          // response
          setIsLoading(false);
          successToast("File upload successful!");
          Sentry.getCurrentScope().setTransactionName('KYC-ID-1 upload');
        })
        .catch((error) => {
          Sentry.captureException(error);
        });

      // Commented Mutation (Keep as it is)
      // await update_status({
      //   variables: {
      //     sale_transaction_id: salesId,
      //     step_index: 1,
      //     status: "Pending Validation",
      //     locked: true
      //   }
      // })
      //   .then((response) => {})
      //   .catch((error) => {});
    }

    if (dirtyFields.upload_file_two) {
      setIsLoading(true);
      await insert_buyer_identity_doc_file({
        variables: {
          buyer_id: buyerId,
          document_number: data[`${cardField}`],
          file_name: idProofFile2?.file_name,
          file_extension: idProofFile2?.file_extension,
          document_type: docType2,
          document: idProofFile2?.haxContent,
        },
      })
        .then((response) => {
          // respose
          setIsLoading(false);
          successToast("File upload successful!");
          Sentry.getCurrentScope().setTransactionName('KYC-ID-2 upload');
        })
        .catch((error) => {
          Sentry.captureException(error);
        });

      // Commented Mutation (Keep as it is)
      // await update_status({
      //   variables: {
      //     sale_transaction_id: salesId,
      //     step_index: 1,
      //     status: "Pending Validation",
      //     locked: true
      //   }
      // })
      //   .then((response) => {})
      //   .catch((error) => {});
    }
    if (lockedValue !== true) {
      // Commented Mutation (Keep as it is)
      // await update_status({
      //   variables: {
      //     sale_transaction_id: salesId,
      //     step_index: 1,
      //     status: "Pending Validation",
      //     locked: true
      //   }
      // })
      //   .then((response) => {})
      //   .catch((error) => {});
      // Commented Mutation (Keep as it is)
      // await update_unlocked({
      //   variables: {
      //     sale_transaction_id: salesId,
      //     step_index: 2,
      //     status: "Locked",
      //     locked: false
      //   }
      // })
      //   .then((response) => {})
      //   .catch((error) => {});
    }
    setIsLoading(false);

    navigate(`/myProperties/allStates/${salesId}`);
  };

  const handleIdProofFileDelete = (idProofFile) => {
    if (removeFile) {
      delete_File({
        variables: {
          buyer_identity_doc_id: idProofFile.buyer_identity_doc_id,
        },
      })
        .then((response) => {
          //response
        })
        .catch((error) => {
          Sentry.captureException(error);
        });
      setValue("upload_file_one", "");
      setIdProofFile({});
    }
  };

  const handleIdProofFile2Delete = (idProofFile2) => {
    if (removeFileCard) {
      delete_File({
        variables: {
          buyer_identity_doc_id: idProofFile2.buyer_identity_doc_id,
        },
      })
        .then((response) => {
          //response
        })
        .catch((error) => {
          Sentry.captureException(error);
        });
      setValue("upload_file_two", "");
      setIdProofFile2({});
    }
  };

  const MAX_FILE_SIZE = 5 * 1024 * 1024;
  // const MAX_IMAGE_WIDTH = 800;
  // const MAX_IMAGE_HEIGHT = 400;    // size condition

  // const validateImageDimensions = (file) => {
  //   return new Promise((resolve, reject) => {
  //     const img = new Image();
  //     img.onload = function () {
  //       const width = img.width;
  //       const height = img.height;

  //       if (width > MAX_IMAGE_WIDTH || height > MAX_IMAGE_HEIGHT) {
  //         reject(
  //           new Error(
  //             `Image dimensions should not exceed ${MAX_IMAGE_WIDTH}x${MAX_IMAGE_HEIGHT}px`
  //           )
  //         );
  //         // errorToast(
  //         //   reject(
  //         //     new Error(
  //         //       `Image dimensions should not exceed ${MAX_IMAGE_WIDTH}x${MAX_IMAGE_HEIGHT}px`
  //         //     )
  //         //   )
  //         // );
  //       } else {
  //         resolve(true);
  //       }
  //     };

  //     img.onerror = function () {
  //       reject(new Error("Error loading image"));
  //     };

  //     img.src = URL.createObjectURL(file);
  //   });
  // };

  const handleUploadIdProof = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const allowedFileTypes = [
      "image/jpeg",
      "image/jpg",
      "image/svg+xml",
      "image/png",
      "application/pdf",
      "application/msword",
      "application/vnd.ms-excel",
    ];
    if (!allowedFileTypes.includes(file.type)) {
      errorToast("Only SVG, PNG, JPEG, and JPG files are allowed.");
      return;
    }
    if (file.size > MAX_FILE_SIZE) {
      errorToast("File size exceeds the limit.");
      return;
    }
    if (file.type.startsWith("image/")) {
      try {
        // await validateImageDimensions(file);  //size condition
      } catch (error) {
       Sentry.captureException(error);
        errorToast(error.message);
        return;
      }
    }
    try {
      const hexData = await convertFileToHexStringPromise(file);
      const fileStore = {
        file_name: file.name.split(".").shift(),
        file_extension: file.name.split(".").pop(),
        haxContent: hexData,
      };
      setIdProofFile(fileStore);
      
    } catch (err) {
      Sentry.captureException(err);
    }
  };
  const handleUploadIdProof2 = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const allowedFileTypes = [
      "image/jpeg",
      "image/jpg",
      "image/svg+xml",
      "image/png",
      "application/pdf",
      "application/msword",
      "application/vnd.ms-excel",
    ];
    if (!allowedFileTypes.includes(file.type)) {
      errorToast("Only SVG, PNG, JPEG, and JPG files are allowed.");
      return;
    }
    if (file.size > MAX_FILE_SIZE) {
      errorToast("File size exceeds the limit.");
      return;
    }
    if (file.type.startsWith("image/")) {
      try {
        // await validateImageDimensions(file);   //size condition
      } catch (error) {
        Sentry.captureException(error);
        errorToast(error.message);
        return;
      }
    }
    try {
      const hexData = await convertFileToHexStringPromise(file);
      const fileStore = {
        file_name: file.name.split(".").shift(),
        file_extension: file.name.split(".").pop(),
        haxContent: hexData,
      };
      setIdProofFile2(fileStore);
    } catch (err) {
      Sentry.captureException(err);
    }
  };
  const handleClose = () => {
    if (removeFile) setRemoveFile(false);
    if (removeFileCard) setRemoveFileCard(false);
  };

  const Header = () => {
    return (
      <>
        {removeFile && <p className="font-bold text-2xl">Remove file</p>}
        {removeFileCard && <p className="font-bold text-2xl">Remove File</p>}
      </>
    );
  };
  const Body = () => {
    return (
      <>
        <Box>
          <Box sx={{ marginBottom: "12px" }}>
            {removeFile && (
              <Typography>Are you sure You want to remove file?</Typography>
            )}
            {removeFileCard && (
              <Typography>Are you sure You want to remove File?</Typography>
            )}
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Button
            sx={{
              borderRadius: "4px",
              gap: "10px",
              backgroundColor: "#FFFFFF",
              fontSize: "15px",
              lineHeight: "20px",
              textAlign: "Center",
              fontWeight: 600,
              color: "#717171",
              textTransform: "capitalize",
              width: "60px",
              height: "36px",
              marginRight: "8px",
            }}
            onClick={handleClose}
          >
            No
          </Button>
          {removeFile && (
            <Button
              sx={{
                borderRadius: "4px",
                gap: "10px",
                backgroundColor: "#0671E0",
                fontSize: "15px",
                lineHeight: "20px",
                textAlign: "Center",
                fontWeight: 600,
                color: "#FFFFFF",
                width: "60px",
                height: "36px",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#0671E0",
                },
              }}
              onClick={() => {
                setRemoveFile(false);
                setValue("upload_file_one", "");
                setIdProofFile({});
              }}
            >
              Yes
            </Button>
          )}
          {removeFileCard && (
            <Button
              sx={{
                borderRadius: "4px",
                gap: "10px",
                backgroundColor: "#0671E0",
                fontSize: "15px",
                lineHeight: "20px",
                textAlign: "Center",
                fontWeight: 600,
                color: "#FFFFFF",
                width: "60px",
                height: "36px",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#0671E0",
                },
              }}
              onClick={() => {
                setRemoveFileCard(false);
                setValue("upload_file_two", "");
                setIdProofFile2({});
              }}
            >
              Yes
            </Button>
          )}
        </Box>
      </>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <Box
          sx={{
            opacity: lockedStatus === true ? 1 : lockedValue === true ? 0.5 : 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormGroup>
                <CustomFormLabel component="legend" required>
                  {IdName} Number
                </CustomFormLabel>
                <CustomTextField
                  {...register(`${IdField}`, {
                    required: "This field is required",
                  })}
                  id="outlined-basic"
                  variant="outlined"
                  autoComplete="off"
                  error={errors[IdField]}
                  helperText={errors[IdField] && errors[IdField].message}
                  InputProps={{
                    readOnly:
                      lockedStatus === true
                        ? false
                        : lockedValue === true
                        ? true
                        : false,
                  }}
                  // onChange={(e) => {
                  //   setIsIdentity(true);
                  // }}
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormGroup>
                <CustomFormLabel
                  component="legend"
                  required={cardName === "OCI Card" ? true : false}
                >
                  {cardName}
                </CustomFormLabel>
                <CustomTextField
                  {...register(`${cardField}`, {
                    required:
                      cardName === "OCI Card" &&
                      identityProof.citizenship === "OCI"
                        ? "This field is required"
                        : false,
                  })}
                  id="outlined-basic"
                  variant="outlined"
                  autoComplete="off"
                  InputProps={{
                    readOnly:
                      lockedStatus === true
                        ? false
                        : lockedValue === true
                        ? true
                        : false,
                  }}
                  error={
                    cardName === "OCI Card" &&
                    identityProof.citizenship === "OCI"
                      ? errors[cardField]
                      : false
                  }
                  helperText={errors[cardField] && errors[cardField].message}
                  // onChange={(e) => {
                  //   setIsIdentity(true);
                  // }}
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12} md={6}>
              {_.isEmpty(idProofFile) ? (
                <Controller
                  control={control}
                  name="upload_file_one"
                  rules={{
                    required: "This field is required.",
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <UploadFile
                        onChange={(e) => {
                          onChange(e);
                          handleUploadIdProof(e);
                        }}
                        acceptFile="image/*, .pdf, .xls, .xlsx, .docx, .doc"
                        value={value}
                        error={error}
                        setSelectedFile={setIdProofFile}
                        lockedValue={lockedValue}
                        lockedStatus={lockedStatus}
                      />
                    );
                  }}
                />
              ) : (
                <Card
                  sx={{
                    padding: "30px 25px",
                    background: "#F5F7FA",
                    boxShadow: "none",
                    borderRadius: "5px",
                    position: "relative",
                  }}
                >
                  <DeleteIcon
                    on
                    sx={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (
                        lockedStatus === true ? false : lockedValue === true
                      ) {
                        // if (lockedValue === true) {
                        return;
                      }
                      handleIdProofFileDelete(idProofFile);
                      setRemoveFile(true);
                    }}
                  />
                  <CardActionArea>
                    <CardContent
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        src="/assets/icons/FileIcon.svg"
                        className="mb-5"
                        alt="file icon"
                      />
                      <Typography
                        variant="body2"
                        fontSize={14}
                        color="#4D4D4D"
                        sx={{ marginBottom: "5px",textAlign: "center"}}
                      >
                        {`${idProofFile?.file_name}.${idProofFile?.file_extension}`}
                      </Typography>
                      <Typography variant="body2" fontSize={14} color="#89939E">
                        35 KB
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {_.isEmpty(idProofFile2) ? (
                <Controller
                  control={control}
                  name="upload_file_two"
                  rules={{
                    required:
                      cardName === "OCI Card" &&
                      identityProof.citizenship === "OCI"
                        ? "This field is required"
                        : false,
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <UploadFile
                      onChange={(e) => {
                        onChange(e);
                        handleUploadIdProof2(e);
                      }}
                      acceptFile="image/*, .pdf, .xls, .xlsx, .docx, .doc"
                      value={value}
                      error={error}
                      setSelectedFile={setIdProofFile2}
                      lockedValue={lockedValue}
                      lockedStatus={lockedStatus}
                    />
                  )}
                />
              ) : (
                <Card
                  sx={{
                    padding: "30px 25px",
                    background: "#F5F7FA",
                    boxShadow: "none",
                    borderRadius: "5px",
                    position: "relative",
                  }}
                >
                  <DeleteIcon
                    on
                    sx={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (
                        lockedStatus === true ? false : lockedValue === true
                      ) {
                        // if (lockedValue === true) {
                        return;
                      }
                      handleIdProofFile2Delete(idProofFile2);
                      setRemoveFileCard(true);
                    }}
                  />
                  <CardActionArea>
                    <CardContent
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        src="/assets/icons/FileIcon.svg"
                        className="mb-5"
                        alt="file icon"
                      />
                      <Typography
                        variant="body2"
                        fontSize={14}
                        color="#4D4D4D"
                        sx={{ marginBottom: "5px", textAlign: "center"}}
                      >
                        {`${idProofFile2.file_name}.${idProofFile2.file_extension}`}
                      </Typography>
                      <Typography variant="body2" fontSize={14} color="#89939E">
                        35 KB
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"end"}
          alignItems={"center"}
          mt={3}
        >
          {activeStep !== 0 && (
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1, width: "109px", height: "48px" }}
            >
              <KeyboardBackspaceIcon sx={{ paddingRight: "8px" }} />
              Back
            </Button>
          )}
          <Button
            type="submit"
            disabled={isLoading}
            sx={{
              mr: 1,
              color: "white",
              textTransform: "capitalize",
              width: "174px",
              height: "48px",
              borderRadius: "4px",
              bgcolor:  "#0671E0",
              "&:hover": {
                bgcolor: "#0671E0",
                color: "white",
              },
            }}
          >
            {isLoading ? (
              <CircularProgress
                size={20}
                sx={{ color: "white", marginLeft: "10px" }}
              />
            ) : Status.locked === true &&
              Status.status === "Pending Validation" ? (
              "Done"
            ) : (
              "Save & Submit"
            )}
          </Button>
        </Box>

        <ModalCommon
          open={removeFile}
          setOpen={setRemoveFile}
          header={<Header />}
          body={<Body />}
          height={180}
          width={551}
          justifyContent={"start"}
        />
        <ModalCommon
          open={removeFileCard}
          setOpen={setRemoveFileCard}
          header={<Header />}
          body={<Body />}
          height={180}
          width={551}
          justifyContent={"start"}
        />
      </Container>
    </form>
  );
};