import React from 'react'
import TabLayout from '../../components/Layouts/TabLayout'
// import { SearchInstallments } from './Dashboard/SearchInstallments'
// import { CancellationRequests } from './Dashboard/CancellationRequests'
// import { BookingRequests } from './Dashboard/BookingRequests'
// import { EventLog } from './Dashboard/EventLog'
// import { BuyerSearch } from './Dashboard/BuyerSearch'
// import { AprooveBooking } from './Dashboard/AprooveBooking'
// import { UploadSaleAgreement } from './Dashboard/UploadSaleAgreement'
// import { UploadPropertyDeed } from './Dashboard/UploadPropertyDeed'
// import { UploadDocument } from './Dashboard/UploadDocument'
// import { CancelPurchase } from './Dashboard/CancelPurchase'
// import { UploadReceipt } from './Dashboard/UploadReceipt'
// import { Inventory } from './Dashboard/Inventory'
// import { CashFlowbyProject } from './Dashboard/CashFlowbyProject'
// import { CashFlowbyTower } from './Dashboard/CashFlowbyTower'
// import { InstallmentsStatus } from './Dashboard/InstallmentsStatus'
// import { VerticalChart } from '../../components/VerticalChart'
// import { OverviewChart } from './Dashboard/OverviewChart'


export const IdVerification = () => {

  return (
    <TabLayout>
      <div>IdVerification</div>
      {/* <AprooveBooking />
      <UploadSaleAgreement />
      <UploadPropertyDeed />
      <UploadDocument />
      <CancelPurchase />
      <UploadReceipt />

      <SearchInstallments />
      <CancellationRequests />
      <BookingRequests />
      <EventLog />
      <BuyerSearch />

      <Inventory />
      <CashFlowbyProject />
      <CashFlowbyTower />
      <InstallmentsStatus />
      <OverviewChart /> */}
    </TabLayout>
  )
}

export default IdVerification