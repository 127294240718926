
const useFileToHexString = () => {
  const convertFileToHexStringPromise = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const arrayBuffer = reader.result;
        const byteArray = new Uint8Array(arrayBuffer);
        const hexString = Array.from(byteArray, (byte) => byte.toString(16).padStart(2, '0')).join('');

        const formattedHexString = hexString.match(/.{1,2}/g).map((str) => '0x' + str).join(',');

        resolve(formattedHexString);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    });
  };

  return { convertFileToHexStringPromise };
};

export default useFileToHexString;
