import { Box, TableCell, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TableData from "../../../../components/TableData";
import { useLazyQuery } from "@apollo/client";
import {
  AdminAdjustmentsCredits, AdminDiscountsAdjustments,
} from "../../../../lib/query/query";
import { useParams } from "react-router-dom";
import * as Sentry from "@sentry/react";

export const Price = ({paymentStatus}) => {
  const [priceEstimateData, setPriceEstimateData] = useState([]);
  const [adjustCreditData, setAdjustCreditData] = useState({});
  const [discAdjustData, setDiscAdjustData] = useState({});
  const [estimateTotal, setEstimateTotal] = useState(0);

  const { salesId, propertyId } = useParams();

  const [adjustmentsCreditsCall, { data: adjustmentsCreditData,error: errorCreditsCall }] =
    useLazyQuery(AdminAdjustmentsCredits, {
      variables: {
        property_id: propertyId 
      },
    },
    );

  const [discountsAdjustmentsCall, { data: discountsAdjustmentData,error:errorAdjustmentsCall }] =
    useLazyQuery(AdminDiscountsAdjustments, {
      variables: { sale_transaction_id: salesId }
    });

  useEffect(() => {
    if (
      adjustmentsCreditData &&
      adjustmentsCreditData.property_price &&
      adjustmentsCreditData.property_price_aggregate
    ) {
      setAdjustCreditData(adjustmentsCreditData);
    }
  }, [adjustmentsCreditData]);

  useEffect(() => {
    if (
      discountsAdjustmentData &&
      discountsAdjustmentData.price_adjustment_aggregate
    ) {
      setDiscAdjustData(discountsAdjustmentData);
    }
  }, [discountsAdjustmentData]);

  useEffect(() => {
    if (priceEstimateData) {
      propertyId && adjustmentsCreditsCall();
      discountsAdjustmentsCall();
    }
  }, [priceEstimateData]);

  function currencyFormat(id) {
    var x;
    x = id.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers !== "") lastThree = "," + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res;
  }

  const PriceColumns = [
    {
      field: "item",
      headerName: "Item",
      width: 400,
      sortable: false,
      renderCell: (params) => (
        <TableCell
          style={{
            color: params.row.isDiscRow ? "red" : "inherit"
          }}
        >
          {params.value}
        </TableCell>
      )
    },
    {
      field: "price",
      headerName: "price",
      width: 200,
      sortable: false,
      align: "right",
      renderCell: (params) => (
        <TableCell
          style={{
            color: params.row.isDiscRow ? "red" : "inherit"
          }}
        >
          {params.value}
        </TableCell>
      )
    }
  ];

  useEffect(() => {
    const priceRows = (adjustCreditData.property_price || []).map((item) => {
      return {
        id: item.property_price_id,
        item: item.price_type,
        price: currencyFormat(item.price_amount),
        isDiscRow: false
      };
    });

    const discRows = (
      discAdjustData.price_adjustment_aggregate?.nodes || []
    ).map((item) => {
      return {
        id: item.price_adjustment_id,
        item: item.adjustment_reason,
        price: currencyFormat(item.adjustment_amount),
        isDiscRow: true
      };
    });

    let priceEstimate = [];
    priceEstimate = priceRows.concat(discRows);
    priceEstimate.push({
      id: priceEstimate.length + 1,
      item: "Estimated Total",
      price: currencyFormat(
        adjustCreditData?.property_price_aggregate?.aggregate?.sum
          ?.price_amount -
          discAdjustData?.price_adjustment_aggregate?.aggregate?.sum
            ?.adjustment_amount
      )
    });

    priceEstimate = priceEstimate.map((item, index) => {
      return {
        id: index,
        item: item.item,
        price:
          item.item === "Estimated Total" ? `(INR) ${item.price}` : item.price,

        isDiscRow: item.isDiscRow ? true : false
      };
    });

    const estimateTotal = priceEstimate.find(
      (item) => item.item === "Estimated Total"
    )?.price;
    setEstimateTotal(estimateTotal);

    const updatedPriceEstimateData = [...priceEstimate];
    updatedPriceEstimateData.pop();
    setPriceEstimateData(updatedPriceEstimateData);
    priceEstimate = [];
  }, [adjustCreditData, discAdjustData]);


  if(errorCreditsCall || errorAdjustmentsCall){
    Sentry.captureException(errorCreditsCall || errorAdjustmentsCall)
  }
  const CustomFooter = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "10px 35px 10px 10px",
          backgroundColor: "rgba(171, 171, 171, 0.20);"
        }}
      >
        <Typography
          sx={{
            color: "#212121",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "28px"
          }}
        >
          Estimated Total
        </Typography>
        <Typography
          sx={{
            color: "#212121",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "28px"
          }}
        >
          {estimateTotal}
        </Typography>
      </Box>
    );
  };


  return (
    <>
      <Box sx={{ mb: "50px"}}>
      <Typography
            sx={{
              fontWeight: 600,
              fontSize: "25px",
              lineHeight: "30px",
              color: "#212121",
              marginBottom: "20px",
              display: "flex",
            }}
          >
            Property Price 
            {paymentStatus === 'Yet to Start' &&  <Typography sx={{
              fontWeight: 600,
              fontSize: "25px",
              lineHeight: "30px",
              color: "darkblue",
              marginBottom: "20px",
              ml: "10px",
            }}> - User yet to book a property </Typography>}
          </Typography>
          
        <Box>
          <TableData
            rows={priceEstimateData}
            columns={PriceColumns}
            width={627}
            backgroundColor={"white"}
            HeaderbackgroundColor={"white"}
            tableHeaderAlign={"start"}
            hideHeaderFooter={true}
            customFooter={CustomFooter}
          />
        </Box>
        
      </Box>
    </>
  );
};
