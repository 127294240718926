import React, { useRef } from "react";
import { Box, Typography } from "@mui/material";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FieldError from "./FieldError";

export const UploadFile = ({
  onChange,
  value,
  acceptFile,
  error,
  setSelectedFile,
  lockedValue,
  lockedStatus
}) => {
  const fileInputRef = useRef(null);

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    setSelectedFile(file);
    onChange(e);
  };
  const handleButtonClick = (e) => {
    e.preventDefault();
    if(lockedStatus === true ? false : lockedValue === true){
      // if (lockedValue === true) {
      return;
    }
    fileInputRef.current.click();
  };

  return (
    <>
      <Box
        sx={{
          border: "1px dashed",
          borderRadius: "10px",
          alignItems: "center",
          fontSize: "12px",
          justifyContent: "center",
          p: 5,
          marginBottom: "20px"
        }}
      >
        <Typography sx={{ textAlign: "center" }}>
          <CloudUploadOutlinedIcon />{" "}
        </Typography>
        <Box
          sx={{ color: "#0671E0", margin: "0 auto" }}
          display={"flex"}
          justifyContent={"center"}
        >
          <input
            type="file"
            accept={acceptFile ? acceptFile : "image/*"}
            ref={fileInputRef}
            value={value}
            style={{ display: "none" }}
            onChange={handleChange}
          />
          <button onClick={(e) => handleButtonClick(e)}>
            <FileUploadOutlinedIcon /> Click to upload{" "}
            <span className="text-black">
              {/* or drag and drop <br /> SVG, PNG, JPG (max. 800x400px) 5 mb max. */}
              or drag and drop <br /> PDF, XLSX, DOCX for document types, and SVG, PNG, JPG for image types (max. 800x400px) 5 mb max.
            </span>
          </button>
        </Box>
      </Box>
      {error && <FieldError error={error} />}
    </>
  );
};
