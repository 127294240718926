import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  FormGroup,
  Grid,
  Typography
} from "@mui/material";
import { UploadFile } from "../../components/UploadFile";
import {
  CustomFormLabel,
  CustomTextField
} from "../../lib/constants/textField";
import { Controller, useForm } from "react-hook-form";
import _ from "lodash";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Delete_File,
  Insert_Abhyasi_ID,
  Insert_Buyer,
  Insert_Buyer_Pic,
  Update_buyer
} from "../../lib/mutation/mutation";
import { useMutation } from "@apollo/client";
import FieldError from "../../components/FieldError";
import { MobileVerification } from "../tabView/MyProperties/MobileVerification";
import { useNavigate, useParams } from "react-router-dom";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import useFileToHexString from "../../hook/useFileToHexString";
import { errorToast, successToast } from "../../pages/Toasts/showToasts";
import ModalCommon from "../../components/ModalCommon";
import Steppercontext from "../../lib/context/contextData";
import * as Sentry from "@sentry/react";

export const BuyInfo = ({ data, handleComplete }) => {
  const { salesId } = useParams();
  const navigate = useNavigate();
  const { convertFileToHexStringPromise } = useFileToHexString();

  const uploadInputRef = useRef(null);
  const [insert_buyer] = useMutation(Insert_Buyer);
  const [update_buyer] = useMutation(Update_buyer);
  const [insert_Abhyasi_ID] = useMutation(Insert_Abhyasi_ID);
  const [insert_Buyer_Pic] = useMutation(Insert_Buyer_Pic);
  const [delete_File] = useMutation(Delete_File);

  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [abhyasiID, setAbhyasiID] = useState({});
  const [passportPic, setPassportPic] = useState({});
  const [insertData, setInsertData] = useState({});
  const [finalSubmit, setFinalSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRemove, setIsRemove] = useState(false);
  const [removePassport, setRemovePassport] = useState(false);
  const [abhyasiId, setAbhyasiId] = useState({});
  const { setSalesId, events } = useContext(Steppercontext);
  const params =
    events &&
    events.sale_transaction &&
    events.sale_transaction[0]?.sale_transaction_statuses;
  const lockedValue = params && params[0]?.locked;

  useEffect(() => {
    setSalesId(salesId);
  }, [salesId]);

  useEffect(() => {
    if (data && data?.buyer[0]) {
      setFormData(data?.buyer[0]);
      setInsertData(data?.buyer[0]);
    }
  }, [data]);

  useEffect(() => {
    if (!_.isEmpty(formData)) {
      const abhyasi_ID = formData?.buyer_identity_docs?.filter(
        (item) => item.document_type === "Abhyasi ID"
      )[0];
      setAbhyasiID(abhyasi_ID);

      const buyer_pic = formData?.buyer_identity_docs?.filter(
        (item) => item.document_type === "Photo"
      )[0];
      setPassportPic(buyer_pic);
    }
  }, [formData]);

  const {
    control,
    handleSubmit,
    register,
    setValue,
    formState: { errors, dirtyFields }
  } = useForm({
    mode: "onChange",
    defaultValues: {
      tel: ""
    }
  });

  useEffect(() => {
    if (!_.isEmpty(formData)) {
      const formDataUpdate = {
        ...formData,
        upload_abhyasi_id: !_.isEmpty(abhyasiID)
          ? `${abhyasiID.file_name}.${abhyasiID.file_extension}`
          : "",
        upload_passport_pic: !_.isEmpty(passportPic)
          ? `${passportPic?.file_name}.${passportPic?.file_extension}`
          : ""
      };

      Object.keys(formDataUpdate).forEach((key) => {
        if (key === "phone") {
          setValue("tel", formData[key] || "");
        } else {
          setValue(key, formDataUpdate[key] || "");
        }
      });
    }
  }, [formData, setValue, abhyasiID, passportPic, insertData]);

  const insertFormText = async (updateData) => {
    await insert_buyer({
      variables: {
        first_name: updateData.first_name,
        last_name: updateData.last_name,
        middle_name: updateData.middle_name,
        abhyasi_id: updateData.abhyasi_id,
        phone: updateData.tel,
        sale_transaction_id: salesId
      }
    })
      .then(async (response) => {
        setInsertData(response.data.insert_buyer.returning[0]);
        const buyerId = response.data.insert_buyer.returning[0].buyer_id;
        await insertAbhyasiFile(updateData, buyerId);
        await insertPassportFile(buyerId);
      })
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });
    setFinalSubmit(true);
  };

  const updateFormText = async (updateData) => {
    if (
      dirtyFields.first_name ||
      dirtyFields.middle_name ||
      dirtyFields.last_name ||
      dirtyFields.tel ||
      dirtyFields.abhyasi_id
    ) {
      await update_buyer({
        variables: {
          buyer_id: insertData.buyer_id,
          first_name: updateData.first_name,
          last_name: updateData.last_name,
          middle_name: updateData.middle_name,
          phone: updateData.tel,
          abhyasi_id: updateData.abhyasi_id,
          sale_transaction_id: salesId
        }
      })
        .then(async(response) => {
          // response
          // const buyerId = response.data.update_buyer.returning[0].buyer_id;
          // await insertAbhyasiFile(updateData,buyerId);
          // await insertPassportFile(buyerId);
        })
        .catch((error) => {
          console.error(error);
          Sentry.captureException(error);
        });
    }
  };


  const insertAbhyasiFile = async (updateData, buyerId) => {
    if (dirtyFields.upload_abhyasi_id) {
      await insert_Abhyasi_ID({
        variables: {
          buyer_id: insertData.buyer_id || buyerId,
          document_number: updateData?.abhyasi_id,
          document_type: "Abhyasi ID",
          file_name: abhyasiID.file_name,
          file_extension: abhyasiID.file_extension,
          document: abhyasiID.haxContent
        }
      })
        .then((res) => {
          // res
          successToast("File upload successful!");
          Sentry.getCurrentScope().setTransactionName('KYC-Abhyasi ID Upload');
        })
        .catch((err) => {
          Sentry.captureException(err);
        });
    }
  };

  const insertPassportFile = async (buyerId) => {
    if (dirtyFields.upload_passport_pic) {
      await insert_Buyer_Pic({
        variables: {
          buyer_id: insertData?.buyer_id || buyerId,
          file_name: passportPic?.file_name,
          file_extension: passportPic?.file_extension,
          document_type: "Photo",
          document: passportPic?.haxContent
        }
      })
        .then((res) => {
          // res
          successToast("File upload successful!");
          Sentry.getCurrentScope().setTransactionName('KYC-PicUpload');
        })
        .catch((err) => {
          Sentry.captureException(err);
        });
    }
  };

  const onSubmit = async (updateData) => {
    setAbhyasiId(updateData);
    if (data.buyer && data.buyer.length > 0) {
      setIsLoading(true);
      await updateFormText(updateData);
      await insertAbhyasiFile(updateData);
      await insertPassportFile();
      setIsLoading(false);
      await handleComplete();

      setFinalSubmit(true);
    } else {
      setIsLoading(true);
      await insertFormText(updateData);
      setIsLoading(false);
      await handleComplete();
      setFinalSubmit(true);
    }
  };

  useEffect(() => {
    const insertFile = async () => {
      await insertAbhyasiFile(abhyasiId);
      await insertPassportFile();
      setIsLoading(false);
      await handleComplete();

      const pathName = window.location.pathname;
      Sentry.addBreadcrumb({
        category: "button_click",
        message: "Next button clicked on KYC Buyer Info Screen",
        data: {
          loading_step: "Buyer Info screen"
        }
      });
      navigate(`${pathName}/${insertData.buyer_id}`);
    };

    if (finalSubmit && !_.isEmpty(insertData)) insertFile();
  }, [insertData]);

  useEffect(() => {
    if (finalSubmit && !_.isEmpty(insertData)) {
      const pathName = window.location.pathname;
      Sentry.addBreadcrumb({
        category: "button_click",
        message: "Next button clicked on KYC Buyer Info Screen",
        data: {
          loading_step: "Buyer Info screen"
        }
      });
      navigate(`${pathName}/${insertData.buyer_id}`);
    }
  }, [finalSubmit, insertData]);

  const MAX_FILE_SIZE = 5 * 1024 * 1024;
  // const MAX_IMAGE_WIDTH = 800;     //size condition
  // const MAX_IMAGE_HEIGHT = 400;

  // const validateImageDimensions = (file) => {
  //   return new Promise((resolve, reject) => {
  //     const img = new Image();
  //     img.onload = function () {
  //       const width = img.width;
  //       const height = img.height;

  //       // if (width > MAX_IMAGE_WIDTH || height > MAX_IMAGE_HEIGHT) {
  //       //   reject(
  //       //     new Error(
  //       //       `Image dimensions should not exceed ${MAX_IMAGE_WIDTH}x${MAX_IMAGE_HEIGHT}px`
  //       //     )
  //       //   );
  //       //   // errorToast(reject(new Error(`Image dimensions should not exceed ${MAX_IMAGE_WIDTH}x${MAX_IMAGE_HEIGHT}px`)))
  //       // } else {
  //       //   resolve(true);
  //       // }
  //     };

  //     img.onerror = function () {
  //       reject(new Error("Error loading image"));
  //     };
  //     img.src = URL.createObjectURL(file);
  //   });
  // };

  const handleUploadAbhyasiID = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const allowedFileTypes = [
      "image/jpeg",
      "image/jpg",
      "application/pdf",
      "image/png"
    ];
    if (!allowedFileTypes.includes(file.type)) {
      errorToast("Only PDF, jpeg, png, and JPG files are allowed.");
      return;
    }
    if (file.size > MAX_FILE_SIZE) {
      errorToast("File size exceeds the limit.");
      return;
    }
    if (file.type.startsWith("image/")) {
      try {
        // await validateImageDimensions(file);  //size condition
      } catch (error) {
        Sentry.captureException(error);
        errorToast(error.message);
        return;
      }
    }
    try {
      const hexData = await convertFileToHexStringPromise(file);
      const fileStore = {
        file_name: file.name.split(".").shift(),
        file_extension: file.name.split(".").pop(),
        haxContent: hexData
      };
      setAbhyasiID(fileStore);
      // successToast("Upload AbhyasiID successfully!");
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const handleUploadPassportPic = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const allowedFileTypes = [
      "image/jpeg",
      "image/jpg",
      "image/svg+xml",
      "image/png",
      "application/pdf",
      "application/msword",
      "application/vnd.ms-excel"
    ];
    if (!allowedFileTypes.includes(file.type)) {
      errorToast("Only SVG, PNG, JPEG, and JPG files are allowed.");
      return;
    }
    if (file.size > MAX_FILE_SIZE) {
      errorToast("File size exceeds the limit.");
      return;
    }
    if (file.type.startsWith("image/")) {
      try {
        // await validateImageDimensions(file);  //size condition
      } catch (error) {
        Sentry.captureException(error);
        errorToast(error.message);
        return;
      }
    }
    try {
      const hexData = await convertFileToHexStringPromise(file);
      const fileStore = {
        file_name: file.name.split(".").shift(),
        file_extension: file.name.split(".").pop(),
        haxContent: hexData
      };
      setPassportPic(fileStore);
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const handlePassportPicDelete = (passportPic) => {
    if (removePassport) {
      delete_File({
        variables: {
          buyer_identity_doc_id: passportPic.buyer_identity_doc_id
        }
      })
        .then((response) => {
          //response
        })
        .catch((error) => {
          console.error(error);
          Sentry.captureException(error);
        });
      setValue("upload_passport_pic", "");
      setPassportPic({});
    }
  };

  const handleAbhyasiIDDelete = (abhyasiID) => {
    if (isRemove) {
      delete_File({
        variables: {
          buyer_identity_doc_id: abhyasiID.buyer_identity_doc_id
        }
      })
        .then((response) => {
          //response
        })
        .catch((error) => {
          console.error(error);
          Sentry.captureException(error);
        });

      setValue("upload_abhyasi_id", "");
      setAbhyasiID({});
    }
  };

  const handleClose = () => {
    if (isRemove) {
      setIsRemove(false);
    } else {
      setRemovePassport(false);
    }
  };

  const Header = () => {
    return (
      <>
        {removePassport && (
          <p className="font-bold text-2xl">Remove Passport Picture</p>
        )}
        {isRemove && <p className="font-bold text-2xl">Remove AbhyasiID</p>}
      </>
    );
  };

  const Body = () => {
    return (
      <>
        <Box>
          <Box sx={{ marginBottom: "12px" }}>
            {isRemove && (
              <Typography>
                Are you sure You want to remove abhyasiID?
              </Typography>
            )}
            {removePassport && (
              <Typography>
                Are you sure You want to remove Passport Picture?
              </Typography>
            )}
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Button
            sx={{
              borderRadius: "4px",
              gap: "10px",
              backgroundColor: "#FFFFFF",
              fontSize: "15px",
              lineHeight: "20px",
              textAlign: "Center",
              fontWeight: 600,
              color: "#717171",
              textTransform: "capitalize",
              width: "60px",
              height: "36px",
              marginRight: "8px"
            }}
            onClick={handleClose}
          >
            No
          </Button>
          {isRemove && (
            <Button
              sx={{
                borderRadius: "4px",
                gap: "10px",
                backgroundColor: "#0671E0",
                fontSize: "15px",
                lineHeight: "20px",
                textAlign: "Center",
                fontWeight: 600,
                color: "#FFFFFF",
                width: "60px",
                height: "36px",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#0671E0"
                }
              }}
              onClick={() => {
                setIsRemove(false);
                setValue("upload_abhyasi_id", "");
                setAbhyasiID({});
              }}
            >
              Yes
            </Button>
          )}
          {removePassport && (
            <Button
              sx={{
                borderRadius: "4px",
                gap: "10px",
                backgroundColor: "#0671E0",
                fontSize: "15px",
                lineHeight: "20px",
                textAlign: "Center",
                fontWeight: 600,
                color: "#FFFFFF",
                width: "60px",
                height: "36px",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#0671E0"
                }
              }}
              onClick={() => {
                setRemovePassport(false);
                setValue("upload_passport_pic", "");
                setPassportPic({});
              }}
            >
              Yes
            </Button>
          )}
        </Box>
      </>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          opacity: lockedValue === true ? 0.5 : 1
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <FormGroup>
              <CustomFormLabel component="legend" required>
                First Name
              </CustomFormLabel>
              <CustomTextField
                {...register("first_name", {
                  required: "This field is required."
                })}
                id="outlined-basic"
                variant="outlined"
                autoComplete="off"
                error={errors.first_name}
                helperText={errors.first_name && errors.first_name.message}
                InputProps={{
                  readOnly: lockedValue === true ? true : false
                }}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={4}>
            <FormGroup>
              <CustomFormLabel component="legend">Middle name</CustomFormLabel>
              <CustomTextField
                {...register("middle_name")}
                id="outlined-basic"
                variant="outlined"
                autoComplete="off"
                error={errors.middle_name}
                helperText={errors.middle_name && errors.middle_name.message}
                InputProps={{
                  readOnly: lockedValue === true ? true : false
                }}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={4}>
            <FormGroup>
              <CustomFormLabel component="legend" required>
                Last name
              </CustomFormLabel>
              <CustomTextField
                {...register("last_name", {
                  required: "This field is required."
                })}
                id="outlined-basic"
                variant="outlined"
                autoComplete="off"
                error={errors.last_name}
                helperText={errors.last_name && errors.last_name.message}
                InputProps={{
                  readOnly: lockedValue === true ? true : false
                }}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormGroup>
              <CustomFormLabel component="legend" required>
                Abhyasi ID
              </CustomFormLabel>
              <CustomTextField
                {...register("abhyasi_id", {
                  required: "This field is required."
                })}
                id="outlined-basic"
                variant="outlined"
                autoComplete="off"
                error={errors.abhyasi_id}
                helperText={errors.abhyasi_id && errors.abhyasi_id.message}
                InputProps={{
                  readOnly: lockedValue === true ? true : false
                }}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              rules={{ required: "This field is required." }}
              defaultValue={abhyasiID?.file_name}
              name="upload_abhyasi_id"
              render={({
                field: { value, onChange },
                fieldState: { error }
              }) => (
                <>
                  <Box
                    display={"flex"}
                    sx={{ marginTop: "30px", marginBottom: "5px" }}
                  >
                    {_.isEmpty(abhyasiID) ? (
                      <Box display={"flex"} flexDirection={"column"}>
                        <Box display={"flex"}>
                          <input
                            ref={uploadInputRef}
                            type="file"
                            // accept="image/*"
                            value={value}
                            accept=".pdf,.jpg,.jpeg,.png"
                            onChange={(e) => {
                              onChange(e);
                              handleUploadAbhyasiID(e);
                            }}
                            style={{ display: "none" }}
                            readOnly={lockedValue === true ? true : false}
                          />
                          <Button
                            variant="contained"
                            sx={{
                              display: "inline-block",
                              fontSize: "16px",
                              fontWeight: 400,
                              textTransform: "capitalize",
                              color: "white",
                              pl: 1.5,
                              helperText: "Please enter your name",
                              backgroundColor: "#0671E0",
                              "&:hover": {
                                backgroundColor: "#0671E0"
                              },
                              marginBottom: "5px"
                            }}
                            onClick={() => {
                              if (lockedValue === true) {
                                return;
                              }
                              uploadInputRef.current &&
                                uploadInputRef.current.click();
                            }}
                          >
                            Upload Abhyasi ID
                          </Button>
                          <CustomFormLabel
                            component="legend"
                            required
                          ></CustomFormLabel>
                        </Box>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: 400,
                            color: "#89939E"
                          }}
                        >
                          PDF, JPG (max. 800x400px) 5 mb max
                        </Typography>
                        {error && <FieldError error={error} />}
                      </Box>
                    ) : (
                      <Box
                        padding={"16px"}
                        borderRadius={"5px"}
                        bgcolor={"#F5F7FA"}
                        display={"flex"}
                        justifyContent={"space-between"}
                        width={"100%"}
                        maxWidth={"380px"}
                        alignItems={"center"}
                      >
                        <Box display={"flex"} alignItems={"center"}>
                          <img
                            src="/assets/icons/FileIcon.svg"
                            className="mr-3"
                            alt="file icon"
                          />

                          <Typography
                            variant="body2"
                            color={"#4D4D4D"}
                            fontSize={12}
                            fontWeight={400}
                            sx={{
                              width: "254px",
                              wordWrap: "break-word"
                            }}
                          >
                            {`${abhyasiID.file_name}.${abhyasiID.file_extension}`}
                          </Typography>
                        </Box>

                        <Typography
                          variant="body2"
                          color={"#0671E0"}
                          fontSize={12}
                          fontWeight={400}
                          onClick={() => {
                            if (lockedValue === true) {
                              return;
                            }
                            handleAbhyasiIDDelete(abhyasiID);
                            setIsRemove(true);
                          }}
                          className="cursor-pointer"
                        >
                          Remove
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </>
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormGroup>
              <CustomFormLabel component="legend" required>
                Phone
              </CustomFormLabel>
              <Controller
                name="tel"
                control={control}
                rules={{ validate: matchIsValidTel }}
                render={({ field, fieldState }) => {
                  // const { onChange, ...fieldProps } = field;
                  // const IndiaNumber = fieldProps.value.startsWith("+91");
                  return (
                    <MuiTelInput
                      {...field}
                      defaultCountry="IN"
                      helperText={fieldState.invalid ? "Phone is invalid" : ""}
                      error={fieldState.invalid}
                      InputProps={{
                        readOnly: lockedValue === true ? true : false
                      }}
                    />
                  );
                }}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={6}>
            {/* <Box
              display={"flex"}
              sx={{ marginTop: "30px", marginBottom: "5px" }}
            >
              <Button
                variant="contained"
                sx={{
                  display: "inline-block",
                  fontSize: "16px",
                  fontWeight: 400,
                  textTransform: "capitalize",
                  color: "white",
                  pl: 1.5,
                  helperText: "Please enter your name",
                  backgroundColor: "#0671E0",
                  "&:hover": {  
                    backgroundColor: "#0671E0"
                  }
                }}
                onClick={() => setOpen(true)}
                disabled={!isIndiaNumber}
              >
                Click to verify
              </Button>
              {isIndiaNumber && <CustomFormLabel component="legend" required></CustomFormLabel>}
            </Box>
            <Typography
              sx={{ fontSize: "12px", fontWeight: 400, color: "#89939E" }}
            >
              You will receive verification code
            </Typography> */}
          </Grid>

          <Grid item xs={12} md={6}>
            <CustomFormLabel component="legend" required>
             Recent Buyer Picture (Passport Pic. Size ONLY)
            </CustomFormLabel>

            {_.isEmpty(passportPic) ? (
              <Controller
                control={control}
                name="upload_passport_pic"
                rules={{
                  required: "This field is required."
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error }
                }) => (
                  <UploadFile
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      handleUploadPassportPic(e);
                    }}
                    acceptFile="image/*, .pdf, .xls, .xlsx, .docx, .doc"
                    error={error}
                    setSelectedFile={setPassportPic}
                    lockedValue={lockedValue}
                  />
                )}
              />
            ) : (
              <Card
                sx={{
                  padding: "30px 25px",
                  background: "#F5F7FA",
                  boxShadow: "none",
                  borderRadius: "5px",
                  position: "relative"
                }}
              >
                <DeleteIcon
                  on
                  sx={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    if (lockedValue === true) {
                      return;
                    }
                    handlePassportPicDelete(passportPic);
                    setRemovePassport(true);
                  }}
                />
                <CardActionArea>
                  <CardContent
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column"
                    }}
                  >
                    <img
                      src="/assets/icons/FileIcon.svg"
                      className="mb-5"
                      alt="file icon"
                    />
                    <Typography
                      variant="body2"
                      fontSize={14}
                      color="#4D4D4D"
                      sx={{ marginBottom: "5px", textAlign: "center" }}
                    >
                      {`${passportPic?.file_name}.${passportPic?.file_extension}`}
                    </Typography>
                    <Typography variant="body2" fontSize={14} color="#89939E">
                      35 KB
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            )}
          </Grid>
        </Grid>
        {open && <MobileVerification open={open} setOpen={setOpen} />}
      </Box>
      <Box display={"flex"} justifyContent={"end"}>
        <Box display={"flex"} justifyContent={"end"}>
          <Button
            type="submit"
            disabled={isLoading}
            sx={{
              mr: 1,
              bgcolor: "#0671E0",
              color: "white",
              textTransform: "capitalize",
              width: "174px",
              height: "48px",
              borderRadius: "4px",
              mt: 3,
              "&:hover": {
                bgcolor: "#0671E0",
                color: "white"
              }
            }}
          >
            {isLoading ? (
              <CircularProgress
                size={20}
                sx={{ color: "white", marginLeft: "10px" }}
              />
            ) : (
              "Next"
            )}
          </Button>
        </Box>
      </Box>
      <ModalCommon
        open={isRemove}
        setOpen={setIsRemove}
        header={<Header />}
        body={<Body />}
        height={180}
        width={551}
        justifyContent={"start"}
      />
      <ModalCommon
        open={removePassport}
        setOpen={setRemovePassport}
        header={<Header />}
        body={<Body />}
        height={180}
        width={551}
        justifyContent={"start"}
      />
    </form>
  );
};
