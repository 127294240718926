import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

export default function ModalCommon({
  open,
  setOpen,
  closeModal,
  header,
  body,
  footer,
  width,
  height,
  justifyContent,
  disableEscapeKeyDown,
  disableEnforceFocus,
  handleClose,
  maxheight,
  overflow,
}) {

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
    width: width,
    height: height,
    maxheight: maxheight,
    overflow: overflow,
  };
  const styleModalHeader = {
    display: "flex",
    justifyContent: justifyContent
  };

  const handleCloseModal = () => {
    if (handleClose) {
      handleClose();
    } else {
      setOpen(false);
    }
  };


  return (
    <div>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEscapeKeyDown={disableEscapeKeyDown}
        disableEnforceFocus={disableEnforceFocus}
      >
        <Box sx={style}>
          <Box sx={styleModalHeader}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              sx={{ mb: 3 }}
              component="h2"
            >
              {header}
            </Typography>
            {closeModal && (
              <span
                className="cursor-pointer"
                style={{ color: "#ABABAB" }}
                onClick={handleCloseModal}
              >
                <CloseIcon />
              </span>
            )}
          </Box>

          <Box id="modal-modal-description">{body}</Box>

          {footer && (
            <Typography
              id="modal-modal-footer"
              sx={{ mt: 4 }}
              variant="h6"
              component="h2"
            >
              {footer}
            </Typography>
          )}
        </Box>
      </Modal>
    </div>
  );
}
