import { toast } from 'react-toastify';

const defaultOptions = {
  autoClose: 3000,
  hideProgressBar: true,
  position: toast.POSITION.TOP_RIGHT,
};

const infoToast = (message) => toast(message, { ...defaultOptions, type: 'info' });

const successToast = (message) => toast(message, { ...defaultOptions, type: 'success' });

const errorToast = (message) => toast(message, { ...defaultOptions, type: 'error' });

const warningToast = (message) => toast(message, { ...defaultOptions, type: 'warning' });

export { infoToast, successToast, errorToast, warningToast };
