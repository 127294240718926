import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

export default function CardStepper({ cardData, handleClick }) {

  const sortedCardsData = cardData.sort((a, b) => a.step - b.step)


  return (
    <>
      <Timeline sx={{ display: "flex", alignItems: "start", paddingLeft: "0" }}>
        {sortedCardsData.map((step, index) => {
          return (
            <TimelineItem
              sx={{
                ":before": {
                  padding: "0"
                }
              }}
              key={index}
            >
              <TimelineSeparator>
                <TimelineDot
                  sx={{
                    height: "40px",
                    width: "40px",
                    margin: "auto",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    background: step.locked === true && step.status === "Yet to Start" ? "gray" : step.iconColor,
                  }}
                >
                  {step.locked === true && step.status === "Yet to Start" ? <img src='/assets/image/icons2.png' alt="imgIcon"/> : <img src={`${step.url}`} alt="ImageIcon"/>}
                </TimelineDot>
                <TimelineConnector
                  sx={{
                    margin: "1px 0 0 0",
                    backgroundColor: "#F5F7FA",
                    width: "6px",
                    height: "105px"
                  }}
                />
              </TimelineSeparator>
              <TimelineContent>
                <Box
                  sx={{
                    minWidth: 399,
                    height: 120,
                    borderRadius: "4px !important",
                    border: "1px solid #E8E8EA !important",
                    display: "flex",
                    marginLeft: "20px",
                    cursor: step.status === 'Locked' ? "" : "pointer",

                    ":before": {
                      content: '" "',
                      height: 119,
                      background: step.locked === true && step.status === "Yet to Start" ? "gray" : step.iconColor, 
                      width: "8px",
                      borderTopLeftRadius: "4px",
                      borderBottomLeftRadius: "4px"
                    }
                  }}
                  onClick={() => handleClick(step)}
                >
                  <Box
                    sx={{
                      margin: "20px",
                      width: "100%"
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "18px",
                        lineHeight: "23px",
                        color: "#717171",
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      Step {step.step}
                      {step.locked === true ? <img src='/assets/icons/ReadOnly.svg' alt="imgIcon"/> : <MoreHorizIcon />}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "23px",
                        margin: "15px 0 10px 0"
                      }}
                    >
                      {step.description}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "400",
                        fontSize: "12px",
                        lineHeight: "15px"
                      }}
                      color={step.iconColor}
                    >
                      {step.status}
                    </Typography>
                  </Box>
                </Box>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </>
  );
}
