import React, { useEffect, useState } from "react"
import { Box, Button, Grid, TableCell, Typography } from "@mui/material"
import TableData from "../../../components/TableData"
import { useLazyQuery } from "@apollo/client"
import {
  downloadProject,
  downloadProjectBuilding,
  downloadpropertyModel,
} from "../../../lib/query/query"
import { toast } from "react-toastify"
import ErrorToast from "../../../components/ErrorToast"
import * as Sentry from "@sentry/react"
import AccountDetails from "./AccountDetails"

const Tower = ({
  selectedRow,
  paymentData,
  bankAccData,
  adjustCreditData,
  discAdjustData,
  setPaymentScheduleTotal,
  Revisit,
}) => {
  const [priceEstimateData, setPriceEstimateData] = useState([])
  const [estimateTotal, setEstimateTotal] = useState(0)

  function currencyFormat(id) {
    var amount = parseFloat(id).toFixed(2)
    return amount.toLocaleString("en-IN")
  }

  const columns = [
    {
      field: "item",
      headerName: "Item",
      width: 200,
      sortable: false,
    },
    {
      field: "amount",
      headerName: "Amount (INR)",
      width: 200,
      sortable: false,
      valueFormatter: (params) => currencyFormat(params.value),
    },
    {
      field: "dueDate",
      headerName: "Due date",
      width: 200,
      sortable: false,
    },
  ]

  const rows = (paymentData || [])?.map((item, index) => {
    const payment =
      estimateTotal && estimateTotal?.split(" ")[1].replace(/,/g, "")

    const due_date = new Date(
      new Date().setDate(new Date().getDate() + item.due_days)
    )

    const yyyy = due_date.getFullYear()
    let mm = due_date.getMonth() + 1
    let dd = due_date.getDate()

    if (dd < 10) dd = "0" + dd
    if (mm < 10) mm = "0" + mm

    const formattedDate = dd + "/" + mm + "/" + yyyy

    return {
      id: index,
      item: item.payment_type,
      amount: item.amount
        ? item.amount
        : (item.amount_percentage / 100) * payment,
      dueDate: formattedDate,
    }
  })

  useEffect(() => {
    setPaymentScheduleTotal(rows)
  }, [rows])

  const PriceColumns = [
    {
      field: "item",
      headerName: "Item",
      width: 400,
      sortable: false,
      renderCell: (params) => (
        <TableCell
          style={{
            color: params.row.isDiscRow ? "red" : "inherit",
          }}>
          {params.value}
        </TableCell>
      ),
    },
    {
      field: "price",
      headerName: "price",
      width: 200,
      sortable: false,
      align: "right",
      renderCell: (params) => (
        <TableCell
          style={{
            color: params.row.isDiscRow ? "red" : "inherit",
          }}>
          {params.value}
        </TableCell>
      ),
    },
  ]

  function currencyFormat(id) {
    var x
    x = id.toString()
    var lastThree = x.substring(x.length - 3)
    var otherNumbers = x.substring(0, x.length - 3)
    if (otherNumbers !== "") lastThree = "," + lastThree
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree
    return res
  }

  useEffect(() => {
    const priceRows = (adjustCreditData.property_price || []).map((item) => {
      return {
        id: item.property_price_id,
        item: item.price_type,
        price: currencyFormat(item.price_amount),
        isDiscRow: false,
      }
    })

    const discRows = (
      discAdjustData.price_adjustment_aggregate?.nodes || []
    ).map((item) => {
      return {
        id: item.price_adjustment_id,
        item: item.adjustment_reason,
        price: currencyFormat(item.adjustment_amount),
        isDiscRow: true,
      }
    })

    let priceEstimate = []
    priceEstimate = priceRows.concat(discRows)
    priceEstimate.push({
      id: priceEstimate.length + 1,
      item: "Estimated Total",
      price: currencyFormat(
        adjustCreditData?.property_price_aggregate?.aggregate?.sum
          ?.price_amount -
          discAdjustData?.price_adjustment_aggregate?.aggregate?.sum
            ?.adjustment_amount
      ),
    })

    priceEstimate = priceEstimate.map((item, index) => {
      return {
        id: index,
        item: item.item,
        price:
          item.item === "Estimated Total" ? `(INR) ${item.price}` : item.price,

        isDiscRow: item.isDiscRow ? true : false,
      }
    })

    const estimateTotal = priceEstimate.find(
      (item) => item.item === "Estimated Total"
    )?.price
    setEstimateTotal(estimateTotal)

    const updatedPriceEstimateData = [...priceEstimate]
    updatedPriceEstimateData.pop()
    setPriceEstimateData(updatedPriceEstimateData)
    priceEstimate = []
  }, [selectedRow, adjustCreditData, discAdjustData])

  const [refetchDownloadProject, { error: errorDownloadProjectData }] =
    useLazyQuery(downloadProject)

  const [
    refetchDownloadProjectBuilding,
    { error: errorDownloadProjectBuildingData },
  ] = useLazyQuery(downloadProjectBuilding)

  const [
    refetchDownloadPropertyModel,
    { error: errorDownloadpropertyModelData },
  ] = useLazyQuery(downloadpropertyModel)

  const handleDownloadProject = async () => {
    const response = await refetchDownloadProject({
      variables: {
        project_id:
          Revisit.property_id === null
            ? selectedRow?.row?.project?.project_id
            : Revisit?.property?.project?.project_id,
      },
    })
    const fetchedDocumentData = response?.data

    if (fetchedDocumentData?.project[0]?.file_type === "link") {
      const res = fetchedDocumentData?.project[0]?.project_layout
      const outsideRes = await fetch(res)
      const blob = await outsideRes.blob()
      const fileDownloadUrl = URL.createObjectURL(blob)
      const downloadLink = document.createElement("a")
      downloadLink.href = fileDownloadUrl
      downloadLink.download = `${fetchedDocumentData?.project[0]?.file_name}.${fetchedDocumentData?.project[0]?.file_extension}`
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      URL.revokeObjectURL(fileDownloadUrl)
      return
    }

    const output =
      fetchedDocumentData &&
      fetchedDocumentData?.project &&
      fetchedDocumentData?.project[0]?.project_layout
        .replace(/\\x/g, "")
        .match(/.{2}/g)
        .map((byte) => String.fromCharCode(parseInt(byte, 16)))
        .join("")
    const hexString =
      output &&
      output
        .split(",")
        .map((hex) => parseInt(hex, 16))
        .map((byte) => byte.toString(16).padStart(2, "0"))
        .join("")
        .toUpperCase()
    const byteArray = new Uint8Array(
      hexString && hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    )
    const blob = new Blob([byteArray], { type: "application/octet-stream" })
    const fileDownloadUrl = URL.createObjectURL(blob)
    const downloadLink = document.createElement("a")
    downloadLink.href = fileDownloadUrl
    downloadLink.download = `${fetchedDocumentData?.project[0]?.file_name}.${fetchedDocumentData?.project[0]?.file_extension}`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
    URL.revokeObjectURL(fileDownloadUrl)
  }

  const handleDownloadProjectBuilding = async () => {
    const response = await refetchDownloadProjectBuilding({
      variables: {
        building_id:
          Revisit.property_id === null
            ? selectedRow?.row?.project_building?.building_id
            : Revisit?.property?.project_building?.building_id,
      },
    })
    const fetchedDocumentData = response?.data

    if (fetchedDocumentData?.project_building[0]?.file_type === "link") {
      const res = fetchedDocumentData?.project_building[0]?.building_floor_plan
      const outsideRes = await fetch(res)
      const blob = await outsideRes.blob()
      const fileDownloadUrl = URL.createObjectURL(blob)
      const downloadLink = document.createElement("a")
      downloadLink.href = fileDownloadUrl
      downloadLink.download = `${fetchedDocumentData?.project_building[0]?.file_name}.${fetchedDocumentData?.project_building[0]?.file_extension}`
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      URL.revokeObjectURL(fileDownloadUrl)
      return
    }

    const output =
      fetchedDocumentData &&
      fetchedDocumentData?.project_building &&
      fetchedDocumentData?.project_building[0]?.building_floor_plan
        .replace(/\\x/g, "")
        .match(/.{2}/g)
        .map((byte) => String.fromCharCode(parseInt(byte, 16)))
        .join("")
    const hexString =
      output &&
      output
        .split(",")
        .map((hex) => parseInt(hex, 16))
        .map((byte) => byte.toString(16).padStart(2, "0"))
        .join("")
        .toUpperCase()
    const byteArray = new Uint8Array(
      hexString && hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    )
    const blob = new Blob([byteArray], { type: "application/octet-stream" })
    const fileDownloadUrl = URL.createObjectURL(blob)
    const downloadLink = document.createElement("a")
    downloadLink.href = fileDownloadUrl
    downloadLink.download = `${fetchedDocumentData?.project_building[0]?.file_name}.${fetchedDocumentData?.project_building[0]?.file_extension}`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
    URL.revokeObjectURL(fileDownloadUrl)
  }

  const handleDownloadPropertyModel = async () => {
    const response = await refetchDownloadPropertyModel({
      variables: {
        model_id:
          Revisit.property_id === null
            ? selectedRow?.row?.property_model.model_id
            : Revisit?.property?.property_model.model_id,
      },
    })

    const fetchedDocumentData = response?.data

    if (fetchedDocumentData?.property_model[0]?.file_type === "link") {
      const res = fetchedDocumentData?.property_model[0]?.unit_floor_plan
      const outsideRes = await fetch(res)
      const blob = await outsideRes.blob()
      const fileDownloadUrl = URL.createObjectURL(blob)
      const downloadLink = document.createElement("a")
      downloadLink.href = fileDownloadUrl
      downloadLink.download = `${fetchedDocumentData?.property_model[0]?.file_name}.${fetchedDocumentData?.property_model[0]?.file_extension}`
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      URL.revokeObjectURL(fileDownloadUrl)
      return
    }

    const output =
      fetchedDocumentData &&
      fetchedDocumentData?.property_model &&
      fetchedDocumentData?.property_model[0]?.unit_floor_plan
        .replace(/\\x/g, "")
        .match(/.{2}/g)
        .map((byte) => String.fromCharCode(parseInt(byte, 16)))
        .join("")
    const hexString =
      output &&
      output
        .split(",")
        .map((hex) => parseInt(hex, 16))
        .map((byte) => byte.toString(16).padStart(2, "0"))
        .join("")
        .toUpperCase()
    const byteArray = new Uint8Array(
      hexString && hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    )
    const blob = new Blob([byteArray], { type: "application/octet-stream" })
    const fileDownloadUrl = URL.createObjectURL(blob)
    const downloadLink = document.createElement("a")
    downloadLink.href = fileDownloadUrl
    downloadLink.download = `${fetchedDocumentData?.property_model[0]?.file_name}.${fetchedDocumentData?.property_model[0]?.file_extension}`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
    URL.revokeObjectURL(fileDownloadUrl)
  }

  if (
    errorDownloadProjectData ||
    errorDownloadProjectBuildingData ||
    errorDownloadpropertyModelData
  ) {
    toast.error(
      <ErrorToast
        email={"info@msvkanha.com"}
        errorMsg={
          (errorDownloadProjectData && errorDownloadProjectData?.message) ||
          (errorDownloadProjectBuildingData &&
            errorDownloadProjectBuildingData?.message) ||
          (errorDownloadpropertyModelData &&
            errorDownloadpropertyModelData?.message)
        }
      />
    )
    Sentry.captureException(
      (errorDownloadProjectData && errorDownloadProjectData?.message) ||
        (errorDownloadProjectBuildingData &&
          errorDownloadProjectBuildingData?.message) ||
        (errorDownloadpropertyModelData &&
          errorDownloadpropertyModelData?.message)
    )
  }

  const CustomFooter = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "10px 35px 10px 10px",
          backgroundColor: "rgba(171, 171, 171, 0.20);",
        }}>
        <Typography
          sx={{
            color: "#212121",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "28px",
          }}>
          Estimated Total
        </Typography>
        <Typography
          sx={{
            color: "#212121",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "28px",
          }}>
          {estimateTotal}
        </Typography>
      </Box>
    )
  }

  return (
    <>
      <Box
        sx={{ backgroundColor: "#F7F9FB", p: 5, my: 5, borderRadius: "10px" }}>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          sx={{
            color: "#212121",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "28px",
            textAlign: "center",
          }}>
          {Revisit.property === null
            ? `${selectedRow?.model_name} - ${selectedRow?.tower} - Unit
        ${selectedRow?.unit_number}`
            : `${Revisit?.property?.property_model?.model_name} - ${Revisit?.property?.project_building?.tower_name} - Unit
          ${Revisit?.property?.unit_number}`}
        </Typography>

        <Grid container spacing={2} sx={{ pt: 2 }}>
          <Grid item xs={2}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <img src="/assets/image/car.png" alt="bedrooms" />

              <Typography
                variant="body2"
                sx={{ paddingLeft: "8px", color: "#4D4D4D", fontSize: "16px" }}>
                {Revisit.property === null
                  ? `${selectedRow?.beds_count} bedrooms`
                  : `${Revisit?.property?.property_model?.beds_count} bedrooms`}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <img src="/assets/image/floor.png" alt="bathroomsfloor" />
              <Typography
                variant="body2"
                sx={{ paddingLeft: "8px", color: "#4D4D4D", fontSize: "16px" }}>
                {Revisit.property === null
                  ? `${selectedRow?.baths_count} bathrooms floor`
                  : `${Revisit?.property?.property_model?.baths_count} bathrooms floor`}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{
              display: "flex !important",
              alignItems: "center !important",
              justifyContent: "center !important",
              flexDirection: "row !important",
            }}>
            <Box
              sx={{
                display: "flex",
              }}>
              <img src="/assets/image/thirdfloor.png" alt="thirdfloor" />
              <Typography
                variant="body2"
                sx={{ paddingLeft: "8px", color: "#4D4D4D", fontSize: "16px" }}>
                {Revisit.property === null
                  ? `${selectedRow?.floor_number} floor`
                  : `${Revisit?.property?.floor_number} floor`}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <img src="/assets/image/squarefeet.png" alt="squarefeet" />
              <Typography
                variant="body2"
                sx={{ paddingLeft: "8px", color: "#4D4D4D", fontSize: "16px" }}>
                {Revisit.property === null
                  ? `${selectedRow?.sqft_area} square feet`
                  : `${Revisit?.property?.property_model?.sqft_area} square feet`}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              display: "flex !important",
              alignItems: "center !important",
              justifyContent: "center !important",
              flexDirection: "row !important",
            }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <img src="/assets/image/CarParking.png" alt="squarefeet" />
              <Typography
                variant="body2"
                sx={{ paddingLeft: "8px", color: "#4D4D4D", fontSize: "16px" }}>
                {Revisit.property === null
                  ? `${selectedRow?.row?.property_model?.car_parking_count} Car Parking`
                  : `${Revisit?.property?.property_model?.car_parking_count} Car Parking`}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <hr style={{ margin: "10px 0" }} />
        <Box>
          <Typography
            sx={{
              color: "#212121",
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "28px",
              pb: 2,
            }}>
            Property documents
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex" }}>
              <Box>
                <img src="/assets/image/fff.png" alt="Document" />
              </Box>
              <Box
                sx={{
                  paddingLeft: "10px",
                }}>
                <Typography
                  sx={{
                    color: "#4D4D4D",
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}>
                  {Revisit.property === null
                    ? `${selectedRow?.row?.project?.file_name}`
                    : `${Revisit?.property?.project?.file_name}`}
                </Typography>
                <Typography
                  sx={{
                    color: "#89939E",
                    fontSize: "13px",
                    lineHeight: "20px",
                  }}>
                  {Revisit.property === null
                    ? `${selectedRow?.row?.project?.file_extension}, 1,4 MB`
                    : `${Revisit?.property?.project?.file_extension}, 1,4 MB`}
                </Typography>
              </Box>
            </Box>

            <Box>
              <Button
                variant="contained"
                sx={{
                  color: "#0671E0",
                  textTransform: "capitalize",
                  fontSize: "14px",
                  lineHeight: "20px",
                  fontWeight: 600,
                  backgroundColor: "white",
                  "&:hover": {
                    backgroundColor: "white",
                  },
                  padding: "7px 12px",
                  border: "1px solid #E8E8EA",
                  borderRadius: "4px",
                  boxShadow: "none",
                }}
                onClick={() => {
                  handleDownloadProject()
                }}>
                Download
              </Button>
            </Box>
          </Box>
          <hr style={{ margin: "10px 0" }} />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex" }}>
              <Box>
                <img src="/assets/image/fff.png" alt="Document" />
              </Box>
              <Box
                sx={{
                  paddingLeft: "10px",
                }}>
                <Typography
                  sx={{
                    color: "#4D4D4D",
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}>
                  {Revisit.property === null
                    ? `${selectedRow?.row?.project_building?.file_name}`
                    : `${Revisit?.property?.project_building?.file_name}`}
                </Typography>
                <Typography
                  sx={{
                    color: "#89939E",
                    fontSize: "13px",
                    lineHeight: "20px",
                  }}>
                  {Revisit.property === null
                    ? `${selectedRow?.row?.project_building?.file_extension}, 1,4 MB`
                    : `${Revisit?.property?.project_building?.file_extension}, 1,4 MB`}
                </Typography>
              </Box>
            </Box>

            <Box>
              <Button
                variant="contained"
                sx={{
                  color: "#0671E0",
                  textTransform: "capitalize",
                  fontSize: "14px",
                  lineHeight: "20px",
                  fontWeight: 600,
                  backgroundColor: "white",
                  "&:hover": {
                    backgroundColor: "white",
                  },
                  padding: "7px 12px",
                  border: "1px solid #E8E8EA",
                  borderRadius: "4px",
                  boxShadow: "none",
                }}
                onClick={() => {
                  handleDownloadProjectBuilding()
                }}>
                Download
              </Button>
            </Box>
          </Box>
          <hr style={{ margin: "10px 0" }} />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex" }}>
              <Box>
                <img src="/assets/image/fff.png" alt="Document" />
              </Box>
              <Box
                sx={{
                  paddingLeft: "10px",
                }}>
                <Typography
                  sx={{
                    color: "#4D4D4D",
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}>
                  {Revisit.property === null
                    ? `${selectedRow?.row?.property_model?.file_name}`
                    : `${Revisit?.property?.property_model?.file_name}`}
                </Typography>
                <Typography
                  sx={{
                    color: "#89939E",
                    fontSize: "13px",
                    lineHeight: "20px",
                  }}>
                  {Revisit.property === null
                    ? `${selectedRow?.row?.property_model?.file_extension}, 1,4 MB`
                    : `${Revisit?.property?.property_model?.file_extension}, 1,4 MB`}
                </Typography>
              </Box>
            </Box>

            <Box>
              <Button
                variant="contained"
                sx={{
                  color: "#0671E0",
                  textTransform: "capitalize",
                  fontSize: "14px",
                  lineHeight: "20px",
                  fontWeight: 600,
                  backgroundColor: "white",
                  "&:hover": {
                    backgroundColor: "white",
                  },
                  padding: "7px 12px",
                  border: "1px solid #E8E8EA",
                  borderRadius: "4px",
                  boxShadow: "none",
                }}
                onClick={() => {
                  handleDownloadPropertyModel()
                }}>
                Download
              </Button>
            </Box>
          </Box>

          <hr style={{ margin: "10px 0" }} />

          <Box sx={{ mt: 4, mb: "50px" }}>
            {selectedRow?.municipal_hold === true && (
              <Box
                sx={{
                  border: "1px solid #E48900",
                  borderRadius: "10px",
                  backgroundColor: "#FFF8EC",
                  maxWidth: "685px",
                  marginBottom: "50px",
                }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    p: 2,
                    gap:"16px"
                  }}>
                  <Box>
                    <Typography
                      sx={{
                        color: "#212121",
                        fontSize: "16px",
                        fontWeight: 600,
                        lineHeight: "28px",
                      }}>
                      Note
                    </Typography>
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#4D4D4D",
                      fontSize: "16px",
                    }}>
                    The selected property is not eligible for obtaining a Bank
                    Loan. If you need a bank loan for the purchase of property,
                    please select a different property.
                  </Typography>
                </Box>
              </Box>
            )}
            <Typography
              sx={{
                color: "#212121",
                fontSize: "18px",
                fontWeight: 500,
                lineHeight: "28px",
                py: 2,
              }}>
              Price Estimate (INR)
            </Typography>
            <Box>
              <TableData
                rows={priceEstimateData}
                columns={PriceColumns}
                width={627}
                backgroundColor={"white"}
                HeaderbackgroundColor={"white"}
                tableHeaderAlign={"start"}
                hideHeaderFooter={true}
                customFooter={CustomFooter}
              />
            </Box>
          </Box>

          <Box>
            <Typography
              sx={{
                color: "#212121",
                fontSize: "18px",
                fontWeight: 500,
                lineHeight: "28px",
                py: 2,
              }}>
              Payment Schedule
            </Typography>
            <Box>
              <TableData
                rows={rows}
                columns={columns}
                width={627}
                backgroundColor={"white"}
                HeaderbackgroundColor={"white"}
                tableHeaderAlign={"start !important"}
                headerColor={"black"}
                rowPerPage={6}
              />
            </Box>
          </Box>

          <Box sx={{ pt: "50px" }}>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 600,
                color: "#4D4D4D",
                lineHeight: "23px",
              }}>
              Bank Account Details
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 400,
                color: "#717171",
                lineHeight: "24px",
                pt: "10px",
              }}>
              Make your payments to the following account. Please note that this
              account is unique to your booking and all payments MUST be made to
              ONLY this account. A receipt will be uploaded to the Downloads
              section with in 3 business days of receiving the payment. Buyer,
              while not required, is welcome to intimate about payments made to{" "}
              <b>info@msvkanha.com</b> .
            </Typography>
          </Box>
          <hr style={{ margin: "10px 0" }} />

          <AccountDetails bankAccData={bankAccData} />
        </Box>
      </Box>
    </>
  )
}

export default React.memo(Tower)
