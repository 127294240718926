export const getCode = () => {

  const hash = window.location.hash.substring(1);

  const params = hash.split('&').reduce((params, param) => {
    const [key, value] = param.split('=');
    params[key] = decodeURIComponent(value);
    return params;
  }, {});

  const idToken = params.id_token;
  const accessToken = params.access_token;
  const expiresIn = params.expires_in;
  const tokenType = params.token_type;

  return idToken ? {idToken, accessToken, expiresIn, tokenType} :  null;
};
