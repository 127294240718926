import React, { useContext, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { setIsTokenValid } from "../redux/auth-store";
import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import * as Sentry from "@sentry/react";
import Steppercontext from "../lib/context/contextData";


const settings = ["Logout"];
const settingsIcon = ["Logout.svg"];

const Header = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const dispatch = useDispatch();

  const { userData } = useContext(Steppercontext);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  let logoutUrl;
  if (typeof window !== "undefined") {
    logoutUrl = `${process.env.REACT_APP_LOGIN_URL}/?response_type=token&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`;
  }

  const checkTokenExpiration = () => {
    const expiresTime = JSON.parse(
      localStorage.getItem("cognitoAuthDetails")
    )?.expiresIn;
    const loginTime = JSON.parse(localStorage.getItem("loginTime"));

    if (expiresTime) {
      let currentLoginTime = loginTime;
      let currentTime = new Date().getTime();

      let expirationTimestamp = new Date(
        currentLoginTime + expiresTime * 1000
      ).getTime();

      if (expirationTimestamp < currentTime) {
        localStorage.clear();
        window.location.href = logoutUrl;
      } else {
      }
    }
    setTimeout(checkTokenExpiration, 5 * 1000); //It will be check on every 5 minutes
  };

  checkTokenExpiration();

  const handleLogout = () => {
    localStorage.clear();
    dispatch(setIsTokenValid(false));
    Sentry.setUser(null);
    const cookies = new Cookies();
    cookies.remove("cognito", {
      path: "/",
      domain: ".hasura-dev.auth.ap-south-1.amazoncognito.com"
    });
    setTimeout(function () {
      window.location.href = logoutUrl;
    }, 500);
  };

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "#033A76", padding: "9px" }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img src="/assets/image/logo.png" alt="image" />

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Typography
              sx={{ fontSize: "25px", fontWeight: 700, paddingLeft: "20px" }}
            >
              Meera Shantivanam
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Typography
                  sx={{
                    color: "white",
                    paddingLeft: "8px",
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  {" "}
                  {userData?.users[0]?.first_name === null
                    ? ""
                    : ` Welcome ${userData?.users[0]?.first_name}`}
                  <ExpandMoreIcon sx={{ paddingLeft: "8px" }} />
                </Typography>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleLogout}>
                  <Typography textAlign="center" sx={{ display: "flex" }}>
                    <img
                      src={`/assets/icons/${settingsIcon}`}
                      style={{ marginRight: "5px" }}
                      alt="image"
                    />
                    {setting}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;
