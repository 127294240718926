import { Box, Typography, Button } from "@mui/material";
import React, { useEffect } from "react";
import TableData from "../../../../components/TableData";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  assocProperties,
  assocPropertiesBuyerID,
  assocPropertiesSetp2,
} from "../../../../lib/query/query";
import ModalCommon from "../../../../components/ModalCommon";
import * as Sentry from "@sentry/react";

const AssocProperties = ({ buyerInfo }) => {
  const [getBuyerId, setBuyerId] = React.useState("");
  const [buyerDataCustomer, setBuyerDataCustomer] = React.useState([]);
  const [buyerData, setBuyerData] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const { data,error: errorData } = useLazyQuery(assocProperties, {
    variables: {
      id: buyerInfo?.user?.id,
    },
  });

  useEffect(() => {
    if (data) {
      setBuyerDataCustomer(data?.sale_transaction);
    }
  }, [data]);

  const [getDocument, {error:errorDoc }] = useLazyQuery(
    assocPropertiesBuyerID
  );

  useEffect(() => {
    if (buyerInfo) {
      const modalIdToFetch = buyerInfo && buyerInfo?.buyers[0]?.buyer_identity_docs.map(
        async (item) =>
          item.document_number &&
          (await getDocument({
            variables: {
              document_number: item.document_number,
              document_type: item.document_type,
            },
          }))
      );
      Promise.all(modalIdToFetch)
        .then((results) => {
          // setBuyerId(results);

          let allIds = [];

          if (results.length > 0) {
            const allResultsData = results.map(
              (item) => item && item.data !== null && item.data
            );
            const allDocumnetData =
              allResultsData &&
              allResultsData.map((item) => item && item.buyer_identity_doc);
            const allBuyerId =
              allDocumnetData &&
              allDocumnetData.map(
                (item) => item && item.map((i) => i.buyer_id)
              );
            let IdArray = [];
            allBuyerId.forEach((item) => item && IdArray.push(...item));
            setBuyerId(IdArray);
          }
        })
        .catch((err) => {
          Sentry.captureException(err);
          console.error("Error while fetching data: ", err);
        });
    }
  }, [buyerInfo, getDocument]);

  const { data: buyerDataStep2, error } = useQuery(assocPropertiesSetp2, {
    variables: { buyer_id: getBuyerId },
    skip: !getBuyerId,
  });

  useEffect(() => {
    if (buyerDataStep2) {
      setBuyerData(buyerDataStep2?.sale_transaction);
    }
  }, [buyerDataStep2]);


  if(error || errorData || errorDoc){
    Sentry.captureException(error);
    Sentry.captureException(errorData);
    Sentry.captureException(errorDoc);
  }
  const columns = [
    {
      field: "customerName",
      headerName: "Customer Name",
      width: 150,
      align: "center",
    },
    {
      field: "customerEmailID",
      headerName: "Customer Email ID",
      width: 150,
      align: "center",
    },
    {
      field: "saleTransactionID",
      headerName: "Sale Transaction ID",
      width: 150,
      align: "center",
    },
    {
      field: "buyerName",
      headerName: "Buyer Name",
      width: 150,
      align: "center",
    },
    {
      field: "buyerAddress",
      headerName: "Buyer Address",
      width: 150,
      align: "center",
    },
    {
      field: "buyerCitizenship",
      headerName: "Buyer Citizenship",
      width: 150,
      align: "center",
    },
    {
      field: "buyerResidency",
      headerName: "Buyer Residency",
      width: 150,
      align: "center",
    },
    {
      field: "unit",
      headerName: "Unit #",
      width: 150,
      align: "center",
    },
    {
      field: "projectName",
      headerName: "Project Name",
      width: 150,
      align: "center",
    },
    {
      field: "propertyLocation",
      headerName: "Property Location",
      width: 150,
      align: "center",
    },
  ];

  const rows =
    buyerDataCustomer.length > 0 &&
    buyerDataCustomer.map((item, index) => {
      return {
        id: item?.sale_transaction_id,
        customerName: `${item?.user?.first_name} ${item?.user?.last_name}`,
        customerEmailID: item?.user?.email,
        saleTransactionID: item?.sale_transaction_id,
        buyerName: `${item?.buyers[0]?.first_name} ${item?.buyers[0]?.last_name}`,
        buyerAddress: `${
          item?.buyers[0]?.street === null ? "-" : item?.buyers[0]?.street +
          "," +
          item?.buyers[0]?.city === null ? "-" : item?.buyers[0]?.city +
          "," +
          item?.buyers[0]?.state === null ? "-" : item?.buyers[0]?.state +
          "," +
          item?.buyers[0]?.country === null ? "-" : item?.buyers[0]?.country
        }`,
        buyerCitizenship: item?.buyers[0]?.citizenship,
        buyerResidency: item?.buyers[0]?.residency_type,
        unit: item?.property?.unit_number,
        projectName: item?.property?.project?.project_name,
        propertyLocation: `${
          item.property === null
            ? "Yet to select"
            : item?.property?.project_building?.tower_name +
              "-" +
              item?.property?.project_building?.city +
              "-" +
              item?.property?.project_building?.state
        }`,
      };
    });

  const rows1 =
    buyerData.length > 0 &&
    buyerData.map((item, index) => {
      return {
        id: item?.sale_transaction_id,
        // id: index + 1 * 2,
        customerName: item?.user
          ? `${item?.user?.first_name} ${item?.user?.last_name}`
          : `-`,
        customerEmailID: item?.user ? item?.user?.email : "-",
        saleTransactionID: item?.sale_transaction_id,
        buyerName: `${item?.buyers[0]?.first_name} ${item?.buyers[0]?.last_name}`,
        buyerAddress: `${
          item?.buyers[0]?.street === null ? "-" : item?.buyers[0]?.street +
          "," +
          item?.buyers[0]?.city === null ? "-" : item?.buyers[0]?.city +
          "," +
          item?.buyers[0]?.state === null ? "-" : item?.buyers[0]?.state +
          "," +
          item?.buyers[0]?.country === null ? "-" : item?.buyers[0]?.country
        }`,
        buyerCitizenship: `${
          item?.buyers[0]?.citizenship === null
            ? "Yet to select"
            : item?.buyers[0]?.citizenship
        }`,
        buyerResidency: `${
          item?.buyers[0]?.residency_type === null
            ? "Yet to select"
            : item?.buyers[0]?.residency_type
        }`,
        unit: item?.property?.unit_number,
        projectName: item?.property?.project?.project_name,
        propertyLocation: `${
          item.property === null
            ? "Yet to select"
            : item?.property?.project_building?.tower_name +
              ", " +
              item?.property?.project_building?.city +
              ", " +
              item?.property?.project_building?.state
        }`,
      };
    });

  const combineData = [];

  if (rows.length > 0) {
    combineData.push(
      ...rows.map((item, index) => ({ ...item, id: `row-${index}` }))
    );
  }
  if (rows1.length > 0) {
    combineData.push(
      ...rows1.map((item, index) => ({ ...item, id: `row1-${index}` }))
    );
  }
  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "25px",
            lineHeight: "30px",
            color: "#212121",
            marginBottom: "22px",
          }}
        >
          Assoc. Properties
        </Typography>

        <img
          src="/assets/image/Infosquarehome.svg"
          alt="Info square"
          width={50}
          style={{
            color: "grey",
            height: "25px",
            paddingTop: "2px",
            cursor: "pointer",
          }}
          onClick={() => {
            setOpen(true);
          }}
        />
      </Box>
      <Box>
        <TableData
          rows={combineData}
          columns={columns}
          HeaderbackgroundColor={"#F5F7FA"}
          tableHeaderAlign={"center !important"}
          noDataFound={"No Records Found"}
        />
      </Box>
      <Box>
        <ModalCommon
          open={open}
          setOpen={setOpen}
          header={<></>}
          closeModal
          justifyContent={"end"}
          height={300}
          width={551}
          body={
            <div>
              <span
                style={{
                  display: "flex",
                  textAlign: "justify",
                  margin: "0px 30px",
                  fontWeight: "600",
                }}
              >
                {`This table provides list of other Sale Transactions where same Government issued ID/IDs (Passport, PAN, Aadhar, OCI) are used - even if the transactions are being done using a different customer account.
              
              The table also lists other sale transactions conducted using the same customer login.`}
              </span>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", pt: 4 }}
              >
                <Button
                  sx={{
                    borderRadius: "4px",
                    gap: "10px",
                    backgroundColor: "#FFFFFF",
                    fontSize: "16px",
                    lineHeight: "24px",
                    textAlign: "Center",
                    fontWeight: 600,
                    color: "#717171",
                    textTransform: "capitalize",
                    width: "209px",
                    height: "56px",
                  }}
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  sx={{
                    borderRadius: "4px",
                    gap: "10px",
                    backgroundColor: "#0671E0",
                    fontSize: "16px",
                    lineHeight: "24px",
                    textAlign: "Center",
                    fontWeight: 600,
                    color: "#FFFFFF",
                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: "#0671E0",
                    },
                    width: "209px",
                    height: "56px",
                  }}
                  onClick={() => setOpen(false)}
                >
                  OK
                </Button>
              </Box>
            </div>
          }
        />
      </Box>
    </Box>
  );
};

export default AssocProperties;
