import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select
} from "@mui/material";
import TableData from "../../../../components/TableData";
import {
  InstallmentCity,
  InstallmentCityEachModel,
  InstallmentCityUniqueList,
  InstallmentData,
  InstallmentDataEachModel,
  InstallmentProject,
  InstallmentProjectEachModel,
  InstallmentProjectUniqueList,
  InstallmentTower,
  InstallmentTowerEachModel,
  InstallmentTowerUniqueList
} from "../../../../lib/query/query";
import { useLazyQuery, useQuery } from "@apollo/client";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import moment from "moment";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";
import ErrorToast from "../../../../components/ErrorToast";

export const InstallmentsStatus = () => {
  const [installmentCity, setInstallmentCity] = useState("");
  const [installmentCityData, setInstallmentCityData] = useState([]);
  const [installmentProject, setInstallmentProject] = useState("");
  const [projectData, setProjectData] = useState([]);
  const [installmentTower, setInstallmentTower] = useState("");
  const [towerData, setTowerData] = useState([]);
  const [cityUnique, setCityUnique] = useState([]);
  const [installmentCityEachRows, setInstallmentCityEachRows] = useState([]);
  const [projectId, setProjectId] = useState("");
  const [projectName, setProjectName] = useState("");
  const [projectUnique, setProjectUnique] = useState([]);
  const [installmentProjectEachRows, setInstallmentProjectEachRows] = useState(
    []
  );
  const [towerName, setTowerName] = useState("");
  const [towerUnique, setTowerUnique] = useState([]);
  const [installmentTowerEachRows, setInstallmentTowerEachRows] = useState([]);
  const [installmentPageData, setInstallmentPageData] = useState([]);
  const [pageData, setPageData] = useState([]);

  const { data, error: errorData } = useQuery(InstallmentCity);

  const { data: instData, error: errorInstData } = useQuery(InstallmentData);

  useEffect(() => {
    if (instData && instData.v_installment_status.length > 0) {
      setInstallmentPageData(instData.v_installment_status);
    }
  }, [instData]);

  const [getinstallmentPageData, { error: errorPageData }] = useLazyQuery(
    InstallmentDataEachModel
  );

  useEffect(() => {
    if (installmentPageData.length > 0) {
      const modalIdToFetch = installmentPageData.map(
        async (item) =>
          await getinstallmentPageData({
            variables: { model_id: item?.model_id }
          })
      );
      Promise.all(modalIdToFetch)
        .then((results) => {
          setPageData(results);
        })
        .catch((err) => {
          Sentry.captureException(err);
          console.error("Error while fetching data: ", err);
        });
    }
  }, [installmentPageData, getinstallmentPageData]);

  let instPageData = [];
  pageData.map((item) => {
    instPageData.push(item.data);
  });

  useEffect(() => {
    if (data && data.project.length > 0) {
      setInstallmentCityData(data && data.project);
    }
  }, [data]);

  const [getInstallmentProject, { data: dataProject, error: errorProject }] =
    useLazyQuery(InstallmentProject, {
      variables: { city: installmentCity }
    });

  useEffect(() => {
    if (installmentCity) {
      getInstallmentProject();
    }
  }, [installmentCity]);

  useEffect(() => {
    if (dataProject && dataProject.project.length > 0) {
      setProjectData(dataProject.project);
    }
  }, [dataProject]);

  useEffect(() => {
    if (installmentCity && installmentProject) {
      getInstallmentProject();
    }
  }, [installmentCity, installmentProject]);

  const [getInstallmentTower, { data: dataTower, error: errorTower }] =
    useLazyQuery(InstallmentTower, {
      variables: { project_id: projectId }
    });
  useEffect(() => {
    if (dataTower && dataTower.project_building.length > 0) {
      setTowerData(dataTower.project_building);
    }
  }, [dataTower]);

  useEffect(() => {
    if (installmentCity && installmentProject) {
      getInstallmentTower();
    }
  }, [installmentCity, installmentProject]);

  const [
    getInstallmentCityUnique,
    { data: dataCityUnique, error: errorCityUnique }
  ] = useLazyQuery(InstallmentCityUniqueList, {
    variables: { city: installmentCity }
  });

  useEffect(() => {
    if (dataCityUnique && dataCityUnique.v_installment_status.length > 0) {
      setCityUnique(dataCityUnique.v_installment_status);
    }
  }, [dataCityUnique]);

  useEffect(() => {
    if (installmentCity) {
      getInstallmentCityUnique();
    }
  }, [installmentCity]);

  const [getInstallmentCityEachRows, { error: errorCityEachRows }] =
    useLazyQuery(InstallmentCityEachModel);

  useEffect(() => {
    if (cityUnique) {
      const modalIdToFetch = cityUnique.map(
        async (item) =>
          await getInstallmentCityEachRows({
            variables: {
              model_id: item?.model_id,
              city: installmentCity
            }
          })
      );
      Promise.all(modalIdToFetch)
        .then((results) => {
          setInstallmentCityEachRows(results);
        })
        .catch((err) => {
          Sentry.captureException(err);
          console.error("Error while fetching data: ", err);
        });
    }
  }, [cityUnique, getInstallmentCityEachRows]);

  let installmentData = [];
  installmentCityEachRows.map((item) => {
    installmentData.push(item.data);
  });

  // useEffect(() => {
  //   if (installmentCity) {
  //     getInstallmentCityEachRows();
  //   }
  // }, [cityUnique]);

  const [
    getInstallmentProjectUnique,
    { data: dataProjectUnique, error: errorProjectUnique }
  ] = useLazyQuery(InstallmentProjectUniqueList, {
    variables: { city: installmentCity, project_name: projectName }
  });
  useEffect(() => {
    if (
      dataProjectUnique &&
      dataProjectUnique.v_installment_status.length > 0
    ) {
      setProjectUnique(dataProjectUnique.v_installment_status);
    }
  }, [dataProjectUnique]);

  useEffect(() => {
    if (installmentCity && installmentProject) {
      getInstallmentProjectUnique();
    }
  }, [installmentCity, installmentProject]);

  const [getInstallmentProjectEachRows, { error: errorProjectEachRows }] =
    useLazyQuery(InstallmentProjectEachModel);

  useEffect(() => {
    if (projectUnique) {
      const modalIdToFetch = projectUnique.map(
        async (item) =>
          await getInstallmentProjectEachRows({
            variables: {
              model_id: item?.model_id,
              project_name: projectName,
              city: installmentCity
            }
          })
      );
      Promise.all(modalIdToFetch)
        .then((results) => {
          setInstallmentProjectEachRows(results);
        })
        .catch((err) => {
          Sentry.captureException(err);
          console.error("Error while fetching data: ", err);
        });
    }
  }, [projectUnique, getInstallmentProjectEachRows]);

  let installmentProjectData = [];
  installmentProjectEachRows.map((item) => {
    installmentProjectData.push(item.data);
  });

  const [
    getInstallmentTowerUnique,
    { data: dataTowerUnique, refetch: refetchTower, error: errorTowerUnique }
  ] = useLazyQuery(InstallmentTowerUniqueList, {
    variables: {
      city: installmentCity,
      project_name: projectName,
      tower_name: towerName
    }
  });

  useEffect(() => {
    if (dataTowerUnique && dataTowerUnique.v_installment_status.length >= 0) {
      setTowerUnique(dataTowerUnique.v_installment_status);
    }
  }, [dataTowerUnique]);

  useEffect(() => {
    if (installmentCity && installmentProject && installmentTower) {
      getInstallmentTowerUnique();
      refetchTower();
    }
  }, [installmentCity, installmentProject, installmentTower]);

  const [getInstallmentTowerEachRows, { error: errorTowerEachRows }] =
    useLazyQuery(InstallmentTowerEachModel);


  useEffect(() => {
    if (towerUnique) {
      const modalIdToFetch = towerUnique.map(
        async (item) =>
          await getInstallmentTowerEachRows({
            variables: {
              model_id: item?.model_id,
              project_name: projectName,
              city: installmentCity,
              tower_name: towerName
            }
          })
      );
      Promise.all(modalIdToFetch)
        .then((results) => {
          setInstallmentTowerEachRows(results);
        })
        .catch((err) => {
          Sentry.captureException(err);
          console.error("Error while fetching data: ", err);
        });
    }
  }, [towerUnique, getInstallmentTowerEachRows]);

  let installmentTowerData = [];
  installmentTowerEachRows.map((item) => {
    installmentTowerData.push(item.data);
  });

  const IndianCurrency = new Intl.NumberFormat("en-IN");

  const columns = [
    {
      field: "installment",
      headerName: "Installment",
      width: 200,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "number_of_Installments_Paid",
      headerName: "Number of Installments Paid",
      width: 200,
      align: "center",
      headerAlign: "center"
    },
    {
      field: "amountReceived",
      headerName: "Amount Received",
      width: 200,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <span>{IndianCurrency.format(params?.value)}</span>
      )
    },
    {
      field: "number_of_Instalments_Pending",
      headerName: "Number of Instalments Pending",
      width: 200,
      align: "center",
      headerAlign: "center"
    },
    {
      field: "amount_Due",
      headerName: "Amount Due",
      width: 200,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <span>{IndianCurrency.format(params?.value)}</span>
      )
    },
    {
      field: "number_of_Instalments_Overdue",
      headerName: "Number of Instalments Overdue",
      width: 170,
      align: "center",
      headerAlign: "center"
    },
    {
      field: "amountOverdue",
      headerName: "Amount Overdue",
      width: 200,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <span>{IndianCurrency.format(params?.value)}</span>
      )
    }
  ];

  const rows =
    installmentPageData.length > 0 &&
    installmentPageData.map((item, index) => {
      return {
        id: index,
        installment: item?.model_name,
        number_of_Installments_Paid:
          instPageData[index]?.v_installment_status[0]?.paid_count,
        amountReceived:
          instPageData[index]?.v_installment_status[0]?.amount_received,
        number_of_Instalments_Pending:
          instPageData[index]?.v_installment_status[0]?.due_count,
        amount_Due: instPageData[index]?.v_installment_status[0]?.amount_due,
        number_of_Instalments_Overdue:
          instPageData[index]?.v_installment_status[0]?.overdue_count,
        amountOverdue:
          instPageData[index]?.v_installment_status[0]?.amount_overdue
      };
    });

  const rowsCity =
    cityUnique.length > 0 &&
    cityUnique.map((item, index) => {
      return {
        id: index,
        installment: item?.model_name,
        number_of_Installments_Paid:
          installmentData[index]?.v_installment_status[0]?.paid_count,
        amountReceived:
          installmentData[index]?.v_installment_status[0]?.amount_received,
        number_of_Instalments_Pending:
          installmentData[index]?.v_installment_status[0]?.due_count,
        amount_Due: installmentData[index]?.v_installment_status[0]?.amount_due,
        number_of_Instalments_Overdue:
          installmentData[index]?.v_installment_status[0]?.overdue_count,
        amountOverdue:
          installmentData[index]?.v_installment_status[0]?.amount_overdue
      };
    });

  const rowsProject =
    projectUnique.length > 0 &&
    projectUnique.map((item, index) => {
      return {
        id: index,
        installment: item?.model_name,
        number_of_Installments_Paid:
          installmentProjectData[index]?.v_installment_status[0]?.paid_count,
        amountReceived:
          installmentProjectData[index]?.v_installment_status[0]
            ?.amount_received,
        number_of_Instalments_Pending:
          installmentProjectData[index]?.v_installment_status[0]?.due_count,
        amount_Due:
          installmentProjectData[index]?.v_installment_status[0]?.amount_due,
        number_of_Instalments_Overdue:
          installmentProjectData[index]?.v_installment_status[0]?.overdue_count,
        amountOverdue:
          installmentProjectData[index]?.v_installment_status[0]?.amount_overdue
      };
    });

  const rowsTower =
    towerUnique.length > 0 &&
    towerUnique.map((item, index) => {
      return {
        id: index,
        installment: item?.model_name,
        number_of_Installments_Paid:
          installmentTowerData[index]?.v_installment_status[0]?.paid_count,
        amountReceived:
          installmentTowerData[index]?.v_installment_status[0]?.amount_received,
        number_of_Instalments_Pending:
          installmentTowerData[index]?.v_installment_status[0]?.due_count,
        amount_Due:
          installmentTowerData[index]?.v_installment_status[0]?.amount_due,
        number_of_Instalments_Overdue:
          installmentTowerData[index]?.v_installment_status[0]?.overdue_count,
        amountOverdue:
          installmentTowerData[index]?.v_installment_status[0]?.amount_overdue
      };
    });

  const handleChange = (event) => {
    setInstallmentCity(event.target.value);
  };
  const handleChangeProject = (event) => {
    setInstallmentProject(event.target.value);
    setProjectId(event.target.value);
    const selectedProjectId = event.target.value;
    const selectedProject = projectData.find(
      (item) => item.project_id === selectedProjectId
    );
    setProjectName(selectedProject.project_name);
  };
  const handleChangeTower = (event) => {
    setInstallmentTower(event.target.value);
    const selectedTowerId = event.target.value;
    const selectedTower = towerData.find(
      (item) => item.building_id === selectedTowerId
    );
    setTowerName(selectedTower.tower_name);
  };

  const date = {
    id: "Date:",
    installment: new Date()
  };

  const title = {
    id: "Title:",
    installment: "Installments"
  };

  const time = {
    id: "Time:",
    installment: moment().format("hh:mm a")
  };

  let selectedCity = {
    id: "City:",
    installment: installmentCity === "" ? "N/A" : installmentCity
  };

  let selectedProject = {
    id: "Project:",
    installment: installmentProject === "" ? "N/A" : installmentProject
  };

  let selectedTower = {
    id: "Tower:",
    installment: installmentTower === "" ? "N/A" : installmentTower
  };

  const generateExcelRow = (
    rows,
    selectedCity,
    selectedProject,
    selectedTower
  ) => {
    return [
      title,
      date,
      time,
      selectedCity,
      selectedProject,
      selectedTower,
      "",
      ...rows
    ];
  };

  const downloadExcel = () => {
    const sheet = XLSX.utils.json_to_sheet(
      (installmentCity
        ? installmentProject
          ? installmentTower
            ? rowsTower &&
              generateExcelRow(
                rowsTower,
                selectedCity,
                selectedProject,
                selectedTower
              )
            : rowsProject &&
              generateExcelRow(
                rowsProject,
                selectedCity,
                selectedProject,
                selectedTower
              )
          : rowsCity &&
            generateExcelRow(
              rowsCity,
              selectedCity,
              selectedProject,
              selectedTower
            )
        : rows &&
          generateExcelRow(
            rows,
            selectedCity,
            selectedProject,
            selectedTower
          )) || [],
      { skipHeader: true }
    );
    let Heading = [
      [
        "Id",
        "Installment",
        "Number Of Installments Paid",
        "Amount Received",
        "Number Of Instalments Pending",
        "Amount Due",
        "Number Of Instalments Overdue",
        "Amount Overdue"
      ]
    ];
    const wb = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(sheet, Heading, { origin: "A7" });
    XLSX.utils.book_append_sheet(wb, sheet, "Sheet1");
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array"
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    });
    saveAs(blob, "Installments.xlsx");
  };


  if (
    errorData ||
    errorInstData ||
    errorPageData ||
    errorCityUnique ||
    errorCityEachRows ||
    errorProject ||
    errorProjectUnique ||
    errorProjectEachRows ||
    errorTower ||
    errorTowerUnique ||
    errorTowerEachRows
  ) {
    toast.error(
      <ErrorToast
        email={"info@msvkanha.com"}
        errorMsg={
        (errorData && errorData.message) ||
        (errorInstData && errorInstData.message) ||
        (errorPageData && errorPageData.message) ||
        (errorCityUnique && errorCityUnique.message) ||
        (errorCityEachRows && errorCityEachRows.message) ||
        (errorProject && errorProject.message) ||
        (errorProjectUnique && errorProjectUnique.message) ||
        (errorProjectEachRows && errorProjectEachRows.message) ||
        (errorTower && errorTower.message) ||
        (errorTowerUnique && errorTowerUnique.message) ||
        (errorTowerEachRows && errorTowerEachRows.message)
        }
      />
    );
    Sentry.captureException(
      errorData ||
        errorInstData ||
        errorPageData ||
        errorCityUnique ||
        errorCityEachRows ||
        errorProject ||
        errorProjectUnique ||
        errorProjectEachRows ||
        errorTower ||
        errorTowerUnique ||
        errorTowerEachRows
    );
  }
  

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6} md={9}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <Box>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    value={installmentCity}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{ color: "inherit" }}
                  >
                    <MenuItem selected disabled value="">
                      Select City
                    </MenuItem>
                    {installmentCityData.length > 0 &&
                      installmentCityData.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.city}>
                            {item.city}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    value={installmentProject}
                    onChange={handleChangeProject}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{ color: "inherit" }}
                  >
                    <MenuItem selected disabled value="">
                      Select Project
                    </MenuItem>
                    {projectData.length > 0 &&
                      projectData.map((item) => {
                        return (
                          <MenuItem
                            key={item.project_id}
                            value={item.project_id}
                          >
                            {item.project_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    value={installmentTower}
                    onChange={handleChangeTower}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{ color: "inherit" }}
                  >
                    <MenuItem selected disabled value="">
                      Select Tower
                    </MenuItem>
                    {towerData.length > 0 &&
                      towerData.map((item) => {
                        return (
                          <MenuItem
                            key={item.building_id}
                            value={item.building_id}
                          >
                            {item.tower_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} md={3}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#0671E0",
                    color: "white",
                    fontWeight: 400,
                    lineHeight: "24px",
                    textTransform: "capitalize",
                    fontSize: "18px",
                    width: "164px",
                    height: "56px",
                    padding: "16px, 32px, 16px, 32px",
                    gap: "8px",
                    borderRadius: "4px",
                    "&:hover": {
                      backgrindColor: "#0671E0"
                    }
                  }}
                >
                  Search
                  <img src="/assets/image/Search.png" alt="SearchIcon" />
                </Button>
              </Box>
            </Grid>
            <Grid item xs={6} md={6}>
              <Box>
                <Button
                  variant="outlined"
                  sx={{
                    backgroundColor: "white",
                    color: "#0671E0",
                    fontWeight: 400,
                    lineHeight: "24px",
                    textTransform: "capitalize",
                    fontSize: "16px",
                    "&:hover": {
                      backgrindColor: "white"
                    },
                    padding: "16px, 32px, 16px, 32px",
                    width: "151px",
                    height: "56px",
                    gap: "10px",
                    border: "1px solid #0671E0",
                    textAlign: "Center"
                  }}
                  onClick={downloadExcel}
                >
                  Download XLS
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ marginTop: "30px" }}>
        <TableData
          rows={
            installmentCity
              ? installmentProject
                ? installmentTower
                  ? rowsTower
                  : rowsProject
                : rowsCity
              : rows
          }
          columns={columns}
          HeaderbackgroundColor={"#F5F7FA"}
          rowPerPage={50}
        />
      </Box>
    </Box>
  );
};
