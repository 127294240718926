import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { listToken, setIsTokenValid } from '../redux/auth-store';
import { getCode } from "../redux/auth-utils";


const useAuthToken = () => {

    const dispatch = useDispatch();

    const onPageInit = async () => {
        dispatch(setIsTokenValid(await isTokenValid()));
    };

    useEffect(() => {
        onPageInit();
    }, []);

    const isTokenValid = async () => {
        const token = localStorage.getItem("cognitoAuthDetails");

        if (token === null) {
            const tokenData = getCode();
            if (tokenData) {
                await dispatch(listToken(tokenData));
            }
            else {
                return false;
            }
        }

        return true;
    };
};

export default useAuthToken;
