import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Container, Tab, Typography } from "@mui/material";
import React, { useState } from "react";
import { Inventory } from "./Inventory";
import { CashFlowbyProject } from "./CashFlowbyProject";
import { CashFlowbyTower } from "./CashFlowbyTower";
import { InstallmentsStatus } from "./InstallmentsStatus";
import { OverviewChart } from "./OverviewChart";
import { ReportsApprovedBookings } from "./ReportsApprovedBookings";
import { AvailableProperties } from "./AvailableProperties";

const tabs = [
  { label: "Summary", value: "1" },
  { label: "Inventory", value: "2" },
  { label: "Cash Flow by Project", value: "3" },
  { label: "Cash Flow by Tower", value: "4" },
  { label: "Installments", value: "5" },
  { label: "Approved Bookings", value: "6" },
  { label: "Available Properties", value: "7" },
];

const ReportsTabs = () => {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Container maxWidth={"xl"}>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider", padding: "0 16px" }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "25px",
                lineHeight: "30px",
                color: "#212121",
                margin: "0px 0px 0px 0px"
              }}
            >
              Reports
            </Typography>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              indicatorColor="#0671E0"
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "#0671E0"
                }
              }}
            >
              {tabs.map((tab, index) => (
                <Tab
                  label={tab.label}
                  value={tab.value}
                  key={index}
                  sx={{ textTransform: "capitalize" }}
                />
              ))}
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ padding: "24px 16px" }}>
            <OverviewChart />
          </TabPanel>
          <TabPanel value="2" >
            <Inventory />
          </TabPanel>
          <TabPanel value="3">
            <CashFlowbyProject />
          </TabPanel>
          <TabPanel value="4">
            <CashFlowbyTower />
          </TabPanel>
          <TabPanel value="5">
            <InstallmentsStatus />
          </TabPanel>
          <TabPanel value="6">
            <ReportsApprovedBookings />
          </TabPanel>
          <TabPanel value="7">
            <AvailableProperties />
          </TabPanel>
        </TabContext>
      </Box>
    </Container>
  );
};

export default ReportsTabs;
