import { Box, Button, Typography, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { idVerificationPhoto } from "../../../lib/query/query";
import { useQuery } from "@apollo/client";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { toast } from "react-toastify";
import ErrorToast from "../../../components/ErrorToast";
import * as Sentry from "@sentry/react";

const IdProof = ({ item, index, status }) => {
  const [imageData, setImageData] = React.useState({});
  const [isLoading, setIsLoading] = useState(false);

  let { data, loading, error } = useQuery(idVerificationPhoto, {
    variables: { buyer_identity_doc_id: item?.buyer_identity_doc_id }
  });

  useEffect(() => {
    if (
      data &&
      data.buyer_identity_doc &&
      data.buyer_identity_doc[0] &&
      data.buyer_identity_doc[0].document
    ) {
      const hexString =
        data &&
        data?.buyer_identity_doc[0].document
          ?.replace(/\\x/g, "")
          .match(/.{2}/g)
          .map((byte) => String.fromCharCode(parseInt(byte, 16)))
          .join("");

      const cleanedHexString =
        hexString &&
        hexString.replace(/\s/g, "").replace(/0x|,/g, "").toUpperCase();

      const byteArray = new Uint8Array(
        cleanedHexString &&
          cleanedHexString?.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
      );

      let mimeType = "image/jpeg";
      if (data?.buyer_identity_doc[0]?.file_extension.toLowerCase() === "png") {
        mimeType = "image/png";
      } else if (
        data?.buyer_identity_doc[0]?.file_extension.toLowerCase() === "gif"
      ) {
        mimeType = "image/gif";
      } else if (
        data?.buyer_identity_doc[0]?.file_extension.toLowerCase() === "svg"
      ) {
        mimeType = "image/svg+xml";
      } else if (
        data?.buyer_identity_doc[0]?.file_extension.toLowerCase() === "jpg"
      ) {
        mimeType = "image/jpg";
      }

      const blob = new Blob([byteArray], { type: mimeType });

      const dataUrl = URL.createObjectURL(blob);

      setImageData(dataUrl);
    }
  }, [data]);

  const handleDocumentClick = async () => {
    setIsLoading(true);
    const output =
      data &&
      data.buyer_identity_doc &&
      data.buyer_identity_doc[0].document
        .replace(/\\x/g, "")
        .match(/.{2}/g)
        .map((byte) => String.fromCharCode(parseInt(byte, 16)))
        .join("");
    const hexString =
      output &&
      output
        .split(",")
        .map((hex) => parseInt(hex, 16))
        .map((byte) => byte.toString(16).padStart(2, "0"))
        .join("")
        .toUpperCase();
    const byteArray = new Uint8Array(
      hexString && hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );
    const blob = new Blob([byteArray], { type: "application/octet-stream" });
    const fileDownloadUrl = URL.createObjectURL(blob);
    const downloadLink = document.createElement("a");
    downloadLink.href = fileDownloadUrl;
    downloadLink.download = `${data?.buyer_identity_doc[0]?.file_name}.${data?.buyer_identity_doc[0]?.file_extension}`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(fileDownloadUrl);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  if (loading) {
    return (
      <CircularProgress
        sx={{
          width: "40px !important",
          height: "40px !important",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto"
        }}
      />
    );
  }

  if(error) {
    toast.error(
      <ErrorToast
        email={"info@msvkanha.com"}
        errorMsg={
          (error && error?.message)
        }
      />
    );
    Sentry.captureException(
      (error && error?.message)
    );
  }

  return (
    <Box
      sx={{
        borderRadius: "10px",
        border:
          status === "Approved"
            ? "2px solid #009262"
            : status === "Rejected"
            ? "1px solid #E8E8EA"
            : "1px solid #E8E8EA",
        padding: "30px"
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box display={"flex"}>
          <Box mr={"40px"}>
            <Typography variant="body1" color={"#717171"}>
              ID Type
            </Typography>
            <Typography variant="body1" color={"#212121"}>
              {item?.document_type}
            </Typography>
          </Box>

          <Box mr={"40px"}>
            <Typography variant="body1" color={"#717171"}>
              ID Number
            </Typography>
            <Typography variant="body1" color={"#212121"}>
              {item?.document_number}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" color={"#717171"}>
              Upload Date
            </Typography>
            <Typography variant="body1" color={"#212121"}>
              {item?.created_date.split("T")[0]}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center"
          }}
        >
          {" "}
          <Button
            type="submit"
            sx={{
              borderRadius: "4px",
              gap: "10px",
              fontSize: "16px",
              lineHeight: "24px",
              textAlign: "Center",
              fontWeight: 600,
              color: "#0671E0",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#fff"
              },
              width: "180px",
              height: "56px"
            }}
            onClick={handleDocumentClick}
          >
            {isLoading ? (
              <CircularProgress
                sx={{
                  width: "20px !important",
                  height: "20px !important"
                }}
              />
            ) : (
              <>
                {" "}
                <FileDownloadIcon /> Download{" "}
              </>
            )}
          </Button>
          <Typography
            variant="body1"
            sx={{
              color:
                status === "Approved"
                  ? "#009262"
                  : status === "Rejected"
                  ? "#E02B1D"
                  : "#E02B1D",
              fontWeight: "600",
              display: "flex",
              alignItems: "center"
            }}
          >
            {status === "Approved" ? (
              <img
                src="/assets/icons/approved.png"
                style={{ height: "16px", width: "16px", marginRight: "8px" }}
              />
            ) : status === "Rejected" ? (
              <img
                src="/assets/icons/Trashcan.svg"
                style={{ height: "16px", width: "16px", marginRight: "8px" }}
              />
            ) : (
              <img
                src="/assets/icons/Trashcan.svg"
                style={{ height: "16px", width: "16px", marginRight: "8px" }}
              />
            )}

            {status === "Approved"
              ? "Approved"
              : status === "Rejected"
              ? "Delete"
              : "Delete"}
          </Typography>
        </Box>
      </Box>

      <Box margin={"30px 0"}>
        {data?.buyer_identity_doc[0]?.file_extension === "pdf" ? (
          <img
            src="/assets/image/pdf_image.jpeg"
            alt="pdf"
            className="w-[150px] h-full mx-auto align-center justify-center"
          />
        ) : (
          <img
            src={!loading && imageData}
            alt="passport"
            className="max-w-full h-full mx-auto"
          />
        )}
      </Box>
    </Box>
  );
};

export default IdProof;
