import React, { useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  Box,
  Container,
  Grid,
  MenuItem,
  Select,
  Typography
} from "@mui/material";

import TableData from "../../../components/TableData";
import {
  paymentAmount,
  paymentScheduleData,
  paymentScheduleTable
} from "../../../lib/query/query";
import { toast } from "react-toastify";
import ErrorToast from "../../../components/ErrorToast";
import * as Sentry from "@sentry/react";

export const PaymentSchedule = () => {
  const [property, setProperty] = useState("");
  const [paymentTable, setPaymentTable] = useState([]);
  const [paymentSchedule, setPaymentSchedule] = useState([]);
  // const [selectProperty, setSelectProperty] = useState([]);
  const [selectAmount, setSelectAmount] = useState([]);

  const filteredPaymentSchedule = paymentSchedule.filter(
    (item) => item.sale_transaction_id === property
  );

  const { data, refetch, error: errorData } = useQuery(paymentScheduleData);

  const [dataTable, { data: paymentTableData, error: errorPaymentTable }] =
    useLazyQuery(paymentScheduleTable, {
      variables: { sale_transaction_id: property }
    });
  useEffect(() => {
    if (property) {
      dataTable();
    }
  }, [property]);

  // useEffect(() => {
  //   if (
  //     paymentTableData &&
  //     paymentTableData?.sale_transaction_payment_schedule?.length > 0
  //   ) {
  //     setSelectProperty(paymentTableData?.sale_transaction_payment_schedule);
  //   }
  // }, [paymentTableData]);

  const [amountData, { data: paymentTotle, error: errorPaymentTotle }] =
    useLazyQuery(paymentAmount, {
      variables: { sale_transaction_id: property }
    });
  useEffect(() => {
    if (property) {
      amountData();
    }
  }, [property]);
  useEffect(() => {
    if (paymentTotle) {
      setSelectAmount(paymentTotle?.payment_history_aggregate?.aggregate);
    }
  }, [paymentTotle]);

  useEffect(() => {
    refetch();
    if (data) {
      setPaymentSchedule(data.sale_transaction);
    }
  }, [data]);

  useEffect(() => {
    if (paymentTableData) {
      setPaymentTable(paymentTableData?.sale_transaction_payment_schedule);
    }
  }, [paymentTableData]);

  useEffect(() => {
    if (paymentSchedule.length > 0) {
      for (let i = 0; i < paymentSchedule.length; i++) {
        if (paymentSchedule[i].property_id !== null) {
          setProperty(paymentSchedule[i].sale_transaction_id);
          break;
        }
      }
    }
  }, [paymentSchedule]);

  const handleProperty = (event) => {
    setProperty(event.target.value);
  };

  const columns = [
    {
      field: "item",
      headerName: "Item",
      width: 270,
      sortable: false
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 260,
      sortable: false,
      align: "center",
      renderCell: (params) => <span>{params?.value?.toLocaleString()}</span>
    },
    {
      field: "due_date",
      headerName: "Due date",
      width: 260,
      sortable: true,
      align: "center"
    }
  ];

  const rows =
    paymentTable?.length > 0 &&
    paymentTable?.map((item, index) => {
      return {
        id: index,
        amount: item?.amount,
        due_date: item?.due_date,
        item: item?.item
      };
    });

  if (errorData || errorPaymentTable || errorPaymentTotle) {
    toast.error(
      <ErrorToast
        email={"info@msvkanha.com"}
        errorMsg={
          (errorData && errorData?.message) ||
          (errorPaymentTable && errorPaymentTable?.message) ||
          (errorPaymentTotle && errorPaymentTotle?.message)
        }
      />
    );
    Sentry.captureException(
      (errorData && errorData?.message) ||
        (errorPaymentTable && errorPaymentTable?.message) ||
        (errorPaymentTotle && errorPaymentTotle?.message)
    );
  }

  return (
    <Container>
      <Box>
        <Box>
          <Typography
            sx={{
              color: "#212121",
              fontSize: "25px",
              fontWeight: 600,
              lineHeight: "30px"
            }}
          >
            Payment Schedule
          </Typography>
        </Box>
        <Box
          sx={{
            borderRadius: "10px",
            border: "1px solid #E8E8EA",
            marginTop: "50px"
          }}
        >
          <Box sx={{ m: 4 }}>
            <Select
              value={property}
              onChange={handleProperty}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{ width: "300px" }}
            >
              <MenuItem selected disabled value="">
                Select property
              </MenuItem>
              {paymentSchedule.length > 0 &&
                paymentSchedule.map((item, index) => {
                  if (item.property !== null) {
                    return (
                      <MenuItem key={index} value={item.sale_transaction_id}>
                        {`${item?.property?.project_building?.tower_name} ${item?.property?.unit_number} - ${item?.property?.project?.project_name}, ${item?.property?.project_building?.city}`}
                      </MenuItem>
                    );
                  }
                  return null;
                })}
            </Select>
          </Box>
          <>
            <Box sx={{ m: 4 }}>
              <Typography
                sx={{
                  color: "#717171",
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "23px",
                  mt: 2
                }}
              >
                Project name
              </Typography>
              <Typography
                sx={{
                  color: "#212121",
                  fontSize: "20px",
                  fontWeight: 600,
                  lineHeight: "23px",
                  mt: "12px"
                }}
              >
                {filteredPaymentSchedule[0]?.property?.project?.project_name}
              </Typography>
            </Box>
            <Box sx={{ m: 4 }}>
              <Typography
                sx={{
                  color: "#717171",
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "23px",
                  mt: 2
                }}
              >
                Tower name
              </Typography>
              <Typography
                sx={{
                  color: "#212121",
                  fontSize: "20px",
                  fontWeight: 600,
                  lineHeight: "23px",
                  mt: "12px"
                }}
              >
                {
                  filteredPaymentSchedule[0]?.property?.project_building
                    ?.tower_name
                }
              </Typography>
            </Box>
            <Box sx={{ m: 4 }}>
              <Typography
                sx={{
                  color: "#717171",
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "23px",
                  mt: 2
                }}
              >
                Unit number
              </Typography>
              <Typography
                sx={{
                  color: "#212121",
                  fontSize: "20px",
                  fontWeight: 600,
                  lineHeight: "23px",
                  mt: "12px"
                }}
              >
                {filteredPaymentSchedule[0]?.property?.unit_number}
              </Typography>
            </Box>

            <Box sx={{ width: "805px" }}>
              <Grid
                container
                spacing={2}
                sx={{
                  padding: "20px",
                  margin: "30px",
                  background: "#F5F7FA",
                  borderRadius: "15px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Grid item xs={3} sx={{ padding: "0 !important" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start"
                    }}
                  >
                    <img src="/assets/image/car.png" alt="bedrooms" />
                    <Typography
                      variant="body2"
                      sx={{
                        paddingLeft: "8px",
                        color: "#4D4D4D",
                        fontSize: "16px"
                      }}
                    >
                      {filteredPaymentSchedule[0]?.property?.property_model
                        ?.beds_count === 0
                        ? "Studio"
                        : filteredPaymentSchedule[0]?.property?.property_model
                            ?.beds_count +
                          " " +
                          "bedrooms"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={3} sx={{ padding: "0 !important" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start"
                    }}
                  >
                    <img src="/assets/image/floor.png" alt="bathroomsfloor" />
                    <Typography
                      variant="body2"
                      sx={{
                        paddingLeft: "8px",
                        color: "#4D4D4D",
                        fontSize: "16px"
                      }}
                    >
                      {
                        filteredPaymentSchedule[0]?.property?.property_model
                          ?.baths_count
                      }{" "}
                      bathrooms
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={3} sx={{ padding: "0 !important" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <img src="/assets/image/thirdfloor.png" alt="thirdfloor" />
                    <Typography
                      variant="body2"
                      sx={{
                        paddingLeft: "8px",
                        color: "#4D4D4D",
                        fontSize: "16px"
                      }}
                    >
                      {filteredPaymentSchedule[0]?.property?.floor_number} floor
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={3} sx={{ padding: "0 !important" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start"
                    }}
                  >
                    <img src="/assets/image/squarefeet.png" alt="squarefeet" />
                    <Typography
                      variant="body2"
                      sx={{
                        paddingLeft: "8px",
                        color: "#4D4D4D",
                        fontSize: "16px"
                      }}
                    >
                      {
                        filteredPaymentSchedule[0]?.property?.property_model
                          ?.sqft_area
                      }{" "}
                      square feet
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ m: 4 }}>
              <TableData
                rows={rows}
                columns={columns}
                width={817}
                HeaderbackgroundColor={"#F5F7FA"}
                tableHeaderAlign={"center !important"}
              />
            </Box>
            <Box sx={{ paddingLeft: "30px" }}>
              {property &&
                (selectAmount?.sum?.amount === null ? (
                  ""
                ) : (
                  <Box sx={{ display: "flex" }}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Amount Paid Till Date:{" "}
                    </Typography>
                    <Typography>
                      {`Rs. ${selectAmount?.sum?.amount?.toLocaleString(
                        "en-IN",
                        {
                          maximumFractionDigits: 2
                        }
                      )}`}
                    </Typography>
                  </Box>
                ))}
            </Box>
          </>
        </Box>
      </Box>
    </Container>
  );
};
