import React, { useEffect, useState } from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import TableData from "../../../components/TableData";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  paymentHistoryData,
  paymentHistoryDownload
} from "../../../lib/query/query";
import { toast } from "react-toastify";
import ErrorToast from "../../../components/ErrorToast";
import * as Sentry from "@sentry/react";

export const PaymentHistory = () => {
  const [paymentData, setPaymentData] = useState({});

  const { data, error: errorData } = useQuery(paymentHistoryData);

  const [download, { error: errorDownloadData }] =
    useLazyQuery(paymentHistoryDownload);

  const handleDownloadDataFile = async (data) => {
    const paymentDocumentId = data.row.document_id;

    const response = await download({
      variables: { document_id: paymentDocumentId }
    });
    const fetchedDocumentData = response.data;
    const output =
      fetchedDocumentData &&
      fetchedDocumentData.document &&
      fetchedDocumentData.document[0].document
        .replace(/\\x/g, "")
        .match(/.{2}/g)
        .map((byte) => String.fromCharCode(parseInt(byte, 16)))
        .join("");
    const hexString =
      output &&
      output
        .split(",")
        .map((hex) => parseInt(hex, 16))
        .map((byte) => byte.toString(16).padStart(2, "0"))
        .join("")
        .toUpperCase();
    const byteArray = new Uint8Array(
      hexString && hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );
    const blob = new Blob([byteArray], { type: "application/octet-stream" });
    const fileDownloadUrl = URL.createObjectURL(blob);
    const downloadLink = document.createElement("a");
    downloadLink.href = fileDownloadUrl;
    downloadLink.download = `${fetchedDocumentData?.document[0]?.file_name}.${fetchedDocumentData?.document[0]?.file_extension}`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(fileDownloadUrl);
  };

  useEffect(() => {
    if (data?.sale_transaction && data?.sale_transaction.length > 0) {
      setPaymentData(data?.sale_transaction);
    }
  }, [data]);

  const columns = [
    {
      field: "payment_date",
      headerName: "Payment Date",
      width: 150,
      flex: 1,
      align: "center"
    },
    {
      field: "amount",
      headerName: "Amount (INR)",
      width: 150,
      flex: 1,
      align: "center",
      renderCell: (params) => <span>{params?.value?.toLocaleString()}</span>
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      flex: 1,
      headerAlign: "left",
      align: "center",
      renderCell: (data) => {
        const status = data.row.status;
        return status === "Paid" ? (
          <Typography
            color={"#009262"}
            fontSize={12}
            padding={"3px 5px"}
            sx={{ backgroundColor: "#F1FBF8", borderRadius: "4px" }}
          >
            Paid
          </Typography>
        ) : status === "Verified" ? (
          <Typography
            color={"#E48900"}
            fontSize={12}
            padding={"3px 5px"}
            sx={{ backgroundColor: "#FFF8EC", borderRadius: "4px" }}
          >
            Verified
          </Typography>
        ) : (
          <Typography
            color={"#E48900"}
            fontSize={12}
            padding={"3px 5px"}
            sx={{ backgroundColor: "#FFF8EC", borderRadius: "4px" }}
          >
            Pending Validation
          </Typography>
        );
      }
    },
    {
      field: "project",
      headerName: "Project",
      width: 150,
      flex: 1
    },
    {
      field: "property",
      headerName: "Property",
      width: 150,
      flex: 1,
      align: "center"
    },
    {
      field: "receipt",
      headerName: "Receipt",
      width: 150,
      flex: 1,
      renderCell: (data) => {
        const receipt = data.row.receipt;
        return (
          <Box display={"flex"} alignItems={"center"}>
            <img src="/assets/icons/File.svg" alt="3bedrooms" />
            <Typography color={"#4D4D4D"} fontSize={14}>
              {receipt}
            </Typography>
          </Box>
        );
      }
    },
    {
      field: "action",
      headerName: "",
      sortable: false,
      flex: 1,
      headerAlign: "right",
      align: "right",
      renderCell: (data) => {
        const handleDownloadClick = async () => {
          await handleDownloadDataFile(data);
        };

        return (
          <>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "white",
                color: "#0671E0",
                textTransform: "capitalize",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "20px",
                marginRight: "20px",
                "&:hover": {
                  backgroundColor: "white"
                }
              }}
              onClick={handleDownloadClick}
            >
              Download
            </Button>
          </>
        );
      }
    }
  ];

  let DocumenstData = [];

  paymentData.length > 0 &&
    paymentData?.forEach((element) => {
      if (element.payment_histories.length > 0) {
        const propertyData = element.property;
        const historyWithPropertyData = element.payment_histories.map(
          (document) => ({
            ...document,
            property: propertyData
          })
        );
        DocumenstData.push(...historyWithPropertyData);
      }
    });

  const rows = DocumenstData.map((item) => {
    const paymentDate = item?.payment_date ? new Date(item.payment_date) : null;
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    return {
      id: item.payment_history_id,
      status: item?.status,
      amount: item?.amount?.toLocaleString("en-IN", {
        maximumFractionDigits: 2
      }),
      payment_date: item?.payment_date,
      receipt: `Receipt #${item.payment_history_id
        .toString()
        .padStart(4, "0")}-${
        monthNames[paymentDate.getMonth()]
      } ${paymentDate.getFullYear()}`,
      payment_history_id: item.payment_history_id,
      document_id: item.document_id,
      project:
        item.property === null
          ? "Yet to select"
          : `${item?.property?.project?.project_name},${item?.property?.project?.city}`,
      property:
        item.property === null
          ? "Yet to select"
          : `${item?.property?.project_building?.tower_name}-${item?.property?.unit_number}`
    };
  });

  if (errorData || errorDownloadData) {
    toast.error(
      <ErrorToast
        email={"info@msvkanha.com"}
        errorMsg={
          (errorData && errorData?.message) ||
          (errorDownloadData && errorDownloadData?.message)
        }
      />
    );
    Sentry.captureException(
      (errorData && errorData?.message) ||
        (errorDownloadData && errorDownloadData?.message)
    );
  }

  return (
    <Container>
      <Box>
        <Box mb={"45px"}>
          <Typography
            sx={{
              color: "#212121",
              fontSize: "25px",
              fontWeight: 600,
              lineHeight: "30px"
            }}
          >
            Payment History
          </Typography>
        </Box>
        <Box>
          <TableData
            rows={rows}
            columns={columns}
            HeaderbackgroundColor={"#F5F7FA"}
            tableHeaderAlign={"center !important"}
            noDataFound={"No Payment Records found"}
          />
        </Box>
      </Box>
    </Container>
  );
};
