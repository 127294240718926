import React from 'react'
import TabLayout from '../../components/Layouts/TabLayout'

export const Payments = () => {
  return (
    <TabLayout>
      <div>Payments</div>
    </TabLayout>
  )
}

export default Payments