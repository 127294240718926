import React from 'react'
import { Box, Typography } from '@mui/material'
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const BackButton = ({ handleBack }) => {
    return (
        <Box sx={{ display: "flex", alignItems: "center", cursor: 'pointer' }} onClick={handleBack}>
            <KeyboardBackspaceIcon
                sx={{ fontSize: "18px", color: "#717171" }}
            />
            <Typography
                sx={{
                    display: "inline-block",
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#717171",

                    lineHeight: "18px",
                    paddingLeft: "8px"
                }}
            >
                Back
            </Typography>
        </Box>
    )
}

export default BackButton