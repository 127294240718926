import * as React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup
} from "@mui/material";
import ModalCommon from "../../../components/ModalCommon";
import { useMutation, useQuery } from "@apollo/client";
import { ConfirmCancellationModal } from "../../../lib/mutation/mutation";
import { Link, useNavigate, useParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { saleTransactionStatus } from "../../../lib/query/query";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";
import ErrorToast from "../../../components/ErrorToast";

export function CancelModal({ open, setOpen }) {
  const [ConfirmCancellation, { error: errorCabcellation }] = useMutation(
    ConfirmCancellationModal
  );

  const { salesId } = useParams();

  const { data: amountStatus, error: errorAmount } = useQuery(
    saleTransactionStatus,
    {
      variables: { sale_transaction_id: salesId }
    }
  );

  const navigate = useNavigate();

  const handleConfirem = async () => {
    await ConfirmCancellation({
      variables: {
        buyer_sale_cancel_id: salesId,
        fee_agreement_flag: true,
        sale_transaction_id: salesId,
        terms_agreement_flag: true
      }
    })
      .then((response) => {})
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });

    navigate(`/cancellationPage/${salesId}`);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleBack = () => {
    setOpen(false);
    navigate(`/myProperties/allStates/${salesId}`);
  };

  if (errorCabcellation || errorAmount) {
    toast.error(
      <ErrorToast
        email={"info@msvkanha.com"}
        errorMsg={
          (errorCabcellation && errorCabcellation?.message) ||
          (errorAmount && errorAmount?.message)
        }
      />
    );
    Sentry.captureException(
      (errorCabcellation && errorCabcellation?.message) ||
        (errorAmount && errorAmount?.message)
    );
  }

  const Header = () => {
    return (
      <DialogTitle
        sx={{
          fontWeight: 600,
          color: "#212121",
          fontSize: "20px",
          lineHeight: "23px",
          p: 0
        }}
      >
        Confirm Cancellation
      </DialogTitle>
    );
  };
  const Body = () => {
    return (
      <>
        <Box sx={{ padding: "30px 0" }}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              sx={{
                color: "#717171",
                fontWeight: 400,
                lineHeight: "20px",
                fontSize: "14px"
              }}
              label={
                <>
                  I understand & acknowledge that a cancellation fee of Rs.{" "}
                  {amountStatus?.sale_transaction[0]?.property?.cancellation_fee?.toLocaleString(
                    "en-IN",
                    {
                      maximumFractionDigits: 2
                    }
                  )}{" "}
                  will be lievied.
                </>
              }
            />
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              sx={{
                color: "#717171",
                fontWeight: 400,
                lineHeight: "20px",
                fontSize: "14px"
              }}
              label={
                <>
                  I agree and understand to the cancellation{" "}
                  <span style={{ color: "#0671E0" }}>
                    <Link to="/termsCondition">Terms & Conditions.</Link>
                  </span>
                </>
              }
            />
          </FormGroup>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "white",
                color: "black",
                textTransform: "capitalize",
                fontSize: "18px",
                padding: "9px 38px",
                border: "1px solid #D0D5DD",
                borderRadius: "4px",
                "&:hover": {
                  backgroundColor: "white"
                },
                fontWeight: 400,
                marginTop: "20px",
                marginRight: "25px",
                boxShadow: "none"
              }}
              onClick={handleBack}
            >
              <KeyboardBackspaceIcon
                sx={{ fontSize: "18px", color: "#717171", paddingRight: "5px" }}
              />
              Back
            </Button>

            <Button
              variant="contained"
              sx={{
                backgroundColor: "#E02B1D",
                color: "white",
                textTransform: "capitalize",
                fontSize: "18px",
                padding: "9px 38px",
                float: "right",
                "&:hover": {
                  backgroundColor: "#E02B1D"
                },

                fontWeight: 400,
                marginTop: "20px"
              }}
              onClick={handleConfirem}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <Container>
      <ModalCommon
        open={open}
        setOpen={setOpen}
        closeModal={handleClose}
        header={<Header />}
        body={<Body />}
        justifyContent={"space-between"}
        height={300}
        width={688}
      />
    </Container>
  );
}
