// export const dashboardTab = [
//   {
//     label: "Dashboard",
//     path: "/admin/dashboard",
//     id: 0
//   },
//   {
//     label: "ID Verification",
//     path: "/admin/dashboardIdVerification",
//     id: 1
//   },
//   {
//     label: "Bookings",
//     path: "/admin/bookings",
//     id: 2
//   },
//   {
//     label: "Cancellation",
//     path: "/admin/cancellation",
//     id: 3
//   },
  
//   {
//     label: "Transaction Search",
//     path: "/admin/transactionSearch",
//     id: 4
//   },
//   {
//     label: "Reports",
//     path: "/admin/reports",
//     id: 5
//   }
// ];

export const dashboardTab = [
  
  {
    label: "ID Verification",
    path: "/admin/dashboardIdVerification",
    id: 0
  },
  {
    label: "Bookings",
    path: "/admin/bookings",
    id: 1
  },
  {
    label: "Cancellation",
    path: "/admin/cancellation",
    id: 2
  },
  
  {
    label: "Transaction Search",
    path: "/admin/transactionSearch",
    id: 3
  },
  {
    label: "Reports",
    path: "/admin/reports",
    id: 4
  }
];



