import { useMutation, useQuery } from "@apollo/client";
import { createContext, useEffect, useState } from "react";
import { stepperData } from "../query/query";
import { familyMemberstatusSave } from "../mutation/mutation";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";
import ErrorToast from "../../components/ErrorToast";

const initialState = {
  events: []
};

const Steppercontext = createContext({ ...initialState });

export default Steppercontext;

export const StepperProvider = (props) => {
  const [events, setEvents] = useState([]);
  const [salesId, setSalesId] = useState();
  const [searchData, setSearchData] = useState({});
  const [selectTexts, setSelectTexts] = useState("");
  const [userData, setUserData] = useState({});
  const { loading, data, refetch, error } = useQuery(stepperData, {
    variables: { customer_id: 1, sale_transaction_id: salesId },
    skip: !salesId
  });
  const [familyMemberSaveStatus, { error: erroeSaveStatus }] = useMutation(
    familyMemberstatusSave
  );

  useEffect(() => {
    if (data) {
      const sortedStatuses = [
        ...data?.sale_transaction[0]?.sale_transaction_statuses
      ].sort((a, b) => {
        return a.step_index - b.step_index;
      });

      setEvents({
        ...data,
        sale_transaction: [
          {
            ...data.sale_transaction[0],
            sale_transaction_statuses: sortedStatuses
          }
        ]
      });
    }
  }, [data]);

  const handleStatus = async () => {
    await familyMemberSaveStatus({
      variables: {
        sale_transaction_id: salesId,
        status: "In Progress",
        step_index: 3
      }
    })
      .then((response) => {
        refetch();
      })
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });
  };

  if (error || erroeSaveStatus) {
    toast.error(
      <ErrorToast
        email={"info@msvkanha.com"}
        errorMsg={
          (error && error?.message) ||
          (erroeSaveStatus && erroeSaveStatus?.message)
        }
      />
    );
    Sentry.captureException(
      (error && error?.message) || (erroeSaveStatus && erroeSaveStatus?.message)
    );
  }

  const { children } = props;

  return (
    <Steppercontext.Provider
      value={{
        events,
        setSalesId,
        loading,
        refetch,
        handleStatus,
        searchData,
        setSearchData,
        selectTexts,
        setSelectTexts,
        userData,
        setUserData
      }}
    >
      {children}
    </Steppercontext.Provider>
  );
};
