import React from "react";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { Box } from "@mui/material";

const TableData = ({
  initialSelectedRow,
  rows,
  columns,
  headerColor,
  width,
  backgroundColor,
  HeaderbackgroundColor,
  tableHeaderAlign,
  handleOnCellClick,
  rowSelectionModel,
  hideHeaderFooter,
  customFooter,
  noDataFound,
  rowPerPage,
  overFlow,
  getRowHeight,
}) => {
  const style = {
    width: width,
    borderRadius: "8px",
    backgroundColor: backgroundColor,
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: HeaderbackgroundColor
    },
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      display: "flex",
      justifyContent: tableHeaderAlign
    },
    "& .MuiDataGrid-columnHeaderTitle":{
      textOverflow:'unset',
      overFlow:'unset',
      whiteSpace:'unset',
      textAlign:'center',
      textWrap:'wrap',
      lineHeight:'17px'
    }

  };

  const cellPaddingStyle = overFlow ? { py :1} : {};

  return (
    <Box sx={style}>
      {hideHeaderFooter ? (
        <DataGrid
          rows={rows}
          columns={columns}
          rowSelectionModel={rowSelectionModel}
          onCellClick={handleOnCellClick}
          disableRowSelectionOnClick={true}
          headerColor={headerColor}
          disableColumnMenu={true}
          slots={{
            columnHeaders: () => null,
            footer: customFooter
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          sx={{
            ".MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within":
            {
              outline: "none !important"
            },
            "& .MuiDataGrid-virtualScroller": {
              maxHeight: "150px",
              "&::-webkit-scrollbar": {
                width: "0.4em"
              },
              "&::-webkit-scrollbar-track": {
                background: "transparent"
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#C1C7DE",
                borderRadius: "8px"
              }
            },
            borderRadius: "8px",
            minHeight: "200px"
          }}
        />
      ) : (
        <DataGrid
          rows={rows}
          columns={columns}
          rowSelectionModel={rowSelectionModel}
          onCellClick={handleOnCellClick}
          disableRowSelectionOnClick={true}
          headerColor={headerColor}
          disableColumnMenu={true}
          getRowHeight={getRowHeight}
          slots={{
            noRowsOverlay: () => (
              <div
                className="MuiDataGrid-overlay css-1w53k9d-MuiDataGrid-overlay"
                style={{ textAlign: "center" }}
              >
                {noDataFound ? noDataFound : "No inventory found"}
              </div>
            )
          }}
          initialState={{
            pagination: {
              paginationModel: {
                page: 0,
                pageSize: rowPerPage ? rowPerPage : 5
              }
            }
          }}
          pageSizeOptions={
            rowPerPage ? [rowPerPage, rowPerPage * 2] : [5, 10, 25, 50, 100]
          }
          sx={{
            ".MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within":
            {
              outline: "none !important"
            },
            ".MuiDataGrid-overlayWrapper": {
              minHeight: "150px"
            },
            borderRadius: "8px",
            minHeight: "200px",
            "& .MuiDataGrid-columnHeaderTitle": {
              color: headerColor
            },
            "& .MuiDataGrid-overlayWrapperInner": {
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            },
            [`& .${gridClasses.cell}`]: cellPaddingStyle 
          }}
        />
      )}
    </Box>
  );
};

export default TableData;
