import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export function VerticalChart({
  totalAmountArray,
  chartData,
  totalDueAmountArray,
  flattenedArray,
}) {

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value, index, values) {
            const formattedValue = new Intl.NumberFormat("en-IN", {
              currency: "INR",
              maximumFractionDigits: 0
            }).format(value);

            return "INR " + formattedValue;
          },
          stepSize: 100000,
          suggestedMin: 100000,
          maxTicksLimit: 5
        },
        stacked: true
      },
      x: {
        stacked: true
      }
    },
    hover: false,

    plugins: {
      tooltip: {
        enabled: true,
        mode: "point",
        displayColors: false,
        callbacks: {
          label: function (context) {
            const formattedValue = new Intl.NumberFormat("en-IN", {
              // style: 'currency',
              currency: "INR",
              maximumFractionDigits: 0
            }).format(context.parsed.y);

            return "INR " + formattedValue;
          },
          title: function () {
            return "";
          }
        }
      },
      legend: {
        display: false
      }
    }
  };

  let data2 = [];

  chartData.map((item) => {
    const filterBuildingId = flattenedArray.filter(
      (item1) => item1.building_id === item.building_id
    );
    data2.push({ filterBuildingId, ...item });
  });

  const data = {
  
     labels: data2.map((item) => {
      const towerName = item.tower_name;
      const filterData = item.filterBuildingId.map((item1) => `${item1.model_name} : ${item1.model_count}`);
      return [towerName, ...filterData];
    }),
    datasets: [
      {
        label: "Dataset 1",
        data: totalAmountArray,
        backgroundColor: "rgba(167, 205, 243, 1)",
        borderWidth: 1
      },
      {
        label: "Dataset 2",
        data: totalDueAmountArray,
        backgroundColor: "#f0f6fc",
        borderWidth: 1
      }
    ]
  };

  return <Bar options={options} data={data} width={400} height={200} />;
}
