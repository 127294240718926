import React from 'react'
import ChartCommon from '../../../components/ChartCommon';
import { Box, Grid, Typography } from '@mui/material';
import ChartBoxData from '../../../components/ChartBoxData';

export const Installments = () => {
    const installmentsData = [
        {
            value: 5,
            label: "Coming Due(Next Month)"
        },
        {
            value: 2,
            label: "Overdue(>60 days)"
        }
    ];

    return (
        <Box sx={{pt:3}}>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "20px",
            lineHeight: "23px",
            color: "#212121",
            paddingBottom: "20px",
            paddingTop: "8px",
          }}
        >
         Installments{" "}
        </Typography>
  
        <Box sx={{ display: "flex", justifyContent: "space-between", height:'225px' }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Box>
                <ChartBoxData chartBoxData={installmentsData}/>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                sx={{
                 
                  height: "225px",
                  borderRadius: "10px",
                  border: "1px solid #E8E8EA",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "28px",
                    color: "#212121",
                    padding: "5px 5px 0px 5px",
                  }}
                >
                Installments This Month
                </Typography>
                <hr />
                <Box sx={{ padding: "0 20px " }}>
                  <ChartCommon />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                sx={{
                 
                  height: "225px",
                  borderRadius: "10px",
                  border: "1px solid #E8E8EA",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "28px",
                    color: "#212121",
                    padding: "5px 5px 0px 5px",
                  }}
                >
                  Cancelations (Quarterly)
                </Typography>
                <hr />
                <Box sx={{ padding: "0 20px " }}>
                  <ChartCommon />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    )
}
