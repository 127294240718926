import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import Router from "./routes";
import useToken from "./hook/useToken";
import { useSelector } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import { ProfileName } from "./lib/query/query";
import AdminRouter from "./routes/admin";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from "@mui/material";
import * as Sentry from "@sentry/react";
import Steppercontext from "./lib/context/contextData";
import { toast } from "react-toastify";
import ErrorToast from "./components/ErrorToast";

function App() {
  const [open, setOpen] = useState();
  const [tokenDeatils, setTokenDeatils] = useState({});
  const [userRole, setUserRole] = useState();
  const { token, tokenExpireModel, setTokenExpireModel } = useToken();

  const [getUserDetails, { data: userDetails, error }] =
    useLazyQuery(ProfileName);
  // const { data: userDetails, refetch, error } = useQuery(ProfileName);

  const { setUserData } = useContext(Steppercontext);

  const isTokenValid = useSelector((state) => state.auth.isTokenValid);

  useEffect(() => {
    // refetch();
    if (userDetails) {
      setUserRole(userDetails?.users[0]?.role || "user");
      setUserData(userDetails);
      Sentry.setUser({
        email: userDetails.users[0].email ?? '',
        username:
          userDetails.users[0].first_name + " " + userDetails.users[0].last_name
      });
    }
  }, [userDetails]);


  useEffect(() => {
    if (token) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [token]);


  let loginUrl;
  if (typeof window !== "undefined") {
    loginUrl = `${process.env.REACT_APP_LOGIN_URL}/?response_type=token&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`;
  }

  if (error?.graphQLErrors[0]?.extensions?.code === "invalid-jwt") {
    // Redirect to the login page
    window.location.href = loginUrl;
  }


  useEffect(() => {
    if (token && token !== '') {
      getUserDetails();
    }
  }, [token]);
  

  if (error) {
    toast.error(
      <ErrorToast
        email={"info@msvkanha.com"}
        errorMsg={error && error?.message}
      />
    );
    Sentry.captureException(error);
  }

  useEffect(() => {
    if (!isTokenValid && open) {
      window.location.href = loginUrl;
    }
  }, [isTokenValid, open]);

  useEffect(() => {
    switch (isTokenValid) {
      case true:
        break;
      case false:
        window.location.href = loginUrl;
        break;
      default:
    }
  }, [isTokenValid]);

  useEffect(() => {
    const tokenData = localStorage.getItem("cognitoAuthDetails");
    if (tokenData) {
      setTokenDeatils(JSON.parse(tokenData));
    }
  }, []);

  const handleClose = () => {
    setTokenExpireModel(false);
  };

  return (
    <>
      {isTokenValid ? (
        <>
          {userRole === "user" ? (
            <Router />
          ) : userRole === "ops" ? (
            <AdminRouter />
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
      <Dialog
        open={tokenExpireModel}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Token Expiration Warning
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your authentication token will expire in 2 minutes. Please take
            action to refresh or renew your token to avoid disruption of
            service.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default App;
