import React, { useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Steppercontext from "../lib/context/contextData";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

export default function HorizontalStepper({
  horizontalData,
  activeStep,
  setActiveStep,
  completed,
  setCompleted,
  data,
  isEdit,
  isEditIdentity
}) {

  const { events } = useContext(Steppercontext);

  const isStatus =
    events &&
    events.sale_transaction &&
    events.sale_transaction[0]?.sale_transaction_statuses[0].status ===
      "Action Required";

  useEffect(() => {
    if (data && data.buyer && data.buyer.length > 0) {
      const buyerObject = data.buyer[0];
      const buyerKeys = Object.keys(buyerObject);

      let validKeyCount = 0;
      for (const key of buyerKeys) {
        if (
          buyerObject[key] !== undefined &&
          buyerObject[key] !== null &&
          buyerObject[key] !== ""
        ) {
          validKeyCount++;
        }
        if (validKeyCount >= 13 && validKeyCount <= 14) {
          setCompleted(Array(2).fill(true));
        } else if (validKeyCount >= 15) {
          setCompleted(Array(horizontalData.length).fill(true));
        }
      }
    }
  }, [data]);

  const LensIcon = (props) => {
    return (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="20"
          cy="14"
          r="12"
          fill={isEdit && props.isSecondStep ? "#009262" : "#E02B1D"}
        />
      </svg>
    );
  };

  const StepIcon = ({ label, color, textColor, isSecondStep }) => (
    <div style={{ position: "relative" }}>
      <LensIcon color={color} isSecondStep={isSecondStep} />
      <div
        style={{
          color: textColor,
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          textAlign: "center",
          lineHeight: "24px"
        }}
      >
        {label}
      </div>
    </div>
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        nonLinear
        activeStep={activeStep}
        alternativeLabel
        sx={{ marginBottom: "30px" }}
      >
        {horizontalData.map((labeldata, index) => {
          const isSecondStep = index === 1;
          const isThirdStep = index === 2;
          return (
            <Step
              key={index}
              completed={completed[index]}
              sx={{
                "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                  {
                    color: "#009262" // Just text label (COMPLETED)
                  },
                "& .MuiStepLabel-root .MuiSvgIcon-root": {
                  color: "#EEF5FC"
                },
                "& .MuiStepLabel-root .Mui-active": {
                  color: "#0671E0"
                },
                "& .MuiStepLabel-root .MuiStepIcon-text": {
                  fill: "#ABBED1"
                },
                "& .MuiStepLabel-root  .Mui-active .MuiStepIcon-text": {
                  fill: "#fff"
                },
                "&  .MuiStepConnector-root  .MuiStepConnector-line": {
                  borderTopWidth: "5px",
                  borderTopColor: "#E8E8EA"
                },
                "& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line":
                  {
                    borderTopWidth: "5px",
                    borderTopColor: isEdit
                      ? "#009262"
                      : isStatus
                      ? "red"
                      : "#009262"
                  },
                "& .MuiStepConnector-root.Mui-active .MuiStepConnector-line": {
                  borderTopWidth: "5px",
                  borderTopColor: "#009262"
                },
                "&.MuiStep-root:first-child": {
                  "& .MuiStepLabel-root .Mui-completed": {
                    color: "#009262 !important" // circle color (COMPLETED)
                  }
                },
                [`&.MuiStep-root:nth-child(${index + 1})`]: {
                  "& .MuiStepLabel-root .Mui-completed": {
                    color:
                      (index === 1 && isEdit) || !isStatus
                        ? "#009262"
                        : index === 1
                        ? "red"
                        : index === 2 && isEditIdentity
                        ? "#009262"
                        : index === 2
                        ? "red"
                        : "#009262"
                  }
                }
              }}
            >
              {isStatus && (isSecondStep || isThirdStep) ? (
                <StepButton
                  color="inherit"
                  icon={
                    <StepIcon
                      label={
                        <PriorityHighIcon
                          style={{ fontSize: "17px", fontweight: 400 }}
                        />
                      }
                      color={
                        isEdit && isThirdStep
                          ? "red"
                          : isEdit
                          ? "#009262"
                          : isStatus
                          ? "red"
                          : "#009262"
                      }
                      textColor={"white"}
                      isSecondStep={isSecondStep}
                    />
                  }
                >
                  {labeldata.label}
                </StepButton>
              ) : isStatus && (!isSecondStep || !isThirdStep) ? (
                <StepButton color="inherit">{labeldata.label}</StepButton>
              ) : (
                <StepButton color="inherit">{labeldata.label}</StepButton>
              )}
            </Step>
          );
        })}
      </Stepper>
      <div>
        <Box
          sx={{
            borderRadius: "10px",
            border: "1px solid #E8E8EA",
            padding: "30px",
            marginBottom: "50px"
          }}
        >
          {horizontalData.map((mapp, index) => (
            <Box key={index}>
              {index === activeStep ? (
                <Box sx={{ mt: 2, mb: 1, py: 1 }}>{mapp.step}</Box>
              ) : (
                ""
              )}
            </Box>
          ))}
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
          </Box>
        </Box>
      </div>
    </Box>
  );
}
