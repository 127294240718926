import React from 'react'
import { Box,Grid,Typography } from '@mui/material'

const AccountDetails = ({ bankAccData }) => {
    return (
        <Box
            sx={{
                display: "flex",
                border: "1px solid #E48900",
                borderRadius: "10px",
                backgroundColor: "#FFF8EC",
                width: "650px !important",
                height: "198px",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "20px",
                mt: "20px"
            }}
        >
            <Grid container spacing={2} sx={{ pt: "10px" }}>
                <Grid item xs={4}>
                    <Box
                        sx={{
                            color: "#212121",
                            fontSize: "18px",
                            lineHeight: "28px",
                            fontWeight: 500
                        }}
                    >
                        <Typography sx={{ pb: "10px" }}>Bank Name</Typography>
                        <Typography sx={{ pb: "10px" }}>Branch Name</Typography>
                        <Typography sx={{ pb: "10px" }}>Account Name</Typography>
                        <Typography sx={{ pb: "10px" }}>Account Number</Typography>
                        <Typography>IFS Code</Typography>
                    </Box>
                </Grid>
                <Grid item xs={8}>
                    <Box
                        sx={{
                            color: "#858482",
                            fontSize: "18px",
                            lineHeight: "28px",
                            fontWeight: 400
                        }}
                    >
                        <Typography sx={{ pb: "10px" }}>
                            {bankAccData?.bank_name}
                        </Typography>
                        <Typography sx={{ pb: "10px" }}>
                            {bankAccData?.branch_name}, {bankAccData?.city}
                        </Typography>
                        <Typography sx={{ pb: "10px" }}>
                            {/* {`MEERA SHANTIVANAM PVT LTD PHASE 6 MCA`} */}
                            {bankAccData?.bank_acct_name === null ? "N/A" : bankAccData?.bank_acct_name}
                        </Typography>
                        <Typography sx={{ pb: "10px" }}>
                            {bankAccData?.account_number}
                        </Typography>
                        <Typography>{bankAccData?.ifs_code}</Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default AccountDetails
