import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  CircularProgress
} from "@mui/material";
import TableData from "../../../components/TableData";
import { BookingRequestsTable } from "../../../lib/query/query";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import moment from "moment";
import { toast } from "react-toastify";
import ErrorToast from "../../../components/ErrorToast";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import RefreshIcon from "@mui/icons-material/Refresh";
import { successToast } from "../../../pages/Toasts/showToasts";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import * as Sentry from "@sentry/react";

export const BookingRequests = () => {
  const [BookingRequest, setBookingRequest] = useState({});

  const { refetch, data, error, loading } = useQuery(BookingRequestsTable, {
    variables: { status: "Pending Validation", step_index: 2, }
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      setBookingRequest(data.sale_transaction);
    }
  }, [data]);


  if (error) {
    toast.error(
      <ErrorToast
        email={"info@msvkanha.com"}
        errorMsg={error && error?.message}
      />
    );
    Sentry.captureException(error && error?.message);
  }

  const columns = [
    {
      field: "salesId",
      headerName: "Sales Id",
      width: 60,
      align: "center"
    },
    {
      field: "date",
      headerName: "Date",
      width: 92,
      align: "center"
    },
    {
      field: "name",
      headerName: "Name",
      width: 130,
      align: "center"
    },
    {
      field: "emailid",
      headerName: "Email ID",
      width: 160,
      align: "center"
    },
    {
      field: "project",
      headerName: "Project Name",
      width: 120,
      align: "center"
    },
    {
      field: "unitNumber",
      headerName: "Unit Number",
      width: 110,
      align: "center"
    },
    {
      field: "city",
      headerName: "City",
      width: 140,
      align: "center"
    },
    {
      field: "state",
      headerName: "State",
      width: 150,
      align: "center"
    },
    {
      field: "country",
      headerName: "Country",
      width: 200,
      align: "center"
    },
    {
      field: "oci",
      headerName: "OCI",
      width: 60,
      align: "center"
    },
    {
      field: "street",
      headerName: "Street",
      width: 150,
      align: "center",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%"
          }}
        >
          <Typography
            sx={{
              maxWidth: "150px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              cursor: "pointer",
              fontSize: "14px",
              flexGrow: 1
            }}
            title={params.value}
          >
            {params.value}
          </Typography>
          {params.value ? (
            <FileCopyIcon
              sx={{ cursor: "pointer", marginLeft: "5px", height: "18px" }}
              onClick={(e) => handleCopy(e, params.value)}
            />
          ) : (
            "-"
          )}
        </Box>
      )
    },
    {
      field: "loanRequired",
      headerName: "Loan Required",
      width: 80,
      align: "center"
    }
  ];

  const handleCopy = (e, text) => {
    e.stopPropagation();
    navigator.clipboard.writeText(text);
    toast.success("Address copied to clipboard");
  };

  const rows =
    BookingRequest.length > 0 &&
    BookingRequest.slice()
      .sort((a, b) => moment(b.created_date).diff(moment(a.created_date)))
      .map((item) => {
        const createdDate = moment(item?.created_date).format("DD/MM/YYYY");
        return {
          id: item?.sale_transaction_id,
          buyerId: item?.buyers[0]?.buyer_id,
          salesId: item?.sale_transaction_id,
          propertyId: item?.property?.property_id,
          date: createdDate,
          name: `${item?.buyers[0] === null
            ? ""
            : item?.buyers[0]?.first_name + " " + item?.buyers[0]?.last_name
            }`,
          emailid: item?.buyers[0]?.email,
          project:
            item?.property === null
              ? "Yet to select"
              : item?.property?.project?.project_name,
          unitNumber: `${item.property === null
            ? "Yet to select"
            : item?.property?.project_building?.tower_name +
            " " +
            item?.property?.unit_number
            }`,
          city: item?.buyers[0]?.city,
          state: item?.buyers[0]?.state,
          country: item?.buyers[0]?.country,
          oci:
            item?.buyers[0]?.oci_number === ""
              ? "N"
              : item?.buyers[0]?.oci_number,
          street: item?.buyers[0]?.street,
          loanRequired: item?.bank_loan_flag ?? "-"
        };
      });

  const handleRowClick = (params) => {
    const rowData = params?.row;
    const buyerId = rowData?.buyerId;
    const salesId = rowData?.salesId;
    const propertyId = rowData?.propertyId;
    let url = `/admin/buyerProfile/${buyerId}/${salesId}`;
    if (propertyId !== undefined) {
      url += `/${propertyId}`;
    }
    navigate(url);
  };

  const date = {
    id: "Date:",
    buyerId: new Date()
  };

  const title = {
    id: "Title:",
    buyerId: "Booking Requests"
  };

  const time = {
    id: "Time:",
    buyerId: moment().format("hh:mm a")
  };

  const downloadExcel = () => {
    const sheet = XLSX.utils.json_to_sheet(
      (rows && [title, date, time, "", ...rows]) || [],
      { skipHeader: true }
    );
    let Heading = [
      [
        "Id",
        "Buyer Id",
        "Sales Id",
        "Property Id",
        "Date",
        "Name",
        "Email Id",
        "Project",
        "Unit Number",
        "City",
        "State",
        "Country",
        "OCI",
        "Street",
        "Loan Required"
      ]
    ];
    XLSX.utils.sheet_add_aoa(sheet, Heading, { origin: "A4" });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, sheet, "Sheet1");
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array"
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    });
    saveAs(blob, "BookingRequests.xlsx");
  };

  const handleRefresh = () => {
    refetch();
    successToast("Booking Requests refreshed successfully");
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ paddingLeft: "14px !important" }}>
        <BackButton handleBack={() => navigate(-1)} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "25px",
                lineHeight: "30px",
                color: "#212121",
                margin: "30px 0px 30px 0px"
              }}
            >
              Booking Requests
            </Typography>
          </Box>
          <RefreshIcon
            sx={{ cursor: "pointer", marginLeft: "5px" }}
            onClick={handleRefresh}
          />
          <Box sx={{ display: "flex", marginLeft: "auto" }}>
            <Button
              variant="outlined"
              sx={{
                backgroundColor: "white",
                color: "#0671E0",
                fontWeight: 400,
                lineHeight: "24px",
                textTransform: "capitalize",
                fontSize: "16px",
                "&:hover": {
                  backgrindColor: "white"
                },
                padding: "16px, 32px, 16px, 32px",
                width: "151px",
                height: "40px",
                gap: "10px",
                border: "1px solid #0671E0",
                textAlign: "Center"
              }}
              onClick={downloadExcel}
            >
              Download XLS
            </Button>
          </Box>
        </Box>
        {loading ? (
          <CircularProgress
            sx={{
              width: "40px !important",
              height: "40px !important",
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          />
        ) : (
          <>
            {!loading && rows.length > 0 && (
              <TableData
                rows={rows}
                columns={columns}
                HeaderbackgroundColor={"#F5F7FA"}
                tableHeaderAlign={"center !important"}
                handleOnCellClick={handleRowClick}
                rowPerPage={25}
              />
            )}
          </>
        )}
      </Box>
    </Container>
  );
};
