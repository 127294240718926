import { gql } from "@apollo/client";

export const propertiesData = gql`
  query MyQuery {
    sale_transaction {
      property_id
      sale_transaction_id
      property {
        project {
          project_name
          __typename
        }
        property_model {
          baths_count
          beds_count
          sqft_area
          model_name
          __typename
        }
        unit_number
        __typename
      }
      status
      __typename
    }
  }
`;

export const stepperData = gql`
  query MyQuery($sale_transaction_id: Int!) {
    sale_transaction(
      where: { sale_transaction_id: { _eq: $sale_transaction_id } }
    ) {
      sale_transaction_statuses {
        locked
        process_step
        sale_transaction_status_id
        status
        step_index
      }
      property {
        unit_number
        project_building {
          tower_name
        }
        project {
          city
          project_name
        }
      }
    }
  }
`;

export const Buyer_Data = gql`
  query MyQuery($sale_transaction_id: Int!) {
    buyer(where: { sale_transaction_id: { _eq: $sale_transaction_id } }) {
      buyer_id
      first_name
      last_name
      middle_name
      phone

      abhyasi_id
      state
      street
      zip
      residency_type
      city
      country
      citizenship
      buyer_identity_docs {
        buyer_identity_doc_id
        file_name
        file_extension
        document_type
        document_number
      }
      pan_number
      oci_number
      passport_number
      aadhar_number
    }
  }
`;

export const bookingLocationData = gql`
  query MyQuery {
    project(order_by: { city: asc }, distinct_on: city) {
      city
    }
  }
`;

export const bookingProjectData = gql`
  query MyQuery($city: String!) {
    project(
      order_by: {}
      distinct_on: project_name
      where: { city: { _eq: $city } }
    ) {
      project_id
      project_name
    }
  }
`;

export const projectTableData = gql`
  query MyQuery($sale_status: String!, $model_type: String!, $city: String!) {
    property(
      where: {
        sale_status: { _eq: $sale_status }
        property_model: { model_type: { _eq: $model_type } }
        project: { city: { _eq: $city } }
      }
    ) {
      building_id
      floor_number
      property_id
      unit_number
      property_model {
        baths_count
        beds_count
        sqft_area
        car_parking_count
        model_name
        model_id
        file_extension
        file_name
      }
      estimated_delivery
      project_building {
        file_extension
        file_name
        tower_name
        building_id
       
      }
         project {
          file_extension
          file_name
          project_name
          project_id
        }
      municipal_hold
    }
  }
`;

export const paymentScheduleData = gql`
  query MyQuery {
    sale_transaction(order_by: { sale_transaction_id: asc }) {
      property_id
      sale_transaction_id
      property {
        project {
          project_name
        }
        property_model {
          baths_count
          beds_count
          sqft_area
        }
        project_building {
          tower_name
          city
        }
        floor_number
        unit_number
      }
      status
    }
  }
`;

export const paymentScheduleTable = gql`
  query MyQuery($sale_transaction_id: Int!) {
    sale_transaction_payment_schedule(
      where: { sale_transaction_id: { _eq: $sale_transaction_id } }
      order_by: { due_date: asc }
    ) {
      amount
      due_date
      item
    }
  }
`;

export const paymentAmount = gql`
  query MyQuery($sale_transaction_id: Int!) {
    payment_history_aggregate(
      where: { sale_transaction_id: { _eq: $sale_transaction_id } }
    ) {
      aggregate {
        sum {
          sale_transaction_id
          amount
        }
      }
    }
  }
`;

export const legalHeirsData = gql`
  query MyQuery($sale_transaction_id: Int!) {
    legal_heir(where: { sale_transaction_id: { _eq: $sale_transaction_id } }) {
      age
      full_name
      id_number
      id_type
      legal_heir_id
      relationship_buyer
      sale_transaction_id
    }
  }
`;

export const paymentHistoryData = gql`
  query MyQuery {
    sale_transaction {
      payment_histories {
        payment_date
        amount
        payment_history_id
        status
        document_id
      }
      property {
        unit_number
        project_building {
          tower_name
        }
        project {
          project_name
          city
        }
      }
    }
  }
`;

export const paymentHistoryDownload = gql`
  query MyQuery($document_id: Int!) {
    document(where: { document_id: { _eq: $document_id } }) {
      file_extension
      file_name
      document
    }
  }
`;

export const PropertyRegistrationFile = gql`
  query MyQuery($document_type: String!) {
    document(
      where: {
        sale_transaction_id: { _is_null: true }
        document_type: { _eq: $document_type }
      }
    ) {
      file_extension
      file_name
      document_type
      document_id
    }
  }
`;
export const PropertyRegistrationFileDownload = gql`
  query MyQuery($document_id: Int!) {
    document(where: { document_id: { _eq: $document_id } }) {
      document
    }
  }
`;
export const CancellationAmount = gql`
  query MyQuery($sale_transaction_id: Int!) {
    payment_history_aggregate(
      where: { sale_transaction_id: { _eq: $sale_transaction_id } }
    ) {
      aggregate {
        sum {
          amount
        }
      }
    }
  }
`;
export const CancelationFee = gql`
  query MyQuery($sale_transaction_id: Int!) {
    sale_transaction(
      where: { sale_transaction_id: { _eq: $sale_transaction_id } }
    ) {
      sale_transaction_id
      property {
        property_id
        cancellation_fee
      }
    }
  }
`;

export const cancelationPreFillData = gql`
  query MyQuery($sale_transaction_id: Int!) {
    buyer_bank_account(
      where: { sale_transaction_id: { _eq: $sale_transaction_id } }
    ) {
      account_number
      account_type
      accountholder_name
      bank_name
      branch_name
      buyer_bank_account_id
      ifsc_code
      refund_agreement_flag
      sale_transaction_id
    }
  }
`;

export const DownloadCards = gql`
  query MyQuery {
    sale_transaction {
      sale_transaction_id
      property {
        unit_number
        project_building {
          tower_name
        }
        project {
          city
          project_name
        }
        property_id
      }
      documents {
        document_id
        document_type
        file_extension
        file_name
        sale_transaction_id
        created_date
      }
    }
  }
`;

export const DownloadCardsFile = gql`
  query MyQuery {
    sale_transaction {
      sale_transaction_id
      property {
        property_id
        unit_number
        project_building {
          tower_name
        }
        project {
          city
          project_name
        }
      }
      buyers {
        buyer_id
        buyer_identity_docs {
          document_type
          file_extension
          file_name
          buyer_identity_doc_id
          created_date
        }
      }
    }
  }
`;

export const BuyerTable = gql`
  query MyQuery($buyer_identity_doc_id: Int!) {
    buyer_identity_doc(
      where: { buyer_identity_doc_id: { _eq: $buyer_identity_doc_id } }
    ) {
      document
    }
  }
`;

export const DocumentTable = gql`
  query MyQuery($document_id: Int!) {
    document(where: { document_id: { _eq: $document_id } }) {
      document
    }
  }
`;

export const IdVerification = gql`
  query MyQuery {
    sale_transaction(
      where: {
        sale_transaction_statuses: {
          status: { eq: "Pending Validation" }
          step_index: { eq: 1 }
        }
      }
    ) {
      sale_transaction_statuses(
        where: { status: { eq: "Pending Validation" }, step_index: { eq: 1 } }
      ) {
        sale_transaction_status_id
        status
      }
      buyer {
        first_name
        last_name
        buyer_id
      }
      sale_transaction_id
    }
    sale_transaction_aggregate(
      where: {
        sale_transaction_statuses: {
          status: { eq: "Pending Validation" }
          step_index: { eq: 1 }
        }
      }
    ) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`;

export const IdBuyerInfo = gql`
  query MyQuery($buyer_id: Int!) {
    buyer(where: { buyer_id: { _eq: $buyer_id } }) {
      country
      first_name
      last_name
      middle_name
      phone
      state
      city
      citizenship
      zip
      buyer_id
      buyer_identity_docs {
        buyer_identity_doc_id
        document
        document_number
        document_type
        file_extension
        file_name
      }
    }
  }
`;

export const unitSize = gql`
  query MyQuery($city: String!) {
    property_model(
      distinct_on: model_type
      where: { properties: { project: { city: { _eq: $city } } } }
    ) {
      model_type
    }
  }
`;

export const downloadProject = gql`
  query myQuery($project_id: Int!) {
    project(where: { project_id: { _eq: $project_id } }) {
      file_extension
      file_name
      project_layout
      file_type
    }
  }
`;

export const downloadProjectBuilding = gql`
  query myQuery($building_id: Int!) {
    project_building(where: { building_id: { _eq: $building_id } }) {
      file_extension
      file_name
      building_floor_plan
      file_type
    }
  }
`;

export const downloadpropertyModel = gql`
  query myQuery($model_id: Int!) {
    property_model(where: { model_id: { _eq: $model_id } }) {
      unit_floor_plan
      file_extension
      file_name
      file_type
    }
  }
`;

export const paymentSchedule = gql`
  query myQuery($property_id: Int!) {
    property(where: { property_id: { _eq: $property_id } }) {
      property_payment_schedule_plan {
        property_payment_schedules(order_by: { due_days: asc }) {
          amount
          amount_percentage
          due_days
          payment_type
          property_payment_schedule_plan_id
        }
      }
    }
  }
`;

export const bankAccountDetails = gql`
  query MyQuery($property_id: Int!) {
    property_bank_acct(where: { property_id: { _eq: $property_id } }) {
      account_number
      bank_name
      branch_name
      bank_acct_name
      city
      ifs_code
      property_bank_acct_id
      property_id
    }
  }
`;

export const adjustmentsCredits = gql`
  query myQuery($property_id: Int!) {
    property_price(where: { property_id: { _eq: $property_id } }) {
      price_type
      price_amount
      property_price_id
      property_id
    }
    property_price_aggregate(where: { property_id: { _eq: $property_id } }) {
      aggregate {
        sum {
          price_amount
        }
      }
    }
  }
`;

export const discountsAdjustments = gql`
  query MyQuery($sale_transaction_id: Int!) {
    price_adjustment_aggregate(
      where: { sale_transaction_id: { _eq: $sale_transaction_id } }
    ) {
      aggregate {
        sum {
          adjustment_amount
        }
      }
      nodes {
        adjustment_reason
        adjustment_amount
        price_adjustment_id
      }
    }
  }
`;

export const bookPropertyRePrefill = gql`
  query MyQuery($sale_transaction_id: Int!) {
    sale_transaction(
      where: { sale_transaction_id: { _eq: $sale_transaction_id } }
    ) {
      property_id
      property {
      municipal_hold
        project {
          city
          project_name
          state
          file_extension
          file_name
          project_id
        }
        project_building {
          tower_name
          file_name
          file_extension
          building_id
        }
        property_model {
          baths_count
          beds_count
          car_parking_count
          model_name
          sqft_area
          file_name
          file_extension
          model_id
        }
        unit_number
        floor_number
        estimated_delivery
      }
      ack_booking_amount
      ack_first_installment
      ack_loan_responsibility
      ack_payment_sched
      bank_loan_flag
    }
  }
`;

export const ProfileName = gql`
  query MyQuery {
    users {
      first_name
      last_name
      role
      email
    }
  }
`;

export const termsCondition = gql`
  query MyQuery($page: String!) {
    static_html(where: { page: { _eq: $page } }) {
      content
    }
  }
`;

export const helpData = gql`
  query MyQuery($page: String!) {
    static_html(where: { page: { _eq: $page } }) {
      content
    }
  }
`;

export const kycInfoComment = gql`
  query MyQuery($sale_transaction_id: Int!) {
    buyer(where: { sale_transaction_id: { _eq: $sale_transaction_id } }) {
      kyc_verification_comment
      kyc_verification_date
      kyc_verification_status
    }
  }
`;

// **********************  Admin Queries  ************************** //

export const IdVerificationRequestTable = gql`
  query MyQuery($status: String!, $step_index: Int!) {
    sale_transaction(
      where: {
        sale_transaction_statuses: {
          status: { _eq: $status }
          step_index: { _eq: $step_index }
        }
        status: { _nin: ["Cancelled", "Completed", "Cancellation Requested"] }
      }
    ) {
      buyers {
        first_name
        last_name
        email
        buyer_id
        city
        country
        oci_number
        state
      }
      sale_transaction_id
      user {
        email
      }
      property {
        project {
          project_name
        }
        project_building {
          tower_name
        }
        unit_number
        property_id
      }
      created_date
      status
    }
  }
`;

export const CancellationRequestsTable = gql`
  query MyQuery($status: String!) {
    sale_transaction(where: { status: { _eq: $status } }) {
      buyers {
        first_name
        last_name
        email
        buyer_id
        city
        country
        oci_number
        state
      }
      sale_transaction_id
      user {
        email
      }
      property {
        project {
          project_name
        }
        project_building {
          tower_name
        }
        unit_number
        property_id
      }
      created_date
    }
  }
`;

export const BookingRequestsTable = gql`
  query MyQuery($status: String!, $step_index: Int!) {
    sale_transaction(
      where: {
        sale_transaction_statuses: {
          status: { _eq: $status }
          step_index: { _eq: $step_index }
        }
        status: { _nin: ["Cancelled", "Completed", "Cancellation Requested"] }
      }
    ) {
      buyers {
        first_name
        last_name
        email
        buyer_id
        city
        country
        oci_number
        state
        street
      }
      sale_transaction_id
      bank_loan_flag
      user {
        email
      }
      property {
        project {
          project_name
        }
        project_building {
          tower_name
        }
        unit_number
        property_id
      }
      property_id
      created_date
      status
    }
  }
`;

export const BuyerSearchDropDown = gql`
  query MyQuery {
    sale_transaction(where: {}) {
      buyers {
        first_name
        last_name
        buyer_id
        city
        country
        oci_number
        state
      }
      sale_transaction_id
      user {
        email
      }
      property {
        project {
          project_name
        }
        project_building {
          tower_name
        }
        unit_number
      }
    }
  }
`;

export const BuyerSearchDropDownPass = gql`
  query MyQuery($passport_number: String!) {
    sale_transaction(
      where: { buyers: { passport_number: { _ilike: $passport_number } } }
    ) {
      buyers {
        first_name
        last_name
        buyer_id
        city
        country
        oci_number
        state
      }
      sale_transaction_id
      user {
        email
      }
      property {
        project {
          project_name
        }
        project_building {
          tower_name
        }
        unit_number
        property_id
      }
    }
  }
`;

export const BuyerSearchDropDownFirst = gql`
  query MyQuery($first_name: String!) {
    sale_transaction(
      where: { buyers: { first_name: { _ilike: $first_name } } }
    ) {
      buyers {
        first_name
        last_name
        buyer_id
        city
        country
        oci_number
        state
      }
      sale_transaction_id
      user {
        email
      }
      property {
        project {
          project_name
        }
        project_building {
          tower_name
        }
        unit_number
        property_id
      }
    }
  }
`;

export const BuyerSearchDropDownLast = gql`
  query MyQuery($last_name: String!) {
    sale_transaction(where: { buyers: { last_name: { _ilike: $last_name } } }) {
      buyers {
        first_name
        last_name
        buyer_id
        city
        country
        oci_number
        state
      }
      sale_transaction_id
      user {
        email
      }
      property {
        project {
          project_name
        }
        project_building {
          tower_name
        }
        unit_number
        property_id
      }
    }
  }
`;

export const BuyerSearchDropDownEmail = gql`
  query MyQuery($email: String!) {
    sale_transaction(where: { user: { email: { _ilike: $email } } }) {
      buyers {
        first_name
        last_name
        buyer_id
        city
        country
        oci_number
        state
        __typename
      }
      sale_transaction_id
      user {
        email
        __typename
      }
      property {
        project {
          project_name
          __typename
        }
        project_building {
          tower_name
          __typename
        }
        unit_number
        property_id
        __typename
      }
      __typename
    }
  }
`;

export const BuyerSearchDropDownOci = gql`
  query MyQuery($oci_number: String!) {
    sale_transaction(
      where: { buyers: { oci_number: { _ilike: $oci_number } } }
    ) {
      buyers {
        first_name
        last_name
        buyer_id
        city
        country
        oci_number
        state
      }
      sale_transaction_id
      user {
        email
      }
      property {
        project {
          project_name
        }
        project_building {
          tower_name
        }
        unit_number
        property_id
      }
    }
  }
`;

export const BuyerSearchDropDownAbhyasi = gql`
  query MyQuery($abhyasi_id: String!) {
    sale_transaction(
      where: { buyers: { abhyasi_id: { _ilike: $abhyasi_id } } }
    ) {
      buyers {
        first_name
        last_name
        buyer_id
        city
        country
        oci_number
        state
      }
      sale_transaction_id
      user {
        email
      }
      property {
        project {
          project_name
        }
        project_building {
          tower_name
        }
        unit_number
        property_id
      }
    }
  }
`;

export const BuyerSearchDropDownAadhaar = gql`
  query MyQuery($aadhar_number: String!) {
    sale_transaction(
      where: { buyers: { aadhar_number: { _ilike: $aadhar_number } } }
    ) {
      buyers {
        first_name
        last_name
        buyer_id
        city
        country
        oci_number
        state
      }
      sale_transaction_id
      user {
        email
      }
      property {
        project {
          project_name
        }
        project_building {
          tower_name
        }
        unit_number
        property_id
      }
    }
  }
`;

export const BuyerSearchDropDownPan = gql`
  query MyQuery($pan_number: String!) {
    sale_transaction(
      where: { buyers: { pan_number: { _ilike: $pan_number } } }
    ) {
      buyers {
        first_name
        last_name
        buyer_id
        city
        country
        oci_number
        state
      }
      sale_transaction_id
      user {
        email
      }
      property {
        project {
          project_name
        }
        project_building {
          tower_name
        }
        unit_number
        property_id
      }
    }
  }
`;

export const BuyerSearchDropDownCity = gql`
  query MyQuery($city: String!) {
    sale_transaction(where: { buyers: { city: { _ilike: $city } } }) {
      buyers {
        first_name
        last_name
        buyer_id
        city
        country
        oci_number
        state
      }
      sale_transaction_id
      user {
        email
      }
      property {
        project {
          project_name
        }
        project_building {
          tower_name
        }
        unit_number
        property_id
      }
    }
  }
`;

export const BuyerSearchDropDownState = gql`
  query MyQuery($state: String!) {
    sale_transaction(where: { buyers: { state: { _ilike: $state } } }) {
      buyers {
        first_name
        last_name
        buyer_id
        city
        country
        oci_number
        state
      }
      sale_transaction_id
      user {
        email
      }
      property {
        project {
          project_name
        }
        project_building {
          tower_name
        }
        unit_number
        property_id
      }
    }
  }
`;

export const BuyerSearchDropDownCountry = gql`
  query MyQuery($country: String!) {
    sale_transaction(where: { buyers: { country: { _ilike: $country } } }) {
      buyers {
        first_name
        last_name
        buyer_id
        city
        country
        oci_number
        state
      }
      sale_transaction_id
      user {
        email
      }
      property {
        project {
          project_name
        }
        project_building {
          tower_name
        }
        unit_number
        property_id
      }
    }
  }
`;

export const BuyerSearchUnitNumber = gql`
  query MyQuery($unit_number: Int!, $tower_name: String!) {
    sale_transaction(
      where: {
        property: {
          unit_number: { _eq: $unit_number }
          project_building: { tower_name: { _like: $tower_name } }
        }
      }
    ) {
      buyers {
        first_name
        last_name
        buyer_id
        city
        country
        oci_number
        state
      }
      sale_transaction_id
      user {
        email
      }
      property {
        project {
          project_name
        }
        project_building {
          tower_name
        }
        unit_number
      }
      property_id
    }
  }
`;

export const BuyerSearchDropDownTransaction = gql`
  query MyQuery($id: Int!) {
    sale_transaction(where: { sale_transaction_id: { _eq: $id } }) {
      buyers {
        first_name
        last_name
        buyer_id
        city
        country
        oci_number
        state
      }
      sale_transaction_id
      user {
        email
      }
      property {
        project {
          project_name
        }
        project_building {
          tower_name
        }
        unit_number
        property_id
      }
    }
  }
`;

export const BuyerSearchDropDownStatus = gql`
  query MyQuery($status: String!) {
    sale_transaction(where: { status: { _ilike: $status } }) {
      buyers {
        first_name
        last_name
        buyer_id
        city
        country
        oci_number
        state
      }
      sale_transaction_id
      user {
        email
      }
      property {
        project {
          project_name
        }
        project_building {
          tower_name
        }
        unit_number
        property_id
      }
      status
    }
  }
`;

export const buyerProfileData = gql`
  query MyQuery($sale_transaction_id: Int!) {
    sale_transaction(
      where: { sale_transaction_id: { _eq: $sale_transaction_id } }
    ) {
      buyers {
        first_name
        middle_name
        last_name
        email
        city
        state
        country
        citizenship
        residency_type
        zip
        street
        phone
        verification_status
        buyer_identity_docs {
          document_number
          document_type
          buyer_identity_doc_id
          created_date
        }
        buyer_id
      }
      user {
        email
        id
      }
      property {
        unit_number
        project {
          project_name
          city
          state
          street
        }
        project_building {
          tower_name
          street
          state
          city
        }
        floor_number
        property_model {
          baths_count
          beds_count
          model_name
          sqft_area
          car_parking_count
        }
        municipal_hold
      }
      sale_transaction_statuses(order_by: { step_index: asc }) {
        step_index
        process_step
        status
      }
    }
  }
`;

export const AdminAdjustmentsCredits = gql`
  query myQuery($property_id: Int!) {
    property_price(where: { property_id: { _eq: $property_id } }) {
      price_type
      price_amount
      property_price_id
      property_id
    }
    property_price_aggregate(where: { property_id: { _eq: $property_id } }) {
      aggregate {
        sum {
          price_amount
        }
      }
    }
  }
`;

export const AdminDiscountsAdjustments = gql`
  query MyQuery($sale_transaction_id: Int!) {
    price_adjustment_aggregate(
      where: { sale_transaction_id: { _eq: $sale_transaction_id } }
    ) {
      aggregate {
        sum {
          adjustment_amount
        }
      }
      nodes {
        adjustment_reason
        adjustment_amount
        price_adjustment_id
      }
    }
  }
`;

export const AdminPaymentScheduleTable = gql`
  query MyQuery($sale_transaction_id: Int!) {
    sale_transaction_payment_schedule(
      where: { sale_transaction_id: { _eq: $sale_transaction_id } }
      order_by: { due_date: asc }
    ) {
      amount
      due_date
      item
    }
  }
`;

export const AdminPaymentHistoryData = gql`
  query MyQuery($sale_transaction_id: Int!) {
    sale_transaction(
      where: { sale_transaction_id: { _eq: $sale_transaction_id } }
    ) {
      payment_histories {
        payment_date
        amount
        payment_history_id
        status
        document_id
        __typename
      }
      property {
        unit_number
        project_building {
          tower_name
          __typename
        }
        project {
          project_name
          city
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const AdminEventLogData = gql`
  query MyQuery($sale_transaction_id: Int!) {
    event_log(
      where: { sale_transaction_id: { _eq: $sale_transaction_id } }
      order_by: { created_date: desc }
    ) {
      sale_transaction_id
      property_id
      event_type
      event_description
      created_date
      user_name
      user_role
      email
      new_value
      old_value
    }
  }
`;

export const AdminlegalHeirsData = gql`
  query MyQuery($sale_transaction_id: Int!) {
    legal_heir(where: { sale_transaction_id: { _eq: $sale_transaction_id } }) {
      age
      full_name
      id_number
      id_type
      legal_heir_id
      relationship_buyer
      sale_transaction_id
    }
  }
`;

export const AdminDocumentTable = gql`
  query MyQuery($document_id: Int!) {
    document(where: { document_id: { _eq: $document_id } }) {
      document
    }
  }
`;

export const assocProperties = gql`
  query MyQuery($id: String!) {
    sale_transaction(
      where: {
        user: { id: { _eq: $id } }
        property: { property_id: { _is_null: false } }
        buyers: { buyer_id: { _is_null: false } }
      }
    ) {
      user {
        email
        id
        first_name
        last_name
      }
      property {
        unit_number
        project {
          project_name
        }
        project_building {
          tower_name
          street
          state
          city
          zip
        }
        floor_number
        property_model {
          model_name
        }
      }
      buyers {
        first_name
        middle_name
        last_name
        city
        state
        country
        citizenship
        residency_type
        zip
        street
        phone
        verification_status
      }
      sale_transaction_id
    }
  }
`;

export const assocPropertiesBuyerID = gql`
  query MyQuery($document_type: String!, $document_number: String!) {
    buyer_identity_doc(
      where: {
        document_number: { _eq: $document_number }
        document_type: { _eq: $document_type }
      }
    ) {
      buyer_id
    }
  }
`;

export const assocPropertiesSetp2 = gql`
  query MyQuery($buyer_id: [Int!]) {
    sale_transaction(where: { buyers: { buyer_id: { _in: $buyer_id } } }) {
      buyers {
        first_name
        last_name
        citizenship
        residency_type
        street
        city
        state
        country
      }
      user {
        first_name
        last_name
        email
        id
      }
      property {
        property_id
        project_building {
          tower_name
          city
          state
        }
        floor_number
        unit_number
        project {
          project_name
        }
      }
      sale_transaction_id
    }
  }
`;

export const idVerificationPhoto = gql`
  query MyQuery($buyer_identity_doc_id: Int!) {
    buyer_identity_doc(
      where: { buyer_identity_doc_id: { _eq: $buyer_identity_doc_id } }
    ) {
      document
      file_extension
      file_name
    }
  }
`;

export const IdentityProofStatus = gql`
  query MyQuery($buyer_id: Int!) {
    buyer(where: { buyer_id: { _eq: $buyer_id } }) {
      kyc_verification_comment
      kyc_verification_date
      kyc_verification_staff
      kyc_verification_status
    }
  }
`;

export const saleTransactionStatus = gql`
  query MyQuery($sale_transaction_id: Int!) {
    sale_transaction(
      where: { sale_transaction_id: { _eq: $sale_transaction_id } }
    ) {
      sale_transaction_id
      property {
        property_id
        cancellation_fee
      }
      status
    }
  }
`;

export const bookPropertiesCancellationfee = gql`
  query MyQuery($sale_transaction_id: Int!) {
    sale_transaction(
      where: { sale_transaction_id: { _eq: $sale_transaction_id } }
    ) {
      sale_transaction_id
      property {
        property_id
        cancellation_fee
      }
    }
  }
`;

export const DownloadCardsAdmin = gql`
  query MyQuery($sale_transaction_id: Int!) {
    sale_transaction(
      where: { sale_transaction_id: { _eq: $sale_transaction_id } }
    ) {
      sale_transaction_id
      property {
        unit_number
        project_building {
          tower_name
          __typename
        }
        project {
          city
          project_name
          __typename
        }
        property_id
        __typename
      }
      documents {
        document_id
        document_type
        file_extension
        file_name
        sale_transaction_id
        created_date
        __typename
      }
      __typename
    }
  }
`;

export const DownloadCardsFileAdmin = gql`
  query MyQuery($sale_transaction_id: Int!) {
    sale_transaction(
      where: { sale_transaction_id: { _eq: $sale_transaction_id } }
    ) {
      sale_transaction_id
      property {
        property_id
        unit_number
        project_building {
          tower_name
        }
        project {
          city
          project_name
        }
      }
      buyers {
        buyer_id
        buyer_identity_docs {
          document_type
          file_extension
          file_name
          buyer_identity_doc_id
          created_date
        }
      }
    }
  }
`;

export const cashFlowCity = gql`
  query MyQuery {
    project(distinct_on: city) {
      city
    }
  }
`;

export const inventoryCity = gql`
  query MyQuery {
    project(distinct_on: city) {
      city
    }
  }
`;

export const cashFlowProject = gql`
  query MyQuery($city: String!) {
    project(where: { city: { _eq: $city } }) {
      project_id
      project_name
    }
  }
`;
export const inventoryProject = gql`
  query MyQuery($city: String!) {
    project(where: { city: { _eq: $city } }) {
      project_id
      project_name
    }
  }
`;

export const cashFlowTower = gql`
  query MyQuery($project_id: Int!) {
    project_building(where: { project_id: { _eq: $project_id } }) {
      building_id
      tower_name
    }
  }
`;

export const inventoryTower = gql`
  query MyQuery($project_id: Int!) {
    project_building(where: { project_id: { _eq: $project_id } }) {
      building_id
      tower_name
    }
  }
`;

export const cashFlowUniqueList = gql`
  query MyQuery {
    v_cash_flow(distinct_on: model_name) {
      model_id
      model_name
    }
  }
`;

export const cashFlowUniqueListTowers = gql`
  query MyQuery($city: String!, $project_name: String!) {
    v_cash_flow(
      distinct_on: tower_name
      where: { city: { _eq: $city }, project_name: { _eq: $project_name } }
    ) {
      tower_name
    }
  }
`;

export const inventoryUniqueList = gql`
  query MyQuery {
    v_inventory(distinct_on: model_name) {
      model_id
      model_name
    }
  }
`;

export const cashFlowTotal = gql`
  query MyQuery {
    v_cash_flow_aggregate {
      aggregate {
        sum {
          booked
          outstanding
          received
          total
        }
      }
    }
  }
`;

export const inventoryTotal = gql`
  query MyQuery {
    v_inventory_aggregate {
      aggregate {
        sum {
          available
          booked
          fully_paid
          partial_paid
          total
        }
      }
    }
  }
`;

export const cashFlowEachRows = gql`
  query MyQuery($model_id: Int!) {
    v_cash_flow_aggregate(where: { model_id: { _eq: $model_id } }) {
      aggregate {
        sum {
          booked
          outstanding
          received
          total
          model_id
        }
      }
      nodes {
        model_name
      }
    }
  }
`;

export const inventoryEachRows = gql`
  query MyQuery($model_id: Int!) {
    v_inventory_aggregate(where: { model_id: { _eq: $model_id } }) {
      aggregate {
        sum {
          available
          booked
          fully_paid
          partial_paid
          total
          model_id
        }
      }
      nodes {
        model_name
      }
    }
  }
`;

export const cashFlowCityList = gql`
  query MyQuery($city: String!) {
    v_cash_flow(distinct_on: model_name, where: { city: { _eq: $city } }) {
      model_id
      model_name
    }
  }
`;

export const inventoryCityList = gql`
  query MyQuery($city: String!) {
    v_inventory(distinct_on: model_name, where: { city: { _eq: $city } }) {
      model_id
      model_name
    }
  }
`;

export const cashFlowCityEachRow = gql`
  query MyQuery($model_id: Int!, $city: String!) {
    v_cash_flow_aggregate(
      where: { model_id: { _eq: $model_id }, city: { _eq: $city } }
    ) {
      aggregate {
        sum {
          booked
          outstanding
          received
          total
          model_id
        }
      }
      nodes {
        model_name
      }
    }
  }
`;

export const inventoryCityEachRow = gql`
  query MyQuery($model_id: Int!, $city: String!) {
    v_inventory_aggregate(
      where: { model_id: { _eq: $model_id }, city: { _eq: $city } }
    ) {
      aggregate {
        sum {
          available
          booked
          fully_paid
          partial_paid
          total
          model_id
        }
      }
      nodes {
        model_name
      }
    }
  }
`;

export const cashFLowCityTotal = gql`
  query MyQuery($city: String!) {
    v_cash_flow_aggregate(where: { city: { _eq: $city } }) {
      aggregate {
        sum {
          booked
          outstanding
          received
          total
        }
      }
    }
  }
`;

export const inventoryCityTotal = gql`
  query MyQuery($city: String!) {
    v_inventory_aggregate(where: { city: { _eq: $city } }) {
      aggregate {
        sum {
          available
          booked
          fully_paid
          partial_paid
          total
        }
      }
    }
  }
`;

export const cashFlowProjectList = gql`
  query MyQuery($city: String!, $project_name: String!) {
    v_cash_flow(
      distinct_on: model_name
      where: { city: { _eq: $city }, project_name: { _eq: $project_name } }
    ) {
      model_id
      model_name
    }
  }
`;

export const inventoryProjectList = gql`
  query MyQuery($city: String!, $project_name: String!) {
    v_inventory(
      distinct_on: model_name
      where: { city: { _eq: $city }, project_name: { _eq: $project_name } }
    ) {
      model_id
      model_name
    }
  }
`;

export const cashFlowProjectTotal = gql`
  query MyQuery($city: String!, $project_name: String!) {
    v_cash_flow_aggregate(
      where: { city: { _eq: $city }, project_name: { _eq: $project_name } }
    ) {
      aggregate {
        sum {
          booked
          outstanding
          received
          total
        }
      }
    }
  }
`;

export const inventoryProjectTotal = gql`
  query MyQuery($city: String!, $project_name: String!) {
    v_inventory_aggregate(
      where: { city: { _eq: $city }, project_name: { _eq: $project_name } }
    ) {
      aggregate {
        sum {
          available
          booked
          fully_paid
          partial_paid
          total
        }
      }
    }
  }
`;

export const cashFlowProjectEachRow = gql`
  query MyQuery($model_id: Int!, $city: String!, $project_name: String!) {
    v_cash_flow_aggregate(
      where: {
        model_id: { _eq: $model_id }
        city: { _eq: $city }
        project_name: { _eq: $project_name }
      }
    ) {
      aggregate {
        sum {
          booked
          outstanding
          received
          total
          model_id
        }
      }
      nodes {
        model_name
      }
    }
  }
`;

export const inventoryProjectEachRow = gql`
  query MyQuery($model_id: Int!, $city: String!, $project_name: String!) {
    v_inventory_aggregate(
      where: {
        model_id: { _eq: $model_id }
        city: { _eq: $city }
        project_name: { _eq: $project_name }
      }
    ) {
      aggregate {
        sum {
          available
          booked
          fully_paid
          partial_paid
          total
          model_id
        }
      }
      nodes {
        model_name
      }
    }
  }
`;

export const cashFlowTowerList = gql`
  query MyQuery($city: String!, $project_name: String!, $tower_name: String!) {
    v_cash_flow(
      distinct_on: model_name
      where: {
        city: { _eq: $city }
        project_name: { _eq: $project_name }
        tower_name: { _eq: $tower_name }
      }
    ) {
      model_id
      model_name
    }
  }
`;

export const inventoryTowerList = gql`
  query MyQuery($city: String!, $project_name: String!, $tower_name: String!) {
    v_inventory(
      distinct_on: model_name
      where: {
        city: { _eq: $city }
        project_name: { _eq: $project_name }
        tower_name: { _eq: $tower_name }
      }
    ) {
      model_id
      model_name
    }
  }
`;

export const cashFlowTowerTotal = gql`
  query MyQuery($city: String!, $project_name: String!, $tower_name: String!) {
    v_cash_flow_aggregate(
      where: {
        city: { _eq: $city }
        project_name: { _eq: $project_name }
        tower_name: { _eq: $tower_name }
      }
    ) {
      aggregate {
        sum {
          booked
          outstanding
          received
          total
        }
      }
    }
  }
`;

export const inventoryTowerTotal = gql`
  query MyQuery($city: String!, $project_name: String!, $tower_name: String!) {
    v_inventory_aggregate(
      where: {
        city: { _eq: $city }
        project_name: { _eq: $project_name }
        tower_name: { _eq: $tower_name }
      }
    ) {
      aggregate {
        sum {
          available
          booked
          fully_paid
          partial_paid
          total
        }
      }
    }
  }
`;

export const cashFlowTowerEachRow = gql`
  query MyQuery(
    $model_id: Int!
    $city: String!
    $project_name: String!
    $tower_name: String!
  ) {
    v_cash_flow_aggregate(
      where: {
        model_id: { _eq: $model_id }
        city: { _eq: $city }
        project_name: { _eq: $project_name }
        tower_name: { _eq: $tower_name }
      }
    ) {
      aggregate {
        sum {
          booked
          outstanding
          received
          total
          model_id
        }
      }
      nodes {
        model_name
      }
    }
  }
`;

export const inventoryTowerEachRow = gql`
  query MyQuery(
    $model_id: Int!
    $city: String!
    $project_name: String!
    $tower_name: String!
  ) {
    v_inventory_aggregate(
      where: {
        model_id: { _eq: $model_id }
        city: { _eq: $city }
        project_name: { _eq: $project_name }
        tower_name: { _eq: $tower_name }
      }
    ) {
      aggregate {
        sum {
          available
          booked
          fully_paid
          partial_paid
          total
          model_id
        }
      }
      nodes {
        model_name
      }
    }
  }
`;

export const cashFlowTowerTotal1 = gql`
  query MyQuery($city: String!, $project_name: String!, $tower_name: String!) {
    v_cash_flow_aggregate(
      where: {
        city: { _eq: $city }
        project_name: { _eq: $project_name }
        tower_name: { _eq: $tower_name }
      }
    ) {
      aggregate {
        sum {
          booked
          outstanding
          received
        }
      }
    }
  }
`;

export const InstallmentCity = gql`
  query MyQuery {
    project(distinct_on: city) {
      city
    }
  }
`;

export const InstallmentProject = gql`
  query MyQuery($city: String!) {
    project(where: { city: { _eq: $city } }) {
      project_id
      project_name
    }
  }
`;

export const InstallmentTower = gql`
  query MyQuery($project_id: Int!) {
    project_building(where: { project_id: { _eq: $project_id } }) {
      building_id
      tower_name
    }
  }
`;

export const InstallmentCityUniqueList = gql`
  query MyQuery($city: String!) {
    v_installment_status(
      distinct_on: model_name
      where: { city: { _eq: $city } }
    ) {
      model_id
      model_name
    }
  }
`;

export const InstallmentCityEachModel = gql`
  query MyQuery($model_id: Int!, $city: String!) {
    v_installment_status(
      where: { model_id: { _eq: $model_id }, city: { _eq: $city } }
    ) {
      amount_due
      amount_overdue
      amount_received
      due_count
      model_name
      overdue_count
      paid_count
    }
  }
`;

export const InstallmentProjectUniqueList = gql`
  query MyQuery($city: String!, $project_name: String!) {
    v_installment_status(
      distinct_on: model_name
      where: { city: { _eq: $city }, project_name: { _eq: $project_name } }
    ) {
      model_id
      model_name
    }
  }
`;

export const InstallmentProjectEachModel = gql`
  query MyQuery($model_id: Int!, $city: String!, $project_name: String!) {
    v_installment_status(
      where: {
        model_id: { _eq: $model_id }
        city: { _eq: $city }
        project_name: { _eq: $project_name }
      }
    ) {
      amount_due
      amount_overdue
      amount_received
      due_count
      model_name
      overdue_count
      paid_count
    }
  }
`;

export const InstallmentTowerUniqueList = gql`
  query MyQuery($city: String!, $project_name: String!, $tower_name: String!) {
    v_installment_status(
      distinct_on: model_name
      where: {
        city: { _eq: $city }
        project_name: { _eq: $project_name }
        tower_name: { _eq: $tower_name }
      }
    ) {
      model_id
      model_name
    }
  }
`;

export const InstallmentTowerEachModel = gql`
  query MyQuery(
    $model_id: Int!
    $city: String!
    $project_name: String!
    $tower_name: String!
  ) {
    v_installment_status(
      where: {
        model_id: { _eq: $model_id }
        city: { _eq: $city }
        project_name: { _eq: $project_name }
        tower_name: { _eq: $tower_name }
      }
    ) {
      amount_due
      amount_overdue
      amount_received
      due_count
      model_name
      overdue_count
      paid_count
    }
  }
`;

export const InstallmentData = gql`
  query MyQuery {
    v_installment_status {
      model_id
      model_name
    }
  }
`;

export const InstallmentDataEachModel = gql`
  query MyQuery($model_id: Int!) {
    v_installment_status(where: { model_id: { _eq: $model_id } }) {
      amount_due
      amount_overdue
      amount_received
      due_count
      model_name
      overdue_count
      paid_count
    }
  }
`;

export const OverviewCity = gql`
  query MyQuery {
    project(distinct_on: city) {
      city
    }
  }
`;

export const OverviewProject = gql`
  query MyQuery($city: String!) {
    project(where: { city: { _eq: $city } }) {
      project_id
      project_name
    }
  }
`;

export const OverviewTower = gql`
  query MyQuery($city: String!, $project_name: String!) {
    v_amount_summary_by_building(
      where: { city: { _eq: $city }, project_name: { _eq: $project_name } }
    ) {
      tower_name
      building_id
      city
      project_name
      total_amount
      total_due
      total_received
    }
  }
`;

export const OverviewCityTower = gql`
  query MyQuery($city: String!, $project_name: String!, $building_id: Int!) {
    v_installment_status(
      where: {
        city: { _eq: $city }
        project_name: { _eq: $project_name }
        building_id: { _eq: $building_id }
      }
    ) {
      model_count
      model_name
      building_id
    }
  }
`;

export const transactionApprover = gql`
  query MyQuery {
    transaction_approvers {
      first_name
      last_name
      approver_id
    }
  }
`;

export const getTransactionApprover = gql`
  query MyQuery($email_id: String!) {
    transaction_approvers(where: { email_id: { _eq: $email_id } }) {
      email_id
      first_name
      last_name
      phone_number
      approver_id
    }
  }
`;

export const cancelBookingRefundAmount = gql`
  query MyQuery($sale_transaction_id: Int!) {
    payment_history_aggregate(
      where: { sale_transaction_id: { _eq: $sale_transaction_id } }
    ) {
      aggregate {
        sum {
          amount
        }
      }
    }
  }
`;

export const addAmentiesLastDate = gql`
  query MyQuery($sale_transaction_id: Int!) {
    sale_transaction_payment_schedule(
      where: { sale_transaction_id: { _eq: $sale_transaction_id } }
      order_by: { due_date: asc }
    ) {
      amount
      due_date
      item
      __typename
    }
  }
`;
export const reportsApprovedBooking = gql`
  query MyQuery($step_index: Int!, $status: String!) {
    sale_transaction(
      where: {
        sale_transaction_statuses: {
          step_index: { _eq: $step_index }
          status: { _eq: $status }
        }
      }
    ) {
      sale_transaction_id
      buyers {
        buyer_id
        abhyasi_id
        email
        first_name
        last_name
        residency_type
        citizenship
        street
        state
        city
        zip
        country
        kyc_verification_status
      }
      property_id
      property {
        property_model {
          baths_count
          beds_count
          car_parking_count
          model_name
          sqft_area
        }
        sale_status
        unit_number
        project_building {
          tower_name
        }
      }
      bank_loan_flag
    }
  }
`;

export const availableProperties = gql`
  query MyQuery($sale_status: String!) {
    property(where: { sale_status: { _eq: $sale_status } }) {
      sale_status
      project_building {
        tower_name
      }
      unit_number
      property_model {
        model_name
        beds_count
        baths_count
        sqft_area
      }
      project {
        project_name
      }
      property_id
    }
  }
`;
