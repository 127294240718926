import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Container, Tab } from "@mui/material";
import React, { useState } from "react";
import PaymentSchedule from "./payment/PaymentSchedule";
import { DownloadsTab } from "./downloads/DownloadsTab";
import AssocProperties from "./assocProperties/AssocProperties";
import EventLog from "./eventLog/EventLog";
import IdentityProofBuyerProfile from "./IdentityProofBuyerProfile";
import { LegalHeirs } from "./legalHeirs/LegalHeirs";
// import { Notes } from "./notes/Notes";

const tabs = [
  { label: "Payments", value: "1" },
  { label: "Downloads", value: "2" },
  { label: "ID Proof", value: "3" },
  { label: "Assoc. Properties", value: "4" },
  { label: "Legal Heir", value: "5" },
  { label: "Event Log", value: "6" },
  // { label: "Notes", value: "7" },
];

const BuyerProfileTabs = ({buyerInfo, data}) => {
  const [value, setValue] = useState("3");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Container maxWidth={"xl"}>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              indicatorColor="#0671E0"
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "#0671E0",
                },
              }}
            >
              {tabs.map((tab, index) => (
                <Tab label={tab.label} value={tab.value} key={index} sx={{ textTransform: "capitalize", }} />
              ))}
            </TabList>
          </Box>
          <TabPanel value="1"><PaymentSchedule buyerInfo={buyerInfo}/></TabPanel>
          <TabPanel value="2"><DownloadsTab /></TabPanel>
          <TabPanel value="3"><IdentityProofBuyerProfile buyerInfo={data?.sale_transaction[0]}/></TabPanel>
          <TabPanel value="4"><AssocProperties buyerInfo={buyerInfo} /></TabPanel>
          <TabPanel value="5"><LegalHeirs /></TabPanel>
          <TabPanel value="6"><EventLog /></TabPanel>
          {/* <TabPanel value="7"><Notes /></TabPanel> */}
        </TabContext>
      </Box>
    </Container>
  );
};

export default BuyerProfileTabs;
