import { gql } from "@apollo/client";

export const Add_Customer_Sales_Id = gql`
  mutation MyMutation($status: String!) {
    insert_sale_transaction(objects: { status: $status }) {
      returning {
        sale_transaction_id
      }
    }
  }
`;
export const Add_Stepper_Status_Id = gql`
  mutation MyMutation(
    $process_step: String!
    $sale_transaction_id: Int!
    $status: String!
    $step_index: Int!
    $locked: Boolean!
  ) {
    insert_sale_transaction_status(
      objects: {
        process_step: $process_step
        sale_transaction_id: $sale_transaction_id
        status: $status
        step_index: $step_index
        locked: $locked
      }
    ) {
      returning {
        sale_transaction_status_id
      }
    }
  }
`;

export const Add_New_Mutation = gql`
  mutation MyMutation(
    $process_step: String!
    $sale_transaction_id: Int!
    $status: String!
    $step_index: Int!
    $locked: Boolean!
  ) {
    insert_sale_transaction_status(
      objects: {
        process_step: $process_step
        sale_transaction_id: $sale_transaction_id
        status: $status
        step_index: $step_index
        locked: $locked
      }
    ) {
      returning {
        sale_transaction_status_id
      }
    }
  }
`;

export const Add_New_Mutation1 = gql`
  mutation MyMutation(
    $process_step: String!
    $sale_transaction_id: Int!
    $status: String!
    $step_index: Int!
    $locked: Boolean!
  ) {
    insert_sale_transaction_status(
      objects: {
        process_step: $process_step
        sale_transaction_id: $sale_transaction_id
        status: $status
        step_index: $step_index
        locked: $locked
      }
    ) {
      returning {
        sale_transaction_status_id
      }
    }
  }
`;

export const Add_New_Mutation2 = gql`
  mutation MyMutation(
    $process_step: String!
    $sale_transaction_id: Int!
    $status: String!
    $step_index: Int!
    $locked: Boolean!
  ) {
    insert_sale_transaction_status(
      objects: {
        process_step: $process_step
        sale_transaction_id: $sale_transaction_id
        status: $status
        step_index: $step_index
        locked: $locked
      }
    ) {
      returning {
        sale_transaction_status_id
      }
    }
  }
`;

export const Add_New_Mutation3 = gql`
  mutation MyMutation(
    $process_step: String!
    $sale_transaction_id: Int!
    $status: String!
    $step_index: Int!
    $locked: Boolean!
  ) {
    insert_sale_transaction_status(
      objects: {
        process_step: $process_step
        sale_transaction_id: $sale_transaction_id
        status: $status
        step_index: $step_index
        locked: $locked
      }
    ) {
      returning {
        sale_transaction_status_id
      }
    }
  }
`;

export const Insert_Buyer = gql`
  mutation MyMutation(
    $sale_transaction_id: Int!
    $first_name: String!
    $last_name: String!
    $middle_name: String!
    $abhyasi_id: String!
    $phone: String!
  ) {
    insert_buyer(
      objects: {
        first_name: $first_name
        last_name: $last_name
        middle_name: $middle_name
        abhyasi_id: $abhyasi_id
        sale_transaction_id: $sale_transaction_id
        phone: $phone
      }
    ) {
      returning {
        buyer_id
      }
    }
  }
`;

export const Update_buyer = gql`
  mutation MyMutation(
    $first_name: String!
    $last_name: String!
    $middle_name: String!
    $abhyasi_id: String!
    $phone: String!
    $sale_transaction_id: Int!
    $buyer_id: Int!
  ) {
    update_buyer(
      where: { buyer_id: { _eq: $buyer_id } }
      _set: {
        first_name: $first_name
        last_name: $last_name
        middle_name: $middle_name
        phone: $phone
        abhyasi_id: $abhyasi_id
        sale_transaction_id: $sale_transaction_id
      }
    ) {
      returning {
        buyer_id
        first_name
        abhyasi_id
        last_name
        middle_name
        phone
        sale_transaction_id
      }
    }
  }
`;

export const Insert_Abhyasi_ID = gql`
  mutation MyMutation(
    $file_name: String!
    $file_extension: String!
    $document_type: String!
    $document_number: String!
    $buyer_id: Int!
    $document: bytea!
  ) {
    insert_buyer_identity_doc(
      objects: {
        buyer_id: $buyer_id
        document_number: $document_number
        document_type: $document_type
        file_name: $file_name
        file_extension: $file_extension
        document: $document
      }
    ) {
      returning {
        buyer_identity_doc_id
      }
    }
  }
`;

export const Update_Abhyasi_ID = gql`
  mutation MyMutation(
    $buyer_identity_doc_id: Int!
    $document: bytea!
    $document_number: String!
    $document_type: String!
    $file_extension: String!
    $file_name: String!
  ) {
    update_buyer_identity_doc(
      where: { buyer_identity_doc_id: { _eq: $buyer_identity_doc_id } }
      _set: {
        document: $document
        document_number: $document_number
        document_type: $document_type
        file_name: $file_name
        file_extension: $file_extension
      }
    )
  }
`;

export const Insert_Buyer_Pic = gql`
  mutation MyMutation(
    $buyer_id: Int!
    $file_name: String!
    $file_extension: String!
    $document_type: String!
    $document: bytea!
  ) {
    insert_buyer_identity_doc(
      objects: {
        buyer_id: $buyer_id
        file_name: $file_name
        file_extension: $file_extension
        document_type: $document_type
        document: $document
      }
    ) {
      returning {
        buyer_identity_doc_id
      }
    }
  }
`;

export const Update_Buyer_Pic = gql`
  mutation MyMutation(
    $buyer_identity_doc_id: Int!
    $document: bytea!
    $document_number: String!
    $document_type: String!
    $file_extension: String!
    $file_name: String!
  ) {
    update_buyer_identity_doc(
      where: { buyer_identity_doc_id: { _eq: $buyer_identity_doc_id } }
      _set: {
        document: $document
        document_number: $document_number
        document_type: $document_type
        file_name: $file_name
        file_extension: $file_extension
      }
    )
  }
`;

export const Update_Buyer = gql`
  mutation MyMutation(
    $buyer_id: Int!
    $residency_type: String!
    $citizenship: String!
    $street: String!
    $state: String!
    $country: String!
    $city: String!
    $zip: String!
  ) {
    update_buyer(
      where: { buyer_id: { _eq: $buyer_id } }
      _set: {
        residency_type: $residency_type
        citizenship: $citizenship
        street: $street
        state: $state
        country: $country
        city: $city
        zip: $zip
      }
    ) {
      returning {
        citizenship
      }
    }
  }
`;

export const Insert_Buyer_Identity_Doc = gql`
  mutation MyMutation(
    $buyer_id: Int!
    $document_number: String!
    $file_name: String!
    $file_extension: String!
    $document_type: String!
    $document: bytea!
  ) {
    insert_buyer_identity_doc(
      objects: {
        buyer_id: $buyer_id
        document_number: $document_number
        file_name: $file_name
        file_extension: $file_extension
        document_type: $document_type
        document: $document
      }
    ) {
      returning {
        buyer_identity_doc_id
        buyer_id
        document_number
        file_name
        file_extension
        document_type
        document
      }
    }
  }
`;

export const Insert_Buyer_Identity_DocFile = gql`
  mutation MyMutation(
    $buyer_id: Int!
    $document_number: String!
    $file_name: String!
    $file_extension: String!
    $document_type: String!
    $document: bytea!
  ) {
    insert_buyer_identity_doc(
      objects: {
        buyer_id: $buyer_id
        document_number: $document_number
        file_name: $file_name
        file_extension: $file_extension
        document_type: $document_type
        document: $document
      }
    ) {
      returning {
        buyer_identity_doc_id
        buyer_id
        document_number
        file_name
        file_extension
        document_type
        document
      }
    }
  }
`;

export const Delete_File = gql`
  mutation MyMutation($buyer_identity_doc_id: Int!) {
    delete_buyer_identity_doc(
      where: { buyer_identity_doc_id: { _eq: $buyer_identity_doc_id } }
    ) {
      returning {
        buyer_identity_doc_id
      }
    }
  }
`;

export const Insert_Document = gql`
  mutation MyMutation(
    $buyer_id: Int!
    $aadhar_number: String!
    $pan_number: String!
    $oci_number: String!
    $passport_number: String!
  ) {
    update_buyer(
      where: { buyer_id: { _eq: $buyer_id } }
      _set: {
        aadhar_number: $aadhar_number
        pan_number: $pan_number
        oci_number: $oci_number
        passport_number: $passport_number
      }
    ) {
      returning {
        aadhar_number
        oci_number
        pan_number
        passport_number
      }
    }
  }
`;

export const familyMemberData = gql`
  mutation MyMutation(
    $age: Int!
    $full_name: String!
    $id_number: String!
    $id_type: String!
    $relationship_buyer: String!
    $sale_transaction_id: Int!
  ) {
    insert_legal_heir(
      objects: {
        age: $age
        full_name: $full_name
        id_number: $id_number
        id_type: $id_type
        relationship_buyer: $relationship_buyer
        sale_transaction_id: $sale_transaction_id
      }
    ) {
      returning {
        legal_heir_id
        age
        full_name
        id_number
        id_type
        relationship_buyer
      }
    }
  }
`;

export const familyMemberDataUpdate = gql`
  mutation MyMutation(
    $legal_heir_id: Int!
    $age: Int!
    $full_name: String!
    $id_number: String!
    $id_type: String!
    $relationship_buyer: String!
  ) {
    update_legal_heir(
      _set: {
        age: $age
        full_name: $full_name
        id_number: $id_number
        id_type: $id_type
        legal_heir_id: $legal_heir_id
        relationship_buyer: $relationship_buyer
      }
      where: { legal_heir_id: { _eq: $legal_heir_id } }
    ) {
      returning {
        age
        full_name
        id_number
        id_type
        legal_heir_id
        relationship_buyer
      }
    }
  }
`;

export const familyMemberDataDelete = gql`
  mutation MyMutation($legal_heir_id: Int!, $sale_transaction_id: Int!) {
    delete_legal_heir(
      where: {
        legal_heir_id: { _eq: $legal_heir_id }
        sale_transaction_id: { _eq: $sale_transaction_id }
      }
    ) {
      returning {
        sale_transaction_id
      }
    }
  }
`;

export const familyMemberOkData = gql`
  mutation MyMutation(
    $sale_transaction_id: Int!
    $step_index: Int!
    $status: String!
  ) {
    update_sale_transaction_status(
      where: {
        sale_transaction_id: { _eq: $sale_transaction_id }
        step_index: { _eq: $step_index }
      }
      _set: { status: $status }
    ) {
      returning {
        sale_transaction_id
        status
        step_index
      }
    }
  }
`;

export const cancellation_insert_buyer_bank_account = gql`
  mutation MyMutation(
    $account_number: String!
    $account_type: String!
    $accountholder_name: String!
    $bank_name: String!
    $branch_name: String!
    $ifsc_code: String!
    $sale_transaction_id: Int!
    $refund_agreement_flag: Boolean!
  ) {
    insert_buyer_bank_account(
      objects: {
        account_number: $account_number
        account_type: $account_type
        accountholder_name: $accountholder_name
        bank_name: $bank_name
        branch_name: $branch_name
        ifsc_code: $ifsc_code
        refund_agreement_flag: $refund_agreement_flag
        sale_transaction_id: $sale_transaction_id
      }
    ) {
      returning {
        buyer_bank_account_id
        sale_transaction_id
      }
    }
  }
`;

export const cancellation_update_buyer_bank_account = gql`
  mutation MyMutation(
    $buyer_bank_account_id: Int!
    $account_number: String!
    $account_type: String!
    $accountholder_name: String!
    $bank_name: String!
    $branch_name: String!
    $ifsc_code: String!
    $refund_agreement_flag: Boolean!
    $sale_transaction_id: Int!
  ) {
    update_buyer_bank_account(
      where: { buyer_bank_account_id: { _eq: $buyer_bank_account_id } }
      _set: {
        account_number: $account_number
        account_type: $account_type
        accountholder_name: $accountholder_name
        bank_name: $bank_name
        branch_name: $branch_name
        ifsc_code: $ifsc_code
        refund_agreement_flag: $refund_agreement_flag
        sale_transaction_id: $sale_transaction_id
      }
    ) {
      returning {
        buyer_bank_account_id
      }
    }
  }
`;

export const ConfirmCancellationModal = gql`
  mutation MyMutation(
    $fee_agreement_flag: Boolean!
    $sale_transaction_id: Int!
    $terms_agreement_flag: Boolean!
  ) {
    insert_buyer_sale_cancel(
      objects: {
        fee_agreement_flag: $fee_agreement_flag
        sale_transaction_id: $sale_transaction_id
        terms_agreement_flag: $terms_agreement_flag
      }
    ) {
      returning {
        buyer_sale_cancel_id
      }
    }
  }
`;

export const bookPropertySubmit = gql`
  mutation MyMutation(
    $amount: numeric
    $due_date: date!
    $item: String!
    $sale_transaction_id: Int!
    $status: String!
  ) {
    insert_sale_transaction_payment_schedule(
      objects: {
        amount: $amount
        due_date: $due_date
        item: $item
        sale_transaction_id: $sale_transaction_id
        status: $status
      }
    ) {
      returning {
        sale_transaction_payment_schedule_id
      }
    }
  }
`;

export const bookPropertyUpdateSubmit = gql`
  mutation MyMutation(
    $sale_transaction_id: Int!
    $ack_first_installment: Boolean!
    $ack_payment_sched: Boolean!
    $bank_loan_flag: Boolean!
    $ack_booking_amount: Boolean!
    $ack_loan_responsibility: Boolean!
    $property_id: Int!
  ) {
    update_sale_transaction(
      where: { sale_transaction_id: { _eq: $sale_transaction_id } }
      _set: {
        ack_first_installment: $ack_first_installment
        ack_payment_sched: $ack_payment_sched
        bank_loan_flag: $bank_loan_flag
        ack_booking_amount: $ack_booking_amount
        ack_loan_responsibility: $ack_loan_responsibility
        property_id: $property_id
      }
    ) {
      returning {
        sale_transaction_id
      }
    }
  }
`;

export const bookPropertyUpdateStepSubmit = gql`
  mutation MyMutation(
    $sale_transaction_id: Int!
    $step_index: Int!
    $locked: Boolean!
    $status: String!
  ) {
    update_sale_transaction_status(
      where: {
        sale_transaction_id: { _eq: $sale_transaction_id }
        step_index: { _eq: $step_index }
      }
      _set: { locked: $locked, status: $status }
    ) {
      returning {
        sale_transaction_status_id
      }
    }
  }
`;

export const bookPropertyNextStepSubmit = gql`
  mutation MyMutation(
    $sale_transaction_id: Int!
    $step_index: Int!
    $locked: Boolean!
  ) {
    update_sale_transaction_status(
      where: {
        sale_transaction_id: { _eq: $sale_transaction_id }
        step_index: { _eq: $step_index }
      }
      _set: { locked: $locked }
    ) {
      returning {
        sale_transaction_status_id
      }
    }
  }
`;

export const bookPropertyUpdateStatusSubmit = gql`
  mutation MyMutation(
    $sale_transaction_id: Int!
    $step_index: Int!
    $locked: Boolean!
    $status: String!
  ) {
    update_sale_transaction_status(
      where: {
        sale_transaction_id: { _eq: $sale_transaction_id }
        step_index: { _eq: $step_index }
      }
      _set: { locked: $locked, status: $status }
    ) {
      returning {
        sale_transaction_status_id
      }
    }
  }
`;

export const IdentityProofUpdateStatus = gql`
  mutation MyMutation(
    $sale_transaction_id: Int!
    $step_index: Int!
    $locked: Boolean!
    $status: String!
  ) {
    update_sale_transaction_status(
      where: {
        sale_transaction_id: { _eq: $sale_transaction_id }
        step_index: { _eq: $step_index }
      }
      _set: { locked: $locked, status: $status }
    ) {
      returning {
        sale_transaction_status_id
        status
        process_step
        sale_transaction_id
        locked
        step_index
      }
    }
  }
`;

export const IdentityProofUpdateUnlocked = gql`
  mutation MyMutation(
    $sale_transaction_id: Int!
    $step_index: Int!
    $locked: Boolean
  ) {
    update_sale_transaction_status(
      where: {
        sale_transaction_id: { _eq: $sale_transaction_id }
        step_index: { _eq: $step_index }
      }
      _set: { locked: $locked }
    ) {
      returning {
        sale_transaction_status_id
        locked
        process_step
        sale_transaction_id
        status
        step_index
      }
    }
  }
`;

export const familyMemberstatusSave = gql`
  mutation MyMutation(
    $sale_transaction_id: Int!
    $step_index: Int!
    $status: String!
  ) {
    update_sale_transaction_status(
      where: {
        sale_transaction_id: { _eq: $sale_transaction_id }
        step_index: { _eq: $step_index }
      }
      _set: { status: $status }
    ) {
      returning {
        sale_transaction_id
        status
        step_index
      }
    }
  }
`;

export const cancelationStatus = gql`
  mutation MyMutation($sale_transaction_id: Int!, $status: String!) {
    update_sale_transaction(
      where: { sale_transaction_id: { _eq: $sale_transaction_id } }
      _set: { status: $status }
    ) {
      returning {
        sale_transaction_id
      }
    }
  }
`;

export const cancellationLocked = gql`
  mutation MyMutation($sale_transaction_id: Int!, $locked: Boolean!) {
    update_sale_transaction_status(
      where: { sale_transaction_id: { _eq: $sale_transaction_id } }
      _set: { locked: $locked }
    ) {
      returning {
        sale_transaction_status_id
      }
    }
  }
`;

export const ApproveUploadDoc = gql`
  mutation MyMutation(
    $file_name: String!
    $sale_transaction_id: Int!
    $file_extension: String!
    $document_type: String!
    $document: bytea!
  ) {
    insert_document(
      objects: {
        file_name: $file_name
        sale_transaction_id: $sale_transaction_id
        file_extension: $file_extension
        document_type: $document_type
        document: $document
      }
    ) {
      returning {
        document_id
      }
    }
  }
`;

export const ApproveBookingMutation = gql`
  mutation MyMutation(
    $document_id: Int!
    $payment_date: date!
    $sale_transaction_id: Int!
    $amount: numeric!
    $status: String!
    $payment_medium: String!
  ) {
    insert_payment_history(
      objects: {
        document_id: $document_id
        payment_date: $payment_date
        sale_transaction_id: $sale_transaction_id
        amount: $amount
        status: $status
        payment_medium: $payment_medium
      }
    ) {
      returning {
        payment_history_id
        sale_transaction_id
      }
    }
  }
`;

export const saleTransactionStatus = gql`
  mutation MyMutation(
    $sale_transaction_id: Int!
    $step_index: Int!
    $status: String!
  ) {
    update_sale_transaction_status(
      where: {
        sale_transaction: { sale_transaction_id: { _eq: $sale_transaction_id } }
        step_index: { _eq: $step_index }
      }
      _set: { status: $status }
    ) {
      returning {
        sale_transaction_id
        sale_transaction_status_id
        status
      }
    }
  }
`;

export const approveStatus = gql`
  mutation MyMutation(
    $buyer_id: Int!
    $kyc_verification_date: date!
    $kyc_verification_staff: String!
    $kyc_verification_status: String!
  ) {
    update_buyer(
      where: { buyer_id: { _eq: $buyer_id } }
      _set: {
        kyc_verification_date: $kyc_verification_date
        kyc_verification_staff: $kyc_verification_staff
        kyc_verification_status: $kyc_verification_status
      }
    ) {
      returning {
        buyer_id
        sale_transaction_id
        kyc_verification_date
        kyc_verification_staff
      }
    }
  }
`;

export const idVerificationStatus = gql`
  mutation MyMutation($sale_transaction_status_id: Int!, $status: String!) {
    update_sale_transaction_status(
      where: {
        sale_transaction_status_id: { _eq: $sale_transaction_status_id }
      }
      _set: { status: $status }
    ) {
      returning {
        sale_transaction_status_id
      }
    }
  }
`;

export const IdVerificationReject = gql`
  mutation MyMutation(
    $buyer_id: Int!
    $kyc_verification_comment: String!
    $kyc_verification_date: date!
    $kyc_verification_staff: String!
    $kyc_verification_status: String!
  ) {
    update_buyer(
      where: { buyer_id: { _eq: $buyer_id } }
      _set: {
        kyc_verification_comment: $kyc_verification_comment
        kyc_verification_date: $kyc_verification_date
        kyc_verification_staff: $kyc_verification_staff
        kyc_verification_status: $kyc_verification_status
      }
    ) {
      returning {
        buyer_id
        sale_transaction_id
        kyc_verification_staff
        kyc_verification_date
      }
    }
  }
`;

export const uploadReceiptDoc = gql`
  mutation MyMutation(
    $file_name: String!
    $sale_transaction_id: Int!
    $file_extension: String!
    $document_type: String!
    $document: bytea!
  ) {
    insert_document(
      objects: {
        file_name: $file_name
        sale_transaction_id: $sale_transaction_id
        file_extension: $file_extension
        document_type: $document_type
        document: $document
      }
    ) {
      returning {
        document_id
      }
    }
  }
`;

export const uploadPropertyDoc = gql`
  mutation MyMutation(
    $file_name: String!
    $sale_transaction_id: Int!
    $file_extension: String!
    $document_type: String!
    $document: bytea!
  ) {
    insert_document(
      objects: {
        file_name: $file_name
        sale_transaction_id: $sale_transaction_id
        file_extension: $file_extension
        document_type: $document_type
        document: $document
      }
    ) {
      returning {
        document_id
      }
    }
  }
`;

export const uploadDocArea = gql`
  mutation MyMutation(
    $file_name: String!
    $sale_transaction_id: Int!
    $file_extension: String!
    $document_type: String!
    $document: bytea!
    $note: String!
  ) {
    insert_document(
      objects: {
        file_name: $file_name
        sale_transaction_id: $sale_transaction_id
        file_extension: $file_extension
        document_type: $document_type
        document: $document
        note: $note
      }
    ) {
      returning {
        document_id
      }
    }
  }
`;

export const uploadDocReceipt = gql`
  mutation MyMutation(
    $file_name: String!
    $sale_transaction_id: Int!
    $file_extension: String!
    $document_type: String!
    $document: bytea!
  ) {
    insert_document(
      objects: {
        file_name: $file_name
        sale_transaction_id: $sale_transaction_id
        file_extension: $file_extension
        document_type: $document_type
        document: $document
      }
    ) {
      returning {
        document_id
      }
    }
  }
`;

export const uploadDocSales = gql`
  mutation MyMutation(
    $document_id: Int!
    $payment_date: date!
    $sale_transaction_id: Int!
    $amount: numeric!
    $status: String!
    $payment_medium: String!
  ) {
    insert_payment_history(
      objects: {
        document_id: $document_id
        payment_date: $payment_date
        sale_transaction_id: $sale_transaction_id
        amount: $amount
        status: $status
        payment_medium: $payment_medium
      }
    ) {
      returning {
        payment_history_id
        sale_transaction_id
      }
    }
  }
`;

export const adminAddAmenity = gql`
  mutation MyMutation(
    $property_id: Int!
    $price_amount: numeric!
    $price_type: String!
  ) {
    insert_property_price(
      objects: {
        property_id: $property_id
        price_amount: $price_amount
        price_type: $price_type
      }
    ) {
      returning {
        property_price_id
      }
    }
  }
`;

export const adminUpdateCancellationStatus = gql`
  mutation MyMutation($sale_transaction_id: Int!) {
    update_sale_transaction(
      where: { sale_transaction_id: { _eq: $sale_transaction_id } }
      _set: { status: "Cancelled" }
    ) {
      returning {
        property_id
        status
      }
    }
  }
`;

export const adminUpdateCancellationPropertyStatus = gql`
  mutation MyMutation($property_id: Int!, $status: String!) {
    update_property(
      where: { property_id: { _eq: $property_id } }
      _set: { sale_status: $status }
    ) {
      returning {
        property_id
        sale_status
      }
    }
  }
`;

export const adminUpdateCancellationStatusLockedAll = gql`
  mutation MyMutation($sale_transaction_id: Int!) {
    update_sale_transaction_status(
      where: { sale_transaction_id: { _eq: $sale_transaction_id } }
      _set: { locked: true }
    ) {
      returning {
        process_step
        locked
      }
    }
  }
`;

export const adminAddAmenityPaymentSchedule = gql`
  mutation MyMutation(
    $amount: numeric!
    $due_date: date!
    $item: String!
    $sale_transaction_id: Int!
    $status: String!
  ) {
    insert_sale_transaction_payment_schedule(
      objects: {
        amount: $amount
        due_date: $due_date
        item: $item
        sale_transaction_id: $sale_transaction_id
        status: $status
      }
    ) {
      returning {
        sale_transaction_payment_schedule_id
      }
    }
  }
`;
