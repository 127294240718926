import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EastIcon from "@mui/icons-material/East";
import { useMutation, useQuery } from "@apollo/client";
import { propertiesData } from "../../../lib/query/query";
import {
  Add_Customer_Sales_Id,
  Add_New_Mutation,
  Add_New_Mutation1,
  Add_New_Mutation2,
  Add_New_Mutation3,
  Add_Stepper_Status_Id
} from "../../../lib/mutation/mutation";
import { EmptyProperties } from "./EmptyProperties";
import { useNavigate } from "react-router-dom";
import Steppercontext from "../../../lib/context/contextData";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";
import ErrorToast from "../../../components/ErrorToast";

const MyPropertiesCard = ({ handleNextStep }) => {
  const settings = ["Edit", "Delete"];
  const navigate = useNavigate();

  const { loading, error, data, refetch } = useQuery(propertiesData, {
    variables: { id: "607728a5-98b1-4e2d-9f3b-314417c07854" }
  });

  const [
    handleAddMutation,
    { loading: loading1, data: addData, error: errorAddData }
  ] = useMutation(Add_Customer_Sales_Id);

  const [hadleUpdateMutation, { loading: loading2, error: errorUpdate }] =
    useMutation(Add_Stepper_Status_Id);
  const [handleNewMutation, { loading: loading3, error: errorNew }] =
    useMutation(Add_New_Mutation);
  const [handleNewMutation1, { loading: loading4, error: errorNew1 }] =
    useMutation(Add_New_Mutation1);
  const [handleNewMutation2, { loading: loading5, error: errorNew2 }] =
    useMutation(Add_New_Mutation2);
  const [handleNewMutation3, { loading: loading6, error: errorNew3 }] =
    useMutation(Add_New_Mutation3);

  const [anchorElUser, setAnchorElUser] = useState(null);
  const [propertiesList, setPropertiesList] = useState([]);

  const { setSalesId } = useContext(Steppercontext);

  useEffect(() => {
    refetch();
    if (data && data.sale_transaction && data.sale_transaction?.length > 0) {
      setPropertiesList(data?.sale_transaction);
    }
  }, [data]);

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleQueryCall = async (saleId) => {
    setSalesId(saleId);
    Sentry.addBreadcrumb({
      category: "button_click",
      message: "Buy New button clicked on KYC Buyer Info Screen",
      data: {
        loading_step: "All step info screen"
      }
    });
    await hadleUpdateMutation({
      variables: {
        process_step: "Verify Your ID (KYC)",
        sale_transaction_id: saleId,
        status: "Yet to Start",
        step_index: 1,
        locked: false
      }
    })
      .then((response) => {
        //response
      })
      .catch((error) => {
        Sentry.captureException(error);
      });

    await handleNewMutation({
      variables: {
        process_step: "Book Property",
        sale_transaction_id: saleId,
        status: "Yet to Start",
        step_index: 2,
        locked: true
      }
    })
      .then((response) => {
        //response
      })
      .catch((error) => {
        Sentry.captureException(error);
      });

    await handleNewMutation1({
      variables: {
        process_step: "Family Members",
        sale_transaction_id: saleId,
        status: "Yet to Start",
        step_index: 3,
        locked: true
      }
    })
      .then((response) => {
        //response
      })
      .catch((error) => {
        Sentry.captureException(error);
      });

    await handleNewMutation2({
      variables: {
        process_step: "Complete Sale Agreement",
        sale_transaction_id: saleId,
        status: "Yet to Start",
        step_index: 4,
        locked: true
      }
    })
      .then((response) => {
        //response
      })
      .catch((error) => {
        Sentry.captureException(error);
      });

    await handleNewMutation3({
      variables: {
        process_step: "Property Registration",
        sale_transaction_id: saleId,
        // status: "Locked",
        status: "Yet to Start",
        step_index: 5,
        locked: true
      }
    })
      .then((response) => {
        //response
      })
      .catch((error) => {
        Sentry.captureException(error);
      });

    navigate(`/myProperties/allStates/${saleId}`);
  };

  useEffect(() => {
    const saleId =
      addData?.insert_sale_transaction?.returning[0]?.sale_transaction_id;

    if (saleId) {
      handleQueryCall(saleId);
    }
  }, [addData]);

  const handleBuyNew = () => {
    Sentry.addBreadcrumb({
      category: "button_click",
      message: "Customer clicked Buy New button"
      // level: 'info'
    });
    handleAddMutation({ variables: { customer_id: 2, status: "In Progress" } })
      .then((response) => {
        //response
        Sentry.getCurrentScope().setTransactionName("New Sale Transaction");
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
    // Sentry.addTransaction("New Sale Transaction");
  };

  const handlePropertyClick = (saleId) => {
    Sentry.addBreadcrumb({
      category: "property_click",
      message: "Customer clicked on a property",
      data: {
        sale_transaction_id: saleId
      }
      // level: 'info'
    });
    navigate(`/myProperties/allStates/${saleId}`);
  };

  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh"
        }}
      >
        <CircularProgress />
      </Box>
    );
  if (loading1 || loading2 || loading3 || loading4 || loading5 || loading6)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh"
        }}
      >
        <CircularProgress />
      </Box>
    );

  if (
    errorAddData ||
    errorUpdate ||
    errorNew ||
    errorNew1 ||
    errorNew2 ||
    errorNew3 ||
    error
  ) {
    toast.error(
      <ErrorToast
        email={"info@msvkanha.com"}
        errorMsg={
          (errorAddData && errorAddData?.message) ||
          (errorUpdate && errorUpdate?.message) ||
          (errorNew && errorNew?.message) ||
          (errorNew1 && errorNew1?.message) ||
          (errorNew2 && errorNew2?.message) ||
          (errorNew3 && errorNew3?.message) ||
          (error && error?.message)
        }
      />
    );
    Sentry.captureException(
      (errorAddData && errorAddData?.message) ||
        (errorUpdate && errorUpdate?.message) ||
        (errorNew && errorNew?.message) ||
        (errorNew1 && errorNew1?.message) ||
        (errorNew2 && errorNew2?.message) ||
        (errorNew3 && errorNew3?.message) ||
        (error && error?.message)
    );
  }

  return (
    <>
      <Container sx={{ marginBottom: "70px" }}>
        {propertiesList?.length === 0 ? (
          <EmptyProperties />
        ) : (
          <Box sx={{ border: "1px solid #E8E8EA", borderRadius: "10px", p: 3 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                p: 2
              }}
            >
              <Box>
                <Typography sx={{ fontSize: "25px", fontWeight: 600 }}>
                  My Properties
                </Typography>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#0671E0",
                    color: "white",
                    py: "12px",
                    px: "22px",
                    textTransform: "capitalize",
                    fontSize: "18px"
                  }}
                  onClick={handleBuyNew}
                >
                  Buy New <EastIcon sx={{ ml: 1, fontSize: "18px" }} />
                </Button>
              </Box>
            </Box>
            <Box sx={{ p: 2 }}>
              {error ? (
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  {error.message}
                </Alert>
              ) : loading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Grid container spacing={2}>
                  {propertiesList.map((property, index) => {
                    return (
                      <Grid item xs={4} key={index}>
                        <Card
                          sx={{
                            border: "1px solid #E8E8EA",
                            borderRadius: "5px",
                            boxShadow: "none",
                            height: "177px",
                            "&:hover": {
                              border: "1px solid #0671E0"
                            }
                          }}
                          // onClick={() => {
                          //   Sentry.addBreadcrumb({
                          //     category: "auth",
                          //     message:
                          //       "Authenticated user " + userData.users[0].email,
                          //     level: "info"
                          //   });
                          //   navigate(
                          //     `/myProperties/allStates/${property.sale_transaction_id}`
                          //   );
                          // }}
                          onClick={() =>
                            handlePropertyClick(property.sale_transaction_id)
                          }
                        >
                          <CardContent>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between"
                              }}
                            >
                              <Badge
                                sx={{
                                  fontSize: 12,
                                  backgroundColor:
                                    property.status === "Complete"
                                      ? "#F1FBF8"
                                      : property.status ===
                                        "Cancellation Requested"
                                      ? "#FCF7EE"
                                      : "#EEF5FC",
                                  color:
                                    property.status === "Complete"
                                      ? "#009262"
                                      : property.status ===
                                        "Cancellation Requested"
                                      ? "#FFA826"
                                      : "#0671E0",

                                  width:
                                    property.status === "Cancellation Requested"
                                      ? "150px"
                                      : "85px",
                                  height: "20px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderRadius: "4px"
                                }}
                              >
                                {property.status}
                              </Badge>

                              <Box sx={{ flexGrow: 0 }}>
                                <Tooltip>
                                  <IconButton sx={{ p: 0 }}>
                                    <MoreHorizIcon />
                                  </IconButton>
                                </Tooltip>
                                <Menu
                                  sx={{ mt: "45px" }}
                                  id="menu-appbar"
                                  anchorEl={anchorElUser}
                                  anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right"
                                  }}
                                  keepMounted
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right"
                                  }}
                                  open={Boolean(anchorElUser)}
                                  onClose={handleCloseUserMenu}
                                >
                                  {settings.map((setting) => (
                                    <MenuItem
                                      key={setting}
                                      onClick={handleCloseUserMenu}
                                    >
                                      <Typography textAlign="center">
                                        {setting}
                                      </Typography>
                                    </MenuItem>
                                  ))}
                                </Menu>
                              </Box>
                            </Box>

                            <Box textAlign={"start"} pt={"25px"}>
                              <Typography
                                sx={{
                                  fontSize: "20px",
                                  fontWeight: 600,
                                  paddingBottom: "6px"
                                }}
                              >
                                {property.property === null
                                  ? "Property yet to be selected"
                                  : property?.property?.project?.project_name}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: 400,
                                  color: "#717171"
                                }}
                              >
                                {property.property === null
                                  ? "-"
                                  : `Unit: ${property?.property?.unit_number} Model: ${property?.property?.property_model?.model_name}`}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: 400,
                                  color: "#717171"
                                }}
                              >
                                {property.property === null
                                  ? "-"
                                  : `${property?.property?.property_model?.beds_count} Bed , ${property?.property?.property_model?.baths_count} Bath , ${property?.property?.property_model?.sqft_area} sq. ft `}
                              </Typography>

                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: 400,
                                  color: "#717171"
                                }}
                              >
                                TXN ID:{" "}
                                {property?.sale_transaction_id === null
                                  ? "-"
                                  : `${property?.sale_transaction_id}`}
                              </Typography>
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </Box>
          </Box>
        )}
      </Container>
    </>
  );
};

export default MyPropertiesCard;
