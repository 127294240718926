import { combineReducers } from "redux";
import * as AuthSore from "./auth-store"


const rootReducer = () => {
  return combineReducers({
    auth: AuthSore.reducer,
  });
};

export default rootReducer;
