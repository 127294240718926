import React from "react";
import { Doughnut } from "react-chartjs-2";

const ChartCommon = () => {
  const chartData = {
    labels: ["January", "February"],
    datasets: [
      {
        label: "Rainfall",
        backgroundColor: ["#3C67B8", "#E97235"],
        hoverOffset: 3,
        data: [25, 75]
      }
    ]
  };

  const options = {
    plugins: {
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }
      },
      aspectRatio: 1,
      responsive: true,
      legend: {
        display: true,
        position: "right"
      },
      radius: 100
    }
  };

  return (
    <div class="myChartDiv" style={{ height: "220px", width: "250px" }}>
      <>
        <Doughnut data={chartData} options={options} />
      </>
    </div>
  );
};

export default ChartCommon;
