import React, { useState } from "react";
import ModalCommon from "../../../components/ModalCommon";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  FormControl,
  Typography
} from "@mui/material";
import { UploadFile } from "../../../components/UploadFile";
import { CustomFormLabel } from "../../../lib/constants/textField";
import { Controller, useForm } from "react-hook-form";
import useFileToHexString from "../../../hook/useFileToHexString";
import { errorToast, successToast } from "../../../pages/Toasts/showToasts";
import _ from "lodash";
import DeleteIcon from "@mui/icons-material/Delete";
import { uploadPropertyDoc } from "../../../lib/mutation/mutation";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ErrorToast from "../../../components/ErrorToast";
import { buyerProfileData } from "../../../lib/query/query";
import { useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";

export const UploadPropertyDeed = ({ open, setOpen, buyerInfo }) => {
  const [uploadDoc, setUploadDoc] = useState({});
  const [loading, setLoading] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  const { salesId } = useParams();

  const { refetch,error:errorBuyerProfile } = useQuery(buyerProfileData, {
    variables: { sale_transaction_id: salesId }
  });

  const [upload_propertyDoc, { error: errorDoc }] =
    useMutation(uploadPropertyDoc);

  const { convertFileToHexStringPromise } = useFileToHexString();

  if (errorBuyerProfile || errorDoc) {
    toast.error(
    <ErrorToast 
    email={"info@msvkanha.com"} 
    errorMsg={
      (errorDoc && errorDoc?.message)||
      (errorBuyerProfile && errorBuyerProfile?.message)
    }
    />
  );
  Sentry.captureException(
    (errorDoc && errorDoc?.message)||
    (errorBuyerProfile && errorBuyerProfile?.message)
  );
  }

  const { control, handleSubmit, setValue } = useForm({
    mode: "onChange"
  });

  const handleUploadPassportPic = async (e) => {
    const file = e.target.files[0];
    try {
      setLoading(true);
      const hexData = await convertFileToHexStringPromise(file);
      const fileStore = {
        file_name: file.name.split(".").shift(),
        file_extension: file.name.split(".").pop(),
        haxContent: hexData
      };
      setUploadDoc(fileStore);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const handlePassportPicDelete = (uploadDoc) => {
    if (uploadDoc) {
      setValue("upload_passport_pic", "");
      setUploadDoc({});
    }
  };

  const onSubmit = async (data) => {
    setIsFileUploaded(true);
    await upload_propertyDoc({
      variables: {
        file_name: uploadDoc?.file_name,
        sale_transaction_id: salesId,
        file_extension: uploadDoc?.file_extension,
        document_type: "Property Deed",
        document: uploadDoc?.haxContent
      }
    })
      .then((response) => {
        setIsFileUploaded(false);
        successToast("File upload successful!");
        setOpen(false);
        refetch();
      })
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
        errorToast("Something went wrong!");
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Header = () => {
    return (
      <Box sx={{ justifyContent: "center", textAlign: "center" }}>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "30px",
            color: "#212121"
          }}
        >
          Upload Property Deed
        </Typography>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "23px",
            color: "#4D4D4D",
            paddingTop: "10px"
          }}
        >
          {`${buyerInfo?.property?.project?.project_name} ${buyerInfo?.property?.project_building?.tower_name} ${buyerInfo?.property?.unit_number}`}
        </Typography>
      </Box>
    );
  };

  const Body = () => {
    return (
      <>
      {isFileUploaded && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 1000,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Box sx={{ marginBottom: "12px" }}>
            <FormControl sx={{ width: "100%" }}>
              <CustomFormLabel component="legend" required>
                Upload Document
              </CustomFormLabel>

              {loading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "178px",
                    background: "#F5F7FA"
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {_.isEmpty(uploadDoc) ? (
                    <Controller
                      control={control}
                      name="upload_passport_pic"
                      rules={{
                        required: "This field is required."
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error }
                      }) => (
                        <UploadFile
                          onChange={(e) => {
                            onChange(e);
                            handleUploadPassportPic(e);
                          }}
                          acceptFile="image/*, .pdf, .xls, .xlsx, .docx, .doc"
                          error={error}
                          setSelectedFile={setUploadDoc}
                        />
                      )}
                    />
                  ) : (
                    <Card
                      sx={{
                        padding: "30px 25px",
                        background: "#F5F7FA",
                        boxShadow: "none",
                        borderRadius: "5px",
                        position: "relative"
                      }}
                    >
                      <DeleteIcon
                        on
                        sx={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                          cursor: "pointer"
                        }}
                        onClick={() => {
                          handlePassportPicDelete(uploadDoc);
                        }}
                      />
                      <CardActionArea>
                        <CardContent
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column"
                          }}
                        >
                          <img
                            src="/assets/icons/FileIcon.svg"
                            className="mb-5"
                            alt="file icon"
                          />
                          <Typography
                            variant="body2"
                            fontSize={14}
                            color="#4D4D4D"
                            sx={{ marginBottom: "5px" }}
                          >
                            {`${uploadDoc?.file_name}.${uploadDoc?.file_extension}`}
                          </Typography>
                          <Typography
                            variant="body2"
                            fontSize={14}
                            color="#89939E"
                          >
                            35 KB
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  )}
                </>
              )}
            </FormControl>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", pt: 4 }}>
          <Button
            sx={{
              borderRadius: "4px",
              gap: "10px",
              backgroundColor: "#FFFFFF",
              fontSize: "16px",
              lineHeight: "24px",
              textAlign: "Center",
              fontWeight: 600,
              color: "#717171",
              textTransform: "capitalize",
              width: "209px",
              height: "56px"
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            sx={{
              borderRadius: "4px",
              gap: "10px",
              backgroundColor: "#0671E0",
              fontSize: "16px",
              lineHeight: "24px",
              textAlign: "Center",
              fontWeight: 600,
              color: "#FFFFFF",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#0671E0"
              },
              width: "209px",
              height: "56px"
            }}
            disabled={isFileUploaded || loading}
          >
            Submit
          </Button>
        </Box>
      </form>
      </>
    );
  };

  return (
    <>
      <ModalCommon
        open={open}
        setOpen={setOpen}
        header={<Header />}
        body={Body()}
        justifyContent={"center"}
        height={464}
        width={551}
      />
    </>
  );
};
