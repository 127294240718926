import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const BuyerProfileInfo = ({ buyerInfo }) => {

  return (
    <Box
      sx={{
        borderRadius: "10px",
        border: "1px solid #E8E8EA",
        padding: "22px",
        marginBottom: "50px",
      }}
    >
      <Grid container spacing={2}>

        <Grid item xs={12} md={4}>
          <Box mb={'25px'}>
            <Typography color={'#ABABAB'} fontSize={16} fontWeight={600} lineHeight={'28px'} mb={'5px'} variant="body1">Full Name</Typography>
            <Typography color={'#212121'} fontSize={16} fontWeight={500} lineHeight={'28px'} variant="body2">{buyerInfo?.buyers && buyerInfo?.buyers[0]?.first_name} {buyerInfo?.buyers && buyerInfo?.buyers[0]?.middle_name} {buyerInfo?.buyers && buyerInfo?.buyers[0]?.last_name}</Typography>
          </Box>

          <Box mb={'25px'}>
            <Typography color={'#ABABAB'} fontSize={16} fontWeight={600} lineHeight={'28px'} mb={'5px'} variant="body1">Email ID</Typography>
            <Box display={'flex'} alignItems={'center'}>
              <Typography color={'#212121'} fontSize={16} fontWeight={500} lineHeight={'28px'} variant="body2" mr={'5px'}>{buyerInfo?.buyers && buyerInfo?.buyers[0]?.email}</Typography>
              <img src="/assets/icons/success.svg" alt="success icon" />
            </Box>
          </Box>

          <Box mb={'25px'}>
            <Typography color={'#ABABAB'} fontSize={16} fontWeight={600} lineHeight={'28px'} mb={'5px'} variant="body1">Phone Number</Typography>
            <Box display={'flex'} alignItems={'center'}>
              <Typography color={'#212121'} fontSize={16} fontWeight={500} lineHeight={'28px'} variant="body2" mr={'5px'}>{buyerInfo?.buyers && buyerInfo?.buyers[0]?.phone}</Typography>
            </Box>
          </Box>

         
        </Grid>

        <Grid item xs={12} md={4}>
          <Box mb={'25px'}>
            <Typography color={'#ABABAB'} fontSize={16} fontWeight={600} lineHeight={'28px'} mb={'5px'} variant="body1">Country</Typography>
            <Typography color={'#212121'} fontSize={16} fontWeight={500} lineHeight={'28px'} variant="body2">{buyerInfo?.buyers && buyerInfo?.buyers[0]?.country}</Typography>
          </Box>

          <Box mb={'25px'}>
            <Typography color={'#ABABAB'} fontSize={16} fontWeight={600} lineHeight={'28px'} mb={'5px'} variant="body1">State</Typography>
            <Typography color={'#212121'} fontSize={16} fontWeight={500} lineHeight={'28px'} variant="body2">{buyerInfo?.buyers && buyerInfo?.buyers[0]?.state}</Typography>
          </Box>

          <Box mb={'25px'}>
            <Typography color={'#ABABAB'} fontSize={16} fontWeight={600} lineHeight={'28px'} mb={'5px'} variant="body1">City</Typography>
            <Typography color={'#212121'} fontSize={16} fontWeight={500} lineHeight={'28px'} variant="body2">{buyerInfo?.buyers && buyerInfo?.buyers[0]?.city}</Typography>
          </Box>


        </Grid>

        <Grid item xs={12} md={4}>
          <Box mb={'25px'}>
            <Typography color={'#ABABAB'} fontSize={16} fontWeight={600} lineHeight={'28px'} mb={'5px'} variant="body1">Citizenship & Residency</Typography>
            <Typography color={'#212121'} fontSize={16} fontWeight={500} lineHeight={'28px'} variant="body2">{`${buyerInfo?.buyers && buyerInfo?.buyers[0]?.citizenship} & ${buyerInfo?.buyers && buyerInfo?.buyers[0]?.residency_type} `}</Typography>
          </Box>
          <Box mb={'25px'}>
            <Typography color={'#ABABAB'} fontSize={16} fontWeight={600} lineHeight={'28px'} mb={'5px'} variant="body1">Pin/Zip</Typography>
            <Typography color={'#212121'} fontSize={16} fontWeight={500} lineHeight={'28px'} variant="body2">{buyerInfo?.buyers && buyerInfo?.buyers[0]?.zip}</Typography>
          </Box>

          <Box mb={'25px'}>
            <Typography color={'#ABABAB'} fontSize={16} fontWeight={600} lineHeight={'28px'} mb={'5px'} variant="body1">Address</Typography>
            <Typography color={'#212121'} fontSize={16} fontWeight={500} lineHeight={'28px'} variant="body2">{buyerInfo?.buyers && buyerInfo?.buyers[0]?.street}</Typography>
          </Box>
        </Grid>


      </Grid>
    </Box>
  );
};

export default BuyerProfileInfo;
