import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { SaleAgreements } from "./SaleAgreements";
import { Installments } from "./Installments";
import { Bookings } from "./Bookings";
import { IDVerifications } from "./IDVerifications";

export const DashboardChart = () => {
  return (
    <Container maxWidth="xl">
      <Box sx={{
         paddingLeft: '14px !important', 
      }}>
        <Box>
          <Typography
            sx={{
              fontSize: "25px",
              fontWeight: 600,
              color: "#212121",
              lineHeight: "23px",
              paddingY: 1.5
            }}
          >
            Dashboard
          </Typography>
          <hr
            style={{ paddingBottom: "20px", color: "#E8E8EA", height: "2px" }}
          />
        </Box>
        {/* <Box><SaleAgreements /></Box>
                <Box><Installments /></Box>
                <Box><Bookings /></Box>
                <Box><IDVerifications /></Box> */}
        <Box>
          <SaleAgreements />
        </Box>
        <Box>
          <Installments />
        </Box>
        <Box>
          <Bookings />
        </Box>
        <Box>
          <IDVerifications />
        </Box>
      </Box>
    </Container>
  );
};
