import { Box, Divider, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import TableData from "../../../../components/TableData"
import PaymentHistory from "./PaymentHistory"
import { Price } from "./Price"
import { useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"
import {
  AdminPaymentScheduleTable,
  bankAccountDetails,
} from "../../../../lib/query/query"
import * as Sentry from "@sentry/react"
import AccountDetails from "../../MyProperties/AccountDetails"
import ErrorToast from "../../../../components/ErrorToast"
import { toast } from "react-toastify"

const PaymentSchedule = ({ buyerInfo }) => {
  const [paymentTable, setPaymentTable] = useState([])
  const [paymentStatus, setPaymentStatus] = useState(null)
  const [bankDetails, setBankDetails] = useState(null)

  const { salesId, propertyId } = useParams()

  useEffect(() => {
    if (buyerInfo) {
      const { sale_transaction_statuses } = buyerInfo
      if (sale_transaction_statuses) {
        sale_transaction_statuses.find(
          (i) => i.process_step === "Book Property"
        ).status === "Yet to Start" && setPaymentStatus("Yet to Start")
      }
    }
  }, [buyerInfo])

  const { data: bankAccountData, error: errorBankAccountCall } = useQuery(
    bankAccountDetails,
    {
      variables: { property_id: propertyId },
    }
  )

  const { data, error: errorData } = useQuery(AdminPaymentScheduleTable, {
    variables: { sale_transaction_id: salesId },
  })

  useEffect(() => {
    if (data && data?.sale_transaction_payment_schedule?.length > 0) {
      setPaymentTable(data?.sale_transaction_payment_schedule)
    }
  }, [data])

  useEffect(() => {
    if (bankAccountData && bankAccountData?.property_bank_acct?.length > 0) {
      setBankDetails(bankAccountData.property_bank_acct[0])
    }
  }, [bankAccountData])

  const columns = [
    {
      field: "item",
      headerName: "Item",
      width: 200,
    },
    {
      field: "dueDate",
      headerName: "Due date",
      width: 200,
    },
    {
      field: "amountDue",
      headerName: "Amount Due",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
  ]

  const rows =
    paymentTable?.length > 0 &&
    paymentTable?.map((item, index) => {
      return {
        id: index,
        amountDue: item?.amount?.toLocaleString("en-IN", {
          maximumFractionDigits: 2,
        }),
        dueDate: item?.due_date,
        item: item?.item,
      }
    })

  if (errorData || errorBankAccountCall) {
    toast.error(
      <ErrorToast
        email={"info@msvkanha.com"}
        errorMsg={
          (errorData && errorData?.message) ||
          (errorBankAccountCall && errorBankAccountCall?.message)
        }
      />
    )
    Sentry.captureException(errorData || errorBankAccountCall)
  }

  return (
    <>
      <Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}>
            <Box>
              <Price paymentStatus={paymentStatus} />
            </Box>

            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ borderWidth: "1px" }}
            />

            <Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "25px",
                  lineHeight: "30px",
                  color: "#212121",
                  marginBottom: "20px",
                  display: "flex",
                }}>
                Bank Account Details
              </Typography>
              <AccountDetails bankAccData={bankDetails} />
            </Box>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "25px",
                lineHeight: "30px",
                color: "#212121",
                marginBottom: "20px",
              }}>
              Property Payment Schedule
            </Typography>
          </Box>
          <Box>
            <TableData
              rows={rows}
              columns={columns}
              HeaderbackgroundColor={"#F5F7FA"}
              width={650}
              noDataFound={"No Inventory Found"}
              rowPerPage={10}
            />
          </Box>
        </Box>

        <Box>
          <PaymentHistory />
        </Box>
      </Box>
    </>
  )
}

export default PaymentSchedule
