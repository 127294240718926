import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { Provider } from "react-redux";
import { StepperProvider } from "./lib/context/contextData";
import configureStore from "./redux/configure-store";
import * as Sentry from "@sentry/react";
import reportWebVitals from "./reportWebVitals";
import useToken from "./hook/useToken";
import App from "./App";
import "./index.css";
import useAuthToken from "./auth/useAuthToken";

const { REACT_APP_URI, REACT_APP_SENTRY_DSN, REACT_APP_SENTRY_SERVER_DSN } =
  process.env;

const store = configureStore();

Sentry.init({
  dsn: REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration(
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      {
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
      }
    ),
  ],
  autoSessionTracking: true,
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", REACT_APP_SENTRY_SERVER_DSN],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  _experiments: {
    canvasRecording: true,
  },
});

const Root = () => {
  useAuthToken();
  const { token } = useToken();
  const [isLoading, setIsLoading] = useState(true);
  const [client, setClient] = useState(null);
  const [error, setError] = useState(null);


  useEffect(() => {
    const storedToken = JSON.parse(localStorage.getItem("cognitoAuthDetails"))
      ?.idToken;

    if (token || storedToken) {
      try {
        const newClient = new ApolloClient({
          uri: REACT_APP_URI,
          headers: {
            Authorization: `Bearer ${token ? token : storedToken}`,
          },
          cache: new InMemoryCache(),
        });
        setClient(newClient);
        setIsLoading(false);
      } catch (err) {
        setError(err);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, [token]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!client) {
    const loginUrl = `${process.env.REACT_APP_LOGIN_URL}/?response_type=token&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`;
    window.location.href = loginUrl;

    return <div>No client available. Please check token retrieval.</div>;
  }

  return (
   
      <ApolloProvider client={client}>
        <StepperProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </StepperProvider>
      </ApolloProvider>
  );
};


ReactDOM.render( <Provider store={store}><Root /></Provider>, document.getElementById("root"));

reportWebVitals();
