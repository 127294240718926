import React from "react";
import { Box, Container, Typography } from "@mui/material";

export const BuyProperties = () => {
  return (
    <Container>
      <Box>
        <Box>
          <Typography
            sx={{
              mt: 1,
              fontSize: "25px",
              color: "#212121",
              fontWeight: 500,
              lineHeight: "25px",
            }}
          >
            Buy properties
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              color: "#717171",
              marginTop: "10px",
              lineHeight: "20px",
            }}
          >
            Compleate this steps to buy new property.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};
