import HomeIcon from "@mui/icons-material/Home";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";

export const homeTab = [
    {
        label: "My properties",
        icon: <HomeIcon />,
        iconPosition: "start",
        path: "/",
        id: 0
    },
    {
        label: "Payments",
        icon: <CreditCardIcon />,
        iconPosition: "start",
        path: "/payments",
        id: 1,
    },
    {
        label: "Downloads",
        icon: <SystemUpdateAltIcon />,
        iconPosition: "start",
        path: "/download",
        id: 2
    },
    {
        label: "Help",
        icon: <HelpCenterIcon />,
        iconPosition: "start",
        path: "/help",
        id: 3
    },
];

