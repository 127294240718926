import React from "react";
import TabLayout from "../../components/Layouts/TabLayout";
import { useQuery } from "@apollo/client";
import { helpData } from "../../lib/query/query";
import { Container } from "@mui/material";
import * as Sentry from "@sentry/react";

export const Help = () => {
  const { data, error } = useQuery(helpData, {
    variables: { page: "help" }
  });

  if (error) {
    Sentry.captureException(error);
  }

  return (
    <TabLayout>
      <Container>
        <div
          dangerouslySetInnerHTML={{ __html: data?.static_html[0]?.content }}
        />
      </Container>
    </TabLayout>
  );
};

export default Help;
