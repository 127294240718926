import React from "react";
import TabLayout from "../components/Layouts/TabLayout";
import MyPropertiesCard from "../containers/tabView/MyProperties/MyPropertiesCard";

const Home = () => {

    return (
        <TabLayout>
            <MyPropertiesCard />
        </TabLayout>

    );
};

export default Home;
