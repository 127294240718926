import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import TableData from "../../../components/TableData"
import BackButton from "../../../components/BackButton"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { CustomFormLabel } from "../../../lib/constants/textField"
import {
  adjustmentsCredits,
  bankAccountDetails,
  // bookPropertiesCancellationfee,
  bookPropertyRePrefill,
  bookingLocationData,
  bookingProjectData,
  discountsAdjustments,
  paymentSchedule,
  projectTableData,
  saleTransactionStatus,
  unitSize,
} from "../../../lib/query/query"
import {
  // bookPropertyNextStepSubmit,
  bookPropertySubmit,
  // bookPropertyUpdateStatusSubmit,
  // bookPropertyUpdateStepSubmit,
  bookPropertyUpdateSubmit,
} from "../../../lib/mutation/mutation"
import Tower from "./Tower"
import { Controller, useForm } from "react-hook-form"
import Steppercontext from "../../../lib/context/contextData"
import _ from "lodash"
import * as Sentry from "@sentry/react"
import { toast } from "react-toastify"
import ErrorToast from "../../../components/ErrorToast"

export const BookProperties = () => {
  const navigate = useNavigate()

  const [Revisit, setRevisit] = useState({})
  const [location, setLocation] = useState("")
  const [locationCity, setLocationCity] = useState([])
  const [bookPropertyTable, setBookPropertyTable] = useState([])
  const [selectedRow, setSelectedRow] = useState({})
  const [selectedRowCheck, setSelectedRowCheck] = useState(false)
  const [propertyUnitSizeData, setPropertyUnitSizeData] = useState([])
  const [unitSizeValue, setUnitSizeValue] = useState("")
  const [paymentData, setPaymentData] = useState([])
  const [bankAccData, setBankAccData] = useState({})
  const [adjustCreditData, setAdjustCreditData] = useState({})
  const [discAdjustData, setDiscAdjustData] = useState({})
  const [paymentScheduleTotal, setPaymentScheduleTotal] = useState({})
  const [checkCheckbox, setCheckCheckbox] = useState({
    bank_loan_flag: false,
    bankLoanNo: false,
    ack_booking_amount: false,
    ack_first_installment: false,
    ack_payment_sched: false,
    ack_loan_responsibility: false,
  })

  const [isLoading, setIsLoading] = useState(false)

  const { salesId } = useParams()

  const { loading, data, error: errorBooking } = useQuery(bookingLocationData)

  const [submitPaymentSchedule, { error: errorBookProperty }] =
    useMutation(bookPropertySubmit)

  const [bookPropertyUpdate, { error: errorBookPropertyUpdate }] = useMutation(
    bookPropertyUpdateSubmit
  )
  // const [bookPropertyUpdateStep] = useMutation(bookPropertyUpdateStepSubmit);
  // const [bookPropertyNextStep] = useMutation(bookPropertyNextStepSubmit);
  // const [bookPropertyUpdateStatus] = useMutation(
  //   bookPropertyUpdateStatusSubmit
  // );

  const { data: data1, error: errorData1 } = useQuery(bookPropertyRePrefill, {
    variables: { sale_transaction_id: salesId },
  })

  const { data: saleTransaction, error: errorTransactionStatus } = useQuery(
    saleTransactionStatus,
    {
      variables: { sale_transaction_id: salesId },
    }
  )

  const { setSalesId, events } = useContext(Steppercontext)

  const params =
    events &&
    events.sale_transaction &&
    events.sale_transaction[0]?.sale_transaction_statuses

  const sortedParams = params
    ?.slice()
    .sort((a, b) => a.step_index - b.step_index)

  const lockedValue = sortedParams && sortedParams[1]?.locked

  const cancledTransacton =
    saleTransaction?.sale_transaction[0]?.status === "Cancellation Requested"
  const bookingConfirm = params?.find(
    (p) =>
      p.process_step === "Book Property" &&
      p.status === "Complete" &&
      !cancledTransacton
  )

  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      bank_loan_flag: false,
      bankLoanNo: false,
      ack_booking_amount: false,
      ack_first_installment: false,
      ack_payment_sched: false,
      ack_loan_responsibility: false,
    },
  })

  useEffect(() => {
    setSalesId(salesId)
  }, [salesId])

  useEffect(() => {
    if (data1 && data1?.sale_transaction[0]) {
      setRevisit(data1?.sale_transaction[0])
    }
  }, [data1])

  useEffect(() => {
    if (!_.isEmpty(Revisit)) {
      Object.keys(Revisit).forEach((key) => {
        setValue(key, Revisit[key] || "")
      })
    }
  }, [Revisit, setValue])

  useEffect(() => {
    if (data && data.project.length > 0) {
      setLocationCity(data.project)
    }
  }, [data])

  const [
    unitSizeCall,
    { data: unitSizeData, refetch: projectNameRefetch, error: errorUnitSize },
  ] = useLazyQuery(unitSize, {
    variables: { city: location },
  })

  useEffect(() => {
    if (location) {
      unitSizeCall()
    }
  }, [location])

  const [
    projectTable,
    { data: projectTableDetails, refetch, error: errorTableDetails },
  ] = useLazyQuery(projectTableData)

  useEffect(() => {
    if (projectTableDetails && projectTableDetails.property) {
      setBookPropertyTable(projectTableDetails.property)
      setSelectedRowCheck(true)
    }
  }, [projectTableDetails])

  useEffect(() => {
    if (unitSizeValue || unitSizeValue === 0) {
      projectTable({
        variables: {
          sale_status: "Available",
          model_type: unitSizeValue,
          city: location,
        },
      })
    }
  }, [unitSizeValue])

  useEffect(() => {
    if (unitSizeData && unitSizeData.property_model) {
      setSelectedRowCheck(true)
      setPropertyUnitSizeData(unitSizeData.property_model)
      setUnitSizeValue("")
    }
  }, [unitSizeData])

  const handleLocation = (event) => {
    setLocation(event.target.value)
  }

  const handleUnitSize = (event) => {
    setUnitSizeValue(event.target.value)
    setSelectedRowCheck(true)
  }

  const formatDate = (date) => {
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]
    var monthIndex = date.getMonth(),
      year = date.getFullYear().toString()
    return monthNames[monthIndex]?.length <= 5
      ? `${monthNames[monthIndex]} ${year}`
      : `${monthNames[monthIndex]?.slice(0, 3)}. ${year}`
  }

  const columns = [
    {
      field: "model_name",
      headerName: "Model Name",
      width: 150,
      sortable: false,
    },
    {
      field: "beds_count",
      headerName: "Bed",
      width: 90,
      sortable: false,
    },
    {
      field: "baths_count",
      headerName: "Bath",
      width: 90,
      sortable: false,
    },
    {
      field: "sqft_area",
      headerName: "Sq.ft.",
      width: 90,
      sortable: false,
    },
    {
      field: "project_name",
      headerName: "Project Name",
      width: 200,
      sortable: false,
    },
    {
      field: "tower",
      headerName: "Tower",
      width: 150,
      sortable: false,
    },
    {
      field: "floor_number",
      headerName: "Floor",
      width: 90,
      sortable: false,
    },
    {
      field: "unit_number",
      headerName: "Unit#",
      width: 90,
      sortable: false,
    },
    {
      field: "est_delivery",
      headerName: "Est. Delivery",
      width: 200,
      sortable: false,
      valueFormatter: (params) => {
        return formatDate(new Date(params.value))
      },
    },
  ]

  const rows = bookPropertyTable.map((item) => {
    return {
      id: item.property_id,
      beds_count: item.property_model.beds_count,
      baths_count: item.property_model.baths_count,
      sqft_area: item.property_model.sqft_area,
      tower: item?.project_building?.tower_name,
      floor_number: item.floor_number,
      unit_number: item.unit_number,
      est_delivery: item.estimated_delivery,
      model_name: item.property_model.model_name,
      file_name: item.property_model.file_name,
      file_extension: item.property_model.file_extension,
      project_name: item.project.project_name,
      municipal_hold: item.municipal_hold,
      row: item,
    }
  })

  const filteredRows = rows

  const handleOnCellClick = (params) => {
    setSelectedRow(params.row)
  }

  useEffect(() => {
    if (selectedRowCheck) {
      if (filteredRows.length > 0) {
        setSelectedRow(filteredRows[0])
        setSelectedRowCheck(false)
      }
    }
  }, [filteredRows])

  const [
    paymentScheduleCall,
    {
      data: paymentScheduleData,
      refetch: paymentRefetch,
      error: errorPatmentSchedule,
    },
  ] = useLazyQuery(paymentSchedule, {
    variables: {
      property_id:
        Revisit.property_id === null ? selectedRow?.id : Revisit.property_id,
    },
  })

  useEffect(() => {
    if (paymentScheduleData && paymentScheduleData.property) {
      const payments =
        paymentScheduleData.property[0].property_payment_schedule_plan
          ?.property_payment_schedules
      setPaymentData(payments)
    }
  }, [paymentScheduleData])

  const [
    bankAccountCall,
    {
      data: bankAccountData,
      refetch: bankRefetch,
      error: errorBankAccountCall,
    },
  ] = useLazyQuery(bankAccountDetails, {
    variables: {
      property_id:
        Revisit.property_id === null ? selectedRow?.id : Revisit.property_id,
    },
  })

  useEffect(() => {
    if (bankAccountData && bankAccountData.property_bank_acct) {
      const bankAccount = bankAccountData.property_bank_acct[0]
      setBankAccData(bankAccount)
    }
  }, [bankAccountData])

  const [
    adjustmentsCreditsCall,
    {
      data: adjustmentsCreditData,
      refetch: adjustmentRefetch,
      error: errorAdjustmentsCredits,
    },
  ] = useLazyQuery(adjustmentsCredits, {
    variables: {
      property_id:
        Revisit.property_id === null ? selectedRow?.id : Revisit.property_id,
    },
  })

  useEffect(() => {
    if (
      adjustmentsCreditData &&
      adjustmentsCreditData.property_price &&
      adjustmentsCreditData.property_price_aggregate
    ) {
      setAdjustCreditData(adjustmentsCreditData)
    }
  }, [adjustmentsCreditData])

  const [
    discountsAdjustmentsCall,
    {
      data: discountsAdjustmentData,
      refetch: discountRefetch,
      error: errorDiscountsAdjustments,
    },
  ] = useLazyQuery(discountsAdjustments, {
    variables: { sale_transaction_id: salesId },
  })

  useEffect(() => {
    if (
      discountsAdjustmentData &&
      discountsAdjustmentData.price_adjustment_aggregate
    ) {
      setDiscAdjustData(discountsAdjustmentData)
    }
  }, [discountsAdjustmentData])

  useEffect(() => {
    if (selectedRow?.id) {
      paymentScheduleCall()
      bankAccountCall()
      adjustmentsCreditsCall()
      discountsAdjustmentsCall()
      refetch()
      paymentRefetch()
      bankRefetch()
      adjustmentRefetch()
      discountRefetch()
    }
  }, [selectedRow?.id])

  const onSubmit = async (data) => {
    setIsLoading(true)
    if (paymentScheduleTotal) {
      for (var i = 0; i < paymentScheduleTotal.length; i++) {
        const payment = paymentScheduleTotal[i]

        let initial = payment.dueDate.split(/\//)
        const dueDate = [initial[1], initial[0], initial[2]].join("/")

        await submitPaymentSchedule({
          variables: {
            amount: payment.amount,
            due_date: dueDate,
            item: payment.item,
            sale_transaction_id: salesId,
            status: "Pending",
          },
        })
          .then((res) => {
            //
          })
          .catch((err) => {
            Sentry.captureException(err)
          })
      }
    }
    const formData = getValues()
    await bookPropertyUpdate({
      variables: {
        sale_transaction_id: salesId,
        ack_first_installment: checkCheckbox.ack_first_installment,
        ack_payment_sched: checkCheckbox.ack_payment_sched,
        bank_loan_flag: formData.bank_loan_flag,
        ack_booking_amount: checkCheckbox.ack_booking_amount,
        ack_loan_responsibility: checkCheckbox.ack_loan_responsibility,
        property_id: selectedRow?.id,
      },
    })
      .then((res) => {
        //
      })
      .catch((err) => {
        Sentry.captureException(err)
      })

    // Commented Mutation (Keep as it is)
    // await bookPropertyUpdateStep({
    //   variables: {
    //     sale_transaction_id: salesId,
    //     step_index: 2,
    //     locked: true,
    //     status: "Pending Validation"
    //   }
    // })
    //   .then((res) => {
    //     //
    //   })
    //   .catch((err) => {});

    // Commented Mutation (Keep as it is)
    // await bookPropertyNextStep({
    //   variables: {
    //     sale_transaction_id: salesId,
    //     step_index: 3,
    //     locked: false
    //   }
    // })
    //   .then((res) => {})
    //   .catch((err) => {});

    // Commented Mutation (Keep as it is)
    // await bookPropertyUpdateStatus({
    //   variables: {
    //     sale_transaction_id: salesId,
    //     step_index: 4,
    //     locked: false,
    //     status: "Action Required"
    //   }
    // })
    //   .then((res) => {
    //     //
    //   })
    //   .catch((err) => {});
    setIsLoading(false)
    Sentry.getCurrentScope().setTransactionName("Book Property")

    navigate(`/myProperties/allStates/${salesId}`)
  }

  const handleCheckboxChange = (checkbox) => {
    if (checkbox === "yes") {
      setCheckCheckbox({
        ...checkCheckbox,
        bank_loan_flag: true,
        bankLoanNo: false,
      })
    } else if (checkbox === "no") {
      setCheckCheckbox({
        ...checkCheckbox,
        bank_loan_flag: false,
        bankLoanNo: true,
      })
    }
  }

  useEffect(() => {
    if (Revisit?.property_id) {
      bankAccountCall()
      adjustmentsCreditsCall()
      paymentScheduleCall()
      discountsAdjustmentsCall()
    }
  }, [Revisit?.property_id])

  useEffect(() => {
    if (Revisit.bank_loan_flag === true) {
      setCheckCheckbox({
        ...checkCheckbox,
        bank_loan_flag: true,
        bankLoanNo: false,
      })
    } else if (Revisit.bank_loan_flag === false) {
      setCheckCheckbox({
        ...checkCheckbox,
        bank_loan_flag: false,
        bankLoanNo: true,
      })
    }
  }, [Revisit.bank_loan_flag])

  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}>
        <CircularProgress />
      </Box>
    )

  if (
    errorBooking ||
    errorData1 ||
    errorTransactionStatus ||
    errorTableDetails ||
    errorUnitSize ||
    errorPatmentSchedule ||
    errorBankAccountCall ||
    errorAdjustmentsCredits ||
    errorDiscountsAdjustments ||
    errorBookProperty ||
    errorBookPropertyUpdate
  ) {
    toast.error(
      <ErrorToast
        email={"info@msvkanha.com"}
        errorMsg={
          (errorBooking && errorBooking.message) ||
          (errorData1 && errorData1.message) ||
          (errorTransactionStatus && errorTransactionStatus.message) ||
          (errorTableDetails && errorTableDetails.message) ||
          (errorUnitSize && errorUnitSize.message) ||
          (errorPatmentSchedule && errorPatmentSchedule.message) ||
          (errorBankAccountCall && errorBankAccountCall.message) ||
          (errorAdjustmentsCredits && errorAdjustmentsCredits.message) ||
          (errorDiscountsAdjustments && errorDiscountsAdjustments.message) ||
          (errorBookProperty && errorBookProperty.message) ||
          (errorBookPropertyUpdate && errorBookPropertyUpdate.message)
        }
      />
    )
    Sentry.captureException(
      (errorBooking && errorBooking.message) ||
        (errorData1 && errorData1.message) ||
        (errorTransactionStatus && errorTransactionStatus.message) ||
        (errorTableDetails && errorTableDetails.message) ||
        (errorUnitSize && errorUnitSize.message) ||
        (errorPatmentSchedule && errorPatmentSchedule.message) ||
        (errorBankAccountCall && errorBankAccountCall.message) ||
        (errorAdjustmentsCredits && errorAdjustmentsCredits.message) ||
        (errorDiscountsAdjustments && errorDiscountsAdjustments.message) ||
        (errorBookProperty && errorBookProperty.message) ||
        (errorBookPropertyUpdate && errorBookPropertyUpdate.message)
    )
  }

  console.log("selectedRow", selectedRow)

  return (
    <Container>
      {!loading && (
        <Box>
          <BackButton handleBack={() => navigate(-1)} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ marginTop: "10px" }}>
              <Typography
                sx={{
                  fontSize: "25px",
                  fontWeight: 600,
                  lineHeight: "30px",
                  color: cancledTransacton
                    ? "red"
                    : bookingConfirm
                    ? "green"
                    : "black",
                }}>
                {cancledTransacton
                  ? "Sale Transaction Cancelled"
                  : bookingConfirm
                  ? "Booking Confirmed"
                  : "Book Property"}
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 400,
                  color: "#717171",
                  lineHeight: "24px",
                  paddingTop: "10px",
                }}>
                {cancledTransacton
                  ? `The transaction has been cancelled.`
                  : bookingConfirm
                  ? `Your selected property has been reserved for you. Please ensure that you adhere to the payment schedule. Failure to make timely payments could impact the booking.`
                  : `Select the property of your choice and make the payments to the
                unique account number. Failure to pay the booking fee within two
                days OR failure to pay the first instalment in the following 15
                days will result in cancellation of the booking.`}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "#4D4D4D",
                  fontWeight: 600,
                  fontSize: "20px",
                  lineHeight: "23px",
                  py: 5,
                }}>
                {Revisit.property === null
                  ? "Select property"
                  : `${Revisit?.property?.project?.city} - ${Revisit?.property?.project?.project_name} - ${Revisit?.property?.property_model?.model_name} - ${Revisit?.property?.project_building?.tower_name} - Unit
          ${Revisit?.property?.unit_number}`}
              </Typography>
              {Revisit.property === null && (
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}>
                      <CustomFormLabel component="legend" required>
                        Location
                      </CustomFormLabel>
                      <Select
                        value={location}
                        onChange={handleLocation}
                        displayEmpty
                        sx={{
                          margin: "10px 0 15px 0",
                          width: "100%",
                          opacity: lockedValue === true ? 0.5 : 1,
                        }}
                        inputProps={{
                          readOnly: lockedValue === true ? true : false,
                          "aria-label": "Without label",
                        }}>
                        <MenuItem selected disabled value="">
                          {Revisit?.property === null
                            ? "City"
                            : Revisit?.property?.project?.city}
                        </MenuItem>

                        {locationCity.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item.city}>
                              {item.city}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </Box>
                  </Grid>

                  <Grid item xs={4}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}>
                      <CustomFormLabel component="legend" required>
                        Unit Size (Bed Room)
                      </CustomFormLabel>
                      <Select
                        value={unitSizeValue}
                        onChange={handleUnitSize}
                        displayEmpty
                        sx={{
                          margin: "10px 0 15px 0",
                          width: "100%",
                          opacity: lockedValue === true ? 0.5 : 1,
                        }}
                        inputProps={{
                          readOnly: lockedValue === true ? true : false,
                          "aria-label": "Without label",
                        }}>
                        <MenuItem selected disabled value="">
                          {Revisit?.property === null
                            ? "Unit Size"
                            : Revisit?.property?.property_model?.beds_count ===
                              0
                            ? "Studio"
                            : `${Revisit?.property?.property_model?.beds_count} BHK`}
                        </MenuItem>

                        {propertyUnitSizeData.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item?.model_type}>
                              {item?.model_type}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Box>
            <Box>
              {(unitSizeValue || unitSizeValue === 0) &&
                filteredRows.length === 0 && (
                  <Typography
                    sx={{
                      color: "#4D4D4D",
                      fontSize: "16px",
                      fontWeight: 500,
                      textAlign: "center",
                      marginTop: 4,
                    }}>
                    No properties that match the selected criteria are available
                    for booking.
                  </Typography>
                )}
            </Box>
            <Box sx={{ pt: 1, cursor: "pointer" }}>
              {selectedRow?.id && filteredRows.length > 0 && (
                <TableData
                  rows={filteredRows}
                  columns={columns}
                  width={"100%"}
                  HeaderbackgroundColor={"#F5F7FA"}
                  handleOnCellClick={handleOnCellClick}
                  rowSelectionModel={selectedRow?.id}
                  noDataFound={
                    "No properties that match the selected criteria are available for booking."
                  }
                />
              )}
            </Box>

            <>
              {Revisit.property === null ? (
                <>
                  {selectedRow?.id && filteredRows.length > 0 && (
                    <Tower
                      selectedRow={selectedRow}
                      paymentData={paymentData}
                      bankAccData={bankAccData}
                      adjustCreditData={adjustCreditData}
                      discAdjustData={discAdjustData}
                      setPaymentScheduleTotal={setPaymentScheduleTotal}
                      lockedValue={lockedValue}
                      Revisit={Revisit}
                    />
                  )}
                </>
              ) : (
                <Tower
                  selectedRow={selectedRow}
                  paymentData={paymentData}
                  bankAccData={bankAccData}
                  adjustCreditData={adjustCreditData}
                  discAdjustData={discAdjustData}
                  setPaymentScheduleTotal={setPaymentScheduleTotal}
                  lockedValue={lockedValue}
                  Revisit={Revisit}
                />
              )}
            </>

            <>
              {Revisit.property === null ? (
                <>
                  {selectedRow?.id && filteredRows.length > 0 && (
                    <Box>
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "25px",
                            fontWeight: 600,
                            color: "#212121",
                            marginTop: "px",
                          }}>
                          {selectedRow?.municipal_hold === true
                            ? "Will you be taking Bank Loan?"
                            : "Will you be taking a bank loan for the payments?"}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: 400,
                            color: "#717171",
                            lineHeight: "24px",
                          }}>
                          {selectedRow?.municipal_hold === true
                            ? "Bank loan cannot be obtained for this property due to the mortgage-hold by Municipal Authorities. By selecting No below - I confirm that I wont need a bank loan to purchase this property."
                            : "This information is required to determine the appropriate Property Registration Agreement. You’ll also receive the contact information of the bank that has the project pre-approved for home loans."}
                        </Typography>
                      </Box>
                      <Box>
                        <FormGroup
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            pt: "10px",
                          }}>
                          <Controller
                            name="bank_loan_flag"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => {
                              return (
                                <>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        {...field}
                                        checked={field.value === "yes"}
                                        onChange={() => field.onChange("yes")}
                                        disabled={
                                          lockedValue === true
                                            ? true
                                            : false ||
                                              Revisit.property
                                                ?.municipal_hold === true ||
                                              selectedRow?.municipal_hold ===
                                                true
                                            ? true
                                            : false
                                        }
                                      />
                                    }
                                    label="Yes"
                                    sx={{ marginRight: "40px" }}
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={field.value === "no"}
                                        onChange={() => field.onChange("no")}
                                        disabled={
                                          lockedValue === true ? true : false
                                        }
                                      />
                                    }
                                    label="No"
                                  />
                                </>
                              )
                            }}
                          />
                        </FormGroup>
                        {errors.bank_loan_flag && (
                          <p style={{ color: "red", fontSize: 12 }}>
                            {" "}
                            Please select one of the options.
                          </p>
                        )}
                      </Box>
                    </Box>
                  )}
                </>
              ) : (
                <>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "25px",
                        fontWeight: 600,
                        color: "#212121",
                        marginTop: "px",
                      }}>
                      Will you be taking a bank loan for the payments?
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 400,
                        color: Revisit.property === null ? "#717171" : "grey",
                        lineHeight: "24px",
                      }}>
                      {Revisit.property?.municipal_hold === true
                        ? "Bank loan cannot be obtained for this property due to the mortgage-hold by Municipal Authorities. By selecting No below - I confirm that I wont need a bank loan to purchase this property."
                        : "This information is required to determine the appropriate Property Registration Agreement. You’ll also receive the contact information of the bank that has the project pre-approved for home loans."}
                    </Typography>
                  </Box>
                  <Box>
                    <FormGroup
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        pt: "10px",
                      }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkCheckbox.bank_loan_flag}
                            onChange={() => handleCheckboxChange("yes")}
                            disabled={lockedValue === true ? true : false}
                          />
                        }
                        name="bank_loan_flag"
                        label="Yes"
                        sx={{ marginRight: "40px" }}
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkCheckbox.bankLoanNo}
                            onChange={() => handleCheckboxChange("no")}
                            disabled={lockedValue === true ? true : false}
                          />
                        }
                        label="No"
                        name="bankLoanNo"
                      />
                    </FormGroup>
                  </Box>
                </>
              )}
            </>

            <>
              {Revisit.property === null ? (
                <>
                  {selectedRow?.id && filteredRows.length > 0 && (
                    <Box sx={{ margin: "20px 0" }}>
                      <Box
                        sx={{
                          display: "flex",
                          border: "1px solid #0671E0",
                          borderRadius: "10px",
                          backgroundColor: "#EEF5FC",

                          height: "346px",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "20px",
                          flexDirection: "column",
                        }}>
                        <Controller
                          name="ack_booking_amount"
                          control={control}
                          defaultValue={false}
                          rules={{
                            validate: (value) =>
                              value || "This field is required.",
                          }}
                          render={({ field: { onChange, value } }) => (
                            <Box>
                              <FormControlLabel
                                {...register("ack_booking_amount", {
                                  required: "This field is required.",
                                })}
                                control={
                                  <Checkbox
                                    checked={value}
                                    onChange={(e) => {
                                      setCheckCheckbox({
                                        ...checkCheckbox,
                                        ack_booking_amount: e.target.checked,
                                      })
                                      onChange(e.target.checked)
                                    }}
                                  />
                                }
                                label={
                                  <>
                                    I acknowledge that I have read and agree to
                                    the{" "}
                                    {
                                      <Link
                                        to="/termsCondition"
                                        target="_blnak"
                                        style={{
                                          textDecoration: "underline",
                                          color: "#023a76",
                                        }}>
                                        Terms & Conditions.
                                      </Link>
                                    }{" "}
                                    I understand that I need to pay the Booking
                                    Amount within two business days. Failure to
                                    do so will result in the cancellation of the
                                    booking.*
                                  </>
                                }
                                sx={{
                                  "& .MuiTypography-root": {
                                    fontSize: "16px",
                                    color: "#717171",
                                    lineHeight: "24px",
                                  },
                                  padding: "10px 0",
                                }}
                              />
                              {errors.ack_booking_amount && (
                                <Typography
                                  sx={{ color: "#d32f2f", fontSize: "12px" }}>
                                  {errors.ack_booking_amount.message}
                                </Typography>
                              )}
                            </Box>
                          )}
                        />
                        <Controller
                          name="ack_first_installment"
                          control={control}
                          defaultValue={false}
                          rules={{ required: "This field is required." }}
                          render={({ field: { onChange, value } }) => (
                            <Box>
                              <FormControlLabel
                                {...register("ack_first_installment", {
                                  required: "This field is required.",
                                })}
                                control={
                                  <Checkbox
                                    checked={value}
                                    onChange={(e) => {
                                      setCheckCheckbox({
                                        ...checkCheckbox,
                                        ack_first_installment: e.target.checked,
                                      })
                                      onChange(e.target.checked)
                                    }}
                                  />
                                }
                                label="I acknowledge that I need to make the First Installment payment and complete the Sale Agreement within 15 days. Failure to do so may result in booking cancellation and refund of the booking amount less an application processing fee.*"
                                sx={{
                                  "& .MuiTypography-root": {
                                    fontSize: "16px",
                                    color: "#717171",
                                    lineHeight: "24px",
                                  },
                                  padding: "10px 0",
                                }}
                              />
                              {errors.ack_first_installment && (
                                <Typography
                                  sx={{ color: "#d32f2f", fontSize: "12px" }}>
                                  {errors.ack_first_installment.message}
                                </Typography>
                              )}
                            </Box>
                          )}
                        />
                        <Controller
                          name="ack_payment_sched"
                          control={control}
                          defaultValue={false}
                          rules={{ required: "This field is required." }}
                          render={({ field: { onChange, value } }) => (
                            <Box>
                              <FormControlLabel
                                {...register("ack_payment_sched", {
                                  required: "This field is required.",
                                })}
                                control={
                                  <Checkbox
                                    checked={value}
                                    onChange={(e) => {
                                      setCheckCheckbox({
                                        ...checkCheckbox,
                                        ack_payment_sched: e.target.checked,
                                      })
                                      onChange(e.target.checked)
                                    }}
                                  />
                                }
                                label="I acknowledge that reviewed the Payment Schedule table and that I will be able to make timely payments. I understand that failure to stick to the payment schedule may impact the purchase process.*"
                                sx={{
                                  "& .MuiTypography-root": {
                                    fontSize: "16px",
                                    color: "#717171",
                                    lineHeight: "24px",
                                  },
                                  padding: "10px 0",
                                }}
                              />
                              {errors.ack_payment_sched && (
                                <Typography
                                  sx={{ color: "#d32f2f", fontSize: "12px" }}>
                                  {errors.ack_payment_sched.message}
                                </Typography>
                              )}
                            </Box>
                          )}
                        />
                        <Controller
                          name="ack_loan_responsibility"
                          control={control}
                          defaultValue={false}
                          rules={{ required: "This field is required." }}
                          render={({ field: { onChange, value } }) => (
                            <Box>
                              <FormControlLabel
                                {...register("ack_loan_responsibility", {
                                  required: "This field is required.",
                                })}
                                control={
                                  <Checkbox
                                    checked={value}
                                    onChange={(e) => {
                                      setCheckCheckbox({
                                        ...checkCheckbox,
                                        ack_loan_responsibility:
                                          e.target.checked,
                                      })
                                      onChange(e.target.checked)
                                    }}
                                  />
                                }
                                label="I acknowledge that I am responsible for arranging bank finance / loan (if required) for the purchase of the property and adhere to the payment schedule.*"
                                sx={{
                                  "& .MuiTypography-root": {
                                    fontSize: "16px",
                                    color: "#717171",
                                    lineHeight: "24px",
                                  },
                                  padding: "10px 0",
                                }}
                              />
                              {errors.ack_loan_responsibility && (
                                <Typography
                                  sx={{ color: "#d32f2f", fontSize: "12px" }}>
                                  {errors.ack_loan_responsibility.message}
                                </Typography>
                              )}
                            </Box>
                          )}
                        />
                      </Box>

                      <Box
                        sx={{
                          pt: "40px",
                          mb: "30px",
                          display: "flex",
                          justifyContent: "end",
                        }}>
                        <Button
                          variant="contained"
                          type="submit"
                          sx={{
                            backgroundColor: "#0671E0",
                            color: "white",
                            textTransform: "capitalize",
                            fontSize: "16px",
                            lineHeight: "20px",
                            fontWeight: 400,
                            borderRadius: "4px",
                            "&:hover": {
                              backgroundColor: "#0671E0",
                            },
                            padding: "14px 32px",
                            height: "48px",
                            width: "174px",
                          }}
                          disabled={isLoading}>
                          {isLoading ? (
                            <CircularProgress
                              size={20}
                              sx={{ color: "white", marginLeft: "10px" }}
                            />
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </Box>
                    </Box>
                  )}
                </>
              ) : (
                <Box sx={{ margin: "20px 0" }}>
                  <Box
                    sx={{
                      display: "flex",
                      border: "1px solid #0671E0",
                      borderRadius: "10px",
                      backgroundColor: "#EEF5FC",

                      height: "346px",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "20px",
                      flexDirection: "column",
                    }}>
                    <Controller
                      name="ack_booking_amount"
                      control={control}
                      defaultValue={false}
                      rules={{
                        validate: (value) => value || "This field is required.",
                      }}
                      render={({ field: { onChange, value } }) => (
                        <Box>
                          <FormControlLabel
                            {...register("ack_booking_amount", {
                              required: "This field is required.",
                            })}
                            control={
                              <Checkbox
                                checked={value}
                                onChange={(e) => {
                                  setCheckCheckbox({
                                    ...checkCheckbox,
                                    ack_booking_amount: e.target.checked,
                                  })
                                  onChange(e.target.checked)
                                }}
                                disabled={lockedValue === true ? true : false}
                              />
                            }
                            label={
                              <>
                                I acknowledge that I have read and agree to the{" "}
                                {
                                  <Link
                                    to="/termsCondition"
                                    target="_blnak"
                                    style={{ textDecoration: "underline" }}>
                                    Terms & Conditions.
                                  </Link>
                                }{" "}
                                I understand that I need to pay the Booking
                                Amount within two business days. Failure to do
                                so will result in the cancellation of the
                                booking.*
                              </>
                            }
                            sx={{
                              "& .MuiTypography-root": {
                                fontSize: "16px",
                                color: "#717171",
                                lineHeight: "24px",
                              },
                              padding: "10px 0",
                            }}
                          />
                          {errors.ack_booking_amount && (
                            <Typography
                              sx={{ color: "#d32f2f", fontSize: "12px" }}>
                              {errors.ack_booking_amount.message}
                            </Typography>
                          )}
                        </Box>
                      )}
                    />
                    <Controller
                      name="ack_first_installment"
                      control={control}
                      defaultValue={false}
                      rules={{ required: "This field is required." }}
                      render={({ field: { onChange, value } }) => (
                        <Box>
                          <FormControlLabel
                            {...register("ack_first_installment", {
                              required: "This field is required.",
                            })}
                            control={
                              <Checkbox
                                checked={value}
                                onChange={(e) => {
                                  setCheckCheckbox({
                                    ...checkCheckbox,
                                    ack_first_installment: e.target.checked,
                                  })
                                  onChange(e.target.checked)
                                }}
                                disabled={lockedValue === true ? true : false}
                              />
                            }
                            label="I acknowledge that I need to make the First Installment payment and complete the Sale Agreement within 15 days. Failure to do so may result in booking cancellation and refund of the booking amount less an application processing fee.*"
                            sx={{
                              "& .MuiTypography-root": {
                                fontSize: "16px",
                                color: "#717171",
                                lineHeight: "24px",
                              },
                              padding: "10px 0",
                            }}
                          />
                          {errors.ack_first_installment && (
                            <Typography
                              sx={{ color: "#d32f2f", fontSize: "12px" }}>
                              {errors.ack_first_installment.message}
                            </Typography>
                          )}
                        </Box>
                      )}
                    />
                    <Controller
                      name="ack_payment_sched"
                      control={control}
                      defaultValue={false}
                      rules={{ required: "This field is required." }}
                      render={({ field: { onChange, value } }) => (
                        <Box>
                          <FormControlLabel
                            {...register("ack_payment_sched", {
                              required: "This field is required.",
                            })}
                            control={
                              <Checkbox
                                checked={value}
                                onChange={(e) => {
                                  setCheckCheckbox({
                                    ...checkCheckbox,
                                    ack_payment_sched: e.target.checked,
                                  })
                                  onChange(e.target.checked)
                                }}
                                disabled={lockedValue === true ? true : false}
                              />
                            }
                            label="I acknowledge that reviewed the Payment Schedule table and that I will be able to make timely payments. I understand that failure to stick to the payment schedule may impact the purchase process.*"
                            sx={{
                              "& .MuiTypography-root": {
                                fontSize: "16px",
                                color: "#717171",
                                lineHeight: "24px",
                              },
                              padding: "10px 0",
                            }}
                          />
                          {errors.ack_payment_sched && (
                            <Typography
                              sx={{ color: "#d32f2f", fontSize: "12px" }}>
                              {errors.ack_payment_sched.message}
                            </Typography>
                          )}
                        </Box>
                      )}
                    />
                    <Controller
                      name="ack_loan_responsibility"
                      control={control}
                      defaultValue={false}
                      rules={{ required: "This field is required." }}
                      render={({ field: { onChange, value } }) => (
                        <Box>
                          <FormControlLabel
                            {...register("ack_loan_responsibility", {
                              required: "This field is required.",
                            })}
                            control={
                              <Checkbox
                                checked={value}
                                onChange={(e) => {
                                  setCheckCheckbox({
                                    ...checkCheckbox,
                                    ack_loan_responsibility: e.target.checked,
                                  })
                                  onChange(e.target.checked)
                                }}
                                disabled={lockedValue === true ? true : false}
                              />
                            }
                            label="I acknowledge that I am responsible for arranging bank finance / loan (if required) for the purchase of the property and adhere to the payment schedule.*"
                            sx={{
                              "& .MuiTypography-root": {
                                fontSize: "16px",
                                color: "#717171",
                                lineHeight: "24px",
                              },
                              padding: "10px 0",
                            }}
                          />
                          {errors.ack_loan_responsibility && (
                            <Typography
                              sx={{ color: "#d32f2f", fontSize: "12px" }}>
                              {errors.ack_loan_responsibility.message}
                            </Typography>
                          )}
                        </Box>
                      )}
                    />
                  </Box>

                  <Box
                    sx={{
                      pt: "40px",
                      mb: "30px",
                      display: "flex",
                      justifyContent: "end",
                    }}>
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{
                        backgroundColor: "#0671E0",
                        color: "white",
                        textTransform: "capitalize",
                        fontSize: "16px",
                        lineHeight: "20px",
                        fontWeight: 400,
                        borderRadius: "4px",
                        "&:hover": {
                          backgroundColor: "#0671E0",
                        },
                        padding: "14px 32px",
                        height: "48px",
                        width: "174px",
                      }}
                      disabled={lockedValue === true ? true : false}>
                      {isLoading ? (
                        <CircularProgress
                          size={20}
                          sx={{ color: "white", marginLeft: "10px" }}
                        />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </Box>
                </Box>
              )}
            </>
          </form>
        </Box>
      )}
    </Container>
  )
}
