import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  FormGroup,
  FormLabel,
  TextField,
  Typography
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import styled from "@emotion/styled";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { legalHeirsData } from "../../../lib/query/query";
import { useForm } from "react-hook-form";
import BackButton from "../../../components/BackButton";
import {
  familyMemberData,
  familyMemberDataDelete,
  familyMemberDataUpdate,
  familyMemberOkData
} from "../../../lib/mutation/mutation";
import FamilyMemberForm from "./FamilyMemberForm";
import { errorToast, successToast } from "../../../pages/Toasts/showToasts";
import ModalCommon from "../../../components/ModalCommon";
import Steppercontext from "../../../lib/context/contextData";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";
import ErrorToast from "../../../components/ErrorToast";

export const CustomTextField = styled(TextField)({
  marginBottom: "10px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E8E8EA"
    }
  }
});

export const CustomFormLabel = styled(FormLabel)({
  fontSize: "16px",
  color: "#89939E",
  lineHeight: "1.5",
  fontWeight: 600,
  position: "relative",
  marginBottom: "5px",
  "& .MuiFormLabel-asterisk": {
    color: "red",
    fontSize: "16px",
    lineHeight: "1.5",
    fontWeight: 600
  }
});

const LastChildBox = styled(Box)({
  "&:last-child": {
    boxShadow: "0 2px 4px rgb(118 175 241)",
    opacity: 1,
    borderRadius: "10px"
  }
});

export const FamilyMembers = () => {
  // const [familyMember, setFamilyMember] = useState({});
  const [familyMembers, setFamilyMembers] = useState([{}]);
  const [formData, setFormData] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(
    Array(formData.length).fill(false)
  );
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(Array(formData.length).fill(false));
  const [isClickArray, setIsClickArray] = useState(
    Array(formData.length).fill(false)
  );

  const { salesId } = useParams();

  const { setSalesId, events, handleStatus } = useContext(Steppercontext);

  const params =
    events &&
    events.sale_transaction &&
    events.sale_transaction[0]?.sale_transaction_statuses;
  const sortedParams = params
    ?.slice()
    .sort((a, b) => a.step_index - b.step_index);
  const lockedValue = sortedParams && sortedParams[2]?.locked;
  const {
    control,
    handleSubmit,
    register,
    getValues,
    setValue,
    formState: { errors }
  } = useForm({
    mode: "onChange"
  });

  const navigate = useNavigate();
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    setSalesId(salesId);
  }, [salesId]);

  const setRefElement = (el) => {
    if (!el) return;
    scrollContainerRef.current = el;
  };

  const {
    loading,
    data,
    refetch,
    error: errorData
  } = useQuery(legalHeirsData, {
    variables: { sale_transaction_id: salesId }
  });

  const [FamilyMemberData, { error: errorMemberData }] =
    useMutation(familyMemberData);
  const [FamilyMemberDataUpdate, { error: errorMemberDataUpdate }] =
    useMutation(familyMemberDataUpdate);
  const [familyMemberDelete, { error: errorMemberDataDelete }] = useMutation(
    familyMemberDataDelete
  );
  const [familyMemberOk, { error: errorMemberOk }] =
    useMutation(familyMemberOkData);

  const handleAddMore = () => {
    setFormData((prevState) => [
      ...prevState,
      {
        full_name: "",
        relationship_buyer: "",
        age: "",
        id_type: "",
        id_number: ""
      }
    ]);
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        scroll: "smooth"
      });
    }
    if (
      familyMembers.length === 1 &&
      !familyMembers[0]?.hasOwnProperty("firstClick")
    ) {
      setFamilyMembers([...familyMembers]);
      setTimeout(() => {
        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollIntoView({
            behavior: "smooth",
            block: "end",
            scroll: "smooth"
          });
        }
      }, 0);
    } else {
      setFamilyMembers([...familyMembers, {}]);
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          scroll: "smooth"
        });
      }
    }
  };

  useEffect(() => {
    refetch();
    if (data?.legal_heir?.length > 0) {
      setFormData(data?.legal_heir);
      // setFamilyMember(data?.legal_heir[0]);
    } else if (data?.legal_heir?.length !== 0) {
      setFormData([
        {
          full_name: "",
          relationship_buyer: "",
          age: "",
          id_type: "",
          id_number: ""
        }
      ]);
    }

    if (data && data?.legal_heir?.length > 0) {
      let val = [];
      for (let i = 0; i < data.legal_heir.length; i++) {
        val.push(true);
      }
      setIsClickArray(val);
    }
  }, [data]);


  const handleDelete = async (index) => {
  const legalHeirIdToDelete = formData[index]?.legal_heir_id;

  if (legalHeirIdToDelete) {
    try {
      await familyMemberDelete({
        variables: {
          legal_heir_id: legalHeirIdToDelete,
          sale_transaction_id: salesId
        }
      }).then((response) => {
        successToast("Family Member Deleted Successfully!");
        setFormData((prevState) => {
          const updatedData = [...prevState];
          updatedData.splice(index, 1);
          return updatedData;
        });
        setIsClickArray((prevIsClickArray) => {
          const updatedIsClickArray = [...prevIsClickArray];
          updatedIsClickArray.splice(index, 1);
          return updatedIsClickArray;
        });
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  } else {
   
    setFormData((prevState) => {
      const updatedData = [...prevState];
      updatedData.splice(index, 1);
      return updatedData;
    });
  }
};



  const onSubmit = async (data, index) => {
    const newFamilyMemberData = data.familyMembers[index];
    const isDuplicate = formData.some((member, i) => {
      if (i !== index) {
        return (
          member.id_number === newFamilyMemberData.id_number &&
          member.id_type === newFamilyMemberData.id_type
        );
      }
      return false;
    });
    if (isDuplicate) {
      return errorToast("ID Number And ID Type Already Exist!");
    }

    setEditMode((prevState) => {
      const updatedData = [...prevState];
      updatedData[index] = false;
      return updatedData;
    });

    setIsClickArray((prevIsClickArray) => {
      const updatedIsClickArray = [...prevIsClickArray];
      updatedIsClickArray[index] = true;
      return updatedIsClickArray;
    });

    if (data && formData[index]?.legal_heir_id) {
      setLoadingStatus((prevState) => {
        const updatedData = [...prevState];
        updatedData[index] = true;
        return updatedData;
      });
      await FamilyMemberDataUpdate({
        variables: {
          legal_heir_id: formData[index]?.legal_heir_id,
          age: data.familyMembers[index].age,
          full_name: data.familyMembers[index].full_name,
          id_number: data.familyMembers[index].id_number,
          id_type:
            data.familyMembers[index].id_type !== ""
              ? data.familyMembers[index].id_type
              : "Passport",
          relationship_buyer: data.familyMembers[index].relationship_buyer
        }
      })
        .then((response) => {
          successToast("Family Member Updated Successfully!");
        })
        .catch((error) => {
          console.error(error);
          Sentry.captureException(error);
        });
      setLoadingStatus((prevState) => {
        const updatedData = [...prevState];
        updatedData[index] = false;
        return updatedData;
      });
    } else {
      setLoadingStatus((prevState) => {
        const updatedData = [...prevState];
        updatedData[index] = true;
        return updatedData;
      });
      await FamilyMemberData({
        variables: {
          full_name: data.familyMembers[index].full_name,
          relationship_buyer: data.familyMembers[index].relationship_buyer,
          age: data.familyMembers[index].age,
          id_type:
            data.familyMembers[index].id_type !== ""
              ? data.familyMembers[index].id_type
              : "Passport",
          id_number: data.familyMembers[index].id_number,
          sale_transaction_id: salesId
        }
      })
        .then((response) => {
          // setFamilyMember(response?.data?.insert_legal_heir?.returning[0]);
          const newFamilyMember =
            response?.data?.insert_legal_heir?.returning[0];
          if (newFamilyMember) {
            setFormData((prevState) => {
              const updatedData = [...prevState];
              updatedData[index] = newFamilyMember;
              return updatedData;
            });
          }
          successToast("Family Member Added Successfully!");
        })
        .catch((error) => {
          console.error(error);
          Sentry.captureException(error);
        });
      setLoadingStatus((prevState) => {
        const updatedData = [...prevState];
        updatedData[index] = false;
        return updatedData;
      });
    }
    await handleStatus();
  };

  const handleCheckboxChange = (event) => {
    setOpen(event.target.checked);
  };

  const handleConfirmationModal = async () => {
    await familyMemberOk({
      variables: {
        sale_transaction_id: salesId,
        step_index: 3,
        status: "Complete"
        // status: "Completed"
      }
    })
      .then((response) => {
        //response
      })
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });
    navigate(`/myProperties/allStates/${salesId}`);
  };

  const handleEditClick = (index) => {
    setEditMode((prevState) => {
      const updatedData = [...prevState];
      updatedData[index] = true;
      return updatedData;
    });
    setIsClickArray((prevIsClickArray) => {
      const updatedIsClickArray = [...prevIsClickArray];
      updatedIsClickArray[index] = false;
      return updatedIsClickArray;
    });
  };

  if (
    errorData ||
    errorMemberData ||
    errorMemberDataUpdate ||
    errorMemberDataDelete ||
    errorMemberOk
  ) {
    toast.error(
      <ErrorToast
        email={"info@msvkanha.com"}
        errorMsg={
          (errorData && errorData?.message) ||
          (errorMemberData && errorMemberData?.message) ||
          (errorMemberDataUpdate && errorMemberDataUpdate?.message) ||
          (errorMemberDataDelete && errorMemberDataDelete?.message) ||
          (errorMemberOk && errorMemberOk?.message)
        }
      />
    );
    Sentry.captureException(
      (errorData && errorData?.message) ||
        (errorMemberData && errorMemberData?.message) ||
        (errorMemberDataUpdate && errorMemberDataUpdate?.message) ||
        (errorMemberDataDelete && errorMemberDataDelete?.message) ||
        (errorMemberOk && errorMemberOk?.message)
    );
  }

  const Header = () => {
    return (
      <Box>
        <Typography
          sx={{
            lineHeight: "28px",
            fontSize: "18px",
            fontWeight: "700",
            color: "#4D4D4D"
          }}
        >
          Legal Heirs
        </Typography>
      </Box>
    );
  };
  const Body = () => {
    return (
      <Box>
        <Box>
          <Typography>
            This step has been marked as Complete. You can edit step through the
            purchase process.
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleConfirmationModal}
          >
            OK
          </Button>
        </Box>
      </Box>
    );
  };

  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh"
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Container>
      <>
        {!loading && (
          <>
            <Box>
              <BackButton handleBack={() => navigate(-1)} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "15px"
                }}
              >
                <Typography
                  sx={{
                    color: "#212121",
                    fontSize: "25px",
                    fontWeight: 600,
                    lineHeight: "30px"
                  }}
                >
                  Family members
                </Typography>
                <Button
                  variant="contained"
                  type="button"
                  sx={{
                    backgroundColor: "#0671E0",
                    fontWeight: 400,
                    color: "white",
                    textTransform: "capitalize",
                    fontSize: "14px",
                    lineHeight: "16px",
                    opacity: lockedValue === true ? 0.5 : 1
                  }}
                  onClick={() => {
                    if (lockedValue !== true) {
                      handleAddMore();
                    }
                  }}
                >
                  <AddIcon /> Add more
                </Button>
              </Box>
              <Typography
                sx={{
                  mt: 2,
                  color: "#717171",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "24px"
                }}
              >
                Our lawyers said we need these! Please provide your immediate
                family member details. Usually, this is the children,
                wife/husband and parents. In the unlikely event of the Buyer's
                passing before property registration, these family members of
                the Buyer will be contacted for refunds/property registration.
              </Typography>
              <Typography
                sx={{
                  mt: 2,
                  color: "#717171",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "24px",
                  marginBottom: "45px"
                }}
              >
                Legal: Please list all your Class 1 Heirs under the Hindu
                Succession Act, OR all the Sharers & Residuaries under Muslim
                Personal Law, whichever is applicable in your case.
              </Typography>
            </Box>
            <Box>
              <FormGroup
                sx={{
                  color: "#4D4D4D",
                  lineHeight: "28px",
                  fontSize: "18px",
                  fontWeight: "700"
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        sortedParams && sortedParams[2].status === "Completed"
                          ? true
                          : false
                      }
                      onChange={handleCheckboxChange}
                      disabled={lockedValue === true ? true : false}
                    />
                  }
                  label="I attest that I have added all my Legal Heirs. Mark this step as completed."
                />
              </FormGroup>
            </Box>
            {/* }  */}
            <ModalCommon
              open={open}
              setOpen={setOpen}
              header={<Header />}
              body={<Body />}
              width={551}
            />

            <Box ref={scrollContainerRef}>
              {formData &&
                formData?.map((member, index) => {
                  // const isDataExist = !!formData[index]?.legal_heir_id;
                  return (
                    <LastChildBox key={index}>
                      <FamilyMemberForm
                        key={index}
                        member={member}
                        index={index}
                        isLoading={loadingStatus[index]}
                        control={control}
                        errors={errors}
                        onSubmit={(data) => onSubmit(data, index)}
                        register={register}
                        handleSubmit={handleSubmit}
                        getValues={getValues}
                        setValues={setValue}
                        formData={formData[index]}
                        setRefElement={setRefElement}
                        data={formData}
                        handleDelete={(formData) =>
                          handleDelete(index, formData)
                        }
                        editMode={editMode[index]}
                        handleEditClick={(index) => handleEditClick(index)}
                        isClickArray={isClickArray[index]}
                        salesId={salesId}
                        setIsClickArray={setIsClickArray}
                      />
                    </LastChildBox>
                  );
                })}
            </Box>
          </>
        )}
      </>
    </Container>
  );
};
