import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TableData from "../../../../components/TableData";
import { AdminEventLogData } from "../../../../lib/query/query";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import moment from "moment";
import * as Sentry from "@sentry/react";

const EventLog = () => {
  const [eventLogData, setEventLogData] = useState({});
  const { salesId } = useParams();

  const { data,error } = useQuery(AdminEventLogData, {
    variables: { sale_transaction_id: salesId }
  });

  useEffect(() => {
    if (data?.event_log && data?.event_log.length > 0) {
      setEventLogData(data?.event_log);
    }
  }, [data]);

  if(error){
    Sentry.captureException(error)
  }

  const columns = [
    {
      field: "date",
      headerName: "Date",
      width: 130,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "time",
      headerName: "Time",
      width: 90,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "eventType",
      headerName: "Event Type",
      width: 200,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "eventInfo",
      headerName: "Event Info",
      width: 300,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "userName",
      headerName: "User Name",
      width: 200,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "userType",
      headerName: "User Type",
      width: 150,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "oldValue",
      headerName: "Old Value",
      width: 150,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "newValue",
      headerName: "New Value",
      width: 150,
      headerAlign: "center",
      align: "center"
    }
  ];

  const rows =
    eventLogData?.length > 0 &&
    eventLogData.map((item, index) => {
      return {
        id: index,
        saleTransactionId: item?.sale_transaction_id,
        propertyId: item?.property_id,
        eventType: item?.event_type,
        eventInfo: item?.event_description,
        date: moment(item?.created_date).format("DD/MM/YYYY"),
        time: moment(item?.created_date).local().format("H:mm A"),
        userName: item?.user_name,
        userEmail: item?.email,
        userType: item?.user_role,
        newValue: item?.new_value,
        oldValue: item?.old_value
      };
    });

  return (
    <>
      <Box>
        <Box>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "25px",
              lineHeight: "30px",
              color: "#212121",
              marginBottom: "20px"
            }}
          >
            Transaction Event Log
          </Typography>
        </Box>
        <Box>
          <TableData
            rows={rows}
            columns={columns}
            HeaderbackgroundColor={"#F5F7FA"}
            overFlow={"overFlow"}
            rowPerPage={25}
            getRowHeight={() => "auto"}
          />
        </Box>
      </Box>
    </>
  );
};

export default EventLog;
