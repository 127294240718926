import React from 'react'
import TabLayout from '../../components/Layouts/TabLayout'
import { DownloadsMyProperties } from './MyProperties/DownloadsMyProperties'

export const Download = () => {
  return (
    <TabLayout>
      {/* <div>Download</div> */}
      <DownloadsMyProperties />
    </TabLayout>
  )
}

export default Download