import * as React from "react";
import { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Container,
  FormLabel,
  Link,
  TextField,
  Typography
} from "@mui/material";
import ModalCommon from "../../../components/ModalCommon";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield"
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    }
  }
});

export function MobileVerification({ open, setOpen }) {
  const classes = useStyles();
  const [count, setCount] = useState(60);
  const [isReset, setIsReset] = useState(false);
  const [verificationCode, setVerificationCode] = useState(Array(7).fill(""));
  const [autoFocuse, setAutoFocuse] = useState(0);

  const handleClose = () => {
    setOpen(false);
  };

  const startTimer = () => {
    if (count > 0) {
      setTimeout(() => {
        setCount(count - 1);
      }, 1000);
    } else {
      setIsReset(true);
    }
  };

  useEffect(() => {
    startTimer();
    return () => {
      clearTimeout();
    };
  }, [count]);

  const handleResend = () => {
    setCount(60);
    startTimer();
    setIsReset(false);
  };

  useEffect(() => {
    if (verificationCode) {
      for (let i = 0; i < verificationCode.length; i++) {
        if (verificationCode[i] === "") {
          setAutoFocuse(i);
          break;
        }
      }
    }
  }, [verificationCode]);

  const handleCodeInputChange = (index, value) => {
    const newVerificationCode = [...verificationCode];
    newVerificationCode[index] = value;
    setVerificationCode(newVerificationCode);
  };

  const Header = () => {
    return (
      <DialogTitle
        sx={{
          p: 0
        }}
      >
        <Box
          sx={{
            height: "48px",
            width: "48px",
            border: "1px solid #E4E7EC",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <AccessAlarmIcon />
        </Box>
      </DialogTitle>
    );
  };
  const Body = () => {
    return (
      <Box>
        <Typography
          sx={{
            color: "#101828",
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "28px"
          }}
        >
          Verify your number {count} sec..
        </Typography>
        <Typography
          sx={{ color: "#475467", fontWeight: 400, fontSize: "14px" }}
        >
          Enter the code sent to the mobile number you provided.
        </Typography>

        <Box
          sx={{
            paddingTop: "18px"
          }}
        >
          <FormLabel
            sx={{
              color: "#344054",
              fontSize: "14px",
              fontWeight: "500"
            }}
          >
            Verification Code
          </FormLabel>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: "2px"
            }}
          >

            {Array.from({ length: 6 }).map((_, index) => (
              <TextField
                key={index}
                id={`outlined-basic-${index}`}
                label=""
                variant="outlined"
                sx={{
                  height: "64px",
                  width: "64px",
                  "& .MuiInputBase-formControl": {
                    borderRadius: "8px",
                  },
                }}
                className={classes.input}
                type="number"
                inputProps={{
                  inputProps: { min: 0 },
                  maxLength: 1,
                  inputMode: "numeric",
                  style: {
                    textAlign: "center",
                    fontSize: "48px",
                    color: "#D0D5DD",
                    fontWeight: "500",
                    padding: "0px",
                  },
                  onInput: (e) => {
                    e.target.value = e.target.value.slice(0, 1);
                  },
                }}
                value={verificationCode[index]}
                onChange={(e) => handleCodeInputChange(index, e.target.value)}
                autoFocus={autoFocuse === index ? true : false}
              />
            ))}
          
          </Box>
          {isReset && (
            <Typography
              sx={{
                paddingTop: "8px",
                color: "#757575",
                fontSize: "14px"
              }}
            >
              Didn’t get a code?{" "}
              <span>
                {" "}
                <Link
                  href="#"
                  sx={{
                    color: "#757575",
                    textDecorationColor: "#757575"
                  }}
                  onClick={handleResend}
                >
                  {" "}
                  Click to resend.
                </Link>{" "}
              </span>
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            marginTop: "35px"
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "white",
              color: "black",
              textTransform: "capitalize",
              fontSize: "18px",
              padding: "9px 38px",

              width: "48%",
              border: "1px solid #D0D5DD",
              borderRadius: "4px",

              "&:hover": {
                backgroundColor: "white"
              },

              fontWeight: 400,

              boxShadow: "none"
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#0671E0",
              color: "white",
              textTransform: "capitalize",
              fontSize: "18px",
              padding: "9px 38px",
              marginLeft: "10px",
              borderRadius: "4px",
              width: "48%",
              "&:hover": {
                backgroundColor: "#0671E0"
              },

              fontWeight: 400
            }}
            onClick={handleClose}
          >
            Submit
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <Container>
      <ModalCommon
        open={open}
        setOpen={setOpen}
        closeModal={handleClose}
        header={<Header />}
        body={<Body />}
        height={396}
        width={512}
        justifyContent={"space-between"}
      />
    </Container>
  );
}
