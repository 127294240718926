import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import { VerticalChart } from "../../../../components/VerticalChart";
import {
  OverviewCity,
  OverviewCityTower,
  OverviewProject,
  OverviewTower
} from "../../../../lib/query/query";
import { useLazyQuery, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";
import ErrorToast from "../../../../components/ErrorToast";

export const OverviewChart = () => {
  const [city, setCity] = useState("");
  const [project, setProject] = useState("");
  const [cityData, setCityData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [chartData, setChartData] = useState([]);
  const [towerData, setTowerData] = useState([]);

  const { data, error } = useQuery(OverviewCity);

  useEffect(() => {
    if (data && data.project.length > 0) {
      setCityData(data && data.project);
    }
  }, [data]);

  const [getProjectList, { data: dataProject, error: errorProjectList }] =
    useLazyQuery(OverviewProject, {
      variables: { city: city }
    });

  useEffect(() => {
    if (dataProject && dataProject.project.length > 0) {
      setProjectData(dataProject && dataProject.project);
    }
  }, [dataProject]);
  useEffect(() => {
    if (city) {
      getProjectList();
    }
  }, [city]);

  const [getTowerList, { data: dataTower, error: errorTower }] = useLazyQuery(
    OverviewTower,
    {
      variables: { city: city, project_name: projectName }
    }
  );

  useEffect(() => {
    if (dataTower && dataTower.v_amount_summary_by_building.length > 0) {
      setChartData(dataTower && dataTower.v_amount_summary_by_building);
    }
  }, [dataTower]);

  useEffect(() => {
    if ((city, project)) {
      getTowerList();
    }
  }, [city, project]);

  const [getTowerLists] = useLazyQuery(OverviewCityTower);
  useEffect(() => {
    if (chartData.length > 0) {
      const modalIdToFetch = chartData.map(
        async (item) =>
          await getTowerLists({
            variables: {
              city: city,
              project_name: projectName,
              building_id: item.building_id
            }
          })
      );
      Promise.all(modalIdToFetch)
        .then((results) => {
          setTowerData(results);
        })
        .catch((err) => {
          Sentry.captureException(err);
          console.error("Error while fetching data: ", err);
        });
    }
  }, [chartData, getTowerLists]);

  const handleChange = (event) => {
    setCity(event.target.value);
  };
  const handleChangeProject = (event) => {
    setProject(event.target.value);
    const selectedProjectId = event.target.value;
    const selectedProject = projectData.find(
      (item) => item.project_id === selectedProjectId
    );
    setProjectName(selectedProject.project_name);
  };
  const totalAmountArray = chartData.map((item) => item.total_amount);

  const totalDueAmountArray = chartData.map((item) => item.total_due);
  const totalReceived = chartData.map((item) => item.total_received);

  let towerLabel = [];
  towerData.map((item) => {
    towerLabel.push(item.data);
  });

  const flattenedArray = towerLabel.flatMap(
    (item) => item.v_installment_status
  );

  if (error || errorProjectList || errorTower) {
    toast.error(
      <ErrorToast
        email={"info@msvkanha.com"}
        errorMsg={
          (error && error.message) ||
          (errorProjectList && errorProjectList.message) ||
          (errorTower && errorTower.message)
        }
      />
    );
    Sentry.captureException(
      (error && error.message) ||
        (errorProjectList && errorProjectList.message) ||
        (errorTower && errorTower.message)
    );
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6} md={5}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Box>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    value={city}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{ color: "inherit" }}
                  >
                    <MenuItem selected disabled value="">
                      Select City
                    </MenuItem>
                    {cityData.length > 0 &&
                      cityData.map((item) => {
                        return (
                          <MenuItem value={item.city}>{item.city}</MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6} md={6}>
              <Box>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    value={project}
                    onChange={handleChangeProject}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{ color: "inherit" }}
                  >
                    <MenuItem selected disabled value="">
                      Select Project
                    </MenuItem>
                    {projectData.length > 0 &&
                      projectData.map((item) => {
                        return (
                          <MenuItem value={item.project_id}>
                            {item.project_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} md={7}>
          <Grid container>
            <Grid item xs={6} md={3}>
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#0671E0",
                    color: "white",
                    fontWeight: 400,
                    lineHeight: "24px",
                    textTransform: "capitalize",
                    fontSize: "18px",
                    width: "164px",
                    height: "56px",
                    padding: "16px, 32px, 16px, 32px",
                    gap: "8px",
                    borderRadius: "4px",
                    "&:hover": {
                      backgrindColor: "#0671E0"
                    }
                  }}
                >
                  Update
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {city && project ? (
        <Box
          sx={{
            border: "1px solid #E8E8EA",
            margin: "10px",
            padding: "10px",
            borderRadius: "10px"
          }}
        >
          <VerticalChart
            totalAmountArray={totalAmountArray}
            chartData={chartData}
            totalDueAmountArray={totalDueAmountArray}
            totalReceived={totalReceived}
            flattenedArray={flattenedArray}
            towerLabel={towerLabel}
          />
        </Box>
      ) : (
        <Typography
          sx={{
            textAlign: "center",
            marginTop: "50px"
          }}
        >
          Select City And Project To Retrieve Data
        </Typography>
      )}
    </Box>
  );
};
