import Header from "../components/Header";
import Toaster from "../pages/Toasts/index"
import 'react-toastify/dist/ReactToastify.css';

const PageWrapper = ({ children }) => {
    return (
        <>
            <Header />
            <Toaster/>
            {children}
        </>
    );
};
export default PageWrapper;