import { Box, Container, Grid, Typography, Button, Badge } from "@mui/material";
import React, { useEffect } from "react";
import PropertyDetails from "./PropertyDetails";
import AdminVerticalStepper from "../../../components/AdminVerticalStepper";
import BuyerProfileTabs from "./BuyerProfileTabs";
import BuyerProfileInfo from "../../../components/buyerProfileInfo/BuyerProfileInfo";
import { useQuery } from "@apollo/client";
import { buyerProfileData, saleTransactionStatus } from "../../../lib/query/query";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import { successToast } from "../../../pages/Toasts/showToasts";
import { toast } from "react-toastify";
import ErrorToast from "../../../components/ErrorToast";
import * as Sentry from "@sentry/react";

const BuyerProfile = () => {
  const { salesId } = useParams();
  const navigate = useNavigate();

  const [buyerInfo, setBuyerInfo] = React.useState({});
  const { data, error, refetch } = useQuery(buyerProfileData, {
    variables: { sale_transaction_id: salesId }
  });
  const { data: transactionStatus, refetch: statusRefetch,error:errorTransactionStatus } = useQuery(saleTransactionStatus, {
    variables: { sale_transaction_id: salesId }
  });
  const Status = transactionStatus?.sale_transaction[0].status;
  const isCancelledStatus = Status === "Cancellation Requested" || Status === "Cancelled";

  useEffect(() => {
    if (data) {
      setBuyerInfo(data.sale_transaction[0]);
    }
  }, [data]);

  if (error || errorTransactionStatus) {
    toast.error(
      <ErrorToast
        email={"info@msvkanha.com"}
        errorMsg={(error && error?.message) || (errorTransactionStatus && errorTransactionStatus?.message)}
      />
    );
    Sentry.captureException((error && error?.message) || (errorTransactionStatus && errorTransactionStatus?.message));
  }

  const handleRefresh = () => {
    refetch();
    successToast("Sale transaction status timeline refreshed successfully");
  };

  if (!salesId) {
    return (
      <>
        <Container maxWidth="xl">
          <Box
            sx={{
              paddingLeft: "16px",
              display: "flex",
              gap: 3,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100vh - 250px)"
            }}
          >
            <Box>sale_transaction_id is not exist</Box>
            <Button
              onClick={() => navigate("/admin/dashboard")}
              sx={{
                bgcolor: "#0671E0",
                color: "white",
                textTransform: "capitalize",
                width: "161px",
                height: "48px",
                borderRadius: "4px",
                "&:hover": {
                  bgcolor: "#0671E0",
                  color: "white"
                }
              }}
            >
              Go to Dashboard
            </Button>
          </Box>
        </Container>
      </>
    );
  }

  return (
    <Container maxWidth="xl">
      <Box sx={{ paddingLeft: "16px" }}>
        <BackButton handleBack={() => navigate(-1)} />
      </Box>
      <Box sx={{ padding: "16px" }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "25px",
              lineHeight: "30px",
              color: "#212121",
              mr: 1
            }}
          >
            Transaction Details
          </Typography>
          <Typography sx={{ color: "grey" }}>
            (Sale Transaction ID # {salesId})
          </Typography>
          <Typography sx={{color: "#C33025", fontWeight: "bold", textTransform: "uppercase", "ml":1,}}>
            {isCancelledStatus && Status}
          </Typography>
          <hr style={{ margin: "10px 0", color: "#E8E8EA" }} />
        </Box>
        <BuyerProfileInfo buyerInfo={buyerInfo} />

        <Box sx={{ py: "40px" }}>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "25px",
                lineHeight: "30px",
                color: "#4D4D4D"
              }}
            >
              Property Details
            </Typography>
            <hr style={{ margin: "10px 0", color: "#E8E8EA" }} />
          </Box>
          <Box>
            <Grid container spacing={2}>
              {/* <Grid
                item
                xs={8}
                sx={{
                  opacity: buyerInfo && buyerInfo?.property === null ? 0.5 : 1
                }}
              > */}
                <PropertyDetails buyerInfo={buyerInfo} Status={Status} isCancelledStatus={isCancelledStatus} statusRefetch={statusRefetch}/>
              {/* </Grid> */}
              <Grid item xs={4}>
                <Box
                  sx={{
                    border: "1px solid #E8E8EA",
                    borderRadius: "4px",
                    my: 5
                  }}
                >
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}>
                    <Typography
                      sx={{
                        color: "#89939E",
                        fontSize: "16px",
                        fontWeight: 600,
                        padding: "20px",
                        display: "flex",
                      }}
                    >
                      Progress Steps
                      {isCancelledStatus &&
                        <Badge
                          sx={{
                            fontSize: 12,
                            backgroundColor: "#FFF1F0",
                            color: "#C33025",
                            width: "195px",
                            height: "30px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "10px",
                            textTransform: "uppercase",
                            ml:2,
                            fontWeight: 400,
                            lineHeight: "20px",
                            gap: 10,
                          }}
                        >
                          {Status}
                        </Badge>
                      }
                    </Typography>

                    <Box sx={{ pr: 2 }}>
                      <RefreshIcon
                        sx={{ cursor: "pointer" }}
                        onClick={handleRefresh}
                      />
                    </Box>
                  </Box>
                  <AdminVerticalStepper buyerInfo={buyerInfo} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box>
        <BuyerProfileTabs buyerInfo={buyerInfo} data={data} />
      </Box>
    </Container>
  );
};

export default BuyerProfile;
