import React, { useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import {
  Add_Customer_Sales_Id,
  Add_New_Mutation,
  Add_New_Mutation1,
  Add_New_Mutation2,
  Add_New_Mutation3,
  Add_Stepper_Status_Id
} from "../../../lib/mutation/mutation";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";
import ErrorToast from "../../../components/ErrorToast";

export const EmptyProperties = () => {
  const navigate = useNavigate();

  const [handleAddMutation, { data: addData, error: errorAddData }] =
    useMutation(Add_Customer_Sales_Id);

  const [hadleUpdateMutation, { error: errorUpdate }] = useMutation(
    Add_Stepper_Status_Id
  );

  const [handleNewMutation1, { error: errorUpdate1 }] =
    useMutation(Add_New_Mutation1);

  const [handleNewMutation, { error: errorNew }] =
    useMutation(Add_New_Mutation);

  const [handleNewMutation2, { error: errorNew2 }] =
    useMutation(Add_New_Mutation2);

  const [handleNewMutation3, { error: errorNew3 }] =
    useMutation(Add_New_Mutation3);

  const handleQueryCall = async (saleId) => {
    await hadleUpdateMutation({
      variables: {
        process_step: "Verify Your ID (KYC)",
        sale_transaction_id: saleId,
        status: "Yet to Start",
        step_index: 1,
        locked: false
      }
    })
      .then((response) => {
        //response
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });

    await handleNewMutation({
      variables: {
        process_step: "Book Property",
        sale_transaction_id: saleId,
        // status: "Locked",
        status: "Yet to Start",
        step_index: 2,
        locked: true
      }
    })
      .then((response) => {
        //response
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });

    await handleNewMutation1({
      variables: {
        process_step: "Family Members",
        sale_transaction_id: saleId,
        status: "Yet to Start",
        step_index: 3,
        locked: true
      }
    })
      .then((response) => {
        //response
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });

    await handleNewMutation2({
      variables: {
        process_step: "Complete Sale Agreement",
        sale_transaction_id: saleId,
        status: "Yet to Start",
        step_index: 4,
        locked: true
      }
    })
      .then((response) => {
        //response
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });

    await handleNewMutation3({
      variables: {
        process_step: "Property Registration",
        sale_transaction_id: saleId,
        // status: "Locked",
        status: "Yet to Start",
        step_index: 5,
        locked: true
      }
    })
      .then((response) => {
        //response
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });

    navigate(`/myProperties/allStates/${saleId}`);
  };

  useEffect(() => {
    const saleId =
      addData?.insert_sale_transaction?.returning[0]?.sale_transaction_id;
    if (saleId) {
      handleQueryCall(saleId);
    }
  }, [addData]);

  const handleBuyNew = async () => {
    await handleAddMutation({ variables: { status: "In Progress" } })
      .then((response) => {
        //response
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  if (
    errorAddData ||
    errorUpdate ||
    errorUpdate1 ||
    errorNew ||
    errorNew2 ||
    errorNew3
  ) {
    toast.error(
      <ErrorToast
        email={"info@msvkanha.com"}
        errorMsg={
          (errorAddData && errorAddData?.message) ||
          (errorUpdate && errorUpdate?.message) ||
          (errorUpdate1 && errorUpdate1?.message) ||
          (errorNew && errorNew?.message) ||
          (errorNew2 && errorNew2?.message) ||
          (errorNew3 && errorNew3?.message)
        }
      />
    );
    Sentry.captureException(
      (errorAddData && errorAddData?.message) ||
        (errorUpdate && errorUpdate?.message) ||
        (errorUpdate1 && errorUpdate1?.message) ||
        (errorNew && errorNew?.message) ||
        (errorNew2 && errorNew2?.message) ||
        (errorNew3 && errorNew3?.message)
    );
  }
  return (
    <Box sx={{ border: "1px solid #E8E8EA", borderRadius: "10px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          alignItems: "center",

          height: "292px",
          flexDirection: "column"
        }}
      >
        <Box>
          <Typography
            sx={{ fontSize: "25px", fontWeight: 600, lineHeight: "30px" }}
          >
            No Properties, Yet!
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 400,
              color: "#717171",

              lineHeight: "24px",
              margin: "17px 0 23px 0"
            }}
          >
            By properties and then you will be able to review it here.
          </Typography>
        </Box>
        <Box>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#0671E0",
              color: "white",
              textTransform: "capitalize",
              fontSize: "18px",
              width: "143px",
              height: "56px",
              fontWeight: 400,
              lineHeight: "24px"
            }}
            onClick={handleBuyNew}
          >
            {" "}
            Buy New
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
