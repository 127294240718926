import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Badge,
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import IdProof from "./IdProof";
import {
  IdVerificationReject,
  approveStatus,
  idVerificationStatus,
  saleTransactionStatus
} from "../../../lib/mutation/mutation";
import ModalCommon from "../../../components/ModalCommon";
import {
  IdentityProofStatus,
  buyerProfileData
} from "../../../lib/query/query";
import moment from "moment";
import CheckIcon from "@mui/icons-material/Check";
import { useParams } from "react-router-dom";
import { errorToast } from "../../../pages/Toasts/showToasts";

import { toast } from "react-toastify";
import ErrorToast from "../../../components/ErrorToast";
import * as Sentry from "@sentry/react";
import Steppercontext from "../../../lib/context/contextData";

const IdentityProofBuyerProfile = ({ buyerInfo }) => {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [comment, setComment] = useState("");
  const [isValidComment, setIsValidComment] = useState(false);

  const { userData } = useContext(Steppercontext);
  
  const inputRef = useRef();

  const buyerIdDocs = buyerInfo && buyerInfo?.buyers[0]?.buyer_identity_docs;

  const buyer_id = buyerInfo?.buyers[0]?.buyer_id;

  const [getBuyerId, { error, data, refetch }] =
    useLazyQuery(IdentityProofStatus);

  useEffect(() => {
    if (buyer_id) {
      getBuyerId({ variables: { buyer_id: buyer_id } });
    }
  }, [buyer_id]);

  const [Sale_Transaction_Status, { error: errorStatus }] = useMutation(
    saleTransactionStatus
  );
  const [ApproveStatus, { data: dataApprove, error: errorApprove }] =
    useMutation(approveStatus);
  const [id_Verification_Status, { error: errorIdStatus }] =
    useMutation(idVerificationStatus);
  const [id_Verification_Reject, { data: dataReject, error: errorReject }] =
    useMutation(IdVerificationReject);

  const kycVerificationDate = moment();
  const formattedDate = kycVerificationDate.format("MMMM D, YYYY");
  const { salesId } = useParams();

  useEffect(() => {
    if (data && data?.buyer?.length > 0) {
      setStatus(data && data?.buyer[0]?.kyc_verification_status);
    }
  }, [data]);

  useEffect(() => {
    if (dataApprove) {
      refetch();
    }
  }, [dataApprove]);

  useEffect(() => {
    if (dataReject) {
      refetch();
    }
  }, [dataReject]);

  const handleClose = () => {
    setOpen(false);
    setComment("");
  };

  const { refetch: sale_trans_update, data: saleTransacitonData } = useQuery(
    buyerProfileData,
    {
      variables: { sale_transaction_id: salesId }
    }
  );

  const Status =
    saleTransacitonData?.sale_transaction[0]?.sale_transaction_statuses[0];
  const isStatus =
    Status?.step_index === 1 && Status?.status === "Pending Validation";

  const handleApproveStatus = async () => {
    refetch();

    await ApproveStatus({
      variables: {
        buyer_id: buyerInfo?.buyers[0].buyer_id,
        kyc_verification_date: kycVerificationDate.format("YYYY-MM-DD"),
        kyc_verification_staff:
          userData?.users[0]?.first_name +
          " " +
          userData?.users[0]?.last_name,
        kyc_verification_status: "Approved"
      }
    })
      .then((response) => {})
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });

    await id_Verification_Status({
      variables: {
        sale_transaction_status_id: salesId,
        status: "Action Required"
      }
    })
      .then((response) => {})
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });

    await Sale_Transaction_Status({
      variables: {
        sale_transaction_id: salesId,
        step_index: 1,
        status: "Complete"
      }
    })
      .then((response) => {
        sale_trans_update();
      })
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
        errorToast("Something went wrong!");
      });
  };

  const handleIdProofReject = async () => {
    refetch();
    await id_Verification_Reject({
      variables: {
        buyer_id: buyerInfo?.buyers[0].buyer_id,
        kyc_verification_comment: inputRef?.current?.value,
        kyc_verification_date: kycVerificationDate.format("YYYY-MM-DD"),
        kyc_verification_staff:
          userData?.users[0]?.first_name +
          " " +
          userData?.users[0]?.last_name,
        kyc_verification_status: "Rejected"
      }
    })
      .then((response) => {})
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });

    await Sale_Transaction_Status({
      variables: {
        sale_transaction_id: salesId,
        step_index: 1,
        status: "Action Required"
      }
    })
      .then((response) => {
        sale_trans_update();
      })
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
        errorToast("Something went wrong!");
      });

    await handleClose();
  };

  let addData = buyerIdDocs?.map((item, index) => ({
    ...item
  }));

  const buyerData = buyerInfo?.buyers[0];

  const IdName =
    buyerData?.citizenship === "Indian"
      ? "Aadhaar Card"
      : buyerData?.citizenship === "NRI"
      ? "Passport"
      : "Passport";

  // const cardName =
  //   buyerData?.residency_type === "Indian"
  //     ? "PAN Card Number (optional)"
  //     : buyerData?.residency_type === "NRI"
  //     ? "Aadhaar Number (optional)"
  //     : "OCI Card";

  const cardName =
    buyerData?.citizenship === ""
      ? buyerData?.citizenship === "Indian"
        ? "PAN Card Number"
        : buyerData?.citizenship === "NRI"
        ? "Aadhaar Number (optional)"
        : "OCI Card"
      : buyerData?.citizenship === "Indian"
      ? "PAN Card Number (optional)"
      : buyerData?.citizenship === "NRI"
      ? "Aadhaar Number"
      : "OCI Card ";

  if (
    error ||
    errorApprove ||
    errorReject ||
    errorIdStatus ||
    errorStatus
  ) {
    toast.error(
      <ErrorToast
        email={"info@msvkanha.com"}
        errorMsg={
          (error && error?.message) ||
          (errorApprove && errorApprove?.message) ||
          (errorReject && errorReject?.message) ||
          (errorIdStatus && errorIdStatus?.message) ||
          (errorStatus && errorStatus?.message)
        }
      />
    );
    Sentry.captureException(
      (error && error?.message) ||
        (errorApprove && errorApprove?.message) ||
        (errorReject && errorReject?.message) ||
        (errorIdStatus && errorIdStatus?.message) ||
        (errorStatus && errorStatus?.message)
    );
  }

  const Header = () => {
    return (
      <Box
        sx={{
          justifyContent: "center",
          textAlign: "center",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "30px",
            color: "#212121"
          }}
        >
          Reject with comment
        </Typography>
      </Box>
    );
  };

  const Body = () => {
    return (
      <>
        <Box sx={{ padding: "30px 0" }}>
          <FormControl sx={{ width: "100%" }}>
            <TextField
              inputRef={inputRef}
              placeholder="Please provide rejection reason, clearly explaining which document is not acceptable and why. (50 Character minimum)."
              multiline
              rows={3}
              maxRows={4}
              fullWidth
              value={comment}
              onChange={(e) => {
                const inputText = e.target.value;
                setComment(e.target.value);
                setIsValidComment(inputText.length >= 50);
              }}
            />
          </FormControl>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "40px"
            }}
          >
            <Box
              sx={{
                paddingTop: "20px",
                borderRadius: "3px",
                marginBottom: "30px"
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Typography
                  sx={{
                    color: "#212121",
                    fontWeight: 600,
                    fontSize: "16px",
                    lineHeight: "24px"
                  }}
                >
                  Reviewed By:
                </Typography>
                <Typography
                  sx={{
                    color: "#4D4D4D",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "24px",
                    px: 0.5
                  }}
                >
                  {userData?.users[0].first_name +
                    " " +
                    userData?.users[0].last_name}
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Typography
                  sx={{
                    color: "#212121",
                    fontWeight: 600,
                    fontSize: "16px",
                    lineHeight: "24px"
                  }}
                >
                  Review Date:{" "}
                </Typography>
                <Typography
                  sx={{
                    color: "#4D4D4D",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "24px",
                    px: 0.5
                  }}
                >
                  {" "}
                  {formattedDate}
                </Typography>
              </Box>
            </Box>

            <Button
              variant="contained"
              sx={{
                backgroundColor: "#E02B1D",
                color: "white",
                textTransform: "capitalize",
                fontSize: "16px",
                padding: "9px 38px",
                float: "right",
                "&:hover": {
                  backgroundColor: "#E02B1D"
                },
                width: "250px",
                height: "48px",
                fontWeight: 400,
                marginTop: "20px",
                lineHeight: "24px"
              }}
              onClick={handleIdProofReject}
              disabled={!isValidComment}
            >
              Reject with comments
            </Button>
          </Box>
        </Box>
      </>
    );
  };

  const kycVerificationApprovedate = data?.buyer[0]?.kyc_verification_date;

  const formattedDateApprove = moment(kycVerificationApprovedate).format(
    "MMMM DD, YYYY"
  );

  return (
    <>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex" }}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "25px",
                lineHeight: "30px",
                color: "#212121",
                marginBottom: "22px"
              }}
            >
              Identity Proof
            </Typography>
            <Badge
              sx={{
                fontSize: 12,
                backgroundColor:
                  status === "unverified"
                    ? "#DBEDFF"
                    : status === "Rejected"
                    ? "#FFE8E5"
                    : status === "Approved"
                    ? "#E3FCEF"
                    : "#DBEDFF",
                color:
                  status === "unverified"
                    ? "#0053AD"
                    : status === "Rejected"
                    ? "#E02B1D"
                    : status === "Approved"
                    ? "#1E8101"
                    : "#0053AD",
                width: "132px",
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "10px",
                marginLeft: "10px",
                lineHeight: "20px"
              }}
            >
              {status === "unverified"
                ? "Pending review"
                : status === "Rejected"
                ? "Rejected"
                : status === "Approved"
                ? "Approved"
                : "Pending review"}
            </Badge>
          </Box>

          {status === "Rejected" ? (
            <Box>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: isStatus ? "white" : "#EEF5FC",
                  color: isStatus ? "#E02B1D" : "#ABBED1",
                  textTransform: "capitalize",
                  fontSize: "16px",
                  width: "264px",
                  height: "48px",
                  padding: "6px 8px !important",
                  "&:hover": {
                    backgroundColor: isStatus ? "white" : "#EEF5FC"
                  },
                  fontWeight: 400,
                  border: "1px solid #C8C8C84D",
                  boxShadow: "none",
                  marginRight: "20px"
                }}
                onClick={() => setOpen(isStatus ? true : false)}
              >
                Reject with comments
              </Button>
              <Button
                type="submit"
                sx={{
                  bgcolor: "#0671E0",
                  color: "white",
                  textTransform: "capitalize",
                  width: "161px",
                  height: "48px",
                  borderRadius: "4px",
                  "&:hover": {
                    bgcolor: "#0671E0",
                    color: "white"
                  }
                }}
                onClick={handleApproveStatus}
              >
                Approve
              </Button>
            </Box>
          ) : status === "Approved" ? (
            <Box>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "white",
                  color: "#E02B1D",
                  textTransform: "capitalize",
                  fontSize: "16px",
                  width: "264px",
                  height: "48px",
                  padding: "6px 8px !important",
                  "&:hover": {
                    backgroundColor: "white"
                  },
                  fontWeight: 400,
                  border: "1px solid #717171",
                  boxShadow: "none",
                  marginRight: "20px"
                }}
                onClick={() => setOpen(true)}
              >
                Reject with comments
              </Button>
              <Button
                type="submit"
                sx={{
                  bgcolor: "#0671E0",
                  color: "white",
                  textTransform: "capitalize",
                  width: "161px",
                  height: "48px",
                  borderRadius: "4px",
                  "&:hover": {
                    bgcolor: "#0671E0",
                    color: "white"
                  }
                }}
                onClick={handleApproveStatus}
              >
                Approve
                <CheckIcon />
              </Button>
            </Box>
          ) : (
            <Box>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "white",
                  color: "#E02B1D",
                  textTransform: "capitalize",
                  fontSize: "16px",
                  width: "264px",
                  height: "48px",
                  padding: "6px 8px !important",
                  "&:hover": {
                    backgroundColor: "white"
                  },
                  fontWeight: 400,
                  border: "1px solid #717171",
                  boxShadow: "none",
                  marginRight: "20px"
                }}
                onClick={() => setOpen(true)}
              >
                Reject with comments
              </Button>
              <Button
                type="submit"
                sx={{
                  bgcolor: "#0671E0",
                  color: "white",
                  textTransform: "capitalize",
                  width: "161px",
                  height: "48px",
                  borderRadius: "4px",
                  "&:hover": {
                    bgcolor: "#0671E0",
                    color: "white"
                  }
                }}
                onClick={handleApproveStatus}
              >
                Approve
              </Button>
            </Box>
          )}
        </Box>

        <Box sx={{ display: "flex", marginBottom: "30px" }}>
          <Typography
            sx={{
              color: "#212121",
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "24px"
            }}
          >
            Required IDs:
          </Typography>
          <Typography
            sx={{
              color: "#4D4D4D",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              display: "flex"
            }}
          >
            <Typography sx={{ pl: 0.5 }}>
              {""}
              {IdName} <span style={{ color: "red" }}>(Mandatory)</span>
            </Typography>
            <Typography sx={{ px: 0.5 }}>and</Typography>
            <Typography sx={{ display: "flex" }}>
              {" "}
              {cardName}{" "}
              {cardName === "OCI Card " && (
                <span>
                  <Typography sx={{ color: "red", px: 0.5 }}>
                    (Mandatory)
                  </Typography>
                </span>
              )}
            </Typography>
          </Typography>
        </Box>
        {status === "Rejected" ? (
          <Box
            sx={{
              backgroundColor: "#FFF1F0",
              padding: "20px",
              borderRadius: "2px",
              marginBottom: "30px"
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Typography
                sx={{
                  color: "#4D4D4D",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "24px"
                }}
              >
                Reviewed By:
              </Typography>
              <Typography
                sx={{
                  color: "#212121",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "24px",
                  px: 0.5
                }}
              >
                {data?.buyer[0]?.kyc_verification_staff}
              </Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Typography
                sx={{
                  color: "#4D4D4D",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "24px"
                }}
              >
                Review Date:{" "}
              </Typography>
              <Typography
                sx={{
                  color: "#212121",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "24px",
                  px: 0.5
                }}
              >
                {" "}
                {formattedDateApprove}
              </Typography>
            </Box>
            <Box>
              <Box sx={{ display: "flex" }}>
                <Typography
                  sx={{
                    color: "#4D4D4D",
                    fontWeight: 600,
                    fontSize: "16px",
                    lineHeight: "24px",

                    width: "100%",
                    maxWidth: "143px"
                  }}
                >
                  Review Comment:{" "}
                </Typography>
                <Typography
                  sx={{
                    color: "#212121",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "24px",
                    px: 0.5,

                    wordBreak: "break-word"
                  }}
                >
                  {" "}
                  {data?.buyer[0]?.kyc_verification_comment
                    ? ` ${data?.buyer[0]?.kyc_verification_comment}`
                    : "No comments given"}
                </Typography>
              </Box>
            </Box>
          </Box>
        ) : status === "Approved" ? (
          <Box
            sx={{
              backgroundColor: "#F1FBF8",
              padding: "20px",
              borderRadius: "3px",
              marginBottom: "30px"
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Typography
                sx={{
                  color: "#4D4D4D",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "24px"
                }}
              >
                Reviewed By:
              </Typography>
              <Typography
                sx={{
                  color: "#212121",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "24px",
                  px: 0.5
                }}
              >
                {data?.buyer[0]?.kyc_verification_staff}
              </Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Typography
                sx={{
                  color: "#4D4D4D",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "24px"
                }}
              >
                Review Date:{" "}
              </Typography>
              <Typography
                sx={{
                  color: "#212121",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "24px",
                  px: 0.5
                }}
              >
                {" "}
                {formattedDateApprove}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box></Box>
        )}
      </Box>
      <Grid container spacing={2}>
        {addData
          ?.sort((a, b) => new Date(b.created_date) - new Date(a.created_date))
          .map((item, index) => {
            return (
              <Grid item xs={6} key={index}>
                <IdProof item={item} index={index} status={status} />
              </Grid>
            );
          })}
      </Grid>

      <ModalCommon
        open={open}
        setOpen={setOpen}
        header={<Header />}
        body={Body()}
        height={423}
        width={630}
        justifyContent={"center"}
      />
    </>
  );
};

export default IdentityProofBuyerProfile;
